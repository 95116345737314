import { WordCloud2 } from "Components/WordCloud/WordCloud2";
import { wordCloudDataFormatter } from "Routes/Insights/components/CreateInsights/ChartContainer";

export const ChartSwitcher = props=>{
    // debugger;
    const chart = props.chart;
    const chartType = chart.chartType;

    
    // {text: 'Ceo', key: 'ceo', value: 14564, type: '3. Top N KeyPhrases', parent: undefined}
    if (chartType=='KEY_PHRASE_CLOUD'){
        // debugger;
        const mappedItems = wordCloudDataFormatter(chart.dataItems);
        return <WordCloud2 isForDashboard={true} items={mappedItems} />
    }

    return null;
}