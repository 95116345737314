import { useEffect, useState } from "react";
import style from "./style.module.scss";
import { InputNumber } from "antd";
import ButtonElement from "Components/ButtonElement";
import { LoadingOutlined } from "@ant-design/icons";
import { notify } from "Components/Notification";

export const LinesCountSettings2 = props=>{

    const viewLineCountSettings = props.viewLineCountSettings || {};

    const [isLoading, setIsLoading] = useState(false);

    const [lineCount_2x2, setLineCount_2x2] = useState(viewLineCountSettings.lineCount_2x2 || 4);
    const [lineCount_3x4, setLineCount_3x4] = useState(viewLineCountSettings.lineCount_3x4 || 4);
    const [lineCount_ListView, setLineCount_ListView] = useState(viewLineCountSettings.lineCount_ListView || 4);

    useEffect(()=>{
        setLineCount_2x2(props.viewLineCountSettings.lineCount_2x2);
        setLineCount_3x4(props.viewLineCountSettings.lineCount_3x4);
        setLineCount_ListView(props.viewLineCountSettings.lineCount_ListView);
    }, [props.viewLineCountSettings]);

    const saveLineCountSettings = ()=>{
        setIsLoading(true);

        const payload = {
            lineCount_2x2, 
            lineCount_3x4, 
            lineCount_ListView
        };

        props.updateUserLinecountSettings(payload, 
        (success)=>{
            if (success){
                notify.success('Settings updated', true);
            }
            else{
                notify.error('Settings update failed', true);
            }
            setIsLoading(false);
        });
    };

    return (<>    

    <h4 style={{marginTop: '20px'}}>Lines of content in search results</h4>
    {isLoading ? <LoadingOutlined/> : null}
    <ul className={style.preferenceSettings__list}>
        <li style={{display: 'flex', flexDirection: 'row'}}>
            <div style={{minWidth: '80px', display: 'flex'}}>
                <label style={{margin: 'auto 0'}}>2x2</label>
            </div>
        
        <InputNumber 
            disabled={isLoading}
            onChange={value=>setLineCount_2x2(value)}
            value={lineCount_2x2} 
            min={1} max={20} 
        />
        </li>
        <li style={{display: 'flex', flexDirection: 'row'}}>
            <div style={{minWidth: '80px', display: 'flex'}}>
                <label style={{margin: 'auto 0'}}>3x4</label>
            </div>
        
        <InputNumber
            disabled={isLoading} 
            onChange={value=>setLineCount_3x4(value)}
            value={lineCount_3x4} 
            min={1} max={20} 
        />
        </li>
        <li style={{display: 'flex', flexDirection: 'row'}}>
            <div style={{minWidth: '80px', display: 'flex'}}>
                <label style={{margin: 'auto 0'}}>List View</label>
            </div>
        <InputNumber 
            disabled={isLoading}
            onChange={value=>setLineCount_ListView(value)}
            value={lineCount_ListView} 
            min={1} max={20} 
        />
        </li>
        <li>
        <ButtonElement type={'primary'} size='large' disabled={isLoading}
            children={'Update'} onClick={saveLineCountSettings} />
        </li>
    </ul>
</>);
}; 