import TooltipContainer from "Components/Tooltip";
import global from "../../../styles/globel/style.module.scss";
import parse from "html-react-parser";
import { arrayToString } from "utils/dataFormatter";
import { handleDateFormatter } from "../Helper";
import SVGIcons from "Components/SVGIcons";

export const ArticlePictograms = props=>{
    return (<div className={global.headerBottom__searchcontent}>

        <ul className={global.searchitemList}>
            <li>
                <TooltipContainer title={props.filepath ? "File path" : "Website domain"} placement={"left"}>
                    <span> {props.filepath ? <SVGIcons type="SVG-folder" /> : <SVGIcons type="SVG-websource" />}</span>
                </TooltipContainer>
                
                <span>{parse(arrayToString(props.url) || "Website is not available")}</span>
            </li>
            
            <li>
                <TooltipContainer title={"Publish date"} placement="bottom">
                    <span><SVGIcons type="SVG-publisheddate" /></span>
                </TooltipContainer>
                <span>{handleDateFormatter(props.datePublished, props.dateFormat)}</span>
            </li>
        </ul>
    </div>);
};