import global from "../../../styles/globel/style.module.scss";
import { Card } from "Components/Card";
import { ArticleTitle } from "./ArticleTitle";
import { ArticleToolbar } from "./ArticleToolbar";
import { ArticleUserTagsBlock } from "./ArticleUserTagsBlock";
import { MyArticleContentWrapper } from "./MyArticleContentWrapper";
import { ArticleMetadataBlockWrapper } from "./ArticleMetadataBlockWrapper";
import { MetadataBlock2 } from "./MetadataBlock2";
import { useEffect, useMemo, useRef, useState } from "react";

import style from '../../Card/style.module.scss';
import { ArticleToolbar2 } from "./ArticleToolbar2";
import { MyDisplayTagsModal2 } from "../MyDisplayTagsModal2";
import { saveUserTags } from "api/search";
import { removeEMTag } from "utils/dataFormatter";
import PreviewModal from "./PreviewModal";
import { Checkbox } from "antd";
import TooltipContainer from "Components/Tooltip";
import { notify } from "Components/Notification";


export const ArticleTile = props=>{

    const {item, metaDataList}= props;

    const [userTagsModalWindowVisible, setUserTagsModalWindowVisible] = useState(false);

    const [showPreview, setShowPreview] = useState(false);

    const [userTags, setUserTags] = useState(props.item.user_tags); 

    useEffect(()=>{
        setUserTags(props.item.user_tags);
    }, [props.item.user_tags]);

    

    const handleSaveTags = (newUserTags)=>{

        // saving user tags
        // newUserTags are only those that are not deleted
        
        const uniqueNonEmptyTags = newUserTags.filter(t=>t && t.value && t.value.trim).map(t=>t.value.trim()).filter((t, ix, thisArr) => thisArr.indexOf(t) == ix);
        uniqueNonEmptyTags.sort((a, b)=>{
            if (a < b) {
                return -1;
            }
            if (a > b){
                return 1;
            }
            return 0;
        });
        
        saveUserTags(item.ProjectId, item.DocumentId, uniqueNonEmptyTags).then(res=>{
            // res should be the updated document - but we can update the tags here anyways
            setUserTagsModalWindowVisible(false);
            // toastify 'Tags saved'

            console.log(res);

            setUserTags(uniqueNonEmptyTags); 

            props.updateUserTags(item.DocumentId, uniqueNonEmptyTags);

        }).catch(err=>{
            console.log(err);
            debugger;
        });


    };



    const titleRef = useRef();
    const scrollToTitle = ()=>{
        titleRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });
    };

    const onCheckboxChecked = (e)=>{
        const checked = e.target.checked;
        let newSelectedIds;
        if (checked){
            newSelectedIds = [...props.markedDocumentIds, item.DocumentId];
        }
        else {
            newSelectedIds = props.markedDocumentIds.filter(x=>x != item.DocumentId);
        }

        props.setMarkedDocumentIds(newSelectedIds);
        notify.success(`${newSelectedIds.length || "No"} article(s) selected`, true);
    };

    const isChecked = useMemo(()=>{
        const result = props.markedDocumentIds.indexOf(item.DocumentId) > -1;
        return result;
    }, [item.DocumentId, props.markedDocumentIds]);

    
    return (<>
        {userTagsModalWindowVisible ?  <MyDisplayTagsModal2
            handleSave={handleSaveTags}
            onCancel={()=>setUserTagsModalWindowVisible(false)}
            userTags={userTags?.map(t=>removeEMTag(t))}
        /> : null}


        {showPreview ? <PreviewModal showModal={showPreview} setShowPreview={setShowPreview} 
            data={item} // data is the document
        /> : null}
    

        {/* attempt to scroll to the article title, and replace the <Card> component. For scrolling they seem to work the same, so just keep the card for now. */}
        <Card className={global.resultCard}>
        <div className={global.resultCard__header} ref={titleRef}>
            <div className={global.resultCard__header__titleCheckbox}>
                <ArticleTitle item={item} />

                <TooltipContainer title="Select this artcile" placement="bottom" >
                    <div>
                    <Checkbox checked={isChecked} onChange={onCheckboxChecked}></Checkbox>
                    </div>

                </TooltipContainer>

            </div>  


            <ArticleToolbar2 
                item={item} dateFormat={props.dateFormat}
                setShowTagsModal={setUserTagsModalWindowVisible} 
                setShowPreview={setShowPreview}
                allProjectTaxonomies={props.allProjectTaxonomies}
            />
            
        </div>
        <div className={global.resultCard__content}>
            <ArticleUserTagsBlock tags={userTags}/>

            
            <MyArticleContentWrapper 
                showFullContent={props.showFullContent}
                scrollToTitle={scrollToTitle}
                document={item} 
                numberOfLines={props.numberOfLines} 
                expandAllCards={props.expandAllCards}
                simpleSearchText={props.simpleSearchText}
                advancedSearchRows={props.advancedSearchRows}
            />

            <MetadataBlock2 allTaxonomies={metaDataList} document={item} showAllTags={props.showAllTags}/>
            {/* <ArticleMetadataBlockWrapper metaDataList={metaDataList} document={item}/> */}
      </div>
    </Card></>);
};