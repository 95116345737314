import parse from "html-react-parser";
import global from "../../../styles/globel/style.module.scss";
import style from "./style.module.scss";
import { getURL, handleWebsiteDomain } from "utils/commonUtils";
import ButtonBlock from "./buttonBlock";
import { arrayToString, getDatePublished } from "utils/dataFormatter";
import TooltipContainer from "Components/Tooltip";
import SVGIcons from "Components/SVGIcons";
import { handleDateFormatter } from "../Helper";
import { ArticleButtonBlock } from "./ArticleButtonBlock";
import { ArticlePictograms } from "./ArticlePictograms";
import { useMemo } from "react";


export const ArticleToolbar2 = props=>{

    const document = props.item;
    const allProjectTaxonomies = props.allProjectTaxonomies;

    const webLocation = useMemo(()=>{
        const result = document?.website_domain || document["Website Domain"] || getURL(document?.Location);
        return result;
    }, [document]);

    const datePublished = useMemo(()=>{
        const result = getDatePublished(document, allProjectTaxonomies);
        return result;
    }, [document, allProjectTaxonomies]);
    

    const isFilepath = !!(webLocation?.testFile);
    const url = webLocation?.url ? webLocation.url : webLocation;


    // from SearchCardContent
    return (<div className={global.headerBottom}>
            <ArticlePictograms 
                datePublished={datePublished} 
                webLocation={webLocation} 
                isFilepath={isFilepath} url={url} 
                dateFormat={props.dateFormat}
            />
                
            <ArticleButtonBlock setShowTagsModal={props.setShowTagsModal} setShowPreview={props.setShowPreview} article={document}/>
            
    </div>);
};