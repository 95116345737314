import InputField from "Components/InputField";
import ModalWindow from "Components/ModalWindow";
import TooltipContainer from "Components/Tooltip";
import { useEffect, useMemo, useState } from "react";
import style from "./style.module.scss";

export const EditUserModal2 = props=>{

    const [user, setUser] = useState({});
    
    const isLoading = props.isLoading;

    const [errors, setErrors] = useState([]);

    useEffect(()=>{
        setErrors(props.errors);
    }, [props.errors]);


    useEffect(()=>{
        const mappedUser = {
            id: props.user.id, 
            firstName: props.user.firstname, 
            userEmail: props.user.emailid, 
            lastName: props.user.lastname, 
            userEmail: props.user.userName,
            roleId: props.user.roleId ? (''+props.user.roleId) : "4",
            groupId: props.user.groupId ? (''+props.user.groupId) : "1" ,
            isGroupAdmin: props.user.isGroupAdmin ? 'true' : 'false'
        };
        console.log('mappedUser', mappedUser);
        setUser(mappedUser);
    }, [props.user]);

    const allRoleOptions = [
        { label: "Admin", value: "1" }, 
        { label: "Analyst", value: "2" }, 
        { label: "SuperAdmin", value: "3" },
        { label: "User", value: "4" }
    ];

    // superAdmin role can be added when current user is superadmin 
    const roleOptions = allRoleOptions.filter(x=> x.value != '3' || props.loggedInSuperAdmin);

    // // can only create superadmin when creating new users: 
    // if (!user.id){
    //     roleOptions.push({ label: "SuperAdmin", value: "3" });
    // }


    {/* cannot update the role for a user who was made superadmin and for yourself.
        So it's possible to make a user superadmin and then you won't be able to undo it? 
    */}
    const canUpdateRole = useMemo(()=>{
        if (props.isSelf){
            return false;
        }
        if (props.loggedInSuperAdmin){
            return true;
        }

        if (props.user.isSuperAdmin){
            return false;
        }

        return true;

    }, [props.isSelf, props.loggedInSuperAdmin, props.user.isSuperAdmin]);
    

    return (<ModalWindow
        disableOkButton={isLoading}
        title={user.id ? 'Edit User' : 'Add User'}
        open={true} 
        onCancel={props.onCancel}
        onOk={()=>props.onConfirm(user)}
        isNotClosable={true}
        isNonMaskable={true}    
    >

        {/* =={JSON.stringify(user)}== */}
        <ul className={style.userEditlist}>
            <li>
                {!user.id ? (<>
                <>    
                <TooltipContainer title='Provide the email from Windows Active Directory to be used as login.'>
                    <label>User Login:</label>
                </TooltipContainer>

                <InputField disabled={isLoading} placeholder={"login"} onChange={(e) => {
                    setErrors(errors.filter(e=>e.fieldName != 'userEmail'));
                    setUser({...user, userEmail: e.target.value});
                }}
                        value={user.userEmail || ''}/>
                </>
                        
                </>) : (<><TooltipContainer title="User login, once set up, cannot be changed." placement="top">
                        <label>User Login: </label> 
                    </TooltipContainer>
                    <InputField disabled={isLoading} value={user.userEmail || ''} readOnly onChange={()=>{}}/>
                </>)}

            </li>
            <li>
                <div style={{ display: 'flex', width: '100%', gap: '20px' }}>
                    <div style={{ flex: 1 }}>
                        <label>First name:</label>
                      
                        <InputField disabled={isLoading} placeholder="Enter the first name" 
                            onChange={(e) => {
                                debugger;
                                setErrors(errors.filter(e=>e.fieldName != 'firstName'));
                                setUser({...user, firstName: e.target.value});
                             }}
                            value={user.firstName || ''}
                        />
                    </div>

                    <div style={{ flex: 1 }}>
                        <label>Last name:</label>
                      
                        <InputField disabled={isLoading} placeholder="Enter the last name" 
                            onChange={(e) => {
                                setErrors(errors.filter(e=>e.fieldName != 'lastName'));
                                setUser({...user, lastName: e.target.value});
                            }}

                            value={user.lastName || ''}
                        />
                    </div>

                </div>
            </li>
            
            {canUpdateRole ? <li>
                <label>User Role</label>

                <InputField disabled={isLoading} inputType="selectElement" 
                    optionList={roleOptions}
                    value={user.roleId}
                    onChange={(e) => setUser({...user, roleId: e})}
                />
            </li> : null}


            <li>
                <label>Group:</label>
                <InputField
                    disabled={isLoading}
                    inputType="selectElement"
                    optionList={props.allGroups}
                    
                    value={user.groupId}
                    
                    onChange={(e) => setUser({...user, groupId: e})}
                />
            </li>

            <li>
                <label>Is Group Admin:</label>
                <InputField
                    disabled={isLoading}
                    inputType="selectElement"
                    optionList={[{ label: "Yes", value: 'true' }, { label: "No", value: 'false' }]}
                    defaultValue={false}
                    value={user.isGroupAdmin || ''}
                    onChange={(e) => setUser({...user, isGroupAdmin: e})}  
                />

            </li>
        </ul>

        {errors.length ? <div>
            <ul>
                {errors.map((err, ixErr)=>{
                    return <li key={`error-${ixErr}`}>{err.error}</li>
                })}
            </ul>
        </div> : null}
            
    </ModalWindow>)
};
