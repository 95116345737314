import TooltipContainer from "Components/Tooltip";
import parse from "html-react-parser";
import { getCardTitle, removeEMTag } from "utils/dataFormatter";

export const ArticleTitle = props=>{

    // This component displays the title of the article with a tooltip
    const document = props.item;

    // depending on the document type, title might be coming from different fields.  This is handled by getCardTitle helper.
    // Also for the tooltip the EM highlights must be removed
    const title = getCardTitle(document, []); //TODO: figure out what's in allFieldsWithDatatype
    const tooltipText = removeEMTag(title || '');
    const headerText = parse(title || '') || 'Title not available';

    
    return (<TooltipContainer title={tooltipText} placement="bottom">
            <h3>{headerText}</h3>
        </TooltipContainer>
    );

};