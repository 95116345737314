import moment from 'moment';
import { useEffect, useRef, useState } from "react";
import { AdvancedSearchGroupControl } from "./AdvancedSearchGroupControl";
import ButtonElement from "Components/ButtonElement";
import CustomScrollbar from 'Components/CustomScrollbar';
import { validateAdvancedSearchRows } from './AdvancedSearchValidator';


export const AdvancedSearch2 = props=>{

    // we need a counter which always will increase as groups or rows are added (cannot rely on length)
    const rowCounter = useRef(1);
    const groupCounter = useRef(1);

    const [validationErrors, setValidationErrors] = useState([]);

    const justOneRow = {
        rowId: 0, 
        taxonomyName: '',
        taxonomyValue: '',
        taxonomyValues: [], 
        filterOptionType: 'Any'
    };

    const justOneGroup = {
        groupId: 0,
        rows: [justOneRow],
        isOr: null
    };

    const [searchRows, setSearchRows] = useState([justOneGroup]);

    useEffect(()=>{
        // happens when we load the control 
        debugger;
        if (!props.advancedSearchRows || !props.advancedSearchRows.length){
            setSearchRows([justOneGroup]);
        }
        else{
            const newGroupCounter = props.advancedSearchRows.length;
            // need to convert dates to moments:
            const newSearchRows = props.advancedSearchRows.map(group=>{
                const newRows = group.rows.map(row=>{
                    if (row.dateFilterObject){
                        if (row.dateFilterObject.mode == 'range'){
                            const dates = row.dateFilterObject.dates;
                            const from = moment(dates.from, row.dateFilterObject.dateFormat);
                            const to = moment(dates.to, row.dateFilterObject.dateFormat);
                            return {...row, dateFilterObject: {...row.dateFilterObject, dates: {from, to}}};

                        }
                        if (row.dateFilterObject.mode == 'from-date-in-past'){
                            const dates = row.dateFilterObject.dates;
                            const from = moment(dates.from, row.dateFilterObject.dateFormat);
                            return {...row, dateFilterObject: {...row.dateFilterObject, dates: {from}}};
                        }
                    }
                    return row;
                });
                return {...group, rows: newRows};
            });

            setSearchRows(newSearchRows);
            groupCounter.current = newGroupCounter;
            let newRowCounter = 0;
            props.advancedSearchRows.forEach(group=>{
                newRowCounter += group.rows.length;
            });
            rowCounter.current = newRowCounter;
        }
    }, [props.advancedSearchRows]);

    const addAdvancedSearchRow = (group)=>{
        const groupRow = searchRows.find(x=>x.groupId === group.groupId);
        if (!groupRow){
            return;
        }

        const groupRows = [...groupRow.rows];
        const newRow = {...justOneRow, rowId: rowCounter.current, isOr: true};
        groupRows.push(newRow);
        rowCounter.current += 1;
        
        const newSearchRows = searchRows.map(x=>{
            if (x.groupId == group.groupId){
                return {...x, rows: groupRows};
            }
            else{
                return x;
            };
        })
        setSearchRows(newSearchRows);
    };

    const addAdvancedSearchGroup = ()=>{
        debugger;
        const newGroup = JSON.parse(JSON.stringify(justOneGroup));
        newGroup.rows[0].rowId = rowCounter.current;
        rowCounter.current += 1;

        newGroup.groupId = groupCounter.current;
        groupCounter.current +=1 ;
        newGroup.isOr = true;
        const newRows = [...searchRows, newGroup];
        setSearchRows(newRows);
    };
    

    const deleteAdvancedSearchRow  = (group, row)=>{

        const newGroups = searchRows.map(g=>{
            if (g.groupId === group.groupId){
                if (g.rows.length == 1 ){
                    return;
                }
                const newGroupRows = g.rows.filter(r=>{
                    return row.rowId !== r.rowId;
                });
                g.rows = newGroupRows;
            }
            return g; 
        });

        setSearchRows([...newGroups]);
    };

    const deleteAdvancedSearchGroup = group =>{
        if (searchRows.length == 1){
            return;
        }
        const newRows = searchRows.filter(r=>r.groupId !== group.groupId);
        setSearchRows(newRows);
    };

    const handleGroupChanged = group=>{

        const newSearchRows = searchRows.map(g =>{
            if (g.groupId === group.groupId){
                return {...g, isOr: group.isOr};
            }
            else{
                return g;
            }
        });

        setSearchRows(newSearchRows);
    };

    const handleRowChanged = (group, row)=>{   
        debugger;
        // TODO: row might have dateFilterObject
        const newSearchRows = searchRows.map(g =>{
            if (g.groupId === group.groupId){
                const newGroupRows = g.rows.map(r=>{
                    if (r.rowId === row.rowId){

                        console.log(`updating row ${row.rowId} in group ${group.groupId}`, row);
                        return {
                            dateFilterObject: row.dateFilterObject,
                            filterOptionType: row.filterOptionType, 
                            taxonomyName: row.taxonomyName,
                            taxonomyValue: row.taxonomyValue,
                            taxonomyValues: row.taxonomyValues,
                            isOr: row.isOr, 
                            rowId: row.rowId
                        };
                    }
                    else{
                        return r;
                    }
                });
                g.rows = newGroupRows;
            }            
            return g;
        });

        setSearchRows(newSearchRows);
    };

    const onClearAll = ()=>{
        setSearchRows([justOneGroup]);
        rowCounter.current = 1;
        groupCounter.current = 1;
        setValidationErrors([]);
    };

    const onApply = ()=>{
        const validationResult = validateAdvancedSearchRows(searchRows);
        console.log('validationResult', validationResult);

        setValidationErrors(validationResult);
        
        if (validationResult.length)
        {
            return;
        }

        // 1) need to re-assign IDs on the groups and adjust isOr flags (if the first row/group was removed): 
        const newGroups = searchRows.map((g, ixG)=>{
            const isFirstGroup = ixG === 0;
            const newRows = g.rows.map((r, ixR)=>{
                const isFirstRow = ixR === 0;
                const newRow = {...r, rowId: ixR, isOr: isFirstRow ? null : r.isOr};
                return newRow;
            });
            return {...g, groupId: ixG, rows: newRows, isOr: isFirstGroup ? null : g.isOr};
        });

        setSearchRows(newGroups); // even though this is no longer required...

        // 2) need to prepare dates on the searchRows:
        const dateFormat = 'YYYY-MM-DD';
        debugger;
        
        const searchRowsPayload = newGroups.map(group=>{
            const rows = group.rows.map(row=>{
                if (!row.dateFilterObject){
                    return row;
                }
                
                const mode = row.dateFilterObject.mode;
                if (mode == 'last-n-days'){
                    const now = moment();
                    const from = moment(now).add(-row.dateFilterObject.numberOfDays, 'days').startOf('day').format(dateFormat);
                    const dates = {from, to: now.startOf('day').format(dateFormat)};
                    return {...row, dateFilterObject: {...row.dateFilterObject, dates, dateFormat}};
                }

                if (mode == 'from-date-in-past'){
                    const now = moment();
                    const dates = {...row.dateFilterObject.dates, 
                        from: row.dateFilterObject.dates.from.startOf('day').format(dateFormat),
                        to: now.startOf('day').format(dateFormat)};
                    return {...row, dateFilterObject: {...row.dateFilterObject, dates, dateFormat}};
                }
                if (mode == 'range'){
                    const dates = {
                        from: row.dateFilterObject.dates.from.startOf('day').format(dateFormat),
                        to: row.dateFilterObject.dates.to.startOf('day').format(dateFormat),
                    };
                    return {...row, dateFilterObject: {...row.dateFilterObject, dates, dateFormat}};
                }
            });

            group.rows = rows;
            return group;
        });
        props.onApply(searchRowsPayload);
    };
    
    console.log('searchRows', searchRows);
    return (<>


{/* GroupCounter: {groupCounter.current}
<br/>
RowCounter =={rowCounter.current}==
<br/>
=={JSON.stringify(searchRows)}==
         */}

         {/* =={JSON.stringify(validationErrors)}== */}
    
    <CustomScrollbar className="filter-scroller"><>
    {searchRows.map((group, ix)=>{
        return (<AdvancedSearchGroupControl group={group} key={`search-row-group-${ix}`}  allTaxonomies={props.allTaxonomies} 
            projectId={props.projectId}
            index={ix}
            addAdvancedSearchRow={addAdvancedSearchRow} 
            deleteAdvancedSearchRow={deleteAdvancedSearchRow} 
            addAdvancedSearchGroup={addAdvancedSearchGroup}
            deleteAdvancedSearchGroup={deleteAdvancedSearchGroup}
            handleRowChanged={handleRowChanged}
            handleGroupChanged={handleGroupChanged}
            removeGroupButtonVisible={searchRows.length > 1}
            validationErrors={validationErrors}
        />);
    })}
    </></CustomScrollbar>

    <div style={{position: 'absolute', bottom: '40px', display: 'flex', width: '100%'}}>
        <div style={{marginLeft: 'auto'}}>
        <ul style={{listStyleType: 'none', marginRight: '20px'}}>
            <li style={{float: 'left'}}>
                <ButtonElement onClick={onClearAll} disabled={props.clearAllDisabled} type='link'>Clear</ButtonElement>
            </li>
            <li style={{float: 'left'}}>
                <ButtonElement onClick={()=>onApply()} disabled={props.disabled} type='primary'>Apply</ButtonElement>
            </li>
        </ul>
        </div>
    </div>

    </>);
}