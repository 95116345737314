import { Radio } from "antd";
import style from "./style.module.scss";
import { useEffect, useState } from "react";
import { FromNDays } from "./FromNDays";

export const DateOptionFromLastNDays = props=>{
    const [number, setNumber] = useState(1);
    useEffect(()=>{
        setNumber(props.lastNDays);
    }, [props.lastNDays]);

    const handleValueChange = value=>{
        setNumber(value);
    };

    const confirmValueChange = ()=>{
        // happens onBlur and onPressEnter and updates the value up:
        // TODO: some validation?
        props.handleDatesChanged(number);
    };


    return <>

    <div className={style.datesHeader}>
        <Radio className={style.radio} onChange={e=>props.handleDateFilterTypeChanged(e.target.value)} checked={props.dateFilterType == 2} value={2}>
            <span>
                From last {(!number && number != 0) ? 'N' : number } day{number == 1 ? '' : 's'}    
            </span>
        </Radio>
    </div>

    {props.dateFilterType == 2 ? <FromNDays 
        number={number} onChange={value=>handleValueChange(value)} 
        onBlur={confirmValueChange} onPressEnter={confirmValueChange}/> 
    : null}


    </>
}