import { getDashboardDataRows } from "api/dashboard";
import TableElement from "Components/TableElement";
import TooltipContainer from "Components/Tooltip";
import { useEffect, useMemo, useState } from "react";
import global from "../../../styles/globel/style.module.scss";
import SVGIcons from "Components/SVGIcons";
import { SEARCH_RESULT_PDF_KEY } from "constants";

const DataRowActions = props=>{
    const record = props.record;
    return (<ul className={global.tableActions}>
        <li>
            <TooltipContainer
                mouseLeaveDelay={0}
                placement="bottom"
                title={record.Location.includes("http") ? 
                    "Navigate to source" : "Not available"}
            >
                <span onClick={(e) => {
                    if (record.Location.includes("http")){
                        window.open(record.Location);
                    }
                    }}
                >
                    <SVGIcons type="SVG-redirect"/>
                </span>
            </TooltipContainer>
        </li>
        <li>
            <TooltipContainer
                placement="bottom"
                title={record[SEARCH_RESULT_PDF_KEY] ? "Download file" : "Not available"}
                mouseLeaveDelay={0}
            >
                <span
                onClick={(e) => {
                    const pdfRelativePath = record[SEARCH_RESULT_PDF_KEY];
                    const downloadLink = ARTICLE_DOCUMENT_API + pdfRelativePath;
                    const fileName =
                    "Article-" + downloadLink.slice(downloadLink.length - 20);
                    pdfRelativePath &&
                    articlePdfDownloadHandler(downloadLink, fileName);
                }}
                >
                    <SVGIcons type={"SVG-download"} />
                </span>
            </TooltipContainer>
        </li>
    </ul>);
};

export const DashboardInfoTable2 = props=>{
    // this is the table component that shows chart data in table view (N documents that contain the key words)

    const charts = props.charts;

    const [items, setItems] = useState([]);
    const [totalCount, setTotalCount] = useState(0);

    const loadItems = ()=>{
        // make API call to load the items
        const items = charts.map(c=>({chartQuery: c.chartQuery, chartType: c.chartType, insightId: c.id, name: c.name}));
        getDashboardDataRows({items}).then(res=>{
            debugger;
            setItems(res.data.items);
            setTotalCount(res.data.totalCount);
        });


    };


    useEffect(()=>{
        // we need to load DashboardDataRows every time we change the charts (and pagination)
        if (!charts || !charts.length){
            setItems([]);
            return;
        }
        else {
            loadItems();
        }
    }, [charts]);

    const makeColumns = ()=>{
        return [
            {
                title: 'Insight',
                dataIndex: 'insightName', 
                fixed: 'left',
                width: 150
            },
            {
                title: 'Title',
                dataIndex: 'Title',  // need to know which field to use as Article Title
                fixed: 'left',
                width: 150, 
                render: title=>(<>
                <TooltipContainer
                    placement="bottom"
                    title={title}
                    mouseLeaveDelay={0}>
                    {title ? `${title?.substring(0, 25)}...` : "Not available"}
                </TooltipContainer>
                </>)
            },
            {
                title: 'Date Published',  // This can also be flexible
                dataIndex: 'Date Published', // Can be flexible 
                fixed: 'left',
                width: 150,
                render: (title) => (title ? `${title}` : "not available")
            },
            {
                title: 'User Tags',
                dataIndex: 'user_tags', 
                fixed: 'left',
                width: 150,
                render: (value) => (value ? `${value}` : "-"),
            },
            {
                title: 'Actions',
                dataIndex: 'actions',
                fixed: 'right',
                width: 150,
                render: (text, record)=><DataRowActions record={record} />
            }
        ];
    };

    const columns = useMemo(()=>{
        const result = makeColumns();
        return result;
    }, []);

    


    
    return <>
    <h3>Matched Results</h3>

    <TableElement
         scrollY={"calc(100vh - 270px)"}
         columns={columns}
         data={items}
    />
    </>
};