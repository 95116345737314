import { DateOptionUntilNow } from "./DateOptionUntilNow";
import { DateOptionRange } from "./DateOptionRange";
import moment from "moment";
import style from "./style.module.scss";
import { DateOptionFromLastNDays } from "./DateOptionFromLastNDays";
import { useEffect, useState } from "react";

export const TaxonomyFilterDate = props=>{
    
    const dateFormat = 'DD/MM/YYYY';

    const [dateFilterType, setDateFilterType] = useState(0); // 0, 1, 2 for different types

    const [dateFilterObject, setDateFilterObject] = useState({}); // this object will have from and to properties, for mode 0
    
    const [fromDateFilterObject, setFromDateFilterObject] = useState({}); // this object will have a single from date (for mode 1)

    const [lastNDaysDateFilterObject, setLastNDaysDateFilterObject] = useState({}); // this object will have dateFrom, dateTo and N days and which will translate to dates (for mode 2)

    const handleDatesChanged_Option0 = dateObj=>{
        const newObj = {...dateObj};
        setDateFilterObject(newObj);
        
        props.updateTaxonomiesWithDates({dateFilterMode: 0, dateObj: newObj});
    };

    const handleDatesChanged_Option1 = value=>{
        
        const newObj = {...value};
        setFromDateFilterObject(newObj);

        props.updateTaxonomiesWithDates({dateFilterMode: 1, dateObj: newObj});
    };

    const handleDatesChanged_Option2 = value=>{

        // value is N days from today (1 means yesterday). We need to construct a dates filter and pass it up.  N can be null (value has been deleted, which means dates need to be wiped off)
        let newObj = {};
        if (!value && value != 0){
            // value == null means cleared the textbox
            setLastNDaysDateFilterObject(newObj);
        }
        else {
            // number or 0
            const todaysDate = moment().startOf('day');
            const nDaysAgo = moment(todaysDate).add(-value, 'day');
            newObj = {
                from: nDaysAgo, 
                //to: todaysDate, //  we can ignore to date, filter will be just 'from', assuming no documents will have datePublished more than today
                numberOfDays: value
            }; 
            setLastNDaysDateFilterObject(newObj);
        }
        props.updateTaxonomiesWithDates({dateObj: newObj, dateFilterMode: 2});
    }

    const handleDateFilterTypeChanged = newType=>{
        // clicking on radio button 
        debugger
        console.log(`DateFilterType, selected option ${newType}`);
        setDateFilterType(newType);
        let objToSendUpdates = dateFilterObject;
        if (newType == 1){
            // only 'from' date is in the dateObj
            objToSendUpdates = fromDateFilterObject;
        } else if (newType == 2){
            // need to recalc the dates in the lastNDays object
            const value = lastNDaysDateFilterObject.numberOfDays;
            if (!value && value != 0) {    
                objToSendUpdates = {}; // means deleting a row with the date filter
            }
            else {
                // recalc the values: 
                const todaysDate = moment().startOf('day');
                const nDaysAgo = moment(todaysDate).add(-value, 'day');
                const newObj = {
                    from: nDaysAgo, 
                    //to: todaysDate,  // 'to' date doesn't matter
                    numberOfDays: value
                };
                setLastNDaysDateFilterObject(newObj);
                objToSendUpdates = newObj;
            }
        }

        props.updateTaxonomiesWithDates({dateObj: objToSendUpdates, dateFilterMode: newType});
    };


    useEffect(()=>{

        if (props.dateFilterObj){
            // we assume there is only 1 dateFilterRow (to filter by Published Date)
            const dateObj = props.dateFilterObj;
            if (dateObj.dateFilterMode == 2 ){
                setDateFilterType(2);

                const value = dateObj.numberOfDays;
                if (!value && value != 0){
                    // value == null means cleared the textbox
                    setLastNDaysDateFilterObject({});
                }
                else {
                    // number or 0
                    const todaysDate = moment().startOf('day');
                    const nDaysAgo = moment(todaysDate).add(-value, 'day');
                    const newObj = {
                        from: nDaysAgo, 
                        //to: todaysDate, //  we can ignore to date, filter will be just 'from', assuming no documents will have datePublished more than today
                        numberOfDays: value
                    }; 
                    setLastNDaysDateFilterObject(newObj);
                }
                return;
            }
            if (dateObj.dateFilterMode == 1 ){

                setDateFilterType(1);
                setFromDateFilterObject({
                    from: dateObj.dateRangeValueFrom ? moment(dateObj.dateRangeValueFrom, dateObj.dateFormat) : null
                })

                return;
            }
            if (dateObj.dateFilterMode == 0 ){
                setDateFilterType(0);
                setDateFilterObject({
                    from: dateObj.dateRangeValueFrom ? moment(dateObj.dateRangeValueFrom, dateObj.dateFormat) : null,
                    to: dateObj.dateRangeValueTo ? moment(dateObj.dateRangeValueTo, dateObj.dateFormat) : null
                })
                return;
            }
        }
        else {
            
            if (dateFilterType == 2){
                setLastNDaysDateFilterObject({});
            }
            if (dateFilterType == 1){
                setFromDateFilterObject({});
            }
            if (dateFilterType == 0){
                setDateFilterObject({});
            }
        }
    }, [props.dateFilterObj]);


    return (<div style={{padding: '8px'}}>
    <div className={style.datesBlock}>
        <ul className={style.datesBlock__list}>
            <li>
                <DateOptionRange handleDateFilterTypeChanged={handleDateFilterTypeChanged} dateFilterType={dateFilterType} dateFormat={dateFormat} dateFilterObject={dateFilterObject} handleDatesChanged={handleDatesChanged_Option0}/>
            </li>
            <li>
                <DateOptionUntilNow handleDateFilterTypeChanged={handleDateFilterTypeChanged} dateFilterType={dateFilterType} dateFormat={dateFormat} dateFilterObject={fromDateFilterObject} handleDatesChanged={handleDatesChanged_Option1}/>
            </li>
            <li>
                <DateOptionFromLastNDays handleDateFilterTypeChanged={handleDateFilterTypeChanged} dateFilterType={dateFilterType} lastNDays={lastNDaysDateFilterObject.numberOfDays} handleDatesChanged={handleDatesChanged_Option2}/>
            </li>
        </ul>
    </div>
    </div>)


};