import { useEffect, useState } from "react";
import moment from 'moment';
import SelectOptions from "./children/SelectOptions";
import { DateOptionRange } from "Components/MyFilter/DateOptionRange";
import { DateOptionUntilNow } from "Components/MyFilter/DateOptionUntilNow";
import { DateOptionFromLastNDays } from "Components/MyFilter/DateOptionFromLastNDays";
import { FromDate } from "Components/MyFilter/FromDate";
import { BetweenDates2 } from "Components/MyFilter/BetweenDates2";
import InputField from "Components/InputField";
import { FromNDays } from "Components/MyFilter/FromNDays";
import global from "styles/globel/style.module.scss";

export const  AdvancedSearchRowControlDates = props=>{

    
    const [filterType, setFilterType] = useState(null);

    // three properties to describe the state of the date filter 
    const [mode, setMode] = useState(null);
    const [numberOfDays, setNumberOfDays] = useState(null);
    const [dates, setDates] = useState({}); // this will need to be a combination of moment objects


    const [dateFilterObject, setDateFilterObject] = useState({});

    

    const [dateRange, setDateRange] = useState({});

    const OPTIONS_FOR_DATES = [
        {value: 'last-n-days', label: 'Within last N days' },
        {value: 'range', label: 'Select a date range' },
        {value: 'from-date-in-past', label: 'From a date until now' }
    ];

    useEffect(()=>{
        console.log('props.dateFilterObject arrived');

        setMode(props.dateFilterObject.mode);

        let from = null;
        let to = null;

        if (props.dateFilterObject.dates){
            if (props.dateFilterObject.dates.from){
                from = moment(props.dateFilterObject.dates.from, props.dateFilterObject.dateFormat);
            }
            if (props.dateFilterObject.dates.to){
                to = moment(props.dateFilterObject.dates.to, props.dateFilterObject.dateFormat);
            }
        }
        setDates({from, to});
        setNumberOfDays(props.dateFilterObject.numberOfDays);

        // setDateFilterObject(props.dateFilterObject.dates);
    }, [props.dateFilterObject]);

    const handleSelectFilterTypeChanged = value=>{
        debugger;
        setFilterType(value);
        const newMode = value;
        let newValue;
        if (value == 'last-n-days'){
            newValue = numberOfDays;
        } 
        else if (value == 'range'){
            newValue = {...dateFilterObject};
        }
        else if (value == 'from-date-in-past'){
            newValue = {...dateFilterObject};
        }

        setMode(newMode);
        
        props.handleDatesChanged({mode: newMode, value: newValue});
    };

    const handleNumberOfDaysChange = value=>{
        debugger;
        setNumberOfDays(value);
        // setMode('last-n-days'); no need 
        // props.handleDatesChanged({mode, numberOfDays: value}); no need to update on each change, enough to update onBlur or on hitting enter
    };

    const confirmValueChange_Last_N_Days = ()=>{
        // happens onBlur and onPressEnter and updates the value up:
        props.handleDatesChanged({mode: 'last-n-days', numberOfDays: numberOfDays});
    };

    const handleDateRangeChange = (newValue)=>{
        // newValue is {from: moment, to: moment}
        console.log(newValue);

        //  TODO: need to pass this from props
        // props.handleDatesChanged(newValue);
        props.handleDatesChanged({mode: 'range', dates: newValue});
    };

    const handleFromDatesChanged = newValue=>{
        // same as for range, so could be combined  (but it has different mode)
        debugger;
        // newValue is {from: moment, to: moment}
        console.log(newValue);

        props.handleDatesChanged({mode: 'from-date-in-past', dates: newValue});

    };


    return <>
    {/* This is the control for the dates */}
    <div style={{width: '70%', display: 'flex'}}>
        <div style={{minWidth: '220px'}}>


            <InputField    
                showSearch={false}
                name={'Date Filter Type'}
                title={'Date Filter Type'}
                optionList={OPTIONS_FOR_DATES}
                value={mode}
                onChange={e=>handleSelectFilterTypeChanged(e)}
                inputType='selectElement'
                htmlForName={'Date Filter Type'}
            />
            
            <span className={global.controlError}>
                {props.dateModeError}
            </span>

        </div>


        <div style={{minWidth: '240px'}}>
            {mode == 'last-n-days' ? <><FromNDays noBackground={true} number={numberOfDays} onChange={value=>handleNumberOfDaysChange(value)} onBlur={confirmValueChange_Last_N_Days} onPressEnter={confirmValueChange_Last_N_Days} numberOfDaysError={props.numberOfDaysError}/></> : null}
            {mode == 'range' ? <><BetweenDates2 noBackground={true} dateFormat={props.dateFormat} 
                dateFilterObject={dates} onDateChanged={handleDateRangeChange} fromError={props.fromError} toError={props.toError}/></> : null}
            {mode == 'from-date-in-past' ? <><FromDate dateFormat={props.dateFormat} 
                dateFilterObject={dates} onDateChanged={handleFromDatesChanged} noBackground={true} fromError={props.fromError}/></> : null}
        </div>
    <div>

        </div>
    </div>
    </>
}