import env from 'react-dotenv';

export const USERNAME = 'admin@pingar.com';
export const PASSWORD = 'Admin@123';
export const ALERT_MESSAGE = 'Credentials Mismatch, Please check!';
export const VALIDATION_SUCCES =
  'Password reset confirmation email has been sent to your email id. Please check your email';
export const LOGIN_ADFS_BUTTON_TITLE = ' ADFS LOGIN';
export const LOGIN_ADFS_RETRY_BUTTON = ' TRY AGAIN';
export const LOGIN_BUTTON_TITLE = ' LOGIN';
export const FORGOT_PASSWORD_TITLE = 'Forgot Password?';
export const RESTRICTION_MESSAGE = {
  HEADING: 'Restricted Access!',
  LINE_1: 'Only authorised personnel are permitted to proceed.',
  LINE_2: 'Unauthorised login attempts will be deemed illegal.',
};
export const LOGIN_FAILED_MESSAGE = {
  HEADING: 'Someone might have locked the gates!',
  LINE_1: 'It looks like we are having trouble logging you in at the moment.',
  LINE_2: 'Please try again after some time.',
};

export const EMAIL_HINT = '*Enter an email address in the correct format, like name@example.com';
export const EMAIL_ERROR1 = '*Email address cannot be empty';
export const EMAIL_ERROR2 = '*Sorry! The email is not found to be registered with Pingar! Please contact the Admin';
export const PASSWORD_HINT = '*Must have atleast 8 character';
export const PASSWORD_ERROR = '*Enter a valid password';
export const FORGOT_PASSWORD_MESSAGE = 'Forgot Password';
export const SUBMIT_TITLE = 'Submit';
export const MODAL_MESSAGE =
  'Please enter your registered email below. If the email is found to have previously authenticated,' +
  ' a link to reset the password will be sent to it within a minute.';
// export const LOGOUT_CONFIRM_HEADER = 'Sad to see you go!';
export const LOGOUT_CONFIRM_BODY = 'You have successfully logged out!';
export const RESEND_OTP = 'Resend OTP';
export const CONFIRM_PASSWORD_NEWPASSWORD_ERROR = 'Your new password and confirm password do not match';
export const OTP_VALIDATION_ERROR = 'The OTP entered is wrong! Please try again!';
export const EMAIL_PLACEHOLDER = 'Enter your email id';
export const OTP_PLACEHOLDER = 'Enter  OTP';
export const EMPTY_CREDENTIAL_ERROR = 'Please enter login credentials ';
export const LOGIN_SUCCESS = 'Successfully logged in';
export const ADFS_LOGIN_FAILED = 'Login failed! Please contact the support team.';
export const FORGOT_HANDLER_OBJECT = {
  forgetPasswordModal: false,
  email: '',
  status: false,
  otpStatus: false,
  otp: '',
  confirmPassword: {value: '', errorMessage: ''},
  newPassword: '',
  otpErrorMsg: '',
};
export const PASSWORD_VALIDATION_MESSAGE = ' *Password must meet the requirements';
export const OTP_FORM_HEADER = 'Enter the OTP received ';
export const OTP_FORM_BODY = ' Please check your mail box for a 6 digit long OTP.';
export const PASSWORD_FORM_HEADER = 'Choose a new password';
export const PASSWORD_FORM_BODY =
  ' Create a new password that is at least 8 characters long.' +
  ' Make sure your password has a combination of uppercase letters, numbers, and special characters.';
export const PREVIOUS_USED_PASSWORD_ERROR = 'This seems to be one of your old passwords. Please try new password';

export const SAML_LOGIN_URL = env.REACT_APP_ENV === 'local' ? 'https://localhost:8000/auth/login' : '/auth/login';
export const SAML_LOGOUT_URL = env.REACT_APP_ENV === 'local' ? 'https://localhost:8000/auth/initia333teslo' : '/auth/initiateslo';
export const SUPER_ADMIN_LOGOUT_URL = env.REACT_APP_ENV === 'local' ? 'https://localhost:8000/auth/super-admin/logout' : '/auth/super-admin/logout';
