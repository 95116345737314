import {Pagination as PageComponent} from 'antd';
import './style.scss';

export const Pagination2 = props=>{
    const sizeChanger = (current, pageSize) => {};
    return (<div className='pagination-block'>

        <PageComponent
            current={props.current}
            pageSize={props.pageSize}
            total={props.total}
            onChange={props.onChange}
            showSizeChanger={props.showSizeChanger}
            onShowSizeChange={sizeChanger}
            pageSizeOptions={['50', '100', '200']}
            showTitle={false}
        />
    </div>);
}