import style from "./style.module.scss"; 
import { ExcludeTags } from "./Children/ExcludeTags";
import { EXCLUSIOM_INFO } from "Constants/Insights/Insights.constants";
import { useEffect, useState } from "react";
import { getExcludeListItem } from "api/insights";
import { notify } from "Components/Notification";

export const ExclusionsModalContent = props=>{

    const [permanentList, setPermanentList] = useState([]);
    const [delTagList, setDelTagList] = useState([]);

    const [excludeListError, setExcludeListError] = useState({
        add: false,
        remove: false,
        message: "",
    });

    const handleRemovePermanentExclusionList = id=>{

        /*
        original from ExludeModal.js : 
            let delTags = delTagList;
            permanentList.filter((items) => items.id == id && delTags.push(items));
            setDelTagLsit(delTags);
            setPermanentList(permanentList.filter((tag) => tag.id !== id));
        */

        let tagsToDelete = [...delTagList];
        const newPermanentList = permanentList.filter(item =>{
            if (item.id == id){
                tagsToDelete.push(item);
                return false;
            }
            else{
                return true;
            }
        });

        setDelTagLsit(tagsToDelete);
        setPermanentList(newPermanentList);
    };

    useEffect(()=>{
        getExcludeListItem({ pageNumber: 1, pageSize: 100 }).then(res=>{
            debugger;
            setPermanentList(res.data.result);
            setDelTagList([]);
            setExcludeListError({ add: false, remove: false });
        }).catch(err=>{
            debugger;
            console.log(err);
            notify.error('Failed to load exclusions');
        });
    }, []);


    return (<>
    <span className={style.alertInfo}>{EXCLUSIOM_INFO}</span>
    <ExcludeTags tags={permanentList} handleRemoveExclusionList={handleRemovePermanentExclusionList} />
    {excludeListError?.add && (<p className={style.errorInfo}>
        {excludeListError?.message ?? "Exclude list addition failed"}
    </p>)}

    
    {excludeListError?.remove && (<p className={style.errorInfo}>Exclude list delete failed</p>)}
    </>);
}