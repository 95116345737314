import ButtonElement from "Components/ButtonElement";
import TooltipContainer from "Components/Tooltip";
import { useMemo } from "react";

export const CloudSearchWordsBanner = props=>{
    // this is the banner for MUST words
    const searchWordsBannerText = useMemo(()=>{
        
        if (!props.cloudSearchWords || !props.cloudSearchWords.length){
            return '';
        }
        if (props.cloudSearchWords.length == 1){
            return props.cloudSearchWords[0].text;
        }

        let result = props.cloudSearchWords[0].text;
        for (let i = 1; i < props.cloudSearchWords.length; i++){
            result += ` AND ${props.cloudSearchWords[i].text}`;
        }

        return result;
    }, [props.cloudSearchWords]);

    if (!props.cloudSearchWords  || ! props.cloudSearchWords.length){
        return null;
    }

    return (<>
    
    <div style={{display: 'flex', width: '100%'}}>

        <h5>Cloud Words:</h5>
        
        <TooltipContainer title={`Documents MUST contain these words in ${props.cloudTaxonomy}`}>
            <>
            <span>{searchWordsBannerText}&nbsp;</span>
            <b>must</b> be in <span>{props.cloudTaxonomy}</span>
            </>
        </TooltipContainer>
        
        <ButtonElement onClick={()=>props.handleClearCloudSearchWordsClicked()} 
            styleProps={{marginLeft: 'auto', marginRight: '30px', height: '28px'}}
        >Back to Default</ButtonElement> 
    </div>
    
        
    </>);

};