import parse from "html-react-parser";
import global from "../../../styles/globel/style.module.scss";
import style from "./style.module.scss";
import { handleWebsiteDomain } from "utils/commonUtils";
import ButtonBlock from "./buttonBlock";
import { arrayToString, getDatePublished } from "utils/dataFormatter";
import TooltipContainer from "Components/Tooltip";
import SVGIcons from "Components/SVGIcons";
import { handleDateFormatter } from "../Helper";

function SearchCardContent({ websiteDomain, datePublished, dateFormat }) {
    let filepath = websiteDomain?.testFile ? true : false;
    let url = websiteDomain?.url ? websiteDomain.url : websiteDomain;
    return (
      <ul className={global.searchitemList}>
        <li>
          <TooltipContainer
            title={filepath ? "File path" : "Website domain"}
            placement={"left"}
          >
            <span>
              {filepath ? (
                <SVGIcons type="SVG-folder" />
              ) : (
                <SVGIcons type="SVG-websource" />
              )}
            </span>
          </TooltipContainer>
          <span>{parse(arrayToString(url) || "Website is not available")}</span>
        </li>
        <li>
          <TooltipContainer title={"Publish date"} placement="bottom">
            <span>
              <SVGIcons type="SVG-publisheddate" />
            </span>
          </TooltipContainer>
          <span>{handleDateFormatter(datePublished, dateFormat)}</span>
        </li>
      </ul>
    );
  }

export const ArticleToolbar = props=>{
    // this component returns toolbar with buttons per article - website icon, date, tagging buttons, download, preview etc

    const document = props.item;
    const allFieldsWithDatatype = [];
    
    // from SearchCardContent
    return (<div className={global.headerBottom}>
        <div className={global.headerBottom__searchcontent}>
            <SearchCardContent websiteDomain={handleWebsiteDomain(document || {})} datePublished={getDatePublished(document, allFieldsWithDatatype)} dateFormat={props.dateFormat} />
        </div>
        <div className={global.headerBottom__controlsicon}>
            <ul className={global.controlsList}>
                <ButtonBlock handleTagsList={props.handleTagsList} showPreview={props.setShowPreview} article={document}/>
            </ul>
        </div>
    </div>);
};