import SVGIcons from '../../../SVGIcons';
import TooltipContainer from '../../../Tooltip';
import style from './style.module.scss';

export const SaveResultsButton = (props) => {
  const { disabled, title, isEdit } = props;
  return (
    <TooltipContainer title={title ? title : 'Save Search'} placement='bottom'>
      <span className={style.resultSavebutton} onClick={disabled ? undefined : props.handleSavedClicked}>
        <SVGIcons type={isEdit ? 'SVG-edit' : 'SVG-save'} />
      </span>
    </TooltipContainer>
  );
};


