import { Tag } from "antd";
import style from "./style.module.scss";

export const TaxonomyFilterInnerChipItem = props=>{

    return (<Tag closable={props.canClose} 
        onClose={e=>{
            e.preventDefault();
            props.handleDeleteInnerChip(props.value);
        }} 
        className={style.tagInner}>
        {props.value}
    </Tag> );
};