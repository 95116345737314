import {APIInstance} from './apiInstance';
import {BASE_URL} from './constants';

export const getDashboardList = (payload) => {
  const instance = new APIInstance({
    baseURL: '/api/dashboards',
  });

  const api = instance.api;
  return api.post('/GetDashboardsPagesize', payload);
};

export const deleteDashboard = (payload) => {
  const instance = new APIInstance({
    baseURL: '/api/dashboards',
  });

  const api = instance.api;
  return api.delete('/DeleteDashboards', {data: payload});
};


/// obsolete
export const saveDashboards = (payload) => {
  const instance = new APIInstance({
    baseURL: '/api/dashboards',
  });

  const api = instance.api;
  return api.post('/AddDashboards', payload);
};


export const addDashboard = payload => {
  const instance = new APIInstance({
    baseURL: '/api/dashboards',
  });

  const api = instance.api;
  return api.post('/AddDashboard', payload);
};


export const updateDashboard = payload => {
  const instance = new APIInstance({
    baseURL: '/api/dashboards',
  });

  const api = instance.api;
  return api.post('/UpdateDashboard', payload);
};


// obsolete
export const editDashboard = (payload) => {
  const instance = new APIInstance({
    baseURL: '/api/dashboards',
  });

  const api = instance.api;
  return api.post('/UpdateDashboards', payload);
};


// obsolete
export const viewDashboardApi = (payload) => {
  const instance = new APIInstance({
    baseURL: '/api/dashboards',
  });

  const api = instance.api;
  return api.post('/GetDashboardById', payload);
};


export const loadDashboardToView = (dashboardId) => {
  const instance = new APIInstance({
    baseURL: '/api/dashboards',
  });

  const api = instance.api;
  return api.get('/loadDashboardToView', {dashboardId});
};

/**
 * Obsolete
 * @param {{emailId: string, pageNumber: number, pageSize: number}} param
 */
export const getDashboardsByEmail = ({emailId, pageNumber, pageSize}) => {
  const instance = new APIInstance({
    baseURL: BASE_URL.DASHBOARD,
  });

  const api = instance.api;
  return api.post('/GetDashboardsByEmailId', {emailId, pageNumber, pageSize});
};

export const getUserDashboards = (pageNumber, pageSize) => {
  const instance = new APIInstance({
    baseURL: BASE_URL.DASHBOARD,
  });

  const api = instance.api;
  return api.post('/GetUserDashboards', {pageNumber, pageSize});
};



// The meaning is 'By Insights IDs', and this method is obsolete
export const GetDashboardDocumentsById = (payload) => {
  const instance = new APIInstance({
    baseURL: BASE_URL.DASHBOARD,
  });

  const api = instance.api;
  return api.post('/GetDocumentsById', payload);
};

export const getDashboardDataRows = (payload) => {
  const instance = new APIInstance({
    baseURL: BASE_URL.DASHBOARD,
  });

  const api = instance.api;
  return api.post('/GetDashboardDataRows', payload);
};
