import ConfirmationMessage from "Components/ConfirmationMessage";
import ModalWindow from "Components/ModalWindow";

export const DeleteDashboardModal = props=>{

    return (<ModalWindow 
        footer={false}
        isNotClosable={true}
        isNonMaskable={true}
        width='500px'
        open={true}    
    >
       <ConfirmationMessage
          heading={"Confirm Delete"}
          message={"Are you sure you want to delete this item?"}
          hide={false}
          onCancel={(e) => props.onOk()}
          onOk={(e) => props.onCancel()}
          cancelButtonLabel="Yes"
          confirmButtonLabel="No"
        />
    </ModalWindow>);

};