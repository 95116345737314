import { loadTaxonomyCounts } from "api/search";
import { useEffect, useRef, useState } from "react";
import { TaxonomyFilterCheckboxRow } from "./TaxonomyFilterCheckboxRow";
import { Button, Collapse } from "antd";
import { TaxonomyFilterDate } from "./TaxonomyFilterDate";
import CustomScrollbar from "Components/CustomScrollbar";
import TooltipContainer from "Components/Tooltip";

const {Panel} = Collapse;

export const FilterPanel = props=>{

     
    const [taxonomies, setTaxonomies] = useState(props.taxonomies);
    useEffect(()=>{
        setTaxonomies(props.taxonomies);
    }, [props.taxonomies])

    
    
    const [taxonomyFilterRows, setTaxonomyFilterRows] = useState([]);

    const [taxonomyDateFilterObj, setTaxonomyDateFilterObj] = useState(null);

    const [loadedTaxonomies, setLoadedTaxonomies] = useState({});

    const [checkedTaxonomies, setCheckedTaxonomies] = useState({});

    useEffect(()=>{
        if (!props.externalData){
            return;
        }


        if(props.externalData.fromChips){
            const loadedKeys = Object.keys(loadedTaxonomies);
            setTaxonomyDateFilterObj(props.externalData.dateFilterObj);
            loadTaxonomies(loadedKeys, loadedTaxonomies, props.externalData.checked, props.externalData.dateFilterObj, projectId);
        }
        else{
            const newLoaded = {};
            const keys = Object.keys(props.externalData.loaded);
            for (let k = 0; k < keys.length; k++){
                const key = keys[k];

                const loadedRow = props.externalData.loaded[key]

                newLoaded[key] = loadedRow.map(x=>{
                    const checkedRow = props.externalData.checked[key];
                    if (!checkedRow){
                        return x;
                    }
                    const checkedValue = checkedRow.some(c=>c.value == x.value);
                    if (checkedValue){
                        return {...x, checked: true}
                    }
                    else{
                        return x;
                    }
                });
            }


            setLoadedTaxonomies(newLoaded);

            setCheckedTaxonomies({...props.externalData.checked});
        
            setTaxonomyDateFilterObj(props.externalData.dateFilterObj);
            
        }

    }, [props.externalData]);


    const loadTaxonomies = (expandedTaxonomyNamesToLoad, loadedTaxonomies, checkedTaxonomies, taxonomyDateFilterObj, projectId)=>{
        
        const loadedKeys = Object.keys(loadedTaxonomies);

        const taxonomyFilterRowsForLoadCounts = []; 

        for (let k = 0; k < loadedKeys.length; k++){
            const taxonomyKey = loadedKeys[k];
            const taxonomyDataRows = loadedTaxonomies[taxonomyKey];

            for (let v = 0; v < taxonomyDataRows.length; v++){
                const taxonomyDataRow = taxonomyDataRows[v];
                if (taxonomyDataRow.checked){
                    const row = {
                        fieldName: taxonomyKey,
                        fieldValue: taxonomyDataRow.value
                    };

                    taxonomyFilterRowsForLoadCounts.push(row);
                }
            }
        }

        if (taxonomyDateFilterObj && (taxonomyDateFilterObj.dateRangeValueTo || taxonomyDateFilterObj.dateRangeValueFrom)){
            taxonomyFilterRowsForLoadCounts.push({
                fieldName: 'Date Published',  
                dateRangeValueTo: taxonomyDateFilterObj.dateRangeValueTo,
                dateRangeValueFrom: taxonomyDateFilterObj.dateRangeValueFrom,
                dateFormat: taxonomyDateFilterObj.dateFormat
            });
        }

        
        Promise.all(expandedTaxonomyNamesToLoad.map(t=>{
            return loadTaxonomyCounts(t, taxonomyFilterRowsForLoadCounts, projectId).then(res=> ({values: res.data.result, taxonomyName: t}))
        })).then(res=>{
            const loaded = {};
            const newForChecked = {};
            res.map(x=>{
                loaded[x.taxonomyName] = x.values;
                newForChecked[x.taxonomyName] = checkedTaxonomies[x.taxonomyName] || [];
            });

            const newlyLoadedKeys = Object.keys(loaded);

            for (let t = 0; t < newlyLoadedKeys.length; t++){
                const key = newlyLoadedKeys[t];
                const taxonomyValues = checkedTaxonomies[key];
                if (taxonomyValues){
                    for (var k = 0; k < taxonomyValues.length; k++){
                        const valueRow = taxonomyValues[k];
                        if (valueRow.checked){
                            const value = valueRow.value;
                            const loadedRows = loaded[key];
                            for (let x = 0; x < loadedRows.length; x++){
                                if (loadedRows[x].value == value){
                                    loadedRows[x].checked = true;
                                    break;
                                }
                            }
                        }
                    }
                }
            }

            setLoadedTaxonomies(loaded);

            const newChecked = {...checkedTaxonomies, ...newForChecked};
            setCheckedTaxonomies(newChecked);

            if (props.updateLoadedAndCheckedTaxonomies){
                props.updateLoadedAndCheckedTaxonomies(loaded, newChecked, taxonomyDateFilterObj);
            }


        }).catch(err=>{
            debugger;
            console.log(err);
        });

    };

    const onChange = (allExpandedTaxonomies) => {

        let allExpandedTaxonomiesLoaded = true;

        const loadedKeys = Object.keys(loadedTaxonomies);
        for (let i = 0; i < allExpandedTaxonomies.length; i++){
            if (!loadedKeys.some(x=>x==allExpandedTaxonomies[i])){
                // that means a new item has been expanded
                allExpandedTaxonomiesLoaded = false;
                break;
            }
        }

        if (allExpandedTaxonomiesLoaded){
        
            let loadedHasMoreThanExpanded = false;
            let newLoadedTaxonomies = {...loadedTaxonomies};
            for (let i = 0; i < loadedKeys.length; i++){
                if (!allExpandedTaxonomies.some(x=>x == loadedKeys[i])){
                    loadedHasMoreThanExpanded = true;
                    delete newLoadedTaxonomies[loadedKeys[i]];
                    // but keep them in checkedTaxonomies
                }
            }

            if (loadedHasMoreThanExpanded){
                setLoadedTaxonomies(newLoadedTaxonomies);

                if (props.updateLoadedAndCheckedTaxonomies){
                    props.updateLoadedAndCheckedTaxonomies(newLoadedTaxonomies, checkedTaxonomies, taxonomyDateFilterObj);
                }

            }
            return;
        }

        loadTaxonomies(allExpandedTaxonomies, loadedTaxonomies, checkedTaxonomies, taxonomyDateFilterObj, projectId);

        return;
    };


    const reapplyCheckmarksToLoadedTaxonomyValues = (updatedKeys, checkedTaxonomies, loaded)=>{
        for (let k = 0; k < updatedKeys.length; k++){
            const rowToUpdateKey = updatedKeys[k];
            const existingTaxonomyDataRowThatMightHaveChecks = checkedTaxonomies[rowToUpdateKey];
            if (existingTaxonomyDataRowThatMightHaveChecks){
                for (let v = 0; v < existingTaxonomyDataRowThatMightHaveChecks.length; v++){
                    const taxonomyDataRow = existingTaxonomyDataRowThatMightHaveChecks[v];
                    if (taxonomyDataRow.checked){
               
                        const value = taxonomyDataRow.value;

                        const loadedRows = loaded[rowToUpdateKey];
                        for (let j = 0; j < loadedRows.length; j++){
                            if (loadedRows[j].value == value){
                                loadedRows[j].checked = true;
                                break;
                            }
                        }
                    }
                }
            }
        }
    };

    const updateTaxonomiesWithDates = (combinedDateObj) =>{

        const {dateFilterMode, dateObj} = combinedDateObj;

        const dateFormat = 'YYYY-MM-DD';

        let rowDateObj = null;

        if (dateObj.from && dateObj.from.isValid()){        
            rowDateObj = {dateRangeValueFrom: dateObj.from.format(dateFormat), dateFormat, dateFilterMode, numberOfDays: dateObj.numberOfDays};
        }

        if (dateObj.to && dateObj.to.isValid()){
            if (!rowDateObj){
                rowDateObj = {dateRangeValueTo: dateObj.to.format(dateFormat), dateFormat, dateFilterMode, numberOfDays: dateObj.numberOfDays};
            }
            else {
                rowDateObj = {...rowDateObj, dateRangeValueTo: dateObj.to.format(dateFormat), dateFilterMode, numberOfDays: dateObj.numberOfDays};
            }
        }


        if ((taxonomyDateFilterObj && !rowDateObj) || (!taxonomyDateFilterObj && rowDateObj) || (taxonomyDateFilterObj && rowDateObj)){

            setTaxonomyDateFilterObj(rowDateObj);

            const keys = Object.keys(loadedTaxonomies); 
            if (keys.length){
                const filterRows = [];

                for (let k = 0; k < keys.length; k++){
                    const taxonomyKey = keys[k];
                    const taxonomyDataRows = loadedTaxonomies[taxonomyKey];

                    for (let v = 0; v < taxonomyDataRows.length; v++){
                        const taxonomyDataRow = taxonomyDataRows[v];
                        if (taxonomyDataRow.checked){
                            const row = {
                                fieldName: taxonomyKey,
                                fieldValue: taxonomyDataRow.value
                            };

                            filterRows.push(row);
                        }
                    }
                }

                if (rowDateObj){
                    filterRows.push({
                        fieldName: 'Date Published',  
                        dateRangeValueTo: rowDateObj.dateRangeValueTo,
                        dateRangeValueFrom: rowDateObj.dateRangeValueFrom,
                        dateFormat: rowDateObj.dateFormat
                    });
                }


                Promise.all(keys.map(t=>{
                    return loadTaxonomyCounts(t, filterRows, projectId).then(res=> ({values: res.data.result, taxonomyName: t}))
                    })).then(res=>{

                        const loaded = {};

                        res.map(x=>{
                            loaded[x.taxonomyName] = x.values;
                        });


                        const updatedKeys = res.map(x=>x.taxonomyName);

                        reapplyCheckmarksToLoadedTaxonomyValues(updatedKeys, checkedTaxonomies, loaded);

                        setLoadedTaxonomies(loaded);

                        if (props.updateLoadedAndCheckedTaxonomies){
                            props.updateLoadedAndCheckedTaxonomies(loaded, checkedTaxonomies, rowDateObj);
                        }


                    }).catch(err=>{
                        debugger;
                        console.log(err);
                    });
            }
            else{
                if (props.updateLoadedAndCheckedTaxonomies){
                    props.updateLoadedAndCheckedTaxonomies(loadedTaxonomies, checkedTaxonomies, rowDateObj);
                }
            }

        }

    };

    const updateTaxonomies = (userUpdatedTaxonomies, modifiedTaxonomyName, updateModifiedTaxonomy = false)=>{

        const checkedInTaxonomy = userUpdatedTaxonomies[modifiedTaxonomyName].filter(v=>v.checked).map(v=>({value: v.value, checked: v.checked}));
        const newCheckedTaxonomies = {...checkedTaxonomies, [modifiedTaxonomyName]: checkedInTaxonomy};
        setCheckedTaxonomies(newCheckedTaxonomies);

        const filterRows = [];

        const keys = Object.keys(userUpdatedTaxonomies);

        for (let k = 0; k < keys.length; k++){
            const taxonomyKey = keys[k];
            const taxonomyDataRows = userUpdatedTaxonomies[taxonomyKey];

            for (let v = 0; v < taxonomyDataRows.length; v++){
                const taxonomyDataRow = taxonomyDataRows[v];
                if (taxonomyDataRow.checked){
                    const row = {
                        fieldName: taxonomyKey,
                        fieldValue: taxonomyDataRow.value
                    };

                    filterRows.push(row);
                }
            }
        }

        if (taxonomyDateFilterObj && (taxonomyDateFilterObj.dateRangeValueTo || taxonomyDateFilterObj.dateRangeValueFrom)){
            filterRows.push({
                fieldName: 'Date Published',  // TODO: this will need to be a parameter, configurable in taxonomies (concepts)
                dateRangeValueTo: taxonomyDateFilterObj.dateRangeValueTo,
                dateRangeValueFrom: taxonomyDateFilterObj.dateRangeValueFrom,
                dateFormat: taxonomyDateFilterObj.dateFormat
            });
        }

        const keysForTaxonomiesToUpdate = updateModifiedTaxonomy ? keys : keys.filter(x=>x!==modifiedTaxonomyName);
        if (keysForTaxonomiesToUpdate.length){
            Promise.all(keysForTaxonomiesToUpdate.map(t=>{
            return loadTaxonomyCounts(t, filterRows, projectId).then(res=> ({values: res.data.result, taxonomyName: t}))
            })).then(res=>{
                debugger;
                console.log(res);
                const loaded = {};
                res.map(x=>{
                    loaded[x.taxonomyName] = x.values;
                });

                // now need to merge the loaded with userUpdatedTaxonomies - based on the keys !
                debugger;
                console.log('loaded', loaded);
                console.log('userUpdatedTaxonomies', userUpdatedTaxonomies);

                const updatedKeys = keysForTaxonomiesToUpdate;

                reapplyCheckmarksToLoadedTaxonomyValues(updatedKeys, newCheckedTaxonomies, loaded);


                if (updateModifiedTaxonomy){
                    setLoadedTaxonomies(loaded);

                    if (props.updateLoadedAndCheckedTaxonomies){
                        props.updateLoadedAndCheckedTaxonomies(loaded, newCheckedTaxonomies, taxonomyDateFilterObj);
                    }
                }
                else {
                    const newLoadedTaxonomies = {...loaded, [modifiedTaxonomyName]: userUpdatedTaxonomies[modifiedTaxonomyName]};
                    setLoadedTaxonomies(newLoadedTaxonomies);

                    if (props.updateLoadedAndCheckedTaxonomies){
                        props.updateLoadedAndCheckedTaxonomies(newLoadedTaxonomies, newCheckedTaxonomies, taxonomyDateFilterObj);
                    }

                }

            }).catch(err=>{
                debugger;
                console.log(err);
            });
        }
        else {
            const newLoadedTaxonomies = {...loadedTaxonomies, [modifiedTaxonomyName]: userUpdatedTaxonomies[modifiedTaxonomyName]};


            setLoadedTaxonomies(newLoadedTaxonomies);

            if (props.updateLoadedAndCheckedTaxonomies){
                props.updateLoadedAndCheckedTaxonomies(newLoadedTaxonomies, newCheckedTaxonomies, taxonomyDateFilterObj);
            }

        }

    };

    
    const [projectId, setProjectId] = useState(props.projectId);

    useEffect(()=>{
        setProjectId(props.projectId);
    }, [props.projectId]);

    const getTaxonomyPanelTitle = tName=>{
        const countChecked = checkedTaxonomies[tName]?.length || 0;
        if (countChecked){
            return `${tName} (${countChecked})`
        }
        else {
            return tName;
        }
    };

    const handleClearAllTaxonomyFiltersClicked = ()=>{
        const newTaxonomies = {...loadedTaxonomies};
        const keys = Object.keys(newTaxonomies);
        keys.forEach(tName => {
            newTaxonomies[tName] = newTaxonomies[tName].map(v=>({...v, checked: false}));
        });
        setLoadedTaxonomies(newTaxonomies);
        setCheckedTaxonomies([]);
        setTaxonomyDateFilterObj(null);
        props.clearAllTaxonomyFilters();
    };

    return (<>
    <div style={{display: 'block', overflowY: 'hidden', width: '100%'}}>

        <div style={{display: 'flex', padding: '6px', justifyContent: 'space-evenly'}}>
            <div>
            <TooltipContainer
                title='Clear all Taxonomy Filters'
                placement="bottom"
                overlayStyle={{width:'auto'}}
            >
                <Button style={{width: '100%', padding: '4px', height: '32px'}} onClick={handleClearAllTaxonomyFiltersClicked} >Clear All</Button>
            </TooltipContainer>
            </div>
            <div>
                <TooltipContainer
                    title='Apply selected Taxonomy Filters'
                    placement="bottom"
                    overlayStyle={{width:'auto'}}
                >
                    <Button style={{width: '100%', padding: '4px', height: '32px'}} onClick={props.runSearch} >Apply</Button>
                </TooltipContainer>
            </div>
        </div>



        <div style={{ display: 'block', maxHeight: 'calc(100vh - 10rem)'}}>

            <CustomScrollbar className="filter-scroller">
                <div>


            {props.needDateFilter ? <> <TaxonomyFilterDate updateTaxonomiesWithDates={updateTaxonomiesWithDates} dateFilterObj={taxonomyDateFilterObj}/> </> : null}


            <Collapse onChange={onChange} activeKey={Object.keys(loadedTaxonomies)} >

            {taxonomies.map((t, ix)=>{
                return(


                <Panel header={getTaxonomyPanelTitle(t.name)} key={t.name} style={{borderBottom: '1px solid hsla(0,0%,80%,.5)'}}>

                    <TaxonomyFilterCheckboxRow
                        sortOrder={props.sortOrder}
                        taxonomyName={t.name}
                        loadedTaxonomies={loadedTaxonomies}
                        updateTaxonomies={updateTaxonomies}
                    />
                </Panel>);

            })}
            </Collapse>
            </div>
            </CustomScrollbar>
        </div>


    </div>
    </>);
}