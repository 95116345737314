import { ROUTES } from "../../Constants/Routes/Routes.constants";
const commonRoutes = [
  {
    icon: "SVG-article",
    name: "Articles",
    route: ROUTES.HOME,
  },
  {
    icon: "SVG-dashboard",
    name: "Dashboards",
    route: ROUTES.DASHBOARD,
  },
  {
    icon: "SVG-setting",
    name: "Settings",
    route: ROUTES.SETTINGS,
  },

];

const adminRoutes = [
  {
    icon: "SVG-userLogs",
    name: "User Logs",
    route: ROUTES.USER_LOGS,
  },
  {
    icon: "",
    name: "Download Preview",
    route: ROUTES.DOWNLOAD_PREVIEW,
  },
  {
    icon: "",
    name: "View Tab Insights",
    route: ROUTES.VIEW_IN_NEW_TAB,
  },
  {
    icon: "",
    name: "View Tab Articles",
    route: ROUTES.VIEW_TAB_ARTICLES,
  },
  {
    icon: "SVG-user",
    name: "Users",
    route: ROUTES.USERS,
  }
];

export const ROUTES_CONFIG = {
  Admin: [
    ...commonRoutes,
    ...adminRoutes,
  ],
  Analyst: [
    ...commonRoutes,
    {
      icon: "",
      name: "Download Preview",
      route: ROUTES.DOWNLOAD_PREVIEW,
    },
  ],
  SuperAdmin: [
    ...commonRoutes,
    ...adminRoutes,
    {
      icon: "SVG-user-group",
      name: "User Groups",
      route: ROUTES.USER_GROUPS,
    },
  ],

};

export const SUB_ROUTES_CONFIG = [
  {
    pathName: "/buildinsight",
    urlSearch: "",
    route: ROUTES.INSIGHTS,
  },
  {
    pathName: "/createinsight",
    urlSearch: "",
    route: ROUTES.INSIGHTS,
  },
  {
    pathName: "/",
    urlSearch: "",
    route: ROUTES.HOME,
  },
  {
    pathName: "/builddashboard",
    urlSearch: "",
    route: ROUTES.DASHBOARD,
  },
  {
    pathName: "/viewdashboard",
    urlSearch: "",
    route: ROUTES.DASHBOARD,
  },
  {
    pathName: "",
    urlSearch: "insight",
    route: ROUTES.INSIGHTS,
  },
  {
    pathName: "",
    urlSearch: "dashboard",
    route: ROUTES.DASHBOARD,
  },
];
