import React, { useEffect, useMemo, useState } from "react";
import parse from "html-react-parser";

import TooltipContainer from "../../Tooltip";
import {
  formatFilterLabel,
  formatMetadataKeyword,
} from "../../../utils/formatFilterLabel";
import {
  EXTERNAL_TAB,
  NEWS_TAB,
  SEARCH_CARD_METADATA_ITEMS_BLACK_LIST,
} from "../../../Constants/Home/Home.constants";
import { removeEMTag } from "../../../utils/dataFormatter";

import global from "../../../styles/globel/style.module.scss";

const METADATA_VIEW_LESS_COUNT = 6;

const shades = [];
const count = 10;
const hue = 280;

for (let i = 0; i < count; i++) {
    const lightness = 100 - i*5;
    const saturation = 100 - Math.round((50 / count) * i);
    shades.push(`hsl(${hue}, ${saturation}%, ${lightness}%)`);
}


const colorCount = 10;

const colors = [
  {
    color: "hsl(240, 100%, 55%)",   // Very dark blue (almost black)
    lightFont: true
  }, 
  {
    color: "hsl(240, 100%, 60%)",  // Dark blue
    lightFont: true
  },
  {
    color: "hsl(240, 100%, 65%)",
    lightFont: true
  },
  {color: 
    "hsl(240, 100%, 70%)",
    lightFont: true
  },
  {
    color: "hsl(240, 100%, 75%)",
    lightFont: true
  },
  {
    color: "hsl(240, 100%, 80%)",    // Medium blue
  }, 
  {
    color: "hsl(240, 100%, 85%)",
    
  }, 
  {
    color: "hsl(240, 100%, 90%)",
    
  },
  {
    color: "hsl(240, 100%, 95%)",
  
  },
  {
    color: "hsl(240, 100%, 100%)", // Very light blue (almost white)

  }  
];

const TagContent = props=>{
  const {meta, cData} = props;
  
  return (<div className={global.metaListTagElement} style={{background: cData.color}}>
  <TooltipContainer title={formatMetadataKeyword(formatFilterLabel(meta.type))}>
    
    <span style={cData.lightFont ? {color: "white"} : null}>
      {parse(meta.data)}
      {window.showScores ? <>&nbsp; score: {meta.score}</> : null}
    </span>
    
  </TooltipContainer>
</div>);
};


export function MetadataBlock({ data, currentTabType, metaDataList, expandAllTags }) {
  const [isViewMore, setIsViewMore] = useState(expandAllTags);
  const [slicedData, setSlicedData] = useState(Array());

  const formattedData = useMemo(
    () =>{
      const items = 
      getFormattedMetadata(
        removeEMTagFromSourceType(data, currentTabType),
        metaDataList
      );

      const sortedObj = {};
      for(let i = 0; i < items.length; i++){
        const type = items[i].type;
        const data = items[i].data;
        const score = items[i].score || 0;

        if (sortedObj[type]){
          sortedObj[type].push({data, score});
        }
        else {
          sortedObj[type] = [{data, score}];
        }
      }

      const sortedItems = [];
      const sortedKeys = Object.keys(sortedObj).sort((a, b)=>{
        if (a<b){
          return -1;
        }
        if (a>b){
          return 1;
        }
        return 0;
      });
      
      for (let i = 0; i < sortedKeys.length; i++){
        const sortedValues = sortedObj[sortedKeys[i]].sort((a, b)=>{
          if (a.score > b.score){
            return -1;
          }
          if (a.score < b.score){
            return 1;
          }

          if (a.data > b.data){
            return 1
          }
          if (a.data < b.data){
            return -1
          }
          return 0;
        });
        for (let j = 0; j < sortedValues.length; j++){
          sortedItems.push({type: sortedKeys[i], data: sortedValues[j].data, score: sortedValues[j].score});
        }
      }
      return sortedItems;
    },

    [data]
  );

  useEffect(() => {
    let slicedData = formattedData;
    if (!isViewMore) slicedData = slicedData.slice(0, METADATA_VIEW_LESS_COUNT);
    setSlicedData(slicedData);
  }, [isViewMore]);

  useEffect(()=>{
    if (expandAllTags){
      if (!isViewMore){
        setIsViewMore(true);
      }
    }
    else {
      if (!expandAllTags){
        if (isViewMore){
          setIsViewMore(false);
        }
      }
    }
  }, [expandAllTags]);


  const toggleViewMore = () => {
    setIsViewMore(!isViewMore);
  };

  let typeCounter = 0;
  let prevType = '';
  const getShade = item=>{
    debugger;
    if (item.type !== prevType){
      prevType = item.type;
      typeCounter = 0;
    }

    const result = shades[7 - typeCounter];
    typeCounter++;
    return result;
  };

  let typeCounter1 = 0;
  let prevType1 = '';

  const getColor = item=>{
    
    if (item.type !== prevType1){
      prevType1 = item.type;
      typeCounter1++;
    }

    if (typeCounter1 == 10 ){
      typeCounter1 = 0;
    }

    const idx = colorCount - 1 - typeCounter1;
    
    const result = colors[idx];

    return result;
  };

  return (
    <>
      <ul className={global.metaTagList}>
        {slicedData.map((meta, i) => (
          <li key={i + meta?.data}>
            <TagContent meta={meta} cData={getColor(meta)}/>
          </li>
        ))}
      </ul>
      {Object.keys(formattedData).length > METADATA_VIEW_LESS_COUNT && (
        <a className={global.metaTagMoreLink} onClick={toggleViewMore}>
          {isViewMore ? <>View Less</> : <>View More</>}
        </a>
      )}
    </>
  );
}

/**
 *
 * @param {{}} data
 * @returns {{type: string, data: string}[]}
 */
const getFormattedMetadata = (data, metaDataList) => {

  const allDocumentProperties = Object.keys(data || {});
  const notBlacklistedProperties = allDocumentProperties.filter((key) => !SEARCH_CARD_METADATA_ITEMS_BLACK_LIST.includes(key));

  const documentPropsThatAreMetadata = notBlacklistedProperties.filter(key=>{
    const result = metaDataList.includes(key);
    return result;
  });

  const documentMetadataTagsWithValues = documentPropsThatAreMetadata.filter(key=>{
    const propValue = data[key];
    if (Array.isArray(propValue)){
      const needThisProp = propValue.some(x=>x != '');
      return needThisProp;
    }
    else{
      const needThisProp = !["", undefined, null].includes(propValue);
      return needThisProp;
    }
  });

  const metadataTagsWithScore = documentMetadataTagsWithValues.map(tag=>{
    const tagValue = data[tag];

    const minScoreKey = `${tag}_min_score`;
    const maxScoreKey = `${tag}_max_score`;
    const allScoreKey = `${tag}_score`;
    const allValueScores = data[allScoreKey] || (Array.isArray(tagValue) ? [] : 0);
    const minScore = data[minScoreKey] || 0;
    const maxScore = data[maxScoreKey] || 0;
    const resultTag = {tag, minScore, maxScore, allValueScores};
    return resultTag;
  });

   
  const resultItems = [];
  for (let i = 0; i < metadataTagsWithScore.length; i++){
    const {tag, minScore, maxScore, allValueScores} = metadataTagsWithScore[i];

    const tagValue = data[tag];
    if (Array.isArray(tagValue)){
      const tagItems = tagValue.map((x, j)=>{
        return {
          type: tag, data: x, score: allValueScores[j] || 0
        }
      });
      resultItems.push(...tagItems);
    }
    else {
      const tagItem = {
        type: tag, data: tagValue, score: allValueScores 
      };
      resultItems.push(tagItem);
    }
  }

  return resultItems;

};

/**
 *
 * @param {{[key: string]: string | number | string[]}} data
 * @param {'All Articles' |'News' | 'External Reports'} currentTabType
 * @returns
 */
const removeEMTagFromSourceType = (data, currentTabType) => {
  if (currentTabType === EXTERNAL_TAB || currentTabType === NEWS_TAB) {
    const sourceType = data["Source Type"];
    data["Source Type"] = Array.isArray(sourceType)
      ? sourceType.map((type) => removeEMTag(type))
      : removeEMTag(String(sourceType));
  }
  return data;
};
