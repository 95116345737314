import { useMemo } from "react";
import moment from 'moment';
import { TaxonomyFilterOuterChipItem } from "./TaxonomyFilterOuterChipItem";
import style from "./style.module.scss";

export const TaxonomyFilterChips = (props)=>{

    const loadedTaxonomies = props.loadedTaxonomies || {};
    const checkedTaxonomies = props.checkedTaxonomies || {};

    const { taxonomyDateFilterObj, updateLoadedAndCheckedTaxonomies} = props;

    const items = Object.keys(checkedTaxonomies);

    const dateItems = useMemo(()=>{
        if (!taxonomyDateFilterObj){
            return [];
        }
        const result = [];
        if (taxonomyDateFilterObj.dateRangeValueFrom){
            const d = moment(taxonomyDateFilterObj.dateRangeValueFrom, taxonomyDateFilterObj.dateFormat).format('DD/MM/YYYY');
            result.push({value: `From: ${d}`});
        }
        if (taxonomyDateFilterObj.dateRangeValueTo){
            const d = moment(taxonomyDateFilterObj.dateRangeValueTo, taxonomyDateFilterObj.dateFormat).format('DD/MM/YYYY');
            result.push({value: `To: ${d}`});
        }
        return result;
    }, [taxonomyDateFilterObj]);


    const handleInnerChipClosed = (taxonomyName, closedTaxonomyValue)=>{
        debugger;
        const newLoaded = {};
        const newChecked = {};

        const keys = Object.keys(loadedTaxonomies);
        for (let k = 0; k < keys.length; k++){
            const key = keys[k];
            
            if (key == taxonomyName){
                let valuesForNewLoaded = loadedTaxonomies[key].map(x=>({...x, checked: (x.value == closedTaxonomyValue) ? false : x.checked}));
                
                newLoaded[key] = valuesForNewLoaded;
                
                newChecked[key] = checkedTaxonomies[key].filter(x => x.value != closedTaxonomyValue);
            }
            else {
                newLoaded[key] = loadedTaxonomies[key];
                newChecked[key] = checkedTaxonomies[key];
            }
        }
                

        updateLoadedAndCheckedTaxonomies(newLoaded, newChecked, {...taxonomyDateFilterObj}, true);
    };

    const handleOuterChipClosed = (taxonomyName, isDateChip)=>{
        debugger;

        if (isDateChip){
            updateLoadedAndCheckedTaxonomies(loadedTaxonomies, checkedTaxonomies, null, true);
        }
        else{
        
            const newLoaded = {};
            const newChecked = {};

            const keys = Object.keys(loadedTaxonomies);
            for (let k = 0; k < keys.length; k++){
                const key = keys[k];
                if (key != taxonomyName){
                    newLoaded[key] = loadedTaxonomies[key];
                    newChecked[key] = checkedTaxonomies[key];
                }
                else {
                    newLoaded[key] = loadedTaxonomies[key].map(x=>({...x, checked: false}));
                }
            }
            updateLoadedAndCheckedTaxonomies(newLoaded, newChecked, {...taxonomyDateFilterObj}, true);
        }


    };

    const handleDateChipOuterClosed = ()=>{
        debugger;
        // outer chip displays date from and to, and individual dates cannot be closed separately 
        updateLoadedAndCheckedTaxonomies({...loadedTaxonomies}, {...checkedTaxonomies}, null);
    }
    

    return (<div className={style.filtertagsWrapper}>
        <ul className={style.filterTags}>
            {dateItems.length ? 
            <li key={'taxonomy-filter-outer-date-chip'}>
                <TaxonomyFilterOuterChipItem 
                    isDateChip={true}
                    taxonomyValues={dateItems}
                    handleOuterChipClosed={handleOuterChipClosed}
                />
            </li> : null}
            
            {items.map((taxonomyKey, ix)=>{
                return <li key={`taxonomy-filter-outer-chip-${ix}`}>
                    <TaxonomyFilterOuterChipItem taxonomyKey={taxonomyKey}
                        taxonomyValues={(checkedTaxonomies[taxonomyKey] || []).filter(x=>x.checked)} 
                        handleInnerChipClosed={handleInnerChipClosed}
                        handleOuterChipClosed={handleOuterChipClosed}
                    />
                </li>
            })}
        </ul>
    </div>);    
}

 