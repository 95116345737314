
import { useEffect, useState } from "react";
import { FilterSortButton2 } from "./Childern/SortControls/FilterSortButton2";
import style from "./Childern/SortControls/style.module.scss";
export const SortContent2 = props=>{

    const initialSortSettings = {sortingType: 'publishdate', order: 'up'};

    const [sortingData, setSortingData] = useState({});
    const [currentSortSettings, setCurrentSortSettings] = useState(initialSortSettings);

    const [showSorting, setShowSorting] = useState(false);

    useEffect(()=>{

        const newSortingData = {...sortingData, [props.sortingType]: props.sortingOrder}; 
        setSortingData(newSortingData);
        setCurrentSortSettings({sortingType: props.sortingType, order: props.sortingOrder});


    }, [props.sortingType, props.sortingOrder]);

    const handleSortingDirection = sortingKey =>{
        debugger;
        console.log(sortingKey);

        const newOrder = currentSortSettings.order == 'up' ? 'down' : 'up';
        const newCurrentSortSetting = {...currentSortSettings, order: newOrder};
        setCurrentSortSettings(newCurrentSortSetting);
        
        const newSortingData = {...sortingData, [sortingKey]: newOrder}; 

        setSortingData(newSortingData);

        setShowSorting(false);

        props.handleSortingOrderChange(newOrder);

    };

    const handleChangeSortingType = newSortingType=>{
        debugger;


        if (newSortingType == currentSortSettings.sortingType){
            handleSortingDirection(newSortingType);
            return;
        }
        const storedOrder = sortingData[newSortingType];
        if (!storedOrder){
            const newSortingData = {...sortingData, [newSortingType]: 'up'}; // small to large, pointer up
            setSortingData(newSortingData);
            setCurrentSortSettings({sortingType: newSortingType, order: 'up'});
        }
        else{
            setCurrentSortSettings({sortingType: newSortingType, order: storedOrder});
        }        

        setShowSorting(false);
        props.handleSortingTypeChange(newSortingType);
    }


    const disabledClass = props.loading ? style.disabled : "";
    return (<>

    <div className={style.controlsBox + " " + disabledClass}>
            <FilterSortButton2
                showSorting={showSorting}
                handleSortingVisible={value=>setShowSorting(value)}

                currentSortSettings={currentSortSettings}
                handleChangeSortingType={handleChangeSortingType}
                handleSortingDirection={handleSortingDirection}
                
                isRelevanceVisible={props.sortByRelevanceAvailable}

            />
          </div>

    </>);        
};