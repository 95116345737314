import { DatePicker } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import global from "styles/globel/style.module.scss";

export const BetweenDates2 = props=>{

    const [dateObj, setDateObj] = useState({}); // object with to and from keys, and values are moment objects

    useEffect(()=>{
        setDateObj({...props.dateFilterObject});
    }, [props.dateFilterObject])

    const onDateChangeFrom = value=>{
        const newDateObj = {...dateObj, from: value};
        setDateObj(newDateObj);
        if (props.onDateChanged){
            props.onDateChanged(newDateObj);
        }
    };

    const onDateChangeTo = value=>{
        const newDateObj = {...dateObj, to: value};
        setDateObj(newDateObj);
        if (props.onDateChanged){
            props.onDateChanged(newDateObj);
        }
    };

    const disabledDateTodayAfter = (current) => {
        return current && moment(current).startOf("day") > moment().endOf("day");
    };

    return <>

    <div style={{display: 'flex', position: 'relative', backgroundColor: props.noBackground ? undefined : 'rgba(161, 157, 236, 0.5)', padding: '8px'}}>
        <div style={{width: '50%'}}>
            <div>
                From
            </div>
            <div style={{paddingRight: '4px'}}>
                <DatePicker
                    size={"small"}
                    className="filter-picker"
                    onChange={onDateChangeFrom}
                    value={dateObj.from}
                    format={props.dateFormat}
                    placeholder={props.dateFormat}
                    suffixIcon={false}
                    disabledDate={disabledDateTodayAfter}
                    showNow={true}
                    //key={keyRenderFrom}
                />
                <span className={global.controlError}>
                    {props.fromError}
                </span>
            </div>

        </div>
        <div style={{width: '50%'}}>
            <div>
                To
            </div>
            <div style={{paddingLeft: '4px'}}>
                <DatePicker
                    size={"small"}
                    className="filter-picker"
                    onChange={onDateChangeTo}
                    value={dateObj.to}
                    format={props.dateFormat}
                    placeholder={props.dateFormat}
                    suffixIcon={false}
                    disabledDate={disabledDateTodayAfter}
                    // key={keyRenderTo}
                />
                <span className={global.controlError}>
                    {props.toError}
                </span>
            </div>

        </div>

    </div>
    </>
}