export const BASE_URL = {
  ACCOUNT: "/api/Account",
  DASHBOARD: "/api/dashboards",
  INSIGHT: "/api/insights",
  LOGIN: "/api/account",
  SEARCH: "/api/search",
  SETTINGS: "/api/users",
  USER_PROJECT: "/api/UserProject",
  USERS: "/api", // used in account.js because it calls different controllers. Nothing to do with users.
  USER_GROUPS: "/api/UserGroups",
};

export const CANCEL_EXCLUDED_APIS = [
  "GetFieldData",
  "AddtoExcludeList",
  "GetInsightsById",
  "Getkeyphrasecloud",
  "GetHeatMapData",
  "insightsRequestDataCount",
  "InsightsRequest",
  "GetFullArticle",
];
