import { Radio } from "antd";
import style from "./style.module.scss";
import { BetweenDates2 } from "./BetweenDates2";

export const DateOptionRange = props=>{

    const handleDatesChange = (newValue)=>{
        // newValue is {from: moment, to: moment}
        console.log(newValue);

        props.handleDatesChanged(newValue);
    };

    return (<>
    <div className={style.datesHeader}>
        <Radio className={style.radio} onChange={e=>props.handleDateFilterTypeChanged(e.target.value)} checked={props.dateFilterType == 0} value={0}>
            <span>Select a date range</span>
        </Radio>
    </div>

    {props.dateFilterType == 0 ? <BetweenDates2 
        dateFormat={props.dateFormat}
        dateFilterObject={props.dateFilterObject}
        onDateChanged={handleDatesChange}
    /> : null}
    
    </>)
}

