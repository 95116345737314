import { ArticleTile } from "Components/SearchCard/Children/ArticleTile";
import { GridIconSection } from "../SearchControls/GridIconSection";
import { SearchResultsContainer } from "./SearchResultsContainer";
import { TabElement } from "Components/TabElement";
import ButtonElement from "Components/ButtonElement";
import { COLLAPSE_TAGS, EXPAND_ALL_TEXT, EXPAND_TAGS, MINIMIZE_ALL } from "Constants/Home/Home.constants";
import style from "./style.module.scss";
import { MyTotalCount } from "../SearchContent/MyTotalCount";
import { Pagination2 } from "Components/Pagination/Pagination2";
import { useMemo, useState } from "react";
import { SelectionAndTaggingButtons } from "./SelectionAndTaggingButtons";
import ModalWindow from "Components/ModalWindow";
import CustomScrollbar from "Components/CustomScrollbar";
import TagBlock from "Components/TagBlock";
import { handleSaveHelper, handleTagRemoveHelper, tagInputHelper } from "Components/SearchCard/Helper/tagHelper";
import { addTags } from "api/search";
import { CloudSearchWordsBanner } from "../CloudSearchWordsBanner/CloudSearchWordsBanner";
import { ExcludedWordsBanner } from "../CloudSearchWordsBanner/ExcludedWordsBanner";

export const TabsWithSearchResults = props=>{


    const searchItems = props?.searchResult.items || [];

    const paginationProps = {
        current: props.currentPage,
        pageSize: props.pageSize,
        total: props.totalCount, 
        onChange: props.handlePageNumberChange, 
        showSizeChanger: props.showPagesizeChanger
    };

    const numberOfLines = useMemo(()=>{
        let numberOfLines = 4; 
        const { lineCount_2x2, lineCount_3x4, lineCount_ListView } = props.viewLineCountSettings;
        
        if (props.viewType == 'gridTwo'){
            numberOfLines = lineCount_2x2;
        }

        if (props.viewType == 'gridThree'){
            numberOfLines = lineCount_3x4;
        }

        if (props.viewType == 'gridOne'){
            numberOfLines = lineCount_ListView;
        }

        return numberOfLines;
        
    }, [props.viewLineCountSettings, props.viewType]);

    const [tagSelectedDocumentsModal, setTagSelectedDocumentsModal] = useState(null);

    const [tags, setTags] = useState([]);

    const [tagInfoMessage, setTagInfoMessage] = useState([""]);

    const tagSelected = ()=>{
        // show a modal window for tags 
        setTagSelectedDocumentsModal([...props.markedDocumentIds]);
    };

    const handleSave = ()=>{
        debugger;
        // saving tags and documents

        handleSaveHelper({
            uids: tagSelectedDocumentsModal,
            allArticleList: props.searchResult.items,
            setSearchLocalData: (...x)=>{
                
            },
            addTagsHandler: (data)=>{

                addTags({ 
                    fieldname: 'keywords', 
                    emailId: '', 
                    uids: data.uids.map(x=>`${x}`),
                    user_tags: data.tags.map(x=>`${x.value}`), 
                    uidsReturn: data.allArticleList.map(x=>`${x.DocumentId}`), 
                    append: true 
                });

                const newSearchResultItems = props.searchResult.items.map(doc=>{
                    
                    if (data.uids.some(x=>x== doc.DocumentId)){
                        const prevTags = doc.user_tags || [];
                        doc.user_tags = [...prevTags, ...(data.tags.map(x=>`${x.value}`))];
                    }
                    
                    return doc;
                });

                props.setSearchResult({...props.searchResult, items: newSearchResultItems});

            },
            tagInfoMessage,
            tags,
            setTagInfoMessage,
            searchType: 'normalSearch',
            append: true,
            clearSelection: true
          });
          setTagSelectedDocumentsModal(null);
    };

    const handleCancel = ()=>{
        // just hide the modal
        setTagSelectedDocumentsModal(null);
    };

    const handleChange = ({ event, index, name }) =>{
        tagInputHelper({event, index, name, tags, setTags, setTagInfoMessage, tagInfoMessage});
    };

    const handleTagRemove  = (index)=>{
        handleTagRemoveHelper({
            setTags,
            tags,
            index,
            tagInfoMessage,
            setTagInfoMessage,
          });
    };

    const handleTagEmptyAdd = ()=>{
        debugger;
        setTags([{ value: "", type: "public" }]);
    };

    

    const tabContent = (<div>
        <div style={{ width: '100%', display: 'block'}}>
            <div style={{margin: '0 40px 0 20px', display: 'flex', flexDirection: 'column', }}>

                <div style={{marginTop: '12px', display: 'flex', width: '100%'}}>
                    <CloudSearchWordsBanner cloudSearchWords={props.cloudSearchWords} cloudTaxonomy={props.cloudTaxonomy} handleClearCloudSearchWordsClicked={props.handleClearCloudSearchWordsClicked}/>
                </div>
                <div style={{marginTop: '12px', display: 'flex', width: '100%'}}>
                    <ExcludedWordsBanner excludedWords={props.excludedWords} cloudTaxonomy={props.cloudTaxonomy} 
                        clearExcludedWords={props.handleClearExcludedWordsClicked}
                    />
                </div>

                <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <MyTotalCount totalCount={props.searchResult.totalCount} data={props.searchResult.items}/>
                    <div>
                    <Pagination2 {...paginationProps} />
                </div>
                </div>


                

            </div>
            
        </div>
        <div style={{marginTop: '20px'}}>

            <SearchResultsContainer viewType={props.viewType}>

                {searchItems.map((item, ix)=>{
                    return (<ArticleTile 

                        
                                markedDocumentIds={props.markedDocumentIds} 
                                setMarkedDocumentIds={props.setMarkedDocumentIds}
                    
                                key={`search-item-${ix}`} 
                                item={item} 
                                dateFormat={props.dateFormat}

                                metaDataList={props.metaDataList} 
                                allProjectTaxonomies={props.allProjectTaxonomies}

                                simpleSearchText={props.simpleSearchText}
                                advancedSearchRows={props.advancedSearchRows}
                                showFullContent={props.showFullContent}
                                showAllTags={props.showAllTags}
                                updateUserTags={props.updateUserTags}
                                numberOfLines={numberOfLines}
                            />);
                })}
            </SearchResultsContainer>

        </div>
        
    </div>);

    const tabBarExtraContent = (<div className={style.tabRightBar}>

            <SelectionAndTaggingButtons 
                documentsInPage={props.searchResult}
                markedDocumentIds={props.markedDocumentIds} 
                setMarkedDocumentIds={props.setMarkedDocumentIds}
                setTagSelectedDocumentsModal={setTagSelectedDocumentsModal}
                tagSelected={tagSelected}
            />
            
            <ButtonElement size='small' onClick={()=>props.setShowAllTags(!props.showAllTags)}>
                {props.showAllTags ? 'Collapse Tags' : 'View All Tags'}
            </ButtonElement>
            
            <ButtonElement size='small' onClick={props.toggleShowFullContent}>
                {props.showFullContent ? 'Hide full content' : 'View full content'}
            </ButtonElement>
            
            {/* gridOne - list view
            gridTwo - 2x2
            gridThree - 3x4 */}

            <GridIconSection
                handleListView={props.handleViewTypeChange}
                listViewType={props.viewType}
            />

    </div>);

    return (<>
    
    <TabElement
        
        activeKey={props.activeTabKey}
        onChange={props.handleTabChange}
    
        items={[
            {
                key: 'All Articles', 
                label: 'All Articles',
                disabled: false, 
                children: tabContent

            }, {
                key: 'News', 
                label: 'News',
                disabled: false, 
                children: tabContent

            }, 
            {
                key: 'External', 
                label: 'External',
                disabled: false, 
                children: tabContent
            }
        ]}

        tabBarExtraContent={tabBarExtraContent}
    >
    </TabElement>  
    {tagSelectedDocumentsModal && tagSelectedDocumentsModal.length ? <>
        
    
    <ModalWindow 
        open={true}
        title={"Add tags"}
        width={600}
        onOk={handleSave}
        onCancel={handleCancel}
        isNotClosable={true}
        okText={"Apply"}
        cancelText="Cancel"
        confirmLoading={props.tag?.loading}
        >
            <CustomScrollbar className="add-tags-scroller">
                <TagBlock
                    tags={tags}
                    handleTagAdd={()=>setTags([...tags, { value: "", type: "public" }])}
                    handleChange={handleChange}
                    tagInfoMessage={tagInfoMessage}
                    handleTagRemove={handleTagRemove}
                    keyEnter={handleSave}
                    handleTagEmptyAdd={handleTagEmptyAdd}
                />
            </CustomScrollbar>
    </ModalWindow>

    </> : null}

    </>);
}