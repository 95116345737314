import ModalWindow from "Components/ModalWindow";
import { SavedQueriesModalContent } from "./SavedQueriesModalContent";

export const SavedQueriesModal = props=>{

    return (<>
    <ModalWindow 
        open={true}
        width={900}
        okText="Close"
        onOk={() => props.handleCloseModal()}
        isNonMaskable={true}
        isNotClosable={true}
        isHideCancelButton={true}
    >
        <SavedQueriesModalContent projectId={props.projectId} 
            isGroupAdmin={props.isGroupAdmin}
            userProfileId={props.userProfileId}
            setAsGroupDefaultQuery={props.setAsGroupDefaultQuery}
            defaultQueryId={props.defaultQueryId}
            handleLoadQuery={props.handleLoadQuery}
        />
    </ModalWindow>
    </>)
};