import ButtonElement from "Components/ButtonElement"

export const DashboardToolbar = props=>{
    const editMode = props.editMode || false;
    // editMode is when we can modify a dashboard - drag tiles, add/remove insights etc
    // We get into edit mode either by clicking "Edit" for an existing dashboard or when creating a new dashboard

    return <div style={{display: 'flex', backgroundColor: 'rgba(51, 44, 168, 0.06)', padding: '20px', width: '100%'}}>

        =={JSON.stringify(editMode)}==
    <ul style={{marginLeft: 'auto', display: 'flex'}}>

    {editMode ? <>
        

        <li style={{flex: '1 1 auto', margin: '5px'}}>
            <ButtonElement onClick={()=>props.clearAll()}>Clear All</ButtonElement>
        </li>
        <li style={{flex: '1 1 auto', margin: '5px'}}>
            <ButtonElement onClick={()=>props.saveDashboard()}>Save Results</ButtonElement>
        </li>
        <li style={{flex: '1 1 auto', margin: '5px'}}>
            <ButtonElement onClick={()=>props.addInsights()}>Add Insights</ButtonElement>
        </li>

        
        </> : <>

        <li style={{flex: '1 1 auto', margin: '5px'}}>
            <ButtonElement onClick={()=>props.closeDashboard()}>Cancel (close)</ButtonElement>
        </li>

        <li style={{flex: '1 1 auto', margin: '5px'}}>
            <ButtonElement onClick={()=>props.toEditMode()}>Edit</ButtonElement>
        </li>
        

        </>}
    </ul>
    </div>

}