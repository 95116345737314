import { useEffect, useMemo, useState } from "react";
import { addUserGroup2, deleteUserGroup, getUserGroupsPaginated, updateUserGroup2 } from "api/userGroups";
import { getSavedQueriesLookup } from "api/search";
import ButtonElement from "Components/ButtonElement";
import ContentBody from "Components/ContentBody"
import SVGIcons from "Components/SVGIcons";
import TableElement from "Components/TableElement";
import TooltipContainer from "Components/Tooltip";
import global from "../../styles/globel/style.module.scss";
import { notify } from "Components/Notification";
import { DEFAULT_PAGE_COUNT } from "constants";
import { EntityGridPagination } from "Routes/Users/GroupPagination";
import { EditGroupModal2 } from "./TableActionModal/EditGroupModal2";
import { DeleteUserGroupModal2 } from "./TableActionModal/DeleteUserGroupModal2";


const GroupRowActions = props=>{
    const canUpdate = true;
    const canDelete = true;
    return (
        <ul className={global.tableActions}>
        {canUpdate ? (<li>
            <TooltipContainer title={'Edit Group'} mouseLeaveDelay={0} placement='bottom'>
                <span onClick={(e) => { props.actionHandlers.handleUpdate(props.record) }}>
                   <SVGIcons type={'SVG-edit'} />
                </span>
            </TooltipContainer>
          </li>) : null}
        {canDelete ? (<li>
            <TooltipContainer title={'Delete Group'} mouseLeaveDelay={0} placement='bottom'>
                <span onClick={(e) => { props.actionHandlers.handleDelete(props.record) }}>
                    <SVGIcons type={'SVG-delete'} />
                </span>
            </TooltipContainer>
          </li>) : null}
      </ul>
    );
};

const makeColumns = (actionHandlers, sortSettings) => {

    const makeSortOrder = columnKey=>{
        return sortSettings.field === columnKey ? sortSettings.order == "desc" ? "descend" : "ascend" : "";
    };

    return [{
        title: 'Group Id', 
        dataIndex: 'id',
        render: (text)=><span>{text}</span>,
        sorter: true,
        sortOrder: makeSortOrder('id'),
        width: 100
    }, 
    {
        title: 'Name', 
        dataIndex: 'name',
        render: (text)=><span>{text}</span>,
        sorter: true,
        sortOrder: makeSortOrder('name')
    },
    {
        title: 'Default Search', 
        dataIndex: 'defaultQueryName',
        render: (text)=><span>{text}</span>,
        sorter: true,
        sortOrder: makeSortOrder('defaultQueryName')
    },  
    {
        title: 'Note', 
        dataIndex: 'note',
        render: (text)=><span>{text}</span>,
        sorter: true, 
        sortOrder: makeSortOrder('note')
    },
    {
        title: 'Actions',
        key: 'actions', 
        render: (text, record)=><GroupRowActions record={record} actionHandlers={actionHandlers}/>
    }]
};


export const UserGroups3 = props=>{

    const [isLoading, setIsLoading] = useState(false);
    const [groupList, setGroupList] = useState({items: [], totalCount: 0});
    const [savedQueries, setSavedQueries] = useState([]);

    const [errors, setErrors] = useState([]);

    const [pagination, setPagination] = useState ({ pageNumber: 1, pageSize: DEFAULT_PAGE_COUNT });

    const [sortSettings, setSortSettings] = useState({ field: 'id', order: 'asc' });

    const load = ()=>{
        setIsLoading(true);
        const payload = {
            pageSize: pagination.pageSize, 
            pageNumber: pagination.pageNumber, 
            sortField: sortSettings.field, 
            sortOrder: sortSettings.order, 
            projectId: props.projectId
        };

        Promise.all([getUserGroupsPaginated(payload), getSavedQueriesLookup()]).then(res=>{
            setGroupList({
                items: res[0].data.result,
                totalCount: res[0].data.totalCount
            });

            setSavedQueries(res[1].data);
            setIsLoading(false);
        });

        // getUserGroups2(payload).then(res=>{
        //     debugger;
        //     console.log(res);
        //     setGroupList({
        //         items: res.data.result,
        //         totalCount: res.data.totalCount
        //     });
        // });

    };

    useEffect(()=>{
        load();
    }, [pagination, sortSettings]);

    const [editModalVisible, setEditModalVisible] = useState(null);    
    const [deleteModalVisible, setDeleteModalVisible] = useState(null);

    
    const handleUpdate = (record)=>{
        setEditModalVisible({...record});
    };

    const handleDelete = (record)=>{
        setDeleteModalVisible({...record});
    };

    const columns = useMemo(()=>{
        const result = makeColumns({handleUpdate, handleDelete}, sortSettings);
        return result;
    }, [handleUpdate, handleDelete, sortSettings]);


    
    const confirmEditGroup = groupToEdit=>{
        debugger;
        setIsLoading(true);
        if (groupToEdit.id){
            updateUserGroup2({...groupToEdit, projectId: props.projectId }).then(res=>{
                debugger;
                console.log(res);
                if (res.data.errors && res.data.errors.length){
                    // show the errors somehow
                    setErrors(res.data.errors);
                    setIsLoading(false);
                }
                else{
                    setErrors([]);
                    load();
                    setEditModalVisible(null);
                    notify.success("Group updated");
                }
            }).catch(err=>{
                notify.error("Failed to update group");
                setIsLoading(false);
            });
        }
        else{
            addUserGroup2({...groupToEdit }).then(res=>{
                debugger;
                if (res.data.errors && res.data.errors.length){
                    // show the errors somehow
                    setErrors(res.data.errors);
                    setIsLoading(false);
                }
                else{
                    setErrors([]);
                    load();
                    setEditModalVisible(null);
                    notify.success("Group added");
                }
            }).catch(err=>{
                notify.error("Failed to add group");
                setIsLoading(false);
            });
        }
    };

    const confirmDeleteGroup = groupToDelete =>{
        debugger;
        setIsLoading(true);
        deleteUserGroup(groupToDelete.id).then(res=>{
            debugger;
            if (res.data.errors && res.data.errors.length){
                setErrors(res.data.errors);
                setIsLoading(false);
            }
            else{
                setErrors([]);
                load();
                setDeleteModalVisible(null);
                notify.success("Group deleted");
            }

        }).catch(err=>{
            debugger;
            notify.error("Failed to delete group");
            setIsLoading(false);
        });
    };


    const handlePaginationChange = (pageNumber, pageSize)=>{
        setPagination({ pageNumber, pageSize });
        
    };

    const handleSortingChange = (a, b, sorter)=>{
        const newSortSettings = {
            field: sorter.field,
            order: sorter.order == 'descend' ? 'desc' : 'asc'
        };
        setSortSettings(newSortSettings);
    };

    return (<ContentBody>
        <div>
            <ButtonElement disabled={isLoading} styleProps={{marginLeft: 'auto'}} onClick={()=>setEditModalVisible({})}>Add Group</ButtonElement>
        </div>

        <div className={global.paginationWrapper}>
            <div className={global.searchresultCount}>
            <span>
                {groupList.items.length ? `Showing ${groupList.items.length} out of ${groupList.totalCount} entries` : '' }
            </span>
            </div>
            <div className={global.searchresultPage}>
                {groupList.totalCount > DEFAULT_PAGE_COUNT ? <EntityGridPagination 
                    pagination={pagination} totalCount={groupList.totalCount} 
                    handlePaginationChange={handlePaginationChange}
                /> : null}
            </div>
        </div>

        {groupList.items ? <TableElement
            style={{width: 'calc(100vw - 110px)'}}
            scrollY={"calc(100vh - 270px)"}
            loading={isLoading}
            rowKey='id'
            columns={columns}
            data={groupList.items}
            onChange={handleSortingChange}
            tableLayout='fixed'
        /> : null}

        
        {editModalVisible ? <EditGroupModal2 isLoading={isLoading} savedQueries={savedQueries} group={editModalVisible} 
        onCancel={()=>{
            setErrors([]); 
            setIsLoading(false);
            setEditModalVisible(null);
        }} 
        onConfirm={confirmEditGroup} errors={errors}/> : null}
        
        {deleteModalVisible ? <DeleteUserGroupModal2 isLoading={isLoading} group={deleteModalVisible} 
        onCancel={()=>{
            setErrors([]);
            setIsLoading(false);
            setDeleteModalVisible(null);
        }} onConfirm={confirmDeleteGroup} errors={errors} /> : null}

    </ContentBody>);
}