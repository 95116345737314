import {APIInstance} from './apiInstance';
import {BASE_URL} from './constants';

// does not exist in API
// most likely UserProfileController.updateUserProfile
// or AccountController.UpdateUser
export const updateUserProfile = (payload) => {
  const instance = new APIInstance({
    baseURL: BASE_URL.ACCOUNT,
  });

  const api = instance.api;
  return api.patch('/userUpdate', payload);
};

export const changeUserProjects = (payload) => {
  const instance = new APIInstance({
    baseURL: BASE_URL.USERS,
  });

  const api = instance.api;
  return api.post("/account/updateUserProject", payload);
};

export const checkPassword = (payload) => {
  const instance = new APIInstance({
    baseURL: BASE_URL.USERS,
  });

  const api = instance.api;
  return api.post("/Account/checkpassword", payload);
};

export const resetPassword = (payload) => {
  const instance = new APIInstance({
    baseURL: BASE_URL.USERS,
  });

  const api = instance.api;
  return api.post("/Account/changepassword", { ...payload });
};
