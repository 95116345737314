import SVGIcons from "Components/SVGIcons"
import style from './style-button.module.scss';
import { Button } from "antd";

export const FilterButton = props=>{

    return <Button variant='solid' className={style.filterButton}
        onClick={props.onClick}
        style={{height: '32px'}}    
        icon={<div className={props.filterIsOn ? style.filterIsOn : null} style={{height: '12px', width: '12px', display: 'flex'}}><SVGIcons type={"SVG-arrow"} /></div>}
    >
        Filter
    </Button>
};
