import { useState } from "react";
import style from "./style.module.scss";
import { InputNumber } from "antd";
import ButtonElement from "Components/ButtonElement";
import { saveUserLinecountSettingsApi } from "api/userProfile";
import { useDispatch, useSelector } from "react-redux";
import { GetCurrentProfileAction } from "Redux/Actions";
import { LoadingOutlined } from "@ant-design/icons";

export const LinesCountSettings = props=>{

    const currentProjectId = useSelector((state) => state.Settings.profile.currentProject);

    const settings = useSelector(state=>state.Settings.userProjectSettings);

    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useDispatch();

    const viewLineCountSettings = settings.viewLineCountSettings || {};

    const [lineCount_2x2, setLineCount_2x2] = useState(viewLineCountSettings.lineCount_2x2 || 4);
    const [lineCount_3x4, setLineCount_3x4] = useState(viewLineCountSettings.lineCount_3x4 || 4);
    const [lineCount_ListView, setLineCount_ListView] = useState(viewLineCountSettings.lineCount_ListView || 4);

    const saveLineCountSettings = ()=>{
        setIsLoading(true);

        const payload = {
            lineCount_2x2, 
            lineCount_3x4, 
            lineCount_ListView, 
            projectId: currentProjectId
        };

        saveUserLinecountSettingsApi(payload).then(res=>{
            debugger;
            console.log(res);
            dispatch(GetCurrentProfileAction());
        }).catch(err=>{
            debugger;
            console.log(res);
        }).finally(()=>{
            setIsLoading(false);
        })
    };

    return (<>    

    <h4 style={{marginTop: '20px'}}>Lines of content in search results</h4>
    {isLoading ? <LoadingOutlined/> : null}
    <ul className={style.preferenceSettings__list}>
        <li style={{display: 'flex', flexDirection: 'row'}}>
            <div style={{minWidth: '80px', display: 'flex'}}>
                <label style={{margin: 'auto 0'}}>2x2</label>
            </div>
        
        <InputNumber 
            disabled={isLoading}
            onChange={value=>setLineCount_2x2(value)}
            value={lineCount_2x2} 
            min={1} max={20} 
        />
        </li>
        <li style={{display: 'flex', flexDirection: 'row'}}>
            <div style={{minWidth: '80px', display: 'flex'}}>
                <label style={{margin: 'auto 0'}}>3x4</label>
            </div>
        
        <InputNumber
            disabled={isLoading} 
            onChange={value=>setLineCount_3x4(value)}
            value={lineCount_3x4} 
            min={1} max={20} 
        />
        </li>
        <li style={{display: 'flex', flexDirection: 'row'}}>
            <div style={{minWidth: '80px', display: 'flex'}}>
                <label style={{margin: 'auto 0'}}>List View</label>
            </div>
        <InputNumber 
            disabled={isLoading}
            onChange={value=>setLineCount_ListView(value)}
            value={lineCount_ListView} 
            min={1} max={20} 
        />
        </li>
        <li>
        <ButtonElement type={'primary'} size='large' disabled={isLoading}
            children={'Update'} onClick={saveLineCountSettings} />
        </li>
    </ul>
</>);
}; 