
import style from "./style.module.scss";
import global from "styles/globel/style.module.scss";
import { WordCloud2 } from "Components/WordCloud/WordCloud2"
import { KEYS } from "dataKeys";
import { useEffect, useMemo, useState } from "react";
import ButtonElement from "Components/ButtonElement";
import { CloudSearchWordsBanner } from "Routes/Home/components/CloudSearchWordsBanner/CloudSearchWordsBanner";
import ApexHeatMap from "Components/ApexHeatMap";
import { BarChart } from "Components/Barchart";
import { random } from "lodash";
import { ExcludedWordsBanner } from "Routes/Home/components/CloudSearchWordsBanner/ExcludedWordsBanner";




const parentExtractor = (data) => {
    let matches = data.match(/\[(.*?)\]/);
    let subMatch;
    if (matches) {
        subMatch = matches[1];
    }
    return subMatch;
};


const capitalizeFirstLetter = (str)=>{
    return str.charAt(0).toUpperCase() + str.slice(1);
};

const wordCloudChildExtractor = (value) => {
    return capitalizeFirstLetter(value.split("[")[0]);
};

export const wordCloudDataFormatter = sourceValues=>
{
    let newItems = [];
    let list = JSON.parse(sessionStorage?.getItem(KEYS.WORD_CLOUD_TEMPORARY_LIST)) ?? [];
    let permittedValues = [];

    for (let i = 0; i < list.length; i++) {
      permittedValues[i] = list[i]["keyword"].toLowerCase();
    }

    sourceValues.map((item) => {
        let isExcluded = permittedValues.includes(item.key);
        if (!isExcluded) {
            let newObject = {
                text: wordCloudChildExtractor(item.taxonomyValue),
                key: item.taxonomyValue,
                value: item.count,
                type: item.taxonomyName,
                parent: parentExtractor(item.taxonomyValue) ?? undefined,
            };
            newItems.push(newObject);
        }
    });

    return newItems;
};

export const ChartContainer = props=>{

    const [chartItems, setChartItems] =  useState('');

    useEffect(()=>{
        let mappedItems;
        if (props.chartName == 'KEY_PHRASE_CLOUD'){
            mappedItems = wordCloudDataFormatter(props.items);
        }

        if (props.chartName == 'BAR_CHART'){
            mappedItems = props.items.map(x=>({key: x.taxonomyValue, value: x.count}));
        }

        if (props.chartName == 'HEAT_MAP'){
            mappedItems = props.items;
        }

        setChartItems(mappedItems);
    }, [props.items, props.chartName]);

    return (<>
        <div style={{marginTop: '12px', display: 'flex', width: '100%'}}>
            <CloudSearchWordsBanner cloudSearchWords={props.cloudSearchWords} cloudTaxonomy={props.cloudTaxonomy} 
                handleClearCloudSearchWordsClicked={props.handleClearCloudSearchWords}
            />
        </div>
        <div style={{marginTop: '12px', display: 'flex', width: '100%'}}>
            <ExcludedWordsBanner excludedWords={props.excludedWords} cloudTaxonomy={props.cloudTaxonomy} 
                clearExcludedWords={props.handleClearExcludedWords}
            />
        </div>

        {(!chartItems || !chartItems.length) ? 
            <>No data for charts</> : <>
            
            {props.chartName == 'KEY_PHRASE_CLOUD' ? 
            <>
                {/* <div className={style.insightBlock__chart}>
                <div className={global.chartWrapper}> */}

                {/* <div style={{display: 'flex'}}>
                    <div style={{lineHeight: '38px'}}>
                        {searchWordsBannerText}
                    </div>
                    {props.cloudSearchWords && props.cloudSearchWords.length ?
                    <ButtonElement onClick={()=>props.runInsightsSearchWithCloudWords([])} styleProps={{marginLeft: 'auto', marginRight: '30px'}}>Back to Default</ButtonElement> : null}
                </div>        */}

                <div style={{flexGrow: 1}}>
                    <WordCloud2 items={chartItems} 
                        // TODO: check that cloudSearchWords are handled
                        cloudSearchWords={props.cloudSearchWords}
                        excludedWords={props.excludedWords} updateExcludedWords={props.updateExcludedWords}
                        runInsightsSearchWithCloudWords={props.runInsightsSearchWithCloudWords}/>
                </div>
                {/* </div>
                </div> */}
            </> : null}

            {props.chartName == 'BAR_CHART' ? 
            <>
                <BarChart key={JSON.stringify(chartItems)}

                    data={chartItems} config={{cursor: true, fieldValue: props.option1, grid: false, legend: true, panX: false, panY: false, seriesTooltip: true, xAxis: 'key', yAxis: 'value'}}

                    id={JSON.stringify(chartItems)}
                />

            </> : null}

            {props.chartName == 'HEAT_MAP' ? <>
                <ApexHeatMap 
                    key={JSON.stringify(chartItems)}  
                    data={chartItems} config={{categoryField: 'xAxis', categoryXField: props.option1, categoryYField: props.option2, panX: false, panY: false}} 
                    id={"HeatMap" + random(0, 1e6)}
                />
            </> : null}
            
            
        </>}
    </>);

    


/*
    cursor
    :
    true
    fieldValue
    :
    "1.5 Manufacturers"
    grid
    :
    false
    legend
    :
    true
    panX
    :
    false
    panY
    :
    false
    seriesTooltip
    :
    true
    xAxis
    :
    "key"
    yAxis
    :
    "value"
*/






    if (props.chartName == 'BAR_CHART'){
        return <>
{/* =={props.chartName}== */}

            <BarChart key={JSON.stringify(chartItems)}

            data={chartItems} config={{cursor: true, fieldValue: props.option1, grid: false, legend: true, panX: false, panY: false, seriesTooltip: true, xAxis: 'key', yAxis: 'value'}}

            id={JSON.stringify(chartItems)}/>
        </>
    }

    if (props.chartName == 'HEAT_MAP'){
        return <>
        <ApexHeatMap key={JSON.stringify(chartItems)}  data={chartItems} config={{categoryField: 'xAxis', categoryXField: props.option1, categoryYField: props.option2, panX: false, panY: false}} id={"HeatMap" + random(0, 1e6)}/>
        </>
    }

    return <>nothing yet</>
}