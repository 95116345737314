import CustomScrollbar from "Components/CustomScrollbar";
import global from "styles/globel/style.module.scss";

import style from "../SearchContent/style.module.scss";
import { useEffect, useRef } from "react";

export const SearchResultsContainer = props=>{
    // this component knows how to arrange search results based on the ViewType (2x2, 3x4 or list view)

    // gridOne - list view
    // gridTwo - 2x2
    // gridThree - 3x4 


    const viewType = props.viewType;

    let className='';
    if (viewType=='gridTwo'){
        className = 'gridTwo';
    }
    if (viewType=='gridThree'){
        className = 'gridThree';
    }

    const scrollerRef = useRef();

    useEffect(()=>{
        if (scrollerRef.current){
            const scrollableElement = scrollerRef.current.getScrollElement();
            scrollableElement.scrollTop = 0;
        }
    }, [props.children]);

    return (
        <div className={style.resultContainer} style={{overflow: 'hidden', maxHeight: 'calc(100vh - 20rem)', paddingRight: '10px'}}>
        <CustomScrollbar className="result-view-scroller" scrollRef={scrollerRef}>
            <div className={style.resultInner} style={{ overflow: 'hidden'}}>
    
                <div className={`${global.resultBlock} ${global[className]}`}>

                    {props.children}

                </div>
            </div>
        </CustomScrollbar>
    </div>
    );
}