import { useState } from "react";
import InputField from "../../../InputField";
import ModalWindow from "../../../ModalWindow";
import { FILTER_SEARCH } from "../constants";
import style from "./style.module.scss";
export const SaveResultsModal2 = (props) => {

    const [name, setName] = useState('');

    return (<>
    <ModalWindow
        title='Save Search Results'
        open={true}
        okText="Save"
        cancelText="Cancel"
        //confirmLoading={props.state.loadingResults}
        confirmLoading={false}
        onOk={()=>props.handleSaveClicked(name)}
        isNonMaskable={true}
        isNotClosable={true}
        onCancel={props.handleCloseModal}
    >
        <div className={style.saveResult}>
            <div className={style.saveResult__input}>
                <InputField
                    onPressEnter={()=>props.handleSaveClicked(name)}
                    title={FILTER_SEARCH.SAVE_YOUR_SEARCH_RESULTS_SUB_HEADING}
                    htmlForName={FILTER_SEARCH.SAVE_YOUR_SEARCH_RESULTS_SUB_HEADING}
                    name={"Save-Results-Modal-Block"}
                    placeholder='Name the search result'
                    onChange={e=>setName(e.target.value)}
                    autoFocus={true}
                    value={name}
                />
            </div>
        </div>
    </ModalWindow>
</>);
};


