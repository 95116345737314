import ModalWindow from "Components/ModalWindow";
import { SavedInsightsModalContent } from "./SavedInsightsModalContent";

export const SavedInsightsModal = props=>{
    return (<>
    <ModalWindow 
        open={true}
        title="Saved Insights"
        width={900}
        okText="Close"
        onOk={() => props.handleCloseModal()}
        isNonMaskable={true}
        isNotClosable={true}
        isHideCancelButton={true}
    >
        <SavedInsightsModalContent projectId={props.projectId} />
    </ModalWindow>
    </>);
}