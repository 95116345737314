import { getInsightsForCurrentUser } from "api/insights";
import CheckBox from "Components/CheckBox";
import CustomScrollbar from "Components/CustomScrollbar";
import ModalWindow from "Components/ModalWindow"
import { useEffect, useState } from "react";

export const ImportInsightsModal = props=>{

    // this window loads and displays paginated list of insights
    // TODO: optionally load this at the Dashboard level and just pass into here 

    const [insightsList, setInsightsList] = useState([]);

    const [selectedItems, setSelectedItems] = useState([]);

    const loadSavedInsightRecords = ()=>{

        if (!insightsList.length){
            getInsightsForCurrentUser().then(res=>{
                debugger;
                // need to set the checkmarks
                setInsightsList(res.data);
                setSelectedItems(props.selectedInsights);
            });
        }
        else{
            // just set the checkmarks
            setSelectedItems(props.selectedInsights);
        }
        
    };

    useEffect(()=>{
        debugger;
        loadSavedInsightRecords();
    }, [props.selectedInsights]);
    

    const handleSelectionChange = values=>{
        // debugger;
        // console.log(id);
        setSelectedItems([...values]);
    };

    // TODO: think about showing ContentLoader component
    return (<ModalWindow 
        onCancel={()=>props.onCancel()}
        onOk={()=>{
            debugger;
            props.onConfirmSelection(selectedItems);
        }}
        isNotClosable={true}
        isNonMaskable={true}
        width='700px'
        okText='Import Insights'
        open={true}    
    >
        <div>
            <CustomScrollbar className="checklist-scroller">
                <div>
                    <CheckBox value={selectedItems} handleChange={handleSelectionChange} isDashboard={true} items={insightsList}/>
                </div>
            </CustomScrollbar>
        </div>
    </ModalWindow>);
}