import {APIInstance} from './apiInstance';
import {BASE_URL} from './constants';

export const saveFilterSettings = (payload) => {
  const instance = new APIInstance({
    baseURL: BASE_URL.SETTINGS,
  });
  const api = instance.api;
  return api.post('/AddUserSettings', payload);
};

export const getSaveFilterSettings = (payload) => {
  const instance = new APIInstance({
    baseURL: BASE_URL.SETTINGS,
  });
  const api = instance.api;
  return api.post('/GetUserSettingsbyEmail', payload);
};

export function getAllUserSettings(payload) {
  const instance = new APIInstance({
    baseURL: BASE_URL.SETTINGS,
  });
  const api = instance.api;
  return api.get('/GetAllUserSettings');
}

export function getActivityLog() {
  const instance = new APIInstance({
    baseURL: BASE_URL.SETTINGS,
  });
  const api = instance.api;
  return api.get('/GetActivitylog');
}

export function getActivityLogPageSize(payload) {
  const instance = new APIInstance({
    baseURL: BASE_URL.SETTINGS,
  });
  const api = instance.api;
  return api.post('/GetActivitylog', payload);
}

export function savePreferenceSettings(payload) {
  const instance = new APIInstance({
    baseURL: BASE_URL.SETTINGS,
  });
  const api = instance.api;
  return api.post('/UpdateUserPreference', payload);
}

// not used
export function saveUserDetailsSetting(payload) {
  const newInstance = new APIInstance({
    baseURL: BASE_URL.LOGIN,
  });
  const newApi = newInstance.api;
  return newApi.patch('/UpdateUserDetails', payload);
}


