import { useEffect, useRef, useState } from "react";
import ReactWordcloud from "react-wordcloud";

import { notify } from "../Notification";

import style from "./style.module.scss";
import global from "../../styles/globel/style.module.scss";
import { WORD_CLOUD_MAXIMUM_WORD_COUNT } from "Constants/Insights/Insights.constants";
import ExcludeModal from "./Children/ExcludeModal";
import { addExcludeListItem } from "api/insights";

export const WordCloud2 = props=>{

    // isForDashboard applies 100% stretch to scale in the dashboard tile and disables right and left clicks
    const isForDashboard = props.isForDashboard || false;


    const [isModalOpen, setIsModalOpen] = useState(false);

    const [clickedText, setClickedText] = useState('');


    const cloudSearchWords = useRef(props.cloudSearchWords);
    useEffect(()=>{
        cloudSearchWords.current = props.cloudSearchWords;
    }, [props.cloudSearchWords]);


    const onClose = ()=>{
        debugger;
    };

    const handleRightClick = event=>{
        if (isForDashboard){
            // in dashboard mode right-click to add to exclusions is not available 
            return;
        }

        event.preventDefault();
        
        if (event.target.__data__.hasText) {
            let newItem = event.target.__data__.text;
            notify.info(`The word ${newItem} is adding to the exclusion list`);
         
            props.updateExcludedWords([...props.excludedWords, newItem]);

            addExcludeListItem({
                word: newItem?.toLowerCase(),
              }).then(res=>{
                notify.success(res?.data?.result);
                props.setInsightData({
                    dataChanges: [newItem.toLowerCase()],
                });

              }).catch(err=>{
                notify.error(err?.response?.data?.result);
              });
        }
    };

    const paddings = [1, 2, 3, 4, 5, 6, 10, 10 ];

    let boundaries = [];
    

    const colors = [
        "CadetBlue",  // teal  - smallest
        "Gray", 
        "#36454F", 
        // "FireBrick",   //brown
        "Navy",        // blue
        "LimeGreen",  // green
        "#ffc800", // yellow
        "DarkOrange", // orange
        
        
        "Red"
        
    ];

    const doBoundaries = ()=>{
        boundaries = [];
        const words = [...props.items];
        words.sort((a, b)=>{
           
          if (a.value > b.value){
              return 1;
          }
    
          if (a.value < b.value){
              return -1
          }
          return 0;
        }); 

        let max = words[words.length - 1].value;
        let min = words[0].value;


        // temp
        // max = 1000;
        // min = 1;
        // end temp 

        const N = colors.length; // number of sections 
        const q = Math.pow((max / min), (1 / N));

        //const boundaries = [min]; // intervals 
        boundaries = [min];

        for (let i = 1; i < N; i++){
            const boundary = min * Math.pow(q, i)
            boundaries.push(boundary);
        }

        boundaries.push(max); 

    };

    if (props.items.length){
        doBoundaries();
    }
    
    const tooltipElement = useRef();

    useEffect(()=>{
        return ()=>{
            if (tooltipElement.current){
                tooltipElement.current.remove();
                tooltipElement.current = null;
            }
        };
    }, [])


    const getCloudOptions = ()=>{
        return {
            rotations: 0,  // how many rotations
            rotationAngles: [0, 0], // in this range of angles 
            enableOptimizations: true, // beta support 
            deterministic: true, // means always render words in the same place 

            randomSeed: 42, 
    
            fontFamily: "Red Hat Display, Arial, Lato, sans-serif",
            fontSizes: [12, 80], // range of font sizes
            fontStyle: "normal",
            fontWeight: "normal",
    
            scale: 'log', 
            spiral: 'archimedean',  // archimedean seems more like lumininfo 
    
            transitionDuration: 1000,
            enableTooltip: true, 
    
            padding: (word)=>{
                // padding   can be a function too !
    
                let i = 0;
                for (i = 1; i <= boundaries.length; i++){
                    if (word.value <= boundaries[i]){
                        break;
                    }
                }
             
                const padding = paddings[i - 1];

                // console.log(padding);
                return padding;
            },

            tooltipOptions:{
                allowHTML: true
            }
    
            // tooltipOptions: {
            //     placement: "bottom",
            //     render: (value) => {
            //         console.log('cloud value', value);
            //         const key = value.props.content.toLowerCase(); 

            //         const data = props.items.find(x=>`${x.key} (${x.value})` == key);
            //         if (!data){
            //             return;
            //         }

            //         if (!tooltipElement.current){
            //             tooltipElement.current = document.createElement('div');
            //             tooltipElement.current.className = style.wordcloudtoolTip;
            //         }

            //         tooltipElement.current.innerHTML = `
            //             <span>
            //             <label>Value: </label>
            //             <span>${data.text}</span>
            //             </span>
            //             <span>
            //             <label>Type: </label>
            //             <span>${data.type}</span>
            //             </span>
            //             <span>
            //             <label>Documents: </label>
            //             <span>${data.value}</span>
            //             </span>
            //             ${
            //             data.parent
            //                 ? `<span>
            //             <label>Parent: </label>
            //             <span>${data.parent}</span>
            //             </span>`
            //                 : ""
            //             }
            //         `;

            //         return {popper: tooltipElement.current};

            //     },
            // },
        };
    };
    


    const getCallbacks = ()=>{
        let callbacks = {

            // getWordColor: text=>{
            //     // debugger; 
            //     // see what to return here...
            // },

            getWordTooltip: word=>{
            
                const data = props.items.find(x=>x.key == word.key);
                if (!data){
                    return;
                }

                const tooltipContent= `<span>
                    <label>Value: </label>
                    <span>${data.text}</span>
                    </span>
                    <br/>
                    <span>
                    <label>Type: </label>
                    <span>${data.type}</span>
                    </span>
                    <br/>
                    <span>
                    <label>Documents: </label>
                    <span>${data.value}</span>
                    </span>
                    ${
                        data.parent
                            ? `<br/><span>
                        <label>Parent: </label>
                        <span>${data.parent}</span>
                        </span>`
                            : ""
                    }
                `;
                return tooltipContent;
            },

            onWordClick: isForDashboard ? null : function(word){
                debugger;
                // this adds the word into cloudSearchWords
                // if (tooltipElement.current){
                //     tooltipElement.current.remove();
                //     tooltipElement.current = null;
                    const existingWord =  cloudSearchWords.current.find(x=>x.key === word.key);
                    if (existingWord){ 
                        notify.info(`The word ${existingWord.text} has already been added.`);
                    }
                    else{
                        props.runInsightsSearchWithCloudWords([...cloudSearchWords.current, {text: word.text, key: word.key, value: word.value, type: word.type}]);
                    }
                //}
                
            }
        };

        return callbacks;
    };

    const formatData = items=>{
        
        const result = items.map((item) => ({ ...item, type: item?.type?.replace(/_/g, " ") }));
        return result;
    };

    const styleForDashboard={width: '100%', height: '100%'};
    
    return (<>
        <div onContextMenu={isForDashboard ? null : handleRightClick} className={`${props.dashboard ? style.rootContainer : global.chartItem}  ${props.isDownload && global.previewCharts}`}id={props.id} 
        style={isForDashboard ? styleForDashboard : null}
        >
            <ReactWordcloud
                key={JSON.stringify(props.items || [])}
                words={formatData(props.items || [])}
                options={getCloudOptions()}
                callbacks={getCallbacks()}
                maxWords={WORD_CLOUD_MAXIMUM_WORD_COUNT} 
            />
            {isModalOpen ? <ExcludeModal
                isModalOpen={isModalOpen}
                text={clickedText}
                onClose={onClose}
            /> : null}
        </div>
    </>);
}