import CustomScrollbar from "Components/CustomScrollbar";
import { ChartContainer } from "./ChartContainer";

export const PanelWithInsightsChart = props=>{

    return <>

    <div style={{display: 'flex', justifyContent: 'center'}}>

    {/* <CustomScrollbar className="insight-graph-scroller"> */}
        <div style={{height: '100%', width: '100%', display: 'flex', flexDirection: 'column'}}>
            <div style={{margin: '8px 0 8px 0'}}>
            <span style={{padding: '4px'}}>
                Total documents: {props.totalCount}</span>
            <span style={{padding: '4px'}}>
                Active tab: {props.activeTabKey}</span>
            
            </div>
            <ChartContainer chartName={props.chartName}

                handleClearCloudSearchWords={props.handleClearCloudSearchWords}
                handleClearExcludedWords={props.handleClearExcludedWords}
                option1={props.option1}
                option2={props.option2}
                items={props.items} 
                cloudTaxonomy={props.cloudTaxonomy}
                cloudSearchWords={props.cloudSearchWords} 
                excludedWords={props.excludedWords} 
                clearExcludedWords={props.clearExcludedWords}
                updateExcludedWords={props.updateExcludedWords}
                runInsightsSearchWithCloudWords={props.runInsightsSearchWithCloudWords}
            />
        </div>
    {/* </CustomScrollbar> */}
    </div>
    </>;
}