import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';
import { useEffect } from 'react';

export const NewChartStackedBar = props=>{

    const query1 = {
        "taxonomyFilterRows": [],
        "simpleSearchText": "hong kong",
        "advancedSearchRows": [],
        "activeTabKey": "All Articles",
        "projectId": 25,
        "cloudSearchWords": []
    };


    const query2 = {
        "taxonomyFilterRows": [],
        "simpleSearchText": "heathrow",
        "advancedSearchRows": [],
        "activeTabKey": "All Articles",
        "projectId": 25,
        "cloudSearchWords": []
    };


    useEffect(()=>{
        // need to call an endpoint that takes the search and returns series -number of tags

    }, []);

    const arms = [];

   
    const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value'
        },
        yAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        series: [
          {
            name: 'Direct',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [320, 302, 301, 334, 390, 330, 320]
          },
          {
            name: 'Mail Ad',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [120, 132, 101, 134, 90, 230, 210]
          },
          {
            name: 'Affiliate Ad',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [220, 182, 191, 234, 290, 330, 310]
          },
          {
            name: 'Video Ad',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [150, 212, 201, 154, 190, 330, 410]
          },
          {
            name: 'Search Engine',
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: [820, 832, 901, 934, 1290, 1330, 1320]
          }
        ]
    };
    return <>
        <ReactECharts option={option} style={{ height: 400, width: "100%" }}/>
    </>
}