import moment from 'moment';
export const validateAdvancedSearchRows = (searchRows)=>{
    const result = [];
    if (!searchRows.length){
        result.push({message: 'No group'});
    }
    searchRows.forEach(group => {
        if (!group.rows.length){
            result.push({groupId: group.groupId, message: 'No filters'});
        }

        for (let i=0; i < group.rows.length; i++){
            const row = group.rows[i];
        
        //group.rows.forEach(row=>{
            // a row must have...
            if (!row.dateFilterObject){
                if (!row.taxonomyValue){
                    if (!row.taxonomyName){
                        result.push({rowId: row.rowId, groupId: group.groupId, message: 'No selection', field: 'taxonomyName'});
                    }
                    if (!row.taxonomyValues.length){
                        result.push({rowId: row.rowId, groupId: group.groupId, message: 'No values', field: 'taxonomyValues'});
                    }
                    if (!row.taxonomyName && !row.taxonomyValues.length){
                        result.push({rowId: row.rowId, groupId: group.groupId, message: 'No value', field: 'taxonomyValue'});
                    }
                }
            }
            else {
                // validate the dates
                if (!row.dateFilterObject.mode){
                    result.push({rowId: row.rowId, groupId: group.groupId, message: 'No selection', field: 'mode'});
                    continue;
                }

                if (row.dateFilterObject.mode == 'last-n-days'){
                    if (!row.dateFilterObject.numberOfDays && row.dateFilterObject.numberOfDays != 0){
                        result.push({rowId: row.rowId, groupId: group.groupId, message: 'No value', field: 'numberOfDays'});
                    }
                    continue;
                }
                if (row.dateFilterObject.mode == 'range'){
                    let datesAreOkay = true;
                    if (!row.dateFilterObject.dates){
                        result.push({rowId: row.rowId, groupId: group.groupId, message: 'No value', field: 'from'});
                        continue;
                    }
                    if (!row.dateFilterObject.dates.from){
                        result.push({rowId: row.rowId, groupId: group.groupId, message: 'No value', field: 'from'});
                        datesAreOkay = false;
                    }
                    else if (!row.dateFilterObject.dates.from.isValid()) {
                        result.push({rowId: row.rowId, groupId: group.groupId, message: 'Invalid value', field: 'from'});
                        datesAreOkay = false;
                    }
                    if (!row.dateFilterObject.dates.to){
                        result.push({rowId: row.rowId, groupId: group.groupId, message: 'No value', field: 'to'});
                        datesAreOkay = false;
                    }
                    else if (!row.dateFilterObject.dates.to.isValid()) {
                        result.push({rowId: row.rowId, groupId: group.groupId, message: 'Invalid value', field: 'to'});
                        datesAreOkay = false;
                    }
                    if (datesAreOkay){
                        const from = moment(row.dateFilterObject.dates.from).startOf('day');
                        const to = moment(row.dateFilterObject.dates.to).startOf('day');
                        if (from.isAfter(to)){
                            result.push({rowId: row.rowId, groupId: group.groupId, message: 'Invalid date range', field: 'from'});
                        }
                    }
                    continue;
                }

                if (row.dateFilterObject.mode == 'from-date-in-past'){
                    let datesAreOkay = true;
                    if (!row.dateFilterObject.dates.from){
                        result.push({rowId: row.rowId, groupId: group.groupId, message: 'No value', field: 'from'});
                        datesAreOkay = false;
                    }
                    else if (!row.dateFilterObject.dates.from.isValid()) {
                        result.push({rowId: row.rowId, groupId: group.groupId, message: 'Invalid value', field: 'from'});
                        datesAreOkay = false;
                    }
                    if (datesAreOkay){
                        const from = moment(row.dateFilterObject.dates.from).startOf('day');
                        const to = moment().startOf('day');
                        if (from.isAfter(to)){
                            result.push({rowId: row.rowId, groupId: group.groupId, message: 'Invalid date range', field: 'from'});
                        }
                    }
                    continue;
                }
            }
        //});
        }
        
    });

    return result;

    



}

export const getGroupErrors = (errors, groupId)=>{
    const groupErrors = errors.filter(e=>e.groupId === groupId && !e.rowId && e.rowId !==0);
    return groupErrors;
};


export const getGroupRowErrors = (errors, groupId, rowId)=>{
    const result = errors.filter(e=>e.groupId === groupId && e.rowId === rowId);
    return result;
};