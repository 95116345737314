import React from "react";
import style from "./style.module.scss";
import ProfileAvatar from "../../../public/img/avatar.png";
import { ContentLoader } from "Components/ContentLoader";

const ProfileOptions = (props) => {

    const userProfile = props.userProfile;

    const profileId = userProfile.id;

    const profileLoading = props.profileLoading;
    const userRoles = {
        [1]: "Admin",
        [2]: "Analyst", 
        [3]: "SuperAdmin"
    };

    const onProfileClick= ()=>{
        if (profileId == 3){
            props.superAdminCredChange
        }
    };

    return (<div className={style.profileBlock} onClick={()=>onProfileClick()}>   
        <div className={style.profileBlock__image}>
            <span>
              <img src={ProfileAvatar} />
            </span>
        </div>
        
        <div className={style.profileBlock__name}>
            {profileLoading ? 
            <div className={style.loaderDiv}>&nbsp;
                <ContentLoader width={100} height={1.2} spaceBetween={0} />
            </div> : 
            <>
                {userProfile.firstName ? <h4>{`${userProfile.firstName} ${userProfile.lastName}`}</h4> : <span>{userProfile.userEmail}</span>}
                <p>{userRoles[profileId]}</p>
            </>}
        </div>
    </div>
  );
};

export default ProfileOptions;