import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import moment from 'moment';
import { withMsal } from '@azure/msal-react';

import PrivateRoute from '../../utils/PrivateRoute';
import Home from '../Home';
import Dashboard from '../Dashboard';
import Settings from '../Settings';
import Insights from '../Insights';
import { ROUTES } from '../../Constants/Routes/Routes.constants';
import TimeOutModal from './TimeOutModal';
import AdvanceSearch from '../AdvanceSearch';
import { KEYS } from '../../dataKeys';
import DownloadPreview from '../DownloadPreview';
import UserLogs from '../UserLogs';
import SideBar from '../../Components/SideBar';
import PageBody from '../../Components/PageBody';
import PageWrapper from '../../Components/PageWrapper';
import { getAccessToken } from '../../utils/commonUtils';
import PageHeading from '../../Components/PageHeading';
import { SelectElement } from '../../Components/SelectElement';
import { ROUTES_CONFIG } from '../../Components/SideBar/Sidebar.config';
import {
  checkPassword,
  resetPassword,
} from '../../api/account';
import { notify } from '../../Components/Notification';
import { ProjectSwitchingModal } from './ProjectSwitchingModal';
import {
  filterVisibleHelper,
  checkPasswordRegex,
  validateAccessToken,
} from './helper';
import {
  SAML_LOGOUT_URL,
  SUPER_ADMIN_LOGOUT_URL,
} from '../../Constants/Login/Login.constants';

import global from '../../styles/globel/style.module.scss';
import ProfileOptions from '../../Components/ProfileOptions/ProfileOptions';
import Showlogoutmodal from '../../Components/SideBar/ShowLogoutModal';
import { logoutHelper } from '../../Components/SideBar/Helper';
import { PasswordResetModal } from './PasswordModal';
import { COMMUNICATION_KEYS } from 'constants';
import axios from 'axios';
import { SEARCH_TYPE } from 'constants';
import { changeUserProjects } from 'api/account';

class MainFrame extends Component {
  constructor(props) {
    super(props);
    this.channel = new BroadcastChannel('selectedList');
  }
  state = {
    showModal: false,
    timeOutCount: 0,
    currentProject: null,
    currentPass: '',
    newPass: '',
    confirmPass: '',
    superAdminPassChange: false,
    showAdvanceSearchDropDown: {
      visible: false,
      className: 'searchToggle',
    },
    sideBarColapse: {
      visible: true,
      className: 'sideBarColapse',
      iconClassName: 'colapseIcon',
      colapsePageBodyClassName: 'colapsePageBody',
    },
    filterVisible: {
      visible: false,
      className: 'filterToggle',
    },
    projectSwitching: false,
    showProjectSwitchingModal: false,
    events: ['click', 'scroll', 'load', 'keydown'],
    timerTime: 1800000, //30 mint 1800000
    showLogoutModal: false,
    loading: false,
    resetLoader: false,
    projectSwitched: false,
    projectSelectDisabled: false,
  };
  timeOut = undefined;
  timer = undefined;

  setLocalState = (data) =>
    this.setState((prevState) => ({ ...prevState, ...data }));
  componentDidMount() {
    this.addEvents();
    if (getAccessToken()) {
      this.props.GetCurrentProfileAction();
      this.props.getProjectList();
      validateAccessToken(this.timeOut);
    }
  }

  componentWillUnmount() {
    // window.removeEventListener('unload', this.handleTabClose);
    localStorage.setItem(KEYS.RememberMe, 'sessionStorage');
    clearTimeout(this.timer);
    this.removeEvents();
  }

  componentDidUpdate() {
    if (
      (this.props.search.searchType === SEARCH_TYPE.ADVANCED_SEARCH ||
        this.props.insight.searchType === SEARCH_TYPE.ADVANCED_SEARCH) &&
      this.state.filterVisible.visible
    )
      this.handleFilterVisible();

    if (
      this.props?.CurrentProfile?.changePwdCount == 0 &&
      !this.state.superAdminPassChange
    ) {
      this.setState({ superAdminPassChange: true });
    }
  }
  handleProjectSelectDisable = (value) =>
    this.setState((prev) => ({ projectSelectDisabled: value }));

  handleSideBarColapse = (value) => {
    this.setState({
      sideBarColapse: {
        ...this.state.sideBarColapse,
        visible:
          value === 'isFilter' ? true : !this.state.sideBarColapse.visible,
      },
    });
  };
  handleChangeModalCount = (count) =>
    this.setState({
      timeOutCount: count,
    });

  /**
   *
   * - Method will clear localStorage
   * - clear search data from redux
   * - navigate user to login page
   */
  handleIslogout = async (event) => {
    event.preventDefault();
    clearTimeout(this.timer);
    this.removeEvents();
    // this.props.setSearchData({ clear: true });
    localStorage.clear();
    sessionStorage.clear();
    const queryParams = new URLSearchParams(window.location.search);
    const queryterm = queryParams.get('page');
    let queryString = this.props.history.location.search;
    if (
      queryterm == 'newtab' ||
      queryString == '?insight' ||
      this.props.history.location.pathname == '/insights'
    ) {
      this.channel.postMessage({
        type: COMMUNICATION_KEYS.CHILD_TERMINATE,
        isLogout: true,
      });
    } else {
      this.channel.postMessage({
        type: COMMUNICATION_KEYS.PARENT_TERMINATE,
        isLogout: true,
      });
    }
    if (this.props.CurrentProfile.userProfile === 3) {
      window.location.href = SUPER_ADMIN_LOGOUT_URL;
    } else {
      window.location.href = SAML_LOGOUT_URL;
    }
  };

  handleModal = async () => {
    if (this.state.timeOutCount < 1) {
      this.handleChangeModalCount(1);
      this.setState({ showModal: false, timerTime: 3600000 }); // 1hr = 3600000
      this.startTimer();
    } else {
      this.handleChangeModalCount(2);
      this.setState({ showModal: false });
      clearTimeout(this.timer);
      this.removeEvents();
    }
    //To commmunicate with the other tab then close the modal and do actions
    const queryParams = new URLSearchParams(window.location.search);
    const queryterm = queryParams.get('page');
    let queryString = this.props.history.location.search;
    if (
      queryterm == 'newtab' ||
      queryString == '?insight' ||
      this.props.history.location.pathname == '/insights'
    ) {
      //Current Page Insight
      this.channel.postMessage({
        type: COMMUNICATION_KEYS.CHILD_CHOOSE_STAY,
      });
    } else {
      //Current Page Articles
      this.channel.postMessage({
        type: COMMUNICATION_KEYS.PARENT_CHOOSE_STAY,
      });
    }
  };
  handleStayActive = async () => {
    if (this.state.timeOutCount < 1) {
      this.handleChangeModalCount(1);
      this.setState({ showModal: false, timerTime: 3600000 }); // 1hr = 3600000
      this.startTimer();
    } else {
      this.handleChangeModalCount(2);
      this.setState({ showModal: false });
      clearTimeout(this.timer);
      this.removeEvents();
    }
  };
  handleTabClose = () => {
    if (localStorage.getItem(KEYS.RememberMe)) {
    } else {
      this.handleIslogout();
    }
  };
  setPageHeading = () => {
    let title = '';
    ROUTES_CONFIG['Admin'].map(({ name, route }) => {
      if (
        this.props.location.pathname === route &&
        this.props.location.pathname != ROUTES.VIEW_IN_NEW_TAB &&
        this.props.location.pathname != ROUTES.VIEW_TAB_ARTICLES
      ) {
        return (title = name);
      }
      if (
        this.props.location.pathname === route &&
        this.props.location.pathname != ROUTES.VIEW_IN_NEW_TAB
      ) {
        return (title = 'Articles');
      }
      if (this.props.location.pathname === ROUTES.INSIGHTS) {
        return (title = 'Insights');
      }
    });
    return title;
  };

  projectListHandle = () => {
    const options = this.props?.projectList?.data.map((item) => ({
      value: item.id,
      label: item.name,
    }));
    return options;
  };
  handleFilterVisible = () => filterVisibleHelper(this);

  selectProjectHandler = async (projectId) => {
    if (this.state.currentProject !== projectId) {
      this.setState({ projectSwitching: true });
      let email = localStorage.getItem(KEYS.ACCESS_EMAIL);
      changeUserProjects({ emailId: email, projectId: projectId })
        .then(({ data }) => {
          this.state.filterVisible.visible && this.handleFilterVisible();
          this.setState({ showProjectSwitchingModal: true });
          sessionStorage.setItem(KEYS.PROJECT_SWITCHED, true);
          notify.success('Project changed successfully', true);
          // this.props.setSearchData({ clear: true });
          setTimeout(() => {
            this.channel.postMessage({
              type: COMMUNICATION_KEYS.PROJECT_CHANGE,
              payload: {
                data: {
                  projectSwitched: true,
                  message: 'Project switched successfully',
                },
                metadata: { time: new Date(), source: 'MainFrame' },
              },
            });
            let search = this.props.history.location.search;
            this.props.history.replace({
              ...this.props.history.location,
              state: {},
              search: search,
            });
            this.props.history.go(0);
          }, 2000);
          this.setState({
            currentProject: data.result.currentProject,
            projectSwitching: false,
          });
        })
        .catch((err) => {
          this.setState({ projectSwitching: false });
          notify.error('Project change failed', true);
        });
    }
  };
  //------Timer--------
  startTimer = () => {
    this.timer = setTimeout(() => {
      let lastInteractionTime = localStorage.getItem(KEYS.LAST_INTERACTION);
      const diff = moment.duration(moment().diff(moment(lastInteractionTime)));
      let timeOutInterval = this.state.timerTime;
      if (diff && diff._milliseconds) {
        if (diff._milliseconds < timeOutInterval) this.startTimer();
        else this.setState({ showModal: true });
      }
    }, 6000);
  };
  addEvents = () => {
    localStorage.setItem(KEYS.LAST_INTERACTION, moment());
    this.state.events.forEach((eventName) => {
      window.addEventListener(eventName, this.eventHandler);
    });
    this.startTimer();
  };

  removeEvents = () => {
    localStorage.removeItem(KEYS.LAST_INTERACTION);
    this.state.events.forEach((eventName) => {
      window.removeEventListener(eventName, this.eventHandler);
    });
  };
  eventHandler = (eventType) => {
    localStorage.setItem(KEYS.LAST_INTERACTION, moment());
    if (this.timer) {
      this.startTimer();
    }
  };
  handleModalHide = () => this.setState({ showLogoutModal: false });
  handleIsLogout = () => logoutHelper({ state: this });
  handleModalVisible = () => this.setState({ showLogoutModal: true });

  hasToShowSideBar = () => {
    const queryParams = new URLSearchParams(window.location.search);
    let queryString = this.props.history.location.search;
    const queryterm = queryParams.get('from');
    if (
      queryterm == 'newtab' ||
      queryString == '?insight' ||
      this.props.history.location.pathname == '/insights' ||
      window.location.pathname == '/loading'
    ) {
      return false;
    } else {
      return true;
    }
  };
  hasToShowHeader = () => {
    if (window.location.pathname == '/loading') {
      return false;
    } else {
      return true;
    }
  };
  superAdminCredChange = () => {
    this.setState({
      superAdminPassChange: !this.state.superAdminPassChange,
      currentPass: '',
      newPass: '',
      confirmPass: '',
    });
  };
  onResetPasswordChange = (field, value) => {
    this.setState({ [field]: value.trim() });
  };
  resetSuperAdminPassword = async () => {
    this.setState({ resetLoader: true });
    let { newPass, confirmPass, currentPass } = this.state;
    let checkError = checkPasswordRegex(currentPass, newPass, confirmPass);
    if (checkError.error) {
      notify.error(checkError.message);
      this.setState({ resetLoader: false });
    } else {
      await checkPassword({ password: currentPass })
        .then(() => {
          resetPassword({ newpassword: newPass })
            .then(({ data }) => {
              this.props.GetCurrentProfileAction();
              notify.success('Password changed successfully!');
              this.setState({
                resetLoader: false,
                confirmPass: '',
                newPass: '',
                currentPass: '',
                superAdminPassChange: false,
              });
            })
            .catch((err) => {
              notify.error(err?.response?.data?.title);
              this.setState({ resetLoader: false });
            });
        })
        .catch((err) => {
          notify.error(err.response.data.title);
          this.setState({ resetLoader: false });
        });
    }
  };

  render() {
    return (
      <div className={global.mainWrapper}>
        <div
          className={`${global.searchHolder} ${
            this.state.showAdvanceSearchDropDown.visible
              ? global[this.state.showAdvanceSearchDropDown.className]
              : ''
          }`}
        >
          {this.state.showAdvanceSearchDropDown.visible && (
            <AdvanceSearch
              mainFrameState={this}
              isInsights={this.setPageHeading() == 'Insights' ? true : false}
            />
          )}
        </div>
        <PageWrapper hideSideBar={this.hasToShowSideBar()}>
          {this.hasToShowSideBar() && (
            <SideBar
              mainFrameState={this}
              handleSideBarColapse={this.handleSideBarColapse}
              sideBarColapse={this.state.sideBarColapse}
              projectList={this.props.projectList}
              showSideBarNav={this.hasToShowSideBar()}
              handleIslogout={this.handleIslogout}
            />
          )}
          <PageBody
            addOnClassName={
              this.state.sideBarColapse.visible
                ? this.state.sideBarColapse.colapsePageBodyClassName
                : null
            }
          >
            {this.hasToShowHeader() && (
              <PageHeading text={this.setPageHeading()}>
                <>
                  {this.props.location.pathname == ROUTES.INSIGHTS ||
                  this.props.location.pathname == ROUTES.DOWNLOAD_PREVIEW ? (
                    <div className={global.headerProjectname}>
                      <p> Project Selected: </p>
                      <h4>{this.props.CurrentProfile?.projectinfo?.name}</h4>
                    </div>
                  ) : (
                    <>
                      <div className={global.headerSelect}>
                        <SelectElement
                          showSearch
                          disabled={this.state.projectSelectDisabled}
                          optionList={this.projectListHandle()}
                          placeholder={'Select project'}
                          value={
                            this.props?.projectList?.data?.length
                              ? this.props.CurrentProfile?.projectinfo?.id
                              : null
                          }
                          onChange={this.selectProjectHandler}
                          loading={
                            this.state.projectSwitching ||
                            this.props.projectList.loading ||
                            this.props.CurrentProfile.profileLoading
                          }
                        />
                      </div>
                      <ProfileOptions
                        superAdminCredChange={this.superAdminCredChange}
                        handleModalVisible={this.handleModalVisible}
                      />
                    </>
                  )}
                </>
              </PageHeading>
            )}

            <Switch>
              <Route
                exact
                path={ROUTES.INDEX}
                render={() => <Redirect to={ROUTES.HOME} />}
              />
              <PrivateRoute
                path={ROUTES.HOME}
                component={Home}
                componentProps={{ mainFrameState: this }}
              />
              <PrivateRoute
                path={ROUTES.VIEW_TAB_ARTICLES}
                component={Home}
                componentProps={{ mainFrameState: this }}
              />
              <PrivateRoute
                path={ROUTES.INSIGHTS}
                component={Insights}
                componentProps={{ mainFrameState: this }}
              />
              <PrivateRoute
                path={ROUTES.DASHBOARD}
                component={Dashboard}
                componentProps={{ mainFrameState: this }}
              />
              <PrivateRoute path={ROUTES.SETTINGS} component={Settings} />
              <PrivateRoute path={ROUTES.USER_LOGS} component={UserLogs} />
              <PrivateRoute
                path={ROUTES.DOWNLOAD_PREVIEW}
                component={DownloadPreview}
              />
              <PrivateRoute path={ROUTES.USER_LOGS} component={UserLogs} />
              <PrivateRoute
                path={ROUTES.VIEW_IN_NEW_TAB}
                component={Insights}
                componentProps={{ mainFrameState: this }}
              />
            </Switch>
            <TimeOutModal
              logoutModal={this.state.showModal}
              handleModal={this.handleModal}
              handleIslogout={this.handleIslogout}
            />
            <Showlogoutmodal
              handleModal={this.handleModalHide}
              handleIsLogout={this.handleIsLogout}
              showLogoutModal={this.state.showLogoutModal}
              loading={this.state.loading}
            />
            <ProjectSwitchingModal
              showProjectSwitchingModal={this.state.showProjectSwitchingModal}
            />
          </PageBody>
        </PageWrapper>
        <PasswordResetModal
          onChange={this.onResetPasswordChange}
          state={this.state}
          onSubmit={this.resetSuperAdminPassword}
          cancel={this.superAdminCredChange}
          visible={this.state.superAdminPassChange}
          currentProfile={this.props.CurrentProfile}
        />
      </div>
    );
  }
}
export default withMsal(MainFrame);
