import { useEffect, useMemo, useState } from "react";
import { createUser, deleteUser, getUsersPaginated, updateUser } from "api/users";
import { getUserGroupsLookup } from "api/userGroups";
import ButtonElement from "Components/ButtonElement";
import ContentBody from "Components/ContentBody";
import SVGIcons from "Components/SVGIcons";
import TableElement from "Components/TableElement";
import TooltipContainer from "Components/Tooltip";
import global from "../../styles/globel/style.module.scss";
import { notify } from "Components/Notification";
import { DEFAULT_PAGE_COUNT } from "constants";
import { EntityGridPagination } from "./GroupPagination";
import { EditUserModal2 } from "./TableActionModal/EditUserModal2";
import { DeleteUserModal2 } from "./TableActionModal/DeleteUserModal2";
import { validateEmail } from "./helper";


const UserRowActions = props=>{
    const canUpdate = true;
    const canDelete = props.canDelete;
    return (
        <ul className={global.tableActions}>
        {canUpdate ? (<li>
            <TooltipContainer title={'Edit User'} mouseLeaveDelay={0} placement='bottom'>
                <span onClick={(e) => { props.actionHandlers.handleUpdate(props.record) }}>
                   <SVGIcons type={'SVG-edit'} />
                </span>
            </TooltipContainer>
          </li>) : null}
        {canDelete ? (<li>
            <TooltipContainer title={'Delete User'} mouseLeaveDelay={0} placement='bottom'>
                <span onClick={(e) => { props.actionHandlers.handleDelete(props.record) }}>
                    <SVGIcons type={'SVG-delete'} />
                </span>
            </TooltipContainer>
          </li>) : null}
      </ul>
    );
};

const makeColumns = (actionHandlers, sortSettings, currentUserEmail) => {
    const makeSortOrder = columnKey=>{
        return sortSettings.field === columnKey ? sortSettings.order == "desc" ? "descend" : "ascend" : "";
    };

    return [{
        title: 'Id', 
        dataIndex: 'id',
        sorter: true,
        sortOrder: makeSortOrder('id'),
        width: 100
    }, 
    {
        title: 'User Name', 
        dataIndex: 'userName',
        sorter: true,
        sortOrder: makeSortOrder('userName'),

    },
    {
        title: 'First Name', 
        dataIndex: 'firstname',
        sorter: true,
        sortOrder: makeSortOrder('firstname')
    },  
    {
        title: 'Last Name', 
        dataIndex: 'lastname',
        sorter: true,
        sortOrder: makeSortOrder('lastname')
        },  
    {
        title: 'Role', 
        dataIndex: 'role',
        sorter: true,
        sortOrder: makeSortOrder('role')
    },  
    {
        title: 'Group', 
        dataIndex: 'groupName',
        sorter: true,
        sortOrder: makeSortOrder('groupName')
    },  
    {
        title: 'Is Group Admin', 
        dataIndex: 'isGroupAdmin',
        sorter: true,
        sortOrder: makeSortOrder('isGroupAdmin'),
        render: (text, record) => { return <span>{record.isGroupAdmin ? "Yes" : "No"}</span> }
    },  
    {
        title: 'Actions',
        key: 'actions', 
        render: (text, record)=><UserRowActions record={record} actionHandlers={actionHandlers} 
        canDelete={record.roleId != 3 && record.userEmail != currentUserEmail}/>
    }
    ];
};


export const Users3 = props=>{

    const [isLoading, setIsLoading] = useState(false);
    const [userList, setUserList] = useState({items: [], totalCount: 0});

    const [groupList, setGroupList] =  useState([]);

    const [errors, setErrors] = useState([]);

    const [pagination, setPagination] = useState ({ pageNumber: 1, pageSize: DEFAULT_PAGE_COUNT });

    const [sortSettings, setSortSettings] = useState({ field: 'id', order: 'asc' });


    const load = ()=>{
        setIsLoading(true);
        const payload = {
            pageSize: pagination.pageSize, 
            pageNumber: pagination.pageNumber, 
            sortField: sortSettings.field, 
            sortOrder: sortSettings.order
        };

        Promise.all([getUserGroupsLookup(), getUsersPaginated(payload)]).then(([groups, users])=>{
            setGroupList(groups.data.map(g => ({ label: g.name, value: ''+g.id })));
            setUserList({
                items: users.data.result, 
                totalCount: users.data.totalCount
            });
            setIsLoading(false);
        }).catch(err=>{

        });
    };

    useEffect(()=>{
      load();
    }, [pagination, sortSettings]);

    
    const [editModalVisible, setEditModalVisible] = useState(null);    
    const [deleteModalVisible, setDeleteModalVisible] = useState(null);
    
    const handleUpdate = (record)=>{
        setEditModalVisible({...record});
    };
    const handleDelete = (record)=>{
        setDeleteModalVisible({...record});
    };

    const columns = useMemo(()=>{
        const result = makeColumns({handleUpdate, handleDelete}, sortSettings, props.userEmail);
        return result;
    }, [handleUpdate, handleDelete, sortSettings, props.userEmail]);

    
    const confirmEditUser = (userToEdit)=>{
        debugger;
        // some validation for user properties:
        const errors = [];
        const firstName = userToEdit.firstName?.trim();
        if (!firstName){
            errors.push({fieldName: 'firstName', error: 'First Name is required.'});
        }

        const lastName = userToEdit.lastName?.trim();
        if (!lastName){
            errors.push({fieldName: 'lastName', error: 'Last Name is required.'});
        }

        // email should be email: 
        const userEmail = userToEdit.userEmail?.trim();
        if (!userEmail){
            errors.push({fieldName: 'userEmail', error: 'User Email is required.'});
        }
        else{
            const isValidEmail = validateEmail(userEmail);
            if (!isValidEmail){
                errors.push({fieldName: 'userEmail', error: 'User Email has invalid format.'});
            }
        }

        if (errors.length){
            setErrors(errors);
            return;
        }


        setIsLoading(true);

        if (userToEdit.id){ 
          updateUser({...userToEdit, 
            firstName, lastName, userEmail,
            isGroupAdmin: userToEdit.isGroupAdmin==="true", groupId: +userToEdit.groupId}).then(res=>{
              debugger;
              // TODO: update the response contract to return errors
              console.log(res);
              load();
              setEditModalVisible(null);
              notify.success("User details updated");
          }).catch(err=>{
            setIsLoading(false);
            notify.error("Failed to update user details");
          });
        }
        else{
            // new user
            createUser({...userToEdit, isGroupAdmin: userToEdit.isGroupAdmin==="true", groupId: +userToEdit.groupId}).then(res=>{
                debugger;
                if (res.data.errors && res.data.errors.length){
                    setErrors(res.data.errors);
                    setIsLoading(false);
                }
                else{
                    setErrors([]);
                    load();
                    setEditModalVisible(null);
                    notify.success("User added");
                }
            }).catch(err=>{
                notify.error("Failed to add user");
                setIsLoading(false);
            });
        }
    };

    const confirmDeleteUser = (userToDelete)=>{
        debugger;
        setIsLoading(true);
        deleteUser(userToDelete.id).then(res=>{
            debugger;
            if (res.data.errors && res.data.errors.length){
                setErrors(res.data.errors);
                setIsLoading(false);
            }
            else{
                setErrors([]);
                load();
                setDeleteModalVisible(null);
                notify.success("User deleted");
            }
        }).catch(err=>{
            debugger;
            notify.error("Failed to delete user");
            setIsLoading(false);
        });
         
    };

    const handlePaginationChange = (pageNumber, pageSize)=>{
        debug
        setPagination({ pageNumber, pageSize });
        
    };

    const handleSortingChange = (a, b, sorter)=>{
        const newSortSettings = {
            field: sorter.field,
            order: sorter.order == 'descend' ? 'desc' : 'asc'
        };
        setSortSettings(newSortSettings);
    };
    
    
    return (<ContentBody>

        <div>
            <ButtonElement disabled={isLoading} styleProps={{marginLeft: 'auto'}} onClick={()=>setEditModalVisible({})}>Add User</ButtonElement>
        </div>

        <div className={global.paginationWrapper}>
            <div className={global.searchresultCount}>
                <span>
                    {userList.items.length ? `Showing ${userList.items.length} out of ${userList.totalCount} entries` : '' }
                </span>
            </div>
            <div className={global.searchresultPage}>
                {userList.totalCount > DEFAULT_PAGE_COUNT ? <EntityGridPagination 
                    pagination={pagination} totalCount={userList.totalCount} 
                    handlePaginationChange={handlePaginationChange}
                /> : null}
            </div>
        </div>

        {userList.items ? <TableElement   
            style={{width: 'calc(100vw - 125px)'}}
            scrollY={"calc(100vh - 270px)"}
            loading={isLoading}
            rowKey='id'
            columns={columns}
            data={userList.items}
            onChange={handleSortingChange}
            tableLayout='fixed'
        /> : null}
            

        {editModalVisible ? <EditUserModal2 
            isLoading={isLoading}
            isSelf={editModalVisible.userEmail && props.userEmail === editModalVisible.userEmail}
            loggedInSuperAdmin={props.loggedInSuperAdmin}
            
            allGroups={groupList} 
            errors={errors}
            user={editModalVisible}  
            onCancel={()=>{
                setErrors([]); 
                setIsLoading(false);
                setEditModalVisible(null);
            }} 
            onConfirm={confirmEditUser}/> : null}

        {deleteModalVisible ? <DeleteUserModal2 
            isLoading={isLoading}
            errors={errors}
            user={deleteModalVisible} 
            onCancel={()=>{
                setErrors([]); 
                setIsLoading(false);
                setDeleteModalVisible(null);
            }} 
            onConfirm={confirmDeleteUser}/> : null}
    </ContentBody>);

}