import CustomScrollbar from "Components/CustomScrollbar";
import { SearchInput } from "Components/SearchInput";
import { useEffect, useState } from "react";

import { Button } from "antd";

export const TaxonomyFilterCheckboxRow = props=>{

    const [items, setItems] = useState([]);

    useEffect(()=>{
        if (!props.loadedTaxonomies[props.taxonomyName]){
            setItems([]);
            return;
        }
        const sortedItems = [...(props.loadedTaxonomies[props.taxonomyName])];
        
        if (props.sortOrder == 2){
            // results count ascending (small to big)
            sortedItems.sort((a, b)=>{
                if (a.count > b.count) {
                    return 1;
                }
                if (a.count < b.count) {
                    return -1;
                }
                return 0
            });
        }

        if (props.sortOrder == 3){
            // results count descending (big to small)
            sortedItems.sort((a, b)=>{
                if (a.count > b.count) {
                    return -1;
                }
                if (a.count < b.count) {
                    return 1;
                }
                return 0
            });
        }

        if (props.sortOrder == 0){
            // alphabetically ascending (small to big, a-z)
            sortedItems.sort((a, b)=>{
                if (a.value < b.value) {
                    return -1;
                }
                if (a.value > b.value) {
                    return 1;
                }
                return 0
            });
        }

        if (props.sortOrder == 1){
            // alphabetically descending (big to small, z-a)
            sortedItems.sort((a, b)=>{
                if (a.value > b.value) {
                    return -1;
                }
                if (a.value < b.value) {
                    return 1;
                }
                return 0
            });
        }

        setItems(sortedItems);


    }, [props.loadedTaxonomies, props.sortOrder]);


    const [searchKey, setSearchKey] = useState('');

    const [itemsToShow, setItemsToShow] = useState([]);

    const handleSelectAll = ()=>{

        const newLoadedTaxonomies = {...props.loadedTaxonomies, [props.taxonomyName]: itemsToShow.map(x=>({...x, checked: true}))};

        props.updateTaxonomies(newLoadedTaxonomies, props.taxonomyName);
    };

    const handleClearAll = ()=>{
        const newLoadedTaxonomies = {...props.loadedTaxonomies, [props.taxonomyName]: props.loadedTaxonomies[props.taxonomyName].map(x=>({...x, checked: false}))};
        props.updateTaxonomies(newLoadedTaxonomies, props.taxonomyName);
    };

    useEffect(() => {
        if (!items){
            return;
        }
        const formattedSearchKey = searchKey.trim().toLowerCase();
        if (formattedSearchKey !== "") {
            const searchedOptions = items.filter((item) =>
                item.value.toLowerCase().includes(formattedSearchKey)
            );
            setItemsToShow([...searchedOptions]);
        } else {
            setItemsToShow([...items]);
        }
    }, [searchKey, items]);

    const handleCheckboxChecked = (checked, item)=>{

        const newItems = props.loadedTaxonomies[props.taxonomyName].map(x=>{
            if (x.value == item.value){
                return {...x, checked}
            }
            else{
                return x;
            }
        });

        
        const newLoadedTaxonomies = {...props.loadedTaxonomies, [props.taxonomyName]: newItems};

        props.updateTaxonomies(newLoadedTaxonomies, props.taxonomyName);

    }

    if (!itemsToShow){
        return <div style={{textAlign: 'center'}}>No data</div>
        // or return a spinner
    }
    return (<>
    <div>

        <SearchInput value={searchKey} handleChange={e=>setSearchKey(e.target.value)} placeholder={`Searct ${props.taxonomyName}`}/>

            <div style={{display: 'flex'}}>
            <div>
                <Button type="link" size="small" onClick={handleSelectAll}>Select All</Button>
            </div>      
            <div>
              <Button type="link" size="small" onClick={handleClearAll}>Clear All</Button>
            </div>
            </div>
        
            {itemsToShow.length ? 

            <CustomScrollbar className="inner-scroller">
                <div>
                {itemsToShow.map((item, ix)=>{

                return (<div key={`taxonomy-value-row-${ix}`} style={{display: 'flex', position: 'relative', padding: '2px', marginRight: '12px'}}>


                    <div style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', flexShrink: 1, flexBasis: 'auto', maxWidth: '80%'}}>
                        
                        <span style={{padding: '6px'}}>
                        <input type='checkbox' 
                            checked={!!item.checked}
                            onChange={e=>handleCheckboxChecked(e.target.checked, item)}
                        
                        />
                        </span>
                        {item.value}
                    </div>
                    <div style={{
                        position: 'absolute', right: 4, 
                        flexGrow: 1}}>
                        {item.count}
                    </div>

                    
                </div>);
                })}
                </div>

            </CustomScrollbar>
            : <div style={{textAlign: 'center'}}>No data</div>}
        
    </div></>)
}