import React, { useEffect, useRef } from 'react';
import { Dropdown } from '../../../../../Dropdown';
import SVGIcons from '../../../../../SVGIcons';
import TooltipContainer from '../../../../../Tooltip';

import style from './style.module.scss';

export const FilterSortButton2 = props=> {
    const ref = useRef(null);
    const { handleSortingVisible } = props;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                handleSortingVisible(false);
            }
        };
        
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [handleSortingVisible]);

  return (
    <div ref={ref}>        
        <Dropdown
            customButton={
                <div className={style.sortTrigger} style={{minWidth: '190px'}}>

                    <TooltipContainer title={'Change sort method'} placement='top'>
                        <span onClick={() => props.handleSortingVisible(!props.showSorting)}>Sort By:</span>
                    </TooltipContainer>


                    {props.currentSortSettings.sortingType === 'relevance' && (
                        <a to={{}} className={`${style.linkButton} `} onClick={()=>props.handleSortingDirection('relevance')}>
                            <TooltipContainer title={props.currentSortSettings.order === 'up' ? 'Descending' : 'Ascending'} placement='bottom'>
                                <span>
                                    <SVGIcons type={props.currentSortSettings.order === 'up' ? 'SVG-sortarrowup' : 'SVG-sortarrowdown'} />
                                </span>
                            </TooltipContainer>
                            
                            <span>Relevance</span>
                        </a>
                    )}


                    {props.currentSortSettings.sortingType === 'title' && (
                        <a to={{}} className={`${style.linkButton} `} onClick={()=>props.handleSortingDirection('title')}>
                            <TooltipContainer title={props.currentSortSettings.order === 'up' ? 'Descending' : 'Ascending'} placement='bottom'>
                                <span>
                                    <SVGIcons type={props.currentSortSettings.order === 'up' ? 'SVG-sortarrowup' : 'SVG-sortarrowdown'} />
                                </span>
                            </TooltipContainer>
                            <span>Title</span>
                        </a>
                    )}


                    {props.currentSortSettings.sortingType === 'publishdate' && (
                        <a to={{}} className={`${style.linkButton} `} onClick={()=>props.handleSortingDirection('publishdate')}>
                            <TooltipContainer title={props.currentSortSettings.order === 'up' ? 'Descending' : 'Ascending'} placement='bottom'>
                                <span>
                                    <SVGIcons type={props.currentSortSettings.order === 'up' ? 'SVG-sortarrowup' : 'SVG-sortarrowdown'} />
                                </span>
                            </TooltipContainer>
                            <span>Publish Date</span>
                        </a>
                    )}

                    {props.currentSortSettings.sortingType === 'conceptname' && (
                        <a to={{}} className={`${style.linkButton} `} onClick={()=>props.handleSortingDirection('conceptname')}>
                            <TooltipContainer title={props.currentSortSettings.order === 'up' ? 'Descending' : 'Ascending'} placement='bottom'>
                                <span>
                                    <SVGIcons type={props.currentSortSettings.order === 'up' ? 'SVG-sortarrowup' : 'SVG-sortarrowdown'} />
                                </span>
                            </TooltipContainer>
                            <span>Concept Name</span>
                        </a>
                    )}
                </div>
            } 
            
            addOnClass={props.showSorting}
        >
            <ul className={style.sortDroplist}>

                {props.isRelevanceVisible ? <li onClick={() => props.handleChangeSortingType('relevance')}>
                    Relevance
                </li> : null}
                
                
                <li onClick={() => props.handleChangeSortingType('title')}>
                    Title
                </li>
        
                <li onClick={() => props.handleChangeSortingType('publishdate')}>
                    Publish Date
                </li>

                <li onClick={() => props.handleChangeSortingType('conceptname')}>
                    Concept Name
                </li>

            </ul>
        </Dropdown>
    </div>
  );
}


