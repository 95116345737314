import { SelectElement } from "Components/SelectElement";
import style from "./style.module.scss";
import { InputElements } from "./InsightChartFields";
import { ParameterButtonBlock } from "./ParameterButtonBlock";
import { getChartParamsDefaultValue } from "../helper/insightsHelper";
import { 
    State, 
    useEffect,
    useState} from "react";

export const ModifyInsightsModalContent = props=>{
    // copied from InsightsChartFields.js 
    debugger;

    const payload = {
        "Maximum word count": {
          value: 300,
          index: "",
        },
        "Minimum word occurrence": {
          value: 1,
          index: "",
        },
        Datafield: {
          value: "Keywords",
          index: "",
        },
    };

    const CHART_OPTIONS = [{ value: "BAR_CHART", label: "Bar chart" }, { value: "KEY_PHRASE_CLOUD", label: "Key phrase cloud" }, { value: "HEAT_MAP", label: "Heat map" }];

    

    const [selectedChartKey, setSelectedChatKey] = useState(props.insightsChartKey); 

    const [option1, setOption1] = useState(props.option1);
    const [option2, setOption2] = useState(props.option2);

    const [cloudTaxonomy, setCloudTaxonomy] = useState(props.cloudTaxonomy);


    

    
    const handleSelectChart = selectedKey=>{
        setSelectedChatKey(selectedKey);
        setOption1('');
        setOption2('');
    };

    const onApply =()=>{
        props.onApply({selectedChartKey, option1, option2, cloudTaxonomy});
    }


    return (<div className={style.insightElements}>
        
            <div className={style.insightElements__item}>
                <div className={style.listBlock}>
                    <h4>Insight Parameters</h4>
                    <div className={style.listBlock__content}>
                        <div className={style.listInputs}>
                            <SelectElement 
                                value={selectedChartKey}
                                optionList={CHART_OPTIONS} 
                                onChange={handleSelectChart} 
                            />
                        </div>  
                    </div>
                </div>
            </div>

            {selectedChartKey == "BAR_CHART" ? <>
            <span>
                Data on horizontal axis
            </span>
            <div>
                <SelectElement 
                    optionList={props.taxonomies.map(x=>({value: x.name, label: x.name}))} 
                    onChange={value=>setOption1(value)} 
                    value={option1}
                />
            </div>
            </> : null}

            {selectedChartKey == "KEY_PHRASE_CLOUD" ? <>
                <span>
                    Build word cloud on
                </span>
                <SelectElement 
                    optionList={props.taxonomies.map(x=>({value: x.name, label: x.name}))} 
                    onChange={value=>setCloudTaxonomy(value)} 
                    value={cloudTaxonomy}
                />
                Maximum words allowed in word cloud is 300
            </> : null}

            

            {selectedChartKey == "HEAT_MAP" ? <>
                <span>
                Data on horizontal axis
            </span>
            <div>
                <SelectElement 
                    optionList={props.taxonomies.map(x=>({value: x.name, label: x.name}))} 
                    onChange={value=>setOption1(value)} 
                    value={option1}
                />
            </div>
            <span>
                Data on vertical axis
            </span>
            <div>
                <SelectElement 
                    optionList={props.taxonomies.map(x=>({value: x.name, label: x.name}))} 
                    onChange={value=>setOption2(value)} 
                    value={option2}
                />
            </div>
            </> : null}


       
        <div className={style.insightElements__footer}>
            <ParameterButtonBlock handleClose={props.onClose} handleUpdate={onApply}/>
        </div>
    </div>);
}
