import React, {useEffect} from 'react';
import {Input, message} from 'antd';
const CHARACTER_LIMIT = 1500;
const DEFAULT_ROW = 5;
const ERROR_MESSAGE_MAX = 'Maximum character limit reached!';
const TextArea = (props) => {
  const {TextArea} = Input;
  useEffect(() => {
    props.wordCharacterCount &&
      props.wordCharacterCount({target: {value: props.value}});
  }, [props.value]);
  const onChange = (eve) => {
    let limit = props.maxLength || CHARACTER_LIMIT;
    if (eve.target.value.length > limit) {
      message.error(props.maxLimitMessage || ERROR_MESSAGE_MAX);
    } else {
      props.onChange && props.onChange(eve);
      props.wordCharacterCount && props.wordCharacterCount(eve);
    }
  };
  return (
    <>
      <TextArea
        id={props.id}
        as='textarea'
        onBlur={props.onBlur}
        rows={props.rows || DEFAULT_ROW}
        onChange={onChange}
        disabled={props.disabled}
        onFocus={props.onFocus}
        placeholder={
          props.hidePlaceHolder ? undefined : props.placeHolder || props.title
        }
        value={props.value}
        defaultValue={props.defaultValue}
        type={props.htmlType}
        maxLength={props.maxLength + 1 || CHARACTER_LIMIT + 1}
        name={props.name}
        onKeyUp={props.onKeyUp}
        className={`${props.className || 'textarea-item'} ${
          !props.isValid ? ' error-field' : ''
        }`}
        onMouseUp={props.onMouseUp}
      />
    </>
  );
};

export default TextArea;

TextArea.defaultProps = {
  onKeyUp: () => {},
};
