// original component is in SearchCard.js

import ModalWindow from "Components/ModalWindow";


import style from '../TagBlock/style.module.scss';
import { NoTagsControl } from "./NoTagsControl";
import { useState } from "react";
import { TagRow } from "./TagRow";

// This window allows to view/edit user tags 
export const MyDisplayTagsModal2 = (props) =>{
    debugger;

    const [userTags, setUserTags] = useState((props.userTags || []).map(x=>({value: x}))); 

    const handleAddTag = ()=>{
        debugger;
        setUserTags([...userTags, {isNew: true, value: ''}]);
    };

    const handleRemoveTag = tagIndex=>{
        debugger;
        const newTags = userTags.map((t, ix)=>{
            if (ix == tagIndex){
                return {...t, isDeleted: true};
            }
            else{
                return t;
            }
        });
        setUserTags(newTags);
    };

    const handleTagChange = (value, tagIndex)=>{
        
        const newTags = userTags.map((t, ix)=>{
            if (ix == tagIndex){
                return {...t, value};
            }
            else{
                return t;
            }
        });
        setUserTags(newTags);
    };

    const nonDeletedTagCount = userTags.filter(x=>!x.isDeleted).length;

    return (<ModalWindow
        open={true}
        title={"Manage User Tags"}
        width={600}
        onOk={()=>props.handleSave(userTags.filter(x=>!x.isDeleted))}
        isNotClosable={true}
        isNonMaskable={true}
        okText={"Save"}
        cancelText="Cancel"
        confirmLoading={props.loading}
        onCancel={props.onCancel}
    >

        <div className={style.addtagBlock} style={{maxHeight: '400px', overflowY: 'auto'}}>

        {!nonDeletedTagCount ? <NoTagsControl setUserTags={setUserTags}/>  : 
    
        <ul className={style.addtagBlock__list}>
            {userTags.map((item, index) => (<li key={`tag-row-${index}`}>
                {item.isDeleted ? null : 
                <TagRow index={index} tag={item} addButtonVisible={index == nonDeletedTagCount - 1} 
                    handleAddTag={handleAddTag}
                    handleRemoveTag={()=>handleRemoveTag(index)}
                    handleTagChange={(value)=>handleTagChange(value, index)}
                />}


            </li>
            
            ))}
        </ul>
        }

        </div>

    </ModalWindow>);
};
  