import { Radio } from "antd";
import style from "./style.module.scss";
import { FromDate } from "./FromDate";

export const DateOptionUntilNow = props=>{
    
    const handleDatesChange = (newValue)=>{
        debugger;
        // newValue is {from: moment, to: moment}
        console.log(newValue);

        props.handleDatesChanged(newValue);
    };

    return (<>

    <div className={style.datesHeader}>
        <Radio className={style.radio} onChange={e=>props.handleDateFilterTypeChanged(e.target.value)} checked={props.dateFilterType == 1} value={1}>
            <span>From a date until now</span>
        </Radio>
    </div>

    {props.dateFilterType == 1 ? <FromDate 
        dateFormat={props.dateFormat}
        //filterResults={filterResults}
        dateFilterObject={props.dateFilterObject}
        onDateChanged={handleDatesChange}
    /> : null}
    
    </>)
}