import ModalWindow from "Components/ModalWindow";
import { SaveDashboardContent } from "./SaveDashboardContent";
import { useState } from "react";

export const SaveDashboardsModal = props=>{

    const [name, setName] = useState(props.name);

    return (<ModalWindow 
        title='Save Dashboard'
        disableOkButton={!name}

        onOk={()=>{
            debugger;
            props.onOk(name.trim());
        }}
        onCancel={()=>props.onCancel()}
        
        isNotClosable={true}
        isNonMaskable={true}
        width='500px'
        okText='Save'
        open={true}    
    >
        <SaveDashboardContent
            name={name} setName={setName}
            handleEnterConfirm={()=>props.onOk(name.trim())}
        />
    </ModalWindow>);

};