import global from "../../../styles/globel/style.module.scss";
import { SEARCH_CARD_METADATA_ITEMS_BLACK_LIST } from "Constants/Home/Home.constants";
import { TagContent } from "./TagContent";

import styles from './style.module.scss';
import { useState, useEffect } from "react";

const DEFAULT_BRICK_COUNT = 6; // how many bricks are displayed unless showAllTags=true is passed in properties

export const MetadataBlock2 = props=>{

    const allTaxonomies = props.allTaxonomies || [];
    const document = props.document;

    const showAllTagsGlobal = props.showAllTags;

    const [showAllTags, setShowAllTags] = useState(props.showAllTags);
    useEffect(()=>{
        setShowAllTags(showAllTagsGlobal)
    }, [showAllTagsGlobal]);

    let foundTaxonomies = [];

    allTaxonomies.filter(x=>!SEARCH_CARD_METADATA_ITEMS_BLACK_LIST.includes(x)).filter(t=>document[t]).map(t=>{
        const taxonomyValue = document[t];
        if (Array.isArray(taxonomyValue)){
            // we need to get the score out of these guys:
            const score = document[`${t}_score`];
            if (!score){
                // score column not found 
                taxonomyValue.map(splitValue=>{
                    foundTaxonomies.push({name: t, value: splitValue, score: 0});
                });
            }
            else{

                const taxonomyValuesWithScore = taxonomyValue.map((splitValue, ix)=>{
                    return {name: t, value: splitValue, score: score[ix], noHighlightValue: splitValue.replaceAll("<em>", "").replaceAll("</em>", "")};
                });

                taxonomyValuesWithScore.sort((a, b) =>{
                    if (a.score == b.score){
                        if (a.noHighlightValue > b.noHighlightValue){
                            return 1;
                        }
                        if (a.noHighlightValue < b.noHighlightValue){
                            return -1;
                        }
                        return 0;
                    }
                    else {
                        if (a.score > b.score){
                            return -1;
                        }
                        if (a.score < b.score){
                            return 1;
                        }
                        return 0
                    }
                });

                taxonomyValuesWithScore.map(x=>{
                    foundTaxonomies.push(x);
                });
            }
            
        }
        else{
            foundTaxonomies.push({name: t, value: taxonomyValue, score: 0});
        }
    });


    const taxonomiesToShow = showAllTags ? [...foundTaxonomies] : foundTaxonomies.slice(0, DEFAULT_BRICK_COUNT);
    
    let colorCounter = -1;
    let prevName = '';
  

    const colors = [
        {
            color: "hsl(240, 100%, 55%)",   // Very dark blue (almost black)
            lightFont: "hsl(0, 0%, 100%)",    // grey-ish whte
        }, 
        {
            color: "hsl(240, 100%, 65%)",  // Dark blue
            lightFont: "hsl(0, 0%, 100%)",    // grey-ish whte
        },
        {
            color: "hsl(240, 100%, 60%)",
            lightFont: "hsl(0, 0%, 100%)",    // grey-ish whte
        },
        {
            color: "hsl(240, 100%, 65%)",
            lightFont: "hsl(0, 0%, 100%)",    // grey-ish whte
        },
        {
            color: "hsl(240, 100%, 70%)",
            lightFont: "hsl(0, 0%, 100%)",    // grey-ish whte
        },
        {
            color: "hsl(240, 100%, 75%)",    // Medium blue
            lightFont: "hsl(0, 0%, 95%)",    // grey-ish whte
        }, 
        {
            color: "hsl(240, 100%, 80%)",
            //lightFont: "hsl(0, 0%, 94%)",    // grey-ish whte
          
        }, 
        {
            color: "hsl(240, 100%, 87%)",
          
        },
        {
            color: "hsl(240, 100%, 92%)",
        
        },
        {
            color: "hsl(240, 100%, 97%)", // Very light blue (almost white)
        }  
    ];
      
    const getColor = item=>{
        if (item.name !== prevName){
            prevName = item.name;
            colorCounter++;
        }
  
        if (colorCounter == 10 ){
            colorCounter = 0;
        }
  
        const idx = colors.length - 1 - colorCounter;
      
        const result = colors[idx];
  
        return result;
    };


    return (<div className={global.resultMetatag}>


    <ul className={global.metaTagList}>
        {taxonomiesToShow.map((t, ix)=>(<li key={`brick-${ix}`}><TagContent meta={{type: t.name, data: t.value, score: t.score}} cData={getColor(t)} /></li>))}
    </ul>


    {foundTaxonomies.length > DEFAULT_BRICK_COUNT ? <>
        <a className={global.metaTagMoreLink} onClick={()=>setShowAllTags(!showAllTags)}>
          {showAllTags ? <>View Less</> : <>View More ({foundTaxonomies.length})</>}
        </a>
    </> : null}


    <br/>


    </div>);
}