import React from "react";

import InputField from "../../../Components/InputField";
import TooltipContainer from "../../../Components/Tooltip";
import ModalWindow from "../../../Components/ModalWindow";

export function EditUserModal(props) {
  const isSelf = props.emailId === props.user?.emailid;
  return (
    <ModalWindow
      title={props.title}
      isNotClosable={true}
      okText={props.buttonTitle}
      cancelText={"Cancel"}
      onCancel={(e) => {
        props.onCancel();
      }}
      confirmLoading={props.loader}
      onOk={(e) => {
        props.onSubmit();
      }}
      open={props.visible}
      isNonMaskable={true}
    >
      <ul>
        <li>
          {
            props.allowEditId ?
              (
                <>
                  Unique Identifier:
                  <InputField
                    placeholder={"ADFS mail"}
                    onChange={(e) => {
                      props.onChange("emailid", e.target.value.trim());
                    }}
                    value={props.user?.emailid}
                  />
                </>
              ) :
              (
                <TooltipContainer title="This field can not be changed" placement={"top"}>
                  <>
                  Unique Identifier:
                  <div style={{ pointerEvents: 'none' }}>
                    <InputField
                      value={props.user?.emailid}
                      readOnly
                    />
                  </div>
                  </>
                </TooltipContainer>
              )
          }
        </li>
        <li>
          <div style={{ display: 'flex', width: '100%', gap: '20px' }}>
            <div style={{ flex: 1 }}>
              First name:
              <InputField
                placeholder="Enter the first name"
                onChange={(e) => {
                  props.onChange("firstname", e.target.value.trim());
                }}
                value={props.user?.firstname}
              />
            </div>
            <div style={{ flex: 1 }}>
              Last name:
              <InputField
                placeholder="Enter the last name"
                onChange={(e) => {
                  props.onChange("lastname", e.target.value.trim());
                }}
                value={props.user?.lastname}
              />
            </div>
          </div>
        </li>
        {
          (!isSelf && props.user?.userProfile !== 3) && (
            <li>
              User Role:
              <InputField
                inputType={"selectElement"}
                optionList={[
                  { label: "analyst", value: "analyst" },
                  { label: "admin", value: "admin" },
                ]}
                defaultValue={"analyst"}
                value={props.user?.profilename}
                onChange={(e) => {
                  props.onChange("profilename", e);
                }}
              />
            </li>)}
        {props.isSuperAdmin && (
          <li>
            Group:
            <InputField
              inputType={"selectElement"}
              optionList={props.userGroupStore.map(g => ({ label: g.name, value: g.id }))}
              value={props.user?.groupId}
              onChange={(e) => {
                props.onChange("groupId", e);
              }}
            />
          </li>)}
        <li>
          Is Group Admin:
          <InputField
            inputType={"selectElement"}
            optionList={[{ label: "Yes", value: true }, { label: "No", value: false }]}
            defaultValue={false}
            value={props.user?.isGroupAdmin}
            onChange={(e) => {
              props.onChange("isGroupAdmin", e);
            }}
          />
        </li>
      </ul>
    </ModalWindow>
  );
}
