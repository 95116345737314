import { LOGIN_ADFS_BUTTON_TITLE, RESTRICTION_MESSAGE, SAML_LOGIN_URL } from "Constants/Login/Login.constants";
import ButtonElement from "Components/ButtonElement";
import SVGIcons from "../../Components/SVGIcons";
import { APP_VERSION } from "constants";

export const LoginLanding = ()=>{

    const handleLoginWithADFS = ()=>{
        window.location.href = SAML_LOGIN_URL;
    };  


    return <section className='landing-background' style={{width: '100%', height: '100vh', display: 'flex'}}>

        <div style={{width: '50%', height: '100%', overflow: 'hidden'}}>
   
            <div style={{height: '50%', position: 'relative'}}>

            </div>
            <div style={{height: '50%', position: 'relative'}}>


                <div style={{position: 'absolute', bottom: '10px', left: '20px'}}>
                    <p>
                    Powered By
                    </p>
                    <div className="pingar-logo pingar-logo-small">
                    </div>


                    <div className="app-version">
                        <p>
                            <span>Pingar Content Insights</span>

                            <span>version {APP_VERSION}</span>


                        </p>
                    </div>

                </div>
            </div>
        </div>

        <div style={{width: '50%', height: '100%', display: 'flex'}}>
            <div style={{ margin: 'auto', overflow: 'hidden', maxHeight: '100vh', maxWidth: '100vh'}}>
                <div style={{margin: 'auto'}}>
                    
                    <div className="bottom-right-quarter">
                        <div style={{
                            fontSize: '48px', color: 'yellow', fontWeight: 'bold', lineHeight: 1, paddingBottom: '20px'
                            }}>
                            CPD<br/> Knowledge Hub
                        </div>

                        <div style={{minWidth: '300px'}}>
                            <ButtonElement icon={"SVG-ms"} size={"large"} type="primary" style={{margin: 'auto', minWidth: '300px'}} className="microsoft-btn" 
                            onClick={() => handleLoginWithADFS()}>
                                {LOGIN_ADFS_BUTTON_TITLE}
                            </ButtonElement>
                        </div>
                        <div className="warning-box">

                            <div style={{height: '40px', width: '40px', padding: '4px'}}>
                                <span>
                                    <SVGIcons type={"SVG-alertIcon"}/>
                                </span>
                            </div>

                            <div  style={{padding: '4px'}}> 
                                <h3 style={{color: 'white'}}>
                                    {RESTRICTION_MESSAGE.HEADING}
                                </h3>
                                <div>
                                    <p>{RESTRICTION_MESSAGE.LINE_1}</p>
                                    <p>{RESTRICTION_MESSAGE.LINE_2}</p>
                                </div>
                            </div>
                        </div>

                        <div>
                            <h4 style={{color: 'white'}}>
                            Airport Authority Hong Kong
                            </h4>
                        </div>


                        

                    </div>
                </div>
            </div>
        </div>
    </section>
}