import parse from "html-react-parser";
import TooltipContainer from "Components/Tooltip";
import global from "../../../styles/globel/style.module.scss";
import { formatFilterLabel, formatMetadataKeyword } from "utils/formatFilterLabel";

export const TagContent = props=>{
    const {meta, cData} = props;
    
    return (<div className={global.metaListTagElement} style={{background: cData.color}}>
    <TooltipContainer title={formatMetadataKeyword(formatFilterLabel(meta.type))}>
      
      <span style={cData.lightFont ? {color: cData.lightFont} : null}>
        {parse(meta.data)}
        {window.showScores ? <>&nbsp; score: {meta.score}</> : null}
      </span>
      
    </TooltipContainer>
  </div>);
  };