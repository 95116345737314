import ReactECharts from 'echarts-for-react';
import { useEffect } from 'react';

export const NewChartRadar = props=>{

    const query1 = {
        "taxonomyFilterRows": [],
        "simpleSearchText": "hong kong",
        "advancedSearchRows": [],
        "activeTabKey": "All Articles",
        "projectId": 25,
        "cloudSearchWords": []
    };


    const query2 = {
        "taxonomyFilterRows": [],
        "simpleSearchText": "heathrow",
        "advancedSearchRows": [],
        "activeTabKey": "All Articles",
        "projectId": 25,
        "cloudSearchWords": []
    };


    useEffect(()=>{
        // need to call an endpoint that takes the search and returns series -number of tags

    }, []);

    const arms = [];
    const option = {
      title: {
        text: 'Radar'
      },
      legend: {
        data: ['hong kong', 'heathrow']
      },
      radar: {
        shape: 'circle',
        indicator: [
          { name: 'Luggage Loss', max: 6500 },
          { name: 'Insurance Claim', max: 16000 },
          { name: 'Customer Satisfaction', max: 30000 },
          
        ]
      },
      series: [
        {
          name: 'Budget vs spending',
          type: 'radar',
          data: [
            {
              value: [4200, 3000, 20000, 35000, 50000, 18000],
              name: 'hong kong'
            },
            {
              value: [5000, 14000, 28000, 26000, 42000, 21000],
              name: 'heathrow'
            }
          ]
        }
      ]
    };
    return <>
        <ReactECharts option={option} style={{ height: 400, width: "100%" }}/>
    </>
}