import ExportsResults from "./HomeHeader/children/ExportsResults";
import LoadResults from "./HomeHeader/children/LoadResults";
import { SaveResultsButton } from "./HomeHeader/children/SaveResultsButton";
import style from "./style.module.scss";

export const ThreeButtonsControl = props=>{
    return (<ul className={style.controlsElement__list}>
        <li>
            <div className={style.controlsItem}>
                {/* handleSave shows a modal window */}
                <SaveResultsButton title='Save Results' handleSavedClicked={props.handleSavedClicked} 
                />
          </div>
        </li>
        <li>
            <div className={style.controlsItem}>
                <LoadResults toolTipTitle={"Saved Queries"} handleLoad={(value) => props.handleLoadClicked(value)} />
            </div>
        </li>
        <li>
            <div className={style.controlsItem}>
                <ExportsResults
                    handleExport={props.handleExport} exportLoading={props.exportLoading} 
                />
            </div>
        </li>
    </ul>);
}