import ModalWindow from "Components/ModalWindow";
import { LoadDashboardContent } from "./LoadDashboardContent";

export const LoadDashboardsModal = props=>{

    return (<ModalWindow 
        onOk={()=>{
            debugger;
            props.onCancel();
        }}
        isHideCancelButton={true}
        isNotClosable={true}
        isNonMaskable={true}
        width='900px'
        okText='Close'
        open={true}    
    >
        <LoadDashboardContent triggerReload={props.triggerReload} handleView={props.handleView} handleDownload={props.handleDownload} handleDelete={props.handleDelete}/>
    </ModalWindow>);
};