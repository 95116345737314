import { InputNumber } from "antd";
import global from "styles/globel/style.module.scss";

export const FromNDays = props=>{
    return (<div style={{display: 'flex', position: 'relative', backgroundColor: props.noBackground ? undefined : 'rgba(161, 157, 236, 0.5)', padding: '8px'}}>
        <div style={{width: '100%'}}>
            {/* <div style={{height: '1.5em'}}>
                
            </div> */}

            <div style={{paddingLeft: '4px', marginTop: '1.5em'}}>
                <div style={{margin: 'auto'}}>
                <InputNumber style={{width: '100%'}} min={0} max={1000} value={props.number} onChange={props.onChange} 
                    onBlur={props.onBlur} onPressEnter={props.onPressEnter}/>

                    <span className={global.controlError}>
                        {props.numberOfDaysError}
                    </span>
                </div>
            </div>

        </div>

    </div>);
};