import InputField from "Components/InputField";
import ModalWindow from "Components/ModalWindow"
import TooltipContainer from "Components/Tooltip";
import { useEffect, useMemo, useState } from "react";

export const EditGroupModal2 = props=>{
    
    const [group, setGroup] = useState({});

    const isLoading = props.isLoading;

    const [errors, setErrors] = useState([]);

    useEffect(()=>{
        setErrors(props.errors);
    }, [props.errors]);

    useEffect(()=>{
        setGroup({
            ...props.group, 
            defaultQueryId: props.group.defaultQueryId ? props.group.defaultQueryId.toString() : null
        });

    }, [props.group]);

    const handleGroupNameChange = e=>{
        setErrors(errors.filter(e=>e.fieldName != 'name'));
        setGroup({...group, name: e.target.value});
    };

    const queryOptionList = useMemo(()=>{
        // do we want to be able to assign the query only to one group on this form?

        //const selectedQueryId = group.defaultQueryId;
        //const filteredQueries = props.savedQueries.filter(q=>q.id != selectedQueryId);
        return props.savedQueries.map(q=>({label: q.name, value: q.id.toString()}));

        
    }, [props.savedQueries, group.defaultQueryId]);

    const handleQueryChange = e=>{
        if (e){
            const groupLookup = props.savedQueries.find(x=>x.id == e);
            if (groupLookup){
                setGroup({...group, defaultQueryId: e, defaultQueryName: groupLookup.name});
            }
        }
        else{
            setGroup({...group, defaultQueryId: null, defaultQueryName: null});
        }
    };
    
    return (<ModalWindow
        disableOkButton={isLoading}
        title={group.id ? 'Edit User Group' : 'Add User Group'}
        open={true}
        onCancel={props.onCancel}
        onOk={()=>props.onConfirm(group)}
        isNotClosable={true}
        isNonMaskable={true}
    >
        {/* =={JSON.stringify(group)}==

        <br/>
        =={JSON.stringify(props.savedQueries)}==
         */}
        <ul>
            <li>
                <label>Group Name</label>
                <InputField disabled={isLoading} placeholder={"Group Name"} onChange={(e) => handleGroupNameChange(e)} value={group.name || ''}/>
            </li>

            {group.id ? 
            <li>
                <label>Default Search</label>

                <InputField 
                    disabled={isLoading}
                    inputType='selectElement' 
                    optionList={queryOptionList} 
                    value={group.defaultQueryId} 
                    onChange={handleQueryChange} 
                    allowClear={true}/>
            </li> 
            : null}
            
            <li>
                <TooltipContainer title='Optional notes, description for the group.'>
                    <label>Notes</label>
                </TooltipContainer>

                <InputField disabled={isLoading} inputType='textArea' placeholder={"Notes, group description, etc"} onChange={(e) => setGroup({...group, note: e.target.value})} value={group.note || ''}/>
                
            </li>
        </ul>

        {errors.length ? <div>
            <ul>
                {errors.map((err, ixErr)=>{
                    return <li key={`error-${ixErr}`}>{err.error}</li>
                })}
            </ul>
        </div> : null}

    </ModalWindow>);
    
};