import InputField from "Components/InputField";
import { useState } from "react";

export const SaveDashboardContent = props=>{
    const {name, setName, handleEnterConfirm} = props;
    
    return (<>



    <InputField
        placeholder='New dashboard name'
        title='Save the dashboard as'
        htmlForName='Save the dashboard as'
        autoFocus={true}
        value={name}
        onChange={e=>setName(e.target.value)}
        onPressEnter={()=>handleEnterConfirm()}
    />
    </>);
};