import { APIInstance } from "./apiInstance";

const BASE_URL_USERPROFILE = "/api/UserProfile";

export const saveUserProjectSettings = payload=>{
  const instance = new APIInstance({
    baseURL: BASE_URL_USERPROFILE,
  });

  const api = instance.api;
  return api.post("/save-user-project-settings",  payload );
};
 

export const saveUserLinecountSettingsApi = payload=>{
  const instance = new APIInstance({
    baseURL: BASE_URL_USERPROFILE,
  });

  const api = instance.api;
  return api.post("/save-user-project-line-count",  payload );
}