import React, { useState } from "react";
import { Button, Divider, Select } from "antd";
import "./style.scss";
export const SelectElement = (props) => {

  const [value, setValue] = useState('');

  const handleSelectAll = ()=>{
    debugger;

    const lastSearchedValue = value; 
    console.log('handleSelectAll', lastSearchedValue);

    props.handleSelectAll(lastSearchedValue);
  };

  const handleClearAll = ()=>{
    debugger;
    props.handleClearAll();
  };



  function onBlur() {}

  function onFocus() {}

  function onSearch(val) {}

  let customStyle = {};
  if (props.width) {
    customStyle = {
      width: props.width + 'px',
      ...props.style,
    };
  }


  
  let customRenderer;
  if (props.showSelectAllButtons){
    customRenderer = (originalOptions)=>{

      return (<>
           <div style={{display: 'flex'}}>
          
            {/* here are the controls to select all  */}
            <div>
                <Button type="link" size="small" onClick={handleSelectAll}>Select All</Button>
            </div>      
            <div>
              <Button type="link" size="small" onClick={handleClearAll}>Clear All</Button>
            </div>

            {/* <div>
              TODO: have a button 'search more' that would be enabled when at least 3 characters are entered
              This button will run a query with filters to search for particular taxonomy value (with possibly lower count)
              <Button type="link" size="small" onClick={()=>{}}>Search More</Button>
            </div> */}
          </div>

          <Divider style={{ margin: '0' }} />

      {originalOptions}
      </>);
      return <>


       {/* TODO: render a custom shit with scrollbar  */}
      here we go
      {JSON.stringify(props.optionList.map(({ value, label }) => ({
        value,
        label,
      }))
      )}
      
      </>
    }
  }

  return (
    <Select
      dropdownRender={customRenderer}
      autoClearSearchValue={props.autoClearSearchValue}
      showArrow={props.showArrow}
      allowClear={props.allowClear}
      showSearch={props.showSearch}
      tokenSeparators={props.tokenSeparators}
      style={props.style ? props.style : customStyle}
      mode={props.mode}
      placeholder={props.placeholder || 'Select option'}
      optionFilterProp='label' // not 'children'
      onChange={(x)=>{
        // debugger;
        // console.log('SelectElement', x);
        props.onChange(x); // This fires on selection of both conceptName and keyword
        // it goes into Facet.js  
      }}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      size={props.size}
      shape={props.shape}
      maxTagCount={props.maxTagCount || 10}
      dropdownMatchSelectWidth={props.dropdownMatchSelectWidth}
      onSearch={props.showSearch ? 
        (x)=>{
          setValue(x);
          if (props.onSearch){
            props.onSearch(x)
          }
        } : undefined
      }
      defaultValue={props.defaultValue}
      disabled={props.disabled}
      value={props.value}
      className={`${props.className || ''} ${!props.isValid ? ' error-field' : ''}`}
      //filterOption={filterOptions} - WTF 
      options={props.optionList.map(({ value, label }) => ({
        value,
        label,
      }))}
      suffixIcon={props.suffixIcon}
      loading={props.loading}
      onPopupScroll={props.onPopupScroll}
      onClick={props.onClick}
    />
  );
};

function filterOptions(input = '', option = {}) {
  if (option.props && option.props.label && option.props.value)
    return (
      option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
      //option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
    );
  else if (option.value)
    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  return false;
}
