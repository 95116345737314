import Pagination from "Components/Pagination";

export const EntityGridPagination = props=>{
    return (
        <Pagination
          pageSize={props.pagination.pageSize}
          current={props.pagination?.pageNumber}
          total={props.totalCount}
          onChange={props.handlePaginationChange}
          showSizeChanger={true}
          pageSizeOptions={[10, 20, 50, 100]}
        />
      );
}