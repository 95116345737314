import ErrorState from "Components/ErrorState";
import style from "./style.module.scss";
import { DOWNLOAD_PREVIEW_CONSTANTS } from "Constants/DownloadPreview/DownloadPreview.constants";
import { useEffect, useRef, useState } from "react";
import jsPDF from "jspdf";
import { DashboardPreview2 } from "./Children/DashboardPreview2";
import ButtonElement from "Components/ButtonElement";
import TooltipContainer from "Components/Tooltip";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { getInsightsChartDataById } from "api/insights";
import * as htmlToImage from "html-to-image";
import { ChartsInGridExample2 } from "Routes/Dashboard/ChartsInGridExample2";

export const DownloadPreview2 = props=>{
    debugger;
    let error = false;
    let importError = false;
    const createInsightserrorMessage = '';
    const importedInsightserrorMessage = '';

    const history = useHistory();
    const location = useLocation();

    const locationState = location.state;
    
    const isInsight = locationState.isInsight || false; // this.props.location.state.isInsight
    const isDashboard = locationState.isDashboard; //  true; // this.props.location.state.isDashboard

    const dashboardName = locationState.dashboardName;// TODO: pass it from history state , state.state.dashboardName

    const insightName = locationState.insightName; // ''; // state.state.insightName

    const includedInsights = locationState.includedInsights;

    const layout = JSON.parse(locationState.layout);

    debugger;

    const previewRef = useRef(null);

    const [pdfRunning, setPdfRunning] = useState(false);

    const [hasDashboard, setHasDashboard] = useState(false);

    const printDocument = ()=>{
        console.log('print document');
        debugger;
        // from downloadPdfHelper:
        setPdfRunning(true);
        const doc = new jsPDF("p", "px");

        // const input = document.getElementById('download-preview-div');
        try {
            // const elements = document.getElementById('download-preview-div');
            const elements = previewRef.current;
            creatPdf({ doc, elements }).then(()=>{
                doc.save(`${dashboardName || insightName || "chart"}.pdf`);
                setPdfRunning(false);
            }).catch(error=>{
                setPdfRunning(false);
            })
            
        } catch (error) {
            setPdfRunning(false);
        }
    };

    const creatPdf = async ({ doc, elements })=> {
        const padding = 20;
        const marginTop = 20;
        let top = marginTop;
        let startTime = new Date();
        const imgData = await htmlToImage.toPng(elements);
        let elHeight = elements.offsetHeight;
        let elWidth = elements.offsetWidth;
        
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();
        
        if (elWidth > pageWidth) {
            const ratio = pageWidth / elWidth;
            const heightRatio = pageHeight / elWidth;
            elHeight = elHeight * ratio - padding;
            elWidth = elWidth * ratio - padding * 2;
        }
        doc.addImage(imgData, "PNG", padding, top, elWidth, elHeight, `image${1}`);
        top += elHeight + marginTop;
    };

    const handleBackToDashboard = ()=>{
        history.push({pathname: "/dashboard"});
    };
    
    const [charts, setCharts] = useState([]); 

    const loadCharts = (insightIds)=>{
        debugger;
        if (hasDashboard){
            setHasDashboard(false);
        }
        
        Promise.all(insightIds.map(id=>{
            return getInsightsChartDataById(id);
            // Actually it's better to have another API method that returns chart data directly. 
            // No need to make double trip
        })).then(res=>{
            console.log(res);
            //setCharts(res.map(r=>r.data)); // each chart will have items 

            const x = res.map(r=>r.data);

            console.log('charts', x.map((x, id)=>({...x, id })));
            
            setCharts(
                x.map((x, id)=>({...x, id }))
            ); // each chart will have items 


            setHasDashboard(true);

            // now depending on what is the chartType, we need to call different endpoints
            // then the returned data series will have to be rendered according to their chart type 

            
        });
    };

    useEffect(()=>{
        if (includedInsights){
            loadCharts(includedInsights);
        }
    }, [includedInsights]);

    // return (<>
    // {hasDashboard ? 
    //     <ChartsInGridExample2 charts={charts} loadedLayouts={layout.allLayouts} isEditMode={false}/>
    //     : null}
    // </>);


    return (<div>
        {error || importError ? (
            <ErrorState
                content="ERROR"
                type={"errorImage"}
                errorMessage={createInsightserrorMessage || importedInsightserrorMessage}
                customHeight="500"
            />
        ) : (<>
        <div  className={style.downloadElementblock}>
            <div className={style.downloadElementblock__heading}>
                {isInsight && (
                    <ButtonElement
                        children={DOWNLOAD_PREVIEW_CONSTANTS.BACK_TO_DEFAULT}
                        type="secondary"
                        onClick={props.handleBackToDefault}
                    />
                )}
                
                {isDashboard && (
                    <ButtonElement
                        children={DOWNLOAD_PREVIEW_CONSTANTS.BACK_TO_DASHBOARD_CANVAS}
                        type="secondary"
                        onClick={()=>handleBackToDashboard()}
                    />
                )}
                <ButtonElement
                    type="primary"
                    onClick={() => printDocument()}
                    //icon={'SVG-download'}
                    loading={pdfRunning}
                >
                    <TooltipContainer
                        placement="left"
                        title="Download only if the insight has been loaded completely"
                    ><>{DOWNLOAD_PREVIEW_CONSTANTS.BUTTON_LABEL}</>
                    </TooltipContainer>
                </ButtonElement>
            </div>
            </div>
            <div className={style.downloadElementblock__body}>

                {hasDashboard ? 
                <div ref={previewRef}>
                    <h3>{dashboardName}</h3>
                    <ChartsInGridExample2 charts={charts} loadedLayouts={layout.allLayouts} isEditMode={false}/>
                </div>
                 : null}
                {/* <DashboardPreview2 name={dashboardName || insightName || 'Charts'} charts={charts} layout={layout} previewRef={previewRef}/> */}
            </div>
        </>)}
    </div>);
};