import { saveAs } from "file-saver";
import moment from 'moment';
import global from "../../styles/globel/style.module.scss";
import { useEffect, useMemo, useRef, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ROUTES_CONFIG } from "Components/SideBar/Sidebar.config";
import { ROUTES } from "Constants/Routes/Routes.constants";
import { MyPageWrapper } from "Components/PageWrapper/MyPageWrapper";
import MySideBar from "Components/SideBar/MySideBar";
import PageBody from "Components/PageBody";
import PageHeading from "Components/PageHeading";
import { getCurrentUserDetails } from "api/users";
import { notify } from "Components/Notification";
import ProfileOptions from "Components/ProfileOptions/ProfileOptions";
import PrivateRoute from "utils/PrivateRoute";
import Insights from "Routes/Insights";
import Home from "Routes/Home";
import Dashboard from "Routes/Dashboard";
import DownloadPreview from "Routes/DownloadPreview";
import Settings from "Routes/Settings";
import UserLogs from "Routes/UserLogs";
import { KEYS } from "dataKeys";
import { COMMUNICATION_KEYS } from "constants";
import { ProjectSwitchingModal } from "./ProjectSwitchingModal";
import { PasswordResetModal } from "./PasswordModal";
import Showlogoutmodal from "Components/SideBar/ShowLogoutModal";
import TimeOutModal from "./TimeOutModal";
import { SAML_LOGOUT_URL, SUPER_ADMIN_LOGOUT_URL } from "Constants/Login/Login.constants";
import { logoutHelper } from "Components/SideBar/Helper";
import { AdvanceSearchContainer } from "Routes/AdvanceSearch/AdvanceSearchContainer";
import MyHome2 from "Routes/Home/MyHome2";
import { AdvanceSearchContainer2 } from "Routes/AdvanceSearch/AdvanceSearchContainer2";
import { exportAsXlsx2, exportDocumentByIdToExcel, exportDocuments, loadFilterFields, runSearchApi, saveSearchSettings } from "api/search";
import { Insights2 } from "Routes/Insights/Insights2";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getHeatmapData, loadBarchartData, loadCloudData, loadHeatmapData, saveInsights } from "api/insights";
import { getProjectListApi } from "api/userProjects";
import { ProjectSelector } from "./ProjectSelector";
import { removeEMTag } from "utils/dataFormatter";
import { SettingsScreen } from "Routes/Settings/Children/SystemSettings/SettingsScreen";
import { savePreferenceSettings } from "api/settings";
import { MySideBar2 } from "Components/SideBar/MySideBar2";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { UserLogs2 } from "Routes/UserLogs/UserLogs2";
import { Users2 } from "Routes/Users/Users2";
import { UserGroups2 } from "Routes/UserGroups/UserGroups2";
import { getDefaultQuery, setDefaultQuery } from "api/userGroups";
import { Users3 } from "Routes/Users/Users3";
import { UserGroups3 } from "Routes/UserGroups/UserGroups3";
import { changeUserProjects } from "api/account";
import { saveUserLinecountSettingsApi, saveUserProjectSettings } from "api/userProfile";
import { Dashboard2 } from "Routes/Dashboard/Dashboard2";
import { DashboardTest } from "Routes/Dashboard/DashboardTest";
import { DownloadPreview2 } from "Routes/DownloadPreview/DownloadPreview2";
import { NewCharts } from "Components/Charts/NewCharts";
import { NewChartRadar } from "Components/Charts/NewChartRadar";
import { NewChartRelationship } from "Components/Charts/NewChartRelationship";
import { NewChartStream } from "Components/Charts/NewChartStream";
import { NewChartStackedBar } from "Components/Charts/NewChartStackedBar";
import { NewChartRelationship2 } from "Components/Charts/NewChartRelationship2";

export const MyMainFrame2 = props=>{

    const [showStateOfMain, setShowStateOfMain] = useState(false);
    const INITIAL_TIMEOUT = 1800000; // 30 mins
    const EXTENDED_TIMEOUT = 3600000; // 1 hour

    const ACTIVITY_EVENTS = ["click", "scroll", "load", "keydown"];


    const initialState = {
        showModal: false,
        timeOutCount: 0, 
        currentProject: null, 

        currentPass: "",
        newPass: "",
        confirmPass: "",
        superAdminPassChange: false,
        showAdvanceSearchDropDown: {
          visible: false,
          className: "searchToggle",
        },
        sideBarColapse: {
          visible: true,
          className: "sideBarColapse",
          iconClassName: "colapseIcon",
          colapsePageBodyClassName: "colapsePageBody",
        },
        filterVisible: {
          visible: false,
          className: "filterToggle",
        },
        projectSwitching: false, 
        showProjectSwitchingModal: false,
        
        timerTime: INITIAL_TIMEOUT, 
        showLogoutModal: false,
        loading: false,
        resetLoader: false,
        projectSwitched: false,
        projectSelectDisabled: false,
    };

    const [state, setState] = useState(initialState);

    const setMainframeState = data=>setState( prevState=>({...prevState, ...data })); 

    
    const handleSideBarColapse = (value) => {
        const newState = { 
            ...state.sideBarColapse,
            visible: value === "isFilter" ? true : !state.sideBarColapse.visible
        };

        setMainframeState(newState);
    };



    const handleIsLogout = () => {

        logoutHelper({ state: 
            {
                setState: setMainframeState,
                props: {
                    CurrentUserProfile: {
                        userProfile: props.CurrentUserProfile.userProfile
                    },

                }
            }
         });
    };


    const handleIslogout = event=>{

        event.preventDefault(); 
        clearTimeout(timer.current);
        removeEvents();
        localStorage.clear();
        sessionStorage.clear();

        const queryParams = new URLSearchParams(window.location.search);
        const queryterm = queryParams.get('page');
        let queryString = props.history.location.search;
        if (
            queryterm == 'newtab' ||
            queryString == '?insight' ||
            props.history.location.pathname == '/insights'
        ) {
            channel_old.postMessage({
            type: COMMUNICATION_KEYS.CHILD_TERMINATE,
            isLogout: true,
          });
        } 
        else {
            channel_old.postMessage({
                type: COMMUNICATION_KEYS.PARENT_TERMINATE,
                isLogout: true,
            });
        }

        if (props.CurrentProfile.userProfile === 3) {
            window.location.href = SUPER_ADMIN_LOGOUT_URL;
        } else {
            window.location.href = SAML_LOGOUT_URL;
        }
    };

    // global settings: 
    // TODO: load them from API call:
    const [dateFormat, setDateFormat] = useState('DD MONTH YYYY'); // from redux initial state
    const [sortOrder, setSortOrder] = useState('down'); // TODO: sync this with sortingOrder which is used for results
    const [hideResultsZeroCount, setHideResultsZeroCount] = useState(true);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    const [userProfileId, setUserProfileId] = useState(0); // int 1 / 2 / 3

    const [userEmail, setUserEmail] = useState('');

    const [isGroupAdmin, setIsGroupAdmin] = useState(false);

    const [viewType, setViewType] = useState('gridTwo');
    const viewTypeRef = useRef();
    useEffect(()=>{
        viewTypeRef.current = viewType;
    }, [viewType]);


    const [defaultQueryId, setDefaultQueryId] = useState(null);


    
    const [isGlobalLoading, setIsGlobalLoading] = useState(true);

    const [exportLoading, setExportLoading] = useState(false); // flag for excel export running



    const [appliedQuery, setAppliedQuery] = useState(null);

    
    const [viewLineCountSettings, setLineCountSettings] = useState({
        lineCount_2x2: 4, 
        lineCount_3x4: 4, 
        lineCount_ListView: 4
    });

    const updatePreferenceSettings = (newSettings, callback)=>{
        const payload = {
            emailId: '',
            dateFormat: newSettings.dateFormat, 
            sortOrder: newSettings.sortOrder,
            showAllFields: !newSettings.hideResultsZeroCount
        };

        savePreferenceSettings(payload).then(()=>{
            
            setDateFormat(newSettings.dateFormat);
            setSortOrder(newSettings.sortOrder);
            setHideResultsZeroCount(newSettings.hideResultsZeroCount);

            if (callback){
                callback(true);
            };
        }).catch(err=>{
            if (callback){
                callback(false);
            };
        });

    };

    const updateUserLinecountSettings = (newSettings, callback)=>{

        const payload = {...newSettings, projectId: currentProject.id};
        saveUserLinecountSettingsApi(payload).then(res=>{

            setLineCountSettings(payload);

            if (callback){
                callback(true);
            }

        }).catch(err=>{
            
            if (callback){
                callback(false);
            }
        });
    };

    const [markedDocumentIds, setMarkedDocumentIds] = useState([]); // documents selected with a checkbox

    const handleModal = ()=>{

        if (state.timeOutCount < 1){
            setMainframeState({
                timeOutCount: 1, 
                showModal: false, 
                timerTime: EXTENDED_TIMEOUT 
            });
            startTimer();
        }
        else {

            setMainframeState({
                timeOutCount: 2, 
                showModal: false 
            });
            clearTimeout(timer.current);
            removeEvents();
        }

        const queryParams = new URLSearchParams(window.location.search);
        const queryterm = queryParams.get("page");
        let queryString = props.history.location.search;
        if (
            queryterm == "newtab" ||
            queryString == "?insight" ||
            props.history.location.pathname == "/insights"
        ) {
            //Current Page Insight
            channel_old.postMessage({
                type: COMMUNICATION_KEYS.CHILD_CHOOSE_STAY,
            });
        } else 
        {
            //Current Page Articles
            this.channel.postMessage({
                type: COMMUNICATION_KEYS.PARENT_CHOOSE_STAY,
            });
        }
        
    };

    

    const handleStayActive = ()=>{
        if (state.timeOutCount < 1) {
            setMainframeState({
                timeOutCount: 1, 
                showModal: false, 
                timerTime: EXTENDED_TIMEOUT 
            }); 
            startTimer();
          } else {
            setMainframeState({
                timeOutCount: 1, 
                showModal: false 
            });
            clearTimeout(timer.current);
            this.removeEvents();
          }
    };

    // not used
    const handleTabClose = ()=>{
        if (!localStorage.getItem(KEYS.RememberMe)) {
            this.handleIslogout();
        }
    };

    const setPageHeading = () => {
        let title = "";
        ROUTES_CONFIG["SuperAdmin"].map(({ name, route }) => {
          if (
            props.location.pathname === route &&
            props.location.pathname != ROUTES.VIEW_IN_NEW_TAB &&
            props.location.pathname != ROUTES.VIEW_TAB_ARTICLES
          ) {
            title = name;
            return title;
          }
          if (
            props.location.pathname === route &&
            props.location.pathname != ROUTES.VIEW_IN_NEW_TAB
          ) {
            title = "Articles";
            return title;
          }
          if (props.location.pathname.toLowerCase() === ROUTES.INSIGHTS) {
            title = "Insights"
            return title;
          }
        });
        return title;
    };

    
    const handleFilterVisible = ()=>{

        const filterFields = props.FilterFields; 
        if (filterFields.loading !== true && filterFields.allFields?.length === 0) {
            props.loadFilterFields(); 
        }
        
        !state.filterVisible.visible && handleSideBarColapse("isFilter");
        setMainframeState({
            filterVisible: {
                ...state.filterVisible,
                visible: !state.filterVisible.visible,
            }
        });
        
    };

    const handleProjectChange = (projectId, needBroadcast) =>{
        debugger;
        setLoadingData(true); 
        setIsGlobalLoading(true);

        if (channelRef.current && needBroadcast){
            const urlParams = new URLSearchParams(window.location.search);
            const scope = urlParams.get('scope');

            channelRef.current.postMessage({
                type: 'ci-project-switch', 
                scope, 
                payload: {
                    projectId
                }
                , action: 'projectSwitch'
                , sender: window.location.pathname
 
            });
        }
        
        changeUserProjects({projectId}).then(res=>{
            debugger;
            console.log(res);

            setPageNumber(1);
            setCheckedTaxonomies([]);
            setLoadedTaxonomies({});
            setTaxonomiesForFilterPanel([]);
            setTaxonomyDateFilterObj({});
            setAdvancedSearchRows([]);
            setSimpleSearchText('');

            setActiveTabKey('All Articles');
            setCloudSearchWords([]);

            setExcludedWords([]);
            setInsightsChartData(defaultInsightsChartData);

            
            setShowFullContent(false);

            setViewType('gridTwo');

            setSortingType('publishdate');
            setSortOrder('down');

            // reset all state variables to their defaults, can be overridden by the getCurrentUserDetails
            setDateFormat('DD MONTH YYYY');
            setHideResultsZeroCount(true);

            
            getCurrentUserDetails({}).then(res=>{
                
                const {viewTypeFromSettings} = updateSettingsFromUserProfileDataDump(res.data.result);
            
                loadTaxonomiesForFilter(projectId).then(()=>{
                    loadDefaultQuery(projectId).then(queryApplied=>{

                        if (!queryApplied){
                            // else those things have been done by applying the query 
                            // doing it twice but that's ok...

                            setPageNumber(1);
                            setCheckedTaxonomies([]);
                            setLoadedTaxonomies({});
                            
                            setTaxonomyDateFilterObj({});
                            setAdvancedSearchRows([]);
                            setSimpleSearchText('');

                            setActiveTabKey('All Articles');
                            setCloudSearchWords([]);
                            
                            setShowFullContent(false);

                            setViewType('gridTwo');

                            setSortingType('publishdate');
                            setSortOrder('down');
            
                            if (window.location.pathname.indexOf('insights') > -1){
                                doRunInsightsSearch([], '', [], 'All Articles', projectId, [], insightsChartData.chartKey, insightsChartData.option1, insightsChartData.option2, insightsChartData.cloudTaxonomy, excludedWords);
                            }
                            else {
                                const xPageSize = calculatePageSize('gridTwo', listviewPageSize);
                            
                                doRunSearch(xPageSize, 1, [], '', [], [], 'All Articles', 'publishdate', 'down', projectId, false, insightsChartData, excludedWords);

                            }
                            
                            setLoadingData(false);
                        }

                    })

                });

                
            })
            
        });
    };

    const selectProjectHandler = projectId=>{
        debugger;
        console.log(state.currentProject, projectId);
        if (state.currentProject == projectId){
            return;
        }

        setMainframeState({ projectSwitching: true }); 

        
        changeUserProjects({emailId: email, projectId}).then(({data}) =>{
            debugger;
            if (state.filterVisible.visible){
        
                handleFilterVisible();
            }

            setMainframeState({ showProjectSwitchingModal: true }); 
            sessionStorage.setItem(KEYS.PROJECT_SWITCHED, true);
            notify.success("Project changed successfully", true);

            setTimeout(()=>{
                channel_old.postMessage({
                    type: COMMUNICATION_KEYS.PROJECT_CHANGE,
                    payload: {
                        data: {
                            projectSwitched: true,
                            message: "Project switched successfully",
                        },
                        metadata: { time: new Date(), source: "MainFrame" },
                    }
                });

                debugger;
                console.log(props);

                let search = props.history.location.search;
                props.history.replace({
                    ...props.history.location,
                    state: {},
                    search: search,
                });
                props.history.go(0);

            }, 2000);

            setMainframeState({
                currentProject: data.result.currentProject,
                projectSwitching: false 
            });

        }).catch(err=>{
            setMainframeState({ projectSwitching: false });
            notify.error("Project change failed", true);
        });
    };


    let timeOut = undefined; 
    let timer =  useRef(undefined);


    const startTimer = ()=>{

        timer.current = setTimeout(()=>{

            const lastInteractionTime = localStorage.getItem(KEYS.LAST_INTERACTION);
            const diff = moment.duration(moment().diff(moment(lastInteractionTime)));
            const timeOutInterval = state.timerTime;
            if (diff && diff._milliseconds) {
                if (diff._milliseconds < timeOutInterval) 
                {
                    startTimer();
                }
                else 
                {

                    setMainframeState({ showModal: true });
                }
            }
        }, 6000);
    };

    const addEvents = ()=>{

        localStorage.setItem(KEYS.LAST_INTERACTION, moment());
        ACTIVITY_EVENTS.forEach(eventName =>{
            window.addEventListener(eventName, eventHandler);
        });
    };

    const removeEvents = ()=>{
        localStorage.removeItem(KEYS.LAST_INTERACTION);
        ACTIVITY_EVENTS.forEach(eventName => {
            window.removeEventListener(eventName, eventHandler);
        });
    };

    const eventHandler = eventType=>{
        localStorage.setItem(KEYS.LAST_INTERACTION, moment());
        if (timer.current){
            clearTimeout(timer.current);
            startTimer();
        }
    };

    const handleModalHide = ()=>{
        debugger;
        setMainframeState({ showLogoutModal: false });
    };

    const handleModalVisible = ()=>{
        setMainframeState({ showLogoutModal: true });
    };
    

    const hasToShowSideBar = () => {
        const queryParams = new URLSearchParams(window.location.search);
        let queryString = props.history.location.search;
        const queryterm = queryParams.get("from");
        if (queryterm == "newtab" 
            || queryString == "?insight" 
            || props.history.location.pathname == "/insights" 
            || window.location.pathname == "/loading"
        ) {
            return false;
        } else {
            return true;
        }
    };

    const hasToShowHeader = () => {
        if (window.location.pathname == "/loading") {
            return false;
        } else {
            return true;
        }
    };

    const superAdminCredChange = ()=>{
        setMainframeState({
            superAdminPassChange: !state.superAdminPassChange,
            currentPass: '',
            newPass: '',
            confirmPass: '',
        });
    };

    const onResetPasswordChange  = (field, value)=>{
        setMainframeState({ [field]: value.trim() });
    };

    const resetSuperAdminPassword  = ()=>{
        // TODO
    };
    


    const propsForTimeOutModal = {
        logoutModal: state.showModal,
        handleModal: (e)=>{
            handleModal(e);
        }, 
        handleIslogout: e=>{
            debugger;
            handleIslogout(e); 
        } 
    };

    const propsForShowlogoutmodal = {
        handleModal: handleModalHide, 
        handleIsLogout: handleIsLogout, 
        showLogoutModal: state.showLogoutModal, 
        loading: state.loading
    };

    const [advancedSearchVisible, setAdvancedSearchVisible] = useState(false);
    const searchStyle = `${global.searchHolder} ${state.showAdvanceSearchDropDown.visible ? global[state.showAdvanceSearchDropDown.className] : ''}`;


    // uplifted state


    const emptySearchResult = {items: [], totalCount: null};
    const [searchResult, setSearchResult] = useState(emptySearchResult); 

    const [insightsSearchResult, setInsightsSearchResult] = useState({items: [], totalCount: 0});
    
    const handleSaveSearchSettingsClicked = (queryName, callback)=>{
        // this is where we save the current query
        const searchSettings =  {
            simpleSearchText, 
            checkedTaxonomies,
            taxonomyDateFilterObject: taxonomyDateFilterObj, 
            cloudSearchWords,
            advancedSearchRows, 
            sortingOrder, 
            sortingType, 
            viewType, 
            // pageNumber, 
            pageSize, 
            activeTabKey
        };

        const payload = {
            serializedSettings: JSON.stringify(searchSettings),
            queryName, 
            projectId: currentProject.id
        };

        saveSearchSettings(payload).then(res=>{
            debugger;
            console.log(res);

            if (callback){
                callback();
            }
        }).catch(err=>{
            debugger;
            console.log(err);
        });
    };


    const updateUserTags = (documentId, userTags)=>{
        const newSearchResultItems = searchResult.items.map(x=>{
            if (x.DocumentId == documentId){

                // some user_tags can have highlights in them, some magic logic to preserve them:
                const originalTags = {};
                x.user_tags.forEach(value=>{
                    originalTags[removeEMTag(value)] = value;
                });

                const newUserTags = userTags.map(t=>{
                    const tagValue = originalTags[t] || t;
                    return tagValue;
                });

                return {...x, user_tags: newUserTags};
            }
            else{
                return x;
            }
        });

        setSearchResult({...searchResult, items: newSearchResultItems});
            
    };

    const [taxonomiesForFilterPanel, setTaxonomiesForFilterPanel] = useState([]); 
    const [needDateFilter, setNeedDateFilter] = useState(false); 
    const [allProjectTaxonomies, setAllProjectTaxonomies] = useState([]);

    const taxonomiesAsMetadata = useMemo(()=>{
        const result = taxonomiesForFilterPanel.filter(d=>d.dataType != 1 && d.dataType != 7).map(x=>x.name); 
        return result;
    }, [taxonomiesForFilterPanel]);


    const taxonomiesAsMetadataForAdvancedSearch = useMemo(()=>{
        // lame approach, should be more specific on what to include in filters:
        const result = allProjectTaxonomies.filter(d=> d.isFilter)
        // another filter to have only 'Date published' with dataType 4(dates):
        .filter(d=>d.dataType != 4 || d.name.includes('Published'))
        .map(x=>x.name);

        result.sort((a, b)=>{
            if (a > b){
                return 1;
            }
            if (a < b){
                return -1;
            }
            return 0;
        });
        return result;
    }, [allProjectTaxonomies]);

    const [pageNumber, setPageNumber] = useState(1); // pagination is for the human eye, 1 to N

    

    const [previousAllFilterRows, setPreviousAllFilterRows] = useState([]); 

    const [taxonomyDateFilterObj, setTaxonomyDateFilterObj] = useState({});
    const [prevTaxonomyDateFilterObj, setPrevTaxonomyDateFilterObj] = useState({});

    // filter rows (allFilterRows) need to be computed from loadedTaxonomies (which have the checked mark) and dateFilterObj

    const [loadedTaxonomies, setLoadedTaxonomies] = useState({}); 
    const [checkedTaxonomies, setCheckedTaxonomies] = useState({}); 
    

    const [simpleSearchText, setSimpleSearchText] = useState(''); 

    const [advancedSearchRows, setAdvancedSearchRows] = useState([]); 

    const [excludedWords, setExcludedWords] = useState([]);

    const [tabFilterRows, setTabFilterRows] = useState([]); // filterRows from tabs. Not used at the moment. The logic is hard-coded.
    // The logic is when building the query for News we do must_not "Source Type" match_phrase "file system" and for External Reports it should be must with the same things.


    const [cloudSearchWords, setCloudSearchWords] = useState([]);

    const makeAllFilterRows = (checkedTaxonomies, taxonomyDateFilterObj)=>{
        const filterRows = [];
        const keys = Object.keys(checkedTaxonomies);
        for (let k = 0; k < keys.length; k++){
            const key = keys[k];
            const taxonomyRow = checkedTaxonomies[key];

            for (let v = 0; v < taxonomyRow.length; v++){
                const taxonomyDataRow = taxonomyRow[v];

                filterRows.push({
                    fieldName: key, 
                    fieldValue: taxonomyDataRow.value 
                });
            }
        }

        if (taxonomyDateFilterObj){
            if (taxonomyDateFilterObj.dateRangeValueTo || taxonomyDateFilterObj.dateRangeValueFrom){
                filterRows.push({
                    fieldName: 'Date Published', 
                    dateRangeValueTo: taxonomyDateFilterObj.dateRangeValueTo, 
                    dateRangeValueFrom: taxonomyDateFilterObj.dateRangeValueFrom, 
                    dateFormat: taxonomyDateFilterObj.dateFormat
    
                });    
            }
        }

        return filterRows;
    };

    const allFilterRows = useMemo(()=>{
        const result = makeAllFilterRows(checkedTaxonomies, taxonomyDateFilterObj);
        return result;
    }, [checkedTaxonomies, taxonomyDateFilterObj]);

    const compareRows = (row, rowList, deltaDateRows, deltaKey)=>{
        const foundRow = rowList.find(x=>x.dateObj.dateFilterMode == row.dateObj.dateFilterMode);
        if (!foundRow){
            deltaDateRows.push({...row, [deltaKey]: true});
            return;
        }

        const {dateRangeValueTo, dateRangeValueFrom, dateFilterMode, numberOfDays} = foundRow.dateObj;

        if (dateFilterMode == row.dateObj.dateFilterMode){

            if (dateFilterMode == 2){
                if (numberOfDays != row.dateObj.numberOfDays){
                    deltaDateRows.push({...row, updated: [{fieldName: 'numberOfDays', oldValue: numberOfDays}]});
                }
                return;
            }

            if (dateFilterMode == 1){
                if (dateRangeValueFrom != row.dateObj.dateRangeValueFrom){
            
                    deltaDateRows.push({...row, updated: [{fieldName: 'dateRangeValueFrom', oldValue: dateRangeValueFrom}]});
                
                    return;
                }
            }

            if (dateFilterMode == 0){

                if (dateRangeValueTo != row.dateObj.dateRangeValueTo){
                    if (dateRangeValueFrom != row.dateObj.dateRangeValueFrom){
                        deltaDateRows.push({...row, updated: [{fieldName: 'dateRangeValueFrom', oldValue: dateRangeValueFrom}, {fieldName: 'dateRangeValueTo', oldValue: dateRangeValueTo}]});
                    }
                    else {
                        deltaDateRows.push({...row, updated: [{fieldName: 'dateRangeValueTo', oldValue: dateRangeValueTo}]});
                    }
                    return;
                }

                
                if (dateRangeValueFrom != row.dateObj.dateRangeValueFrom){
                
                    deltaDateRows.push({...row, updated: [{fieldName: 'dateRangeValueFrom', oldValue: dateRangeValueFrom}]});
                
                    return;
                }
            }
        }
        else {
            deltaDateRows.push({...row, updated: [{fieldName: 'dateFilterMode', oldValue: dateFilterMode}]});
            return;
        }
    };


    const [activeTabKey, setActiveTabKey] = useState('All Articles');


    const handleTabChange = newTabKey=>{
        // can only happen on Articles tab
        setActiveTabKey(newTabKey);
        doRunSearch(pageSize, pageNumber, allFilterRows, simpleSearchText, advancedSearchRows, 
            cloudSearchWords, newTabKey, sortingType, sortingOrder, currentProject.id, showFullContent, insightsChartData, excludedWords);

        if (channelRef.current){
            const urlParams = new URLSearchParams(window.location.search);
            const scope = urlParams.get('scope');

            channelRef.current.postMessage({
                type: 'ci-updates', 
                scope, 
                payload: {
                    loadedTaxonomies, 
                    checkedTaxonomies, 
                    taxonomyDateFilterObj, 
                    simpleSearchText,
                    advancedSearchRows,
                    cloudSearchWords: cloudSearchWords, 
                    activeTabKey: newTabKey,
                    currentProject
                }
                , action: 'activeTabkeyChange'
                , sender: window.location.pathname
    
            });
        }
    };


    const [sortingType, setSortingType] = useState('publishdate');
    const [sortingOrder, setSortingOrder] = useState('down'); // most recent first

    const sortingTypeRef = useRef();
    useEffect(()=>{
        sortingTypeRef.current = sortingType;
    }, [sortingType]);

    const sortingOrderRef = useRef();
    useEffect(()=>{
        sortingOrderRef.current = sortingOrder;
    }, [sortingOrder]);
    


    const excludedWordsRef = useRef();
    useEffect(()=>{
        excludedWordsRef.current = excludedWords;
    }, [excludedWords]);


    const [listviewPageSize, setListViewPageSize] = useState(50); // page size only for list view viewType
    const listviewPageSizeRef = useRef();
    useEffect(()=>{
        listviewPageSizeRef.current = listviewPageSize;
    }, [listviewPageSize]);

    const calculatePageSize = (viewType, listviewPageSize)=>{
        let pageSize = 1;
        if (viewType == 'gridTwo'){
            pageSize = 4;
        }

        if (viewType == 'gridThree'){
            pageSize = 12;
        }

        if (viewType == 'gridOne'){
            pageSize = listviewPageSize;
        }
        return pageSize;
    }

    const pageSize = useMemo(()=>{
        const result = calculatePageSize(viewType, listviewPageSize);
        return result;
    
    }, [viewType, listviewPageSize]);

    const defaultInsightsChartData = {
        chartKey: 'KEY_PHRASE_CLOUD',  // word cloud is the default chart type, it does not require any option1 or option2 data
        option1: '', 
        option2: '', 
        cloudTaxonomy: '3. Top N KeyPhrases'
    };

    // this state can only be updated by the Insights tab
    const [insightsChartData, setInsightsChartData] = useState(defaultInsightsChartData);

    const insightsChartDataRef = useRef(null);
    useEffect(()=>{
        insightsChartDataRef.current = insightsChartData;
    }, [insightsChartData]);

    
    const handleInsightsChartKeyChanged = newChartOptions =>{
        debugger;
        // need to re-run the search...
        const {selectedChartKey, option1, option2, cloudTaxonomy} = newChartOptions;
        setInsightsChartData({
            chartKey: selectedChartKey, cloudTaxonomy, 
            option1, option2 // these are just some text fields
        });
        
        doRunInsightsSearch(allFilterRows, simpleSearchText, advancedSearchRows, activeTabKey, 
            currentProject.id, cloudSearchWords, 
            selectedChartKey, option1, option2, cloudTaxonomy, excludedWords);
    };

    const updateExcludedWords = (newExcludedWords)=>{
        setExcludedWords(newExcludedWords);
        console.log('updating excluded words', newExcludedWords);
        doRunInsightsSearch(allFilterRows, simpleSearchText, advancedSearchRows, activeTabKey, 
            currentProject.id, cloudSearchWords, 
            insightsChartData.chartKey, insightsChartData.option1, insightsChartData.option2, insightsChartData.cloudTaxonomy, newExcludedWords);

        if (channelRef.current){
            const urlParams = new URLSearchParams(window.location.search);
            const scope = urlParams.get('scope');

            debugger;

            channelRef.current.postMessage({
                type: 'ci-updates', 
                scope, 
                payload: {
                    loadedTaxonomies, 
                    checkedTaxonomies, 
                    taxonomyDateFilterObj, 
                    simpleSearchText,
                    advancedSearchRows,
                    cloudSearchWords, 
                    activeTabKey,
                    currentProject, 
                    insightsChartData, 
                    excludedWords: newExcludedWords
                }
                , action: 'updateExcludedWords'
                , sender: window.location.pathname
                , pageNumber 
    
            });
        }
    };
     

    const handleViewTypeChange = (newViewType)=>{
        if (newViewType == viewType){
            return;
        }
        setViewType(newViewType); 
        // when we change view type, we need to re-run the search 

        // gridOne - list view
        // gridTwo - 2x2
        // gridThree - 3x4


        const xPageSize = calculatePageSize(newViewType, listviewPageSize);
        doRunSearch(xPageSize, pageNumber, allFilterRows, simpleSearchText, advancedSearchRows, 
            cloudSearchWords, activeTabKey, sortingType, sortingOrder, currentProject.id, showFullContent, insightsChartData, excludedWords);

        saveUserProjectSettings({ viewType: newViewType, projectId: currentProject.id});

    };

    const handlePageNumberChange = (newPageNumber, newPageSize) =>{
        
        if (newPageNumber != pageNumber){
            setPageNumber(newPageNumber);
        }
        
        if (viewType =='gridOne'){
            setListViewPageSize(newPageSize);
        }
        

        const xPageSize = calculatePageSize(viewType, viewType=='gridOne' ? newPageSize : pageSize);
        doRunSearch(xPageSize, newPageNumber, allFilterRows, simpleSearchText, advancedSearchRows, 
            cloudSearchWords, activeTabKey, sortingType, sortingOrder, currentProject.id, showFullContent, insightsChartData, excludedWords);
    };


    const handleSortingTypeChange = newSortingType=>{
        setSortingType(newSortingType);
        doRunSearch(pageSize, pageNumber, allFilterRows, simpleSearchText, advancedSearchRows, 
            cloudSearchWords, activeTabKey, newSortingType, sortingOrder, currentProject.id, showFullContent, insightsChartData, excludedWords);
    };

    const handleSortingOrderChange = newSortingOrder=>{
        setSortingOrder(newSortingOrder);
        doRunSearch(pageSize, pageNumber, allFilterRows, simpleSearchText, advancedSearchRows, 
            cloudSearchWords, activeTabKey, sortingType, newSortingOrder, currentProject.id, showFullContent, insightsChartData, excludedWords);
    };

    const runInsightsSearch = ()=>{
        debugger;
        // takes all the values from the state:
        doRunInsightsSearch(allFilterRows, simpleSearchText, advancedSearchRows, activeTabKey, 
            currentProject.id, cloudSearchWords, 
            insightsChartData.chartKey, insightsChartData.option1, insightsChartData.option2, insightsChartData.cloudTaxonomy, excludedWords);
    };


    const handleCloudWordsChanged = newCloudSearchWords=>{
        // should be named handleCloudWordsChanged downstream
        // debugger;
        // console.log('changing cloud words, excluded words are lost? ', excludedWords);
        // console.log('changing cloud words, excluded words are not lost in ref? ', excludedWordsRef);
        
        setCloudSearchWords(newCloudSearchWords);
        doRunInsightsSearch(allFilterRows, 
            simpleSearchText, 
            advancedSearchRows, 
            activeTabKey, 
            currentProject.id, 
            newCloudSearchWords, insightsChartData.chartKey, insightsChartData.option1, insightsChartData.option2, insightsChartData.cloudTaxonomy, 
            // excludedWords
            excludedWordsRef.current
        );

        if (channelRef.current){
            const urlParams = new URLSearchParams(window.location.search);
            const scope = urlParams.get('scope');

            debugger;

            channelRef.current.postMessage({
                type: 'ci-updates', 
                scope, 
                payload: {
                    loadedTaxonomies, 
                    checkedTaxonomies, 
                    taxonomyDateFilterObj, 
                    simpleSearchText,
                    advancedSearchRows,
                    cloudSearchWords: newCloudSearchWords, 
                    activeTabKey,
                    currentProject, 
                    insightsChartData, 
                    excludedWords: excludedWordsRef.current
                }
                , action: 'runInsightsSearchWithCloudWords'
                , sender: window.location.pathname
                , pageNumber 
 
            });
        }
        
    };  

    const doRunInsightsSearch = (allFilterRows, simpleSearchText, advancedSearchRows, activeTabKey, projectId, cloudSearchWords, 
        chartKey, option1, option2, cloudTaxonomy, excludedWords)=>{

        // need to look at chartKey and decide which endpoint to call:

        if (chartKey == 'KEY_PHRASE_CLOUD'){
            const payload = {
                taxonomyFilterRows: allFilterRows,
                simpleSearchText, 
                advancedSearchRows,
                activeTabKey: activeTabKey,
                projectId, 
                cloudTaxonomy,
                cloudSearchWords: cloudSearchWords.map(x=>x.key),
                excludedWords
            };
          
            loadCloudData(payload).then(res=>{
                setInsightsSearchResult(res.data.result);
            });
            // for the word cloud, we need to format this data using chartDataFormatter
        }

        if (chartKey == 'HEAT_MAP'){    
         
            const payload = {
                buckets: [
                    {field: option1},
                    {field: option2},
                ]
            };
            getHeatmapData(payload).then(res=>{
                debugger;
                console.log('HEATMAP DATA IS', res);
                setInsightsSearchResult({
                    items: res.data.result.results,
                    totalCount: res.data.result.uniqueDocuments
                });
                // and set it 
            })
            // loadHeatmapData({
            //     ...payload, option1, option2
            // }).then(res=>{
            //     setInsightsSearchResult(res.data.result);
            // });   
        }      
          
        if (chartKey == 'BAR_CHART'){
            const payload = {
                taxonomyFilterRows: allFilterRows,
                simpleSearchText, 
                advancedSearchRows,
                activeTabKey: activeTabKey,
                projectId, 
                cloudTaxonomy,
                cloudSearchWords: cloudSearchWords.map(x=>x.key),
                excludedWords
            };
          
            loadBarchartData({
                ...payload, option1
            }).then(res=>{
                setInsightsSearchResult(res.data.result);
            });        
        }
    };


    const saveInsightSettings = (name, callback)=>{
        debugger;
        const payload = {
            insightsName: name, 
            chartType: insightsChartData.chartKey, 
            projectId: currentProject.id,
            // insightsContent is dynamic in the API. We pass single object with key v2 to mark that this is the new format of the saved insight data
            insightsContent: {
                
                v2: {
                    chartName: insightsChartData.chartKey, 
                    projectId: currentProject.id,
                    allFilterRows, // these are taxonomy filters + date filter
                    simpleSearchText, 
                    advancedSearchRows, 
                    cloudSearchWords, 
                    // TODO: add exclusions
                    activeTabKey,
                }

                // config: {}, 
                // payload: {}, 
                // requestPayload: {} // these  are the filters
            }
        };

        saveInsights(payload).then(res=>{
            debugger;
            console.log(res);
        }).catch(err=>{
            debugger;
            console.log(err);
        }).finally(()=>{
            if(callback){
                callback();
            }
        })



    }

    const runSearch = ()=>{
        doRunSearch(pageSize, pageNumber, allFilterRows, simpleSearchText, advancedSearchRows, 
            cloudSearchWords, activeTabKey, sortingType, sortingOrder, currentProject.id, showFullContent, insightsChartData, excludedWords);
    };


    const runInsightsSimpleSearch = searchText=>{
        
        console.log('run simple search from insights: ' + searchText  );
        setSimpleSearchText(searchText);
        

        doRunInsightsSearch(allFilterRows, searchText, advancedSearchRows, activeTabKey, currentProject.id, cloudSearchWords, insightsChartData.chartKey, insightsChartData.option1, insightsChartData.option2);
        
        
        
        if (channelRef.current){
            const urlParams = new URLSearchParams(window.location.search);
            const scope = urlParams.get('scope');

            channelRef.current.postMessage({
                type: 'ci-updates', 
                scope, 
                payload: {
                    loadedTaxonomies, 
                    checkedTaxonomies, 
                    taxonomyDateFilterObj, 
                    simpleSearchText: searchText,
                    advancedSearchRows,
                    cloudSearchWords, 
                    activeTabKey,
                    currentProject
                }
                , action: 'runSimpleSearch'
                , sender: window.location.pathname
                , pageNumber 
 
            });
        }
        
    };

    const runSimpleSearch = (searchText)=>{
        
        setSimpleSearchText(searchText);

        const newPageNumber = 1;

        if (window.location.pathname.indexOf('insights') > -1){
            // then we are on insights tab and must run the search with the chartKey
            doRunInsightsSearch(allFilterRows, searchText, advancedSearchRows, activeTabKey, currentProject.id, cloudSearchWords, insightsChartData.chartKey, insightsChartData.option1, insightsChartData.option2);
        }
        else{
            // run the without chartkey but with a page number

            setPageNumber(newPageNumber);

            if (searchText){
                // the existing behavior is to set the sortingType to 'relevance' and order 'down' (most relevant up)
                setSortingType('relevance');
                setSortingOrder('down');
                
                doRunSearch(pageSize, newPageNumber, allFilterRows, searchText, advancedSearchRows, 
                    cloudSearchWords, activeTabKey, 'relevance', 'down', currentProject.id, showFullContent, insightsChartData, excludedWords);
            }
            else{
                // this is also existing behaviour - when search text is cleared, sorting is reset to published date, lastest first
                setSortingType('publishdate');
                setSortingOrder('up');

                doRunSearch(pageSize, newPageNumber, allFilterRows, searchText, advancedSearchRows, 
                    cloudSearchWords, activeTabKey, 'publishdate', 'up', currentProject.id, showFullContent, insightsChartData, excludedWords);
            }

        }

       
        
        if (channelRef.current){
            const urlParams = new URLSearchParams(window.location.search);
            const scope = urlParams.get('scope');

            console.log('posting message 1')
            channelRef.current.postMessage({
                type: 'ci-updates', 
                scope, 
                payload: {
                    loadedTaxonomies, 
                    checkedTaxonomies, 
                    taxonomyDateFilterObj, 
                    simpleSearchText: searchText,
                    advancedSearchRows,
                    cloudSearchWords, 
                    activeTabKey,
                    currentProject
                }
                , action: 'runSimpleSearch'
                , sender: window.location.pathname
                , newPageNumber // to possibly sync with other article tabs
 
            });
        }
        
    };

    

    
    const loadTaxonomiesForFilter = (projectId)=>{
        return loadFilterFields(projectId || currentProject.id).then(res=>{
            
            const allTaxonomies = res.data.result; 
            setAllProjectTaxonomies(allTaxonomies);

            const hasDatePublishedTaxonomy = res.data.result.some(x=>x.name=='Date Published' && x.dataType == 4 && x.isFilter); // TODO: that name is project specific, for file documents it's different
            setNeedDateFilter(hasDatePublishedTaxonomy);

            const taxonomiesForFilter = allTaxonomies.filter(t=>t.isFilter && t.dataType != 4); // 4 is for dates
            // now need to order the taxonomies, alphabetically

            taxonomiesForFilter.sort((a, b)=>{
                if (a.name > b.name){
                    return 1;
                }
                if (a.name < b.name){
                    return -1;
                }
                return 0;
            });

            setTaxonomiesForFilterPanel(taxonomiesForFilter);

        }).catch(err=>{
            console.log(err);
            debugger;
        });
    };
  
    const updateLoadedAndCheckedTaxonomies = (loadedObj, checkedObj, dateFilterObj, fromChips)=>{

        setLoadedTaxonomies(loadedObj);
        setCheckedTaxonomies(checkedObj); 
        setTaxonomyDateFilterObj(dateFilterObj);

        const needResetPageNumber = JSON.stringify(checkedTaxonomies) != JSON.stringify(checkedObj);
        const newPageNumber = 1;
        if (needResetPageNumber){
            setPageNumber(newPageNumber);
        }

        // we only do the stuff below when message is received. Or if ths method is called from chips:
        if (fromChips){
            setExternalDataForFilterPanel({
                loaded: loadedObj, 
                checked: checkedObj,
                //filterRows: payload.allFilterRows
                dateFilterObj: dateFilterObj,
                fromChips
            });
        }

        if (channelRef.current){
            const urlParams = new URLSearchParams(window.location.search);
            const scope = urlParams.get('scope');

            channelRef.current.postMessage({
                type: 'ci-updates', 
                scope, 
                payload: {
                    loadedTaxonomies: loadedObj,  // loaded taxonomies make a big payload, better load them from elastic (or do nothing)
                    checkedTaxonomies: checkedObj, 
                    taxonomyDateFilterObj: dateFilterObj, 
                    simpleSearchText,
                    advancedSearchRows,
                    cloudSearchWords, 
                    activeTabKey,
                    currentProject
                }
                , action: 'updateLoadedAndCheckedTaxonomies'
                , sender: window.location.pathname
                , pageNumber 
 
            });
        }
        
    };
    


    const updateSettingsFromUserProfileDataDump = (userProfileDataDump)=>{
        const currentProj = {
            id: userProfileDataDump.projectinfo.id,
            name: userProfileDataDump.projectinfo.name
        };

        setIsGroupAdmin(userProfileDataDump.isGroupAdmin);
        setCurrentProject(currentProj);

        // set other settings: 
        setDateFormat(userProfileDataDump.dateFormat);

        const userData = userProfileDataDump.projectinfo.userCurrentProjects[0].user;

        setSortOrder(userData.sortOrder); // it's a number 
        // 0 - alphabetically ascending
        // 1 - alphabetically descending
        // 2 - based on results count ascending
        // 3 - based on results count descending 




        setHideResultsZeroCount(!userData.showAllFields);

        setFirstName(userData.firstname);
        setLastName(userData.lastname);

        setUserProfileId(userData.userProfile);
        setUserEmail(userData.emailid);

        const userProjectSettings = userData.userProjectSettings.find(x=>x.projectId == currentProj.id);

        const viewTypeFromSettings = userProjectSettings?.data?.listViewType || 'gridTwo';
        setViewType(viewTypeFromSettings);

        setLineCountSettings(userProjectSettings?.data?.viewLineCountSettings || {});
    

        return {currentProjectId: currentProj.id, viewTypeFromSettings};
    };
        
  
    const reload = (needsChannelSetup)=>{
        // runs on first page load and on switching a user
        
        setIsGlobalLoading(true);
        Promise.all([getCurrentUserDetails({}), getProjectListApi()]).then(res=>{

            const userProfileDataDump = res[0].data.result;
            const projectList = res[1].data.result;

            const {currentProjectId, viewTypeFromSettings} = updateSettingsFromUserProfileDataDump(userProfileDataDump);

            setAllProjects(projectList.map(x=>({id: x.id, name: x.name, locale: x.locale})));

            // allProjects go into the state for the dropdown. The dropdown is displayed in MainFrame

            loadTaxonomiesForFilter(currentProjectId).then(()=>{
                
                loadDefaultQuery(currentProjectId).then(res=>{
                    // debugger;
                    // console.log(res); 
                    if (res){
                        console.log('Applied the default query', res);
                    }
                    else{
                        console.log('No default query applied, need to run the search with empty filters');
                        if (window.location.pathname.includes('insights')){
                            if (!window.location.href.includes('scope=')){
                                doRunInsightsSearch([], '', [], 'All Articles', currentProjectId, [], 'KEY_PHRASE_CLOUD', '', '');
                            }
                        }
                        else{
                            const xPageSize = calculatePageSize(viewTypeFromSettings, listviewPageSize);  
                            // TODO: need to check what's in the states, and possibly reset them  
                            doRunSearch(xPageSize, 1, [], '', [], [], 'All Articles', 'publishdate', 'down', currentProjectId, false, insightsChartData, excludedWords);
                        }
                        
                    }
                }).then(res=>{
                    setLoadingData(false);
                    setIsGlobalLoading(false);        
                })
            })
        }).catch(err=>{
            debugger;
            console.log(err);
        }).finally(()=>{
            setLoadingData(false);
            setIsGlobalLoading(false);

            // if (needsChannelSetup){
            //     setupChannel();
            // }
            
        });

    };



    const applyQuery = (payload, projectId)=>{
        const {simpleSearchText, checkedTaxonomies, 
            taxonomyDateFilterObject, advancedSearchRows, sortingOrder, sortingType, 
            cloudSearchWords, activeTabKey, viewType} = payload;

        // now try to apply these and see what happens

        setSimpleSearchText(simpleSearchText);
        setCheckedTaxonomies(checkedTaxonomies);
        setTaxonomyDateFilterObj(taxonomyDateFilterObject);
        setAdvancedSearchRows(advancedSearchRows);
        setSortingOrder(sortingOrder);
        setSortingType(sortingType);

        setCloudSearchWords(cloudSearchWords);
        setActiveTabKey(activeTabKey);
        setViewType(viewType);
        
        setPageNumber(1);

        const pageSize = calculatePageSize(viewType, listviewPageSize);

        const allFilterRows = makeAllFilterRows(checkedTaxonomies, taxonomyDateFilterObject);

        // TODO: check how insightsChartData and excludedWords are saved and taken from the default query
        debugger;
        doRunSearch(pageSize, 1, allFilterRows, simpleSearchText, advancedSearchRows, 
            cloudSearchWords, activeTabKey, sortingType, sortingOrder, projectId, false, insightsChartData, excludedWords);
    };


    const loadDefaultQuery = projectId=>{
        return getDefaultQuery({projectId}).then(res=>{

            console.log('getDefaultQueryResponse', res);
            if (res?.data.id){
                setDefaultQueryId(res.data.id);
                let payload = null;
                try{
                    payload = JSON.parse(res.data.searchHistory); // or savedResult ?                     
                }
                catch (ex){
                    console.log('error parsing the default query payload', ex);
                }

                if (payload){
                    setAppliedQuery({id: res.data.id, name: res.data.searchKeyword, payload})
                    applyQuery(payload, currentProjectRef.current.id);
                    return {queryApplied: payload};
                }
                else {
                    return null;
                }
            }
        });
    };


    const clearAllTaxonomyFilters = ()=>{

        updateLoadedAndCheckedTaxonomies([], [], {});

        if (window.location.pathname.toLowerCase().indexOf('insights') > -1){
            doRunInsightsSearch([], simpleSearchText, advancedSearchRows, activeTabKey, currentProject.id, cloudSearchWords, insightsChartData.chartKey, insightsChartData.option1, insightsChartData.option2);
        }
        else {
            doRunSearch(pageSize, 1, [], simpleSearchText, advancedSearchRows, 
                cloudSearchWords, activeTabKey, sortingType, sortingOrder, currentProject.id, showFullContent, insightsChartData, excludedWords);
        }
    };

    const doRunSearch = (pageSize, pageNumber, allFilterRows, simpleSearchText, advancedSearchRows, 
        cloudSearchWords, activeTabKey, sortingType, sortingOrder, projectId, showFullContent, insightsChartData, excludedWords)=>{
        const payload = {
            pageSize,
            pageNumber,
            taxonomyFilterRows: allFilterRows,
            simpleSearchText,
            advancedSearchRows, 
            activeTabKey, 
            sortingType, sortingOrder,
            projectId, 
            showFullContent, 
            cloudTaxonomy: insightsChartData.cloudTaxonomy, 
            cloudSearchWords: cloudSearchWords.map(x=>x.key), 
            excludedWords: excludedWords
        };

        runSearchApi(payload).then(res=>{

            setPreviousAllFilterRows([...allFilterRows]);
            if (res.status==204){
                setSearchResult(emptySearchResult);
            }
            else{
                const newSearchResult = {items: res.data.result.result, totalCount: res.data.result.resultHits};
                setSearchResult(newSearchResult);
            }

        }).catch(err=>{
            debugger;
            console.log(err);
        });

    };

    const clearAdvancedSearch = ()=>{
        onApplyAdvanceSearch([]);
    };

    const onApplyAdvanceSearch = searchRows =>{
        debugger;

        setAdvancedSearchVisible(false);
        setAdvancedSearchRows(searchRows);


        if (window.location.pathname?.toLowerCase().includes('insights')){

            doRunInsightsSearch(allFilterRows, simpleSearchText, searchRows, activeTabKey, currentProject.id, cloudSearchWords, insightsChartData.chartKey, insightsChartData.option1, insightsChartData.option2);


            if (channelRef.current){
                const urlParams = new URLSearchParams(window.location.search);
                const scope = urlParams.get('scope');
    
                channelRef.current.postMessage({
                    type: 'ci-updates', 
                    scope, 
                    payload: {
                        loadedTaxonomies, 
                        checkedTaxonomies, 
                        taxonomyDateFilterObj, 
                        simpleSearchText,
                        advancedSearchRows: searchRows,
                        cloudSearchWords, 
                        activeTabKey,
                        currentProject
                    }
                    , action: 'runAdvancedSearch'
                    , sender: window.location.pathname
     
                });
            }



        }
        else{
            const newPageNumber = 1;
            setPageNumber(1);
            doRunSearch(pageSize, newPageNumber, allFilterRows, simpleSearchText, searchRows, 
                cloudSearchWords, activeTabKey, sortingType, sortingOrder, currentProject.id, showFullContent, insightsChartData, excludedWords);

            if (channelRef.current){
                const urlParams = new URLSearchParams(window.location.search);
                const scope = urlParams.get('scope');
    
                channelRef.current.postMessage({
                    type: 'ci-updates', 
                    scope, 
                    payload: {
                        loadedTaxonomies, 
                        checkedTaxonomies, 
                        taxonomyDateFilterObj, 
                        simpleSearchText,
                        advancedSearchRow: searchRows,
                        cloudSearchWords, 
                        activeTabKey,
                        currentProject
                    }
                    , action: 'runAdvancedSearch'
                    , sender: window.location.pathname
                    , pageNumber 
     
                });
            }
        }
        
    };

    const exportToXlsTemp = ()=>{
        // 1) need to return all the documents from the search
        // 2) export each document as individual excel file
        // 3) see which file is failing


        const p = {
            documentId: 242881
        };

        exportDocumentByIdToExcel(p).then(res=>{
            debugger;
            
            // res.arrayBuffer().then(binaryData=>{
            //     debugger;
            //     const blob = new Blob([binaryData], { type: "application/vnd.ms-excel" });
            //     saveAs(blob, "DataSheet.xlsx");
            // });
        


            const blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
            saveAs(blob, `DataSheet-${0}.xlsx`);
        });

        return;


        const payload = {
            // pageSize,
            // pageNumber,
            taxonomyFilterRows: allFilterRows,
            simpleSearchText,
            advancedSearchRows, 
            activeTabKey, 
            sortingType, sortingOrder,
            projectId: currentProject.id, 
            showFullContent: false
        };

        exportDocuments(payload).then(response=>{
            debugger;
            console.log(response);

            const idList = response.data.documents.map(d=>d.DocumentId);

                const p = {
                    documentId: idList[0]
                };

                exportDocumentByIdToExcel(p).then(res=>{
                    const blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
                    saveAs(blob, `DataSheet-${0}.xlsx`);
                });

            // idList.forEach((id, ix) => {
            //     const p = {
            //         documentId: id
            //     };

            //     exportDocumentByIdToExcel(p).then(res=>{
            //         const blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
            //         saveAs(blob, `DataSheet-${id}.xlsx`);
            //     });


                
            // });
            
            // setLoaderOff(); 
        }).finally(()=>{
            
        });
    };

    const exportToXls = ()=>{
        // this was calling into AdvanceSearchXLS endpoint
        // see handlers.js, handleExportHelper - it removes pageSize and pageNumber and the method is supposed to return everything.
        // We also pass showFullContent as false. Or do we not? 
        const payload = {
            taxonomyFilterRows: allFilterRows,
            simpleSearchText,
            advancedSearchRows, 
            activeTabKey, 
            sortingType, sortingOrder,
            projectId: currentProject.id, 
            showFullContent: false
        };
        
        setExportLoading(true);
        exportAsXlsx2(payload).then(response=>{
            debugger;
            
            const blob = new Blob([response.data], { type: "application/vnd.ms-excel" });
            saveAs(blob, "DataSheet.xlsx");
            // setLoaderOff(); 
        }).finally(()=>{
            setExportLoading(false);
        });
    };

    const [loadingData, setLoadingData] = useState(true);
    const [allProjects, setAllProjects] = useState([]);

    const [currentProject, setCurrentProject] = useState({}); // object that has {id, name}

    const currentProjectRef = useRef();
    useEffect(()=>{
        currentProjectRef.current = currentProject;
    }, [currentProject]);

    // useEffect(()=>{
    //     console.log('running effect on currentProject', currentProject);
    //     if (currentProject){
    //         projectIdRef.current = currentProject.id;
    //     }
    //     else{
    //         projectIdRef.current = 0;
    //     }
    // }, [currentProject]);

    const [receivedMessage, setReceivedMessage] = useState(null);

    const [externalDataForFilterPanel, setExternalDataForFilterPanel] = useState(null);

    const history = useHistory();

    const channelRef = useRef();
   

    
    // useEffect(()=>{
    //     if (!channel){
    //         return;
    //     }

    //     const payload = {
    //         loadedTaxonomies, checkedTaxonomies, taxonomyDateFilterObj, cloudSearchWords, 
    //         allFilterRows, 
    //         simpleSearchText, 
    //         advancedSearchRows, 
    //         activeTabKey, 
    //         sender: 'from cloudSearchWords'
    //     };

    //     console.log('running effect with channel, posting message', JSON.stringify(payload));
    //     setTimeout(()=>{
    //         channel.postMessage({
    //             type: 'ci-updates', 
    //             scope: channelScope, 
    //             payload
    //         });
    //     }, 1000);
        
    // }, [cloudSearchWords]);




    const handleChannelMessage = msg =>{
        debugger;

        const urlParams = new URLSearchParams(window.location.search);
        const scope = urlParams.get('scope');

        console.log('incoming message to '+ window.location.pathname, JSON.stringify(msg.data));
        
        const messageData = msg.data;

        if (messageData.scope != scope){
            return;
        }

        if (messageData.type=='opening_insights_from_articles'){
            // then we know exactly what we need to do. Otherwise what we do might depend on which screen we are on and where the message came from.

            debugger;
            // 1) we need to set the important state variables:
            const {checkedTaxonomies, taxonomyDateFilterObj, simpleSearchText, advancedSearchRows, activeTabKey, cloudSearchWords, currentProject} = messageData.payload;

            setCheckedTaxonomies(checkedTaxonomies);
            setTaxonomyDateFilterObj(taxonomyDateFilterObj);
            setSimpleSearchText(simpleSearchText);
            setAdvancedSearchRows(advancedSearchRows);
            setActiveTabKey(activeTabKey);
            setCloudSearchWords(cloudSearchWords);
            setCurrentProject(currentProject);

            // 2) and run the search for the default cloud:

            const allFilterRows = makeAllFilterRows(checkedTaxonomies, taxonomyDateFilterObj);

            doRunInsightsSearch(
                allFilterRows, 
                simpleSearchText, 
                advancedSearchRows, 
                activeTabKey, 
                currentProject.id, 
                cloudSearchWords, 
                'KEY_PHRASE_CLOUD', '', '' // these are the default settings for the wordcloud chart
            );
            
            // 3) update the selection in the filters
            setExternalDataForFilterPanel({
                loaded: {}, 
                checked: checkedTaxonomies,
                dateFilterObj: taxonomyDateFilterObj,
                //fromChips
            });

            return;
        }

        if (msg.data.type==='ci-project-switch'){
            const {projectId} = messageData.payload;
            console.log('switching projects');
            handleProjectChange(projectId);
            // project switch only possible from Articles (project switch is disabled on Insights tab)
        }


        if (msg.data.type==='ci-updates'){
            debugger;

            const { checkedTaxonomies, taxonomyDateFilterObj, simpleSearchText, advancedSearchRows, activeTabKey, cloudSearchWords, currentProject, 
                insightsChartData, excludedWords
            } = messageData.payload;

            setCheckedTaxonomies(checkedTaxonomies);
            setTaxonomyDateFilterObj(taxonomyDateFilterObj);
            setSimpleSearchText(simpleSearchText);
            setAdvancedSearchRows(advancedSearchRows);
            setActiveTabKey(activeTabKey);
            setCloudSearchWords(cloudSearchWords);
            //setCurrentProject(currentProject);  - no, do not switch the projects. Will be done via separate event

            setInsightsChartData({...insightsChartData});

            setExcludedWords(excludedWords);

            // 2) We need to decide which search to run so simply look at what the sender did: 
            const {action, sender} = messageData;

            const allFilterRows = makeAllFilterRows(checkedTaxonomies, taxonomyDateFilterObj);

            if (sender.indexOf('home') > -1){
                if (action == 'runSimpleSearch'){

                    // then we run simpleSearch for Insights:
                    doRunInsightsSearch(allFilterRows, simpleSearchText, advancedSearchRows, activeTabKey, currentProjectRef.current.id, cloudSearchWords, 
                        insightsChartDataRef.current.chartKey, insightsChartDataRef.current.option1, insightsChartDataRef.current.option2  // but these items need to be what was selected in the cloud tab
                    );

                   
                }

                if (action == 'runAdvancedSearch'){
                    doRunInsightsSearch(allFilterRows, simpleSearchText, advancedSearchRows, activeTabKey, currentProjectRef.current.id, cloudSearchWords, 
                        insightsChartDataRef.current.chartKey, insightsChartDataRef.current.option1, insightsChartDataRef.current.option2);
                }

                if (action == 'updateLoadedAndCheckedTaxonomies'){
                    doRunInsightsSearch(allFilterRows, simpleSearchText, advancedSearchRows, activeTabKey, currentProjectRef.current.id, cloudSearchWords, 
                        insightsChartDataRef.current.chartKey, insightsChartDataRef.current.option1, insightsChartDataRef.current.option2);

                    const {loadedTaxonomies} =  messageData.payload;
                    setExternalDataForFilterPanel({
                        loaded: loadedTaxonomies, 
                        checked: checkedTaxonomies,
                        //filterRows: payload.allFilterRows
                        dateFilterObj: taxonomyDateFilterObj
                    });
                }

                if (action == 'runInsightsSearchWithCloudWords'){
                    doRunInsightsSearch(allFilterRows, simpleSearchText, advancedSearchRows, activeTabKey, currentProjectRef.current.id, cloudSearchWords, 
                        insightsChartDataRef.current.chartKey, insightsChartDataRef.current.option1, insightsChartDataRef.current.option2, insightsChartData.cloudTaxonomy, excludedWords);
                }

                if (action == 'updateExcludedWords'){
                    doRunInsightsSearch(allFilterRows, simpleSearchText, advancedSearchRows, activeTabKey, currentProjectRef.current.id, cloudSearchWords, 
                        insightsChartDataRef.current.chartKey, insightsChartDataRef.current.option1, insightsChartDataRef.current.option2, insightsChartData.cloudTaxonomy, excludedWords);
                }

                if (action == 'clearExcludedWords'){
                    doRunInsightsSearch(allFilterRows, simpleSearchText, advancedSearchRows, activeTabKey, currentProjectRef.current.id, cloudSearchWords, 
                        insightsChartDataRef.current.chartKey, insightsChartDataRef.current.option1, insightsChartDataRef.current.option2, insightsChartData.cloudTaxonomy, excludedWords);                    
                }

                if (action == 'clearCloudSearchWords'){
                    doRunInsightsSearch(allFilterRows, simpleSearchText, advancedSearchRows, activeTabKey, currentProjectRef.current.id, cloudSearchWords, 
                        insightsChartDataRef.current.chartKey, insightsChartDataRef.current.option1, insightsChartDataRef.current.option2, insightsChartData.cloudTaxonomy, excludedWords);                    
                }

                if (action == 'activeTabkeyChange'){
                    doRunInsightsSearch(allFilterRows, simpleSearchText, advancedSearchRows, activeTabKey, currentProjectRef.current.id, cloudSearchWords, 
                        insightsChartDataRef.current.chartKey, insightsChartDataRef.current.option1, insightsChartDataRef.current.option2);                    
                }

                
                
            }
            else {
                setPageNumber(1);
                const xPageSize = calculatePageSize(viewTypeRef.current, listviewPageSizeRef.current);
                if (action == 'runSimpleSearch'){

                    // then we run simpleSearch for articles:
   
                    doRunSearch(xPageSize, 1, allFilterRows, simpleSearchText, advancedSearchRows, 
                        cloudSearchWords, activeTabKey, 
                        
                        // these are all from ref because we cannot take them from the state directly: 
                        sortingTypeRef.current, 
                        sortingOrderRef.current, 
                        currentProjectRef.current.id, 
                        showFullContentRef.current, 
                        insightsChartData, excludedWords
                    );

                }

                if (action == 'runAdvancedSearch'){
                    
                    doRunSearch(xPageSize, 1, allFilterRows, simpleSearchText, advancedSearchRows, 
                        cloudSearchWords, activeTabKey, 
                        
                        // these are all from ref: 
                        sortingTypeRef.current, 
                        sortingOrderRef.current, 
                        currentProjectRef.current.id, 
                        showFullContentRef.current,
                        insightsChartData, excludedWords
                    );
                }

                if (action == 'updateLoadedAndCheckedTaxonomies'){
                    
                    doRunSearch(xPageSize, 1, allFilterRows, simpleSearchText, advancedSearchRows, 
                        cloudSearchWords, activeTabKey, 
                        
                        // these are all from ref: 
                        sortingTypeRef.current, 
                        sortingOrderRef.current, 
                        currentProjectRef.current.id, 
                        showFullContentRef.current,
                        insightsChartData, excludedWords
                    );

                    const {loadedTaxonomies} =  messageData.payload;
                    setExternalDataForFilterPanel({
                        loaded: loadedTaxonomies, 
                        checked: checkedTaxonomies,
                        //filterRows: payload.allFilterRows
                        dateFilterObj: taxonomyDateFilterObj
                    });
                }

                if (action == 'runInsightsSearchWithCloudWords'){
                    debugger;
                    console.log('cloud words added in insights', cloudSearchWords, excludedWords);
                    doRunSearch(xPageSize, 1, allFilterRows, simpleSearchText, advancedSearchRows, 
                        cloudSearchWords, activeTabKey, 
                        
                        // these are all from ref: 
                        sortingTypeRef.current, 
                        sortingOrderRef.current, 
                        currentProjectRef.current.id, 
                        showFullContentRef.current, 

                        insightsChartData, 
                        excludedWords
                    );
                }

                if (action == 'updateExcludedWords'){
                    debugger;
                    console.log('handling updateExcludedWords from insights!!!')
                    console.log('excludedWords', excludedWords);
                    doRunSearch(xPageSize, 1, allFilterRows, simpleSearchText, advancedSearchRows, 
                        cloudSearchWords, activeTabKey, 

                        sortingTypeRef.current, 
                        sortingOrderRef.current, 
                        currentProjectRef.current.id, 
                        showFullContentRef.current, 

                        insightsChartData, 
                        excludedWords
                    );
                }

                if (action == 'clearExcludedWords'){
                    doRunSearch(xPageSize, 1, allFilterRows, simpleSearchText, advancedSearchRows, 
                        cloudSearchWords, activeTabKey, 
                        
                        // these are all from ref: 
                        sortingTypeRef.current, 
                        sortingOrderRef.current, 
                        currentProjectRef.current.id, 
                        showFullContentRef.current,
                        
                        insightsChartData, 
                        excludedWords
                    );
                }

                if (action == 'clearCloudSearchWords'){
                    doRunSearch(xPageSize, 1, allFilterRows, simpleSearchText, advancedSearchRows, 
                        cloudSearchWords, activeTabKey, 
                        
                        // these are all from ref: 
                        sortingTypeRef.current, 
                        sortingOrderRef.current, 
                        currentProjectRef.current.id, 
                        showFullContentRef.current,
                        
                        insightsChartData, 
                        excludedWords
                    );
                }
                

            }
         
            return
            


            const payload = msg.data.payload;

            

            console.log('MESSAGE RECEIVED', JSON.stringify(payload));

            if (payload.sender=='from channel'){
                return;
            }

            setExternalDataForFilterPanel({
                loaded: payload.loadedTaxonomies, 
                checked: payload.checkedTaxonomies,
                //filterRows: payload.allFilterRows
                dateFilterObj: payload.taxonomyDateFilterObj
            });

            // setLoadedTaxonomies(payload.loadedTaxonomies);
            setCheckedTaxonomies(payload.checkedTaxonomies);
            setTaxonomyDateFilterObj(payload.taxonomyDateFilterObj);
            setAdvancedSearchRows(payload.advancedSearchRows);
            setCloudSearchWords(payload.cloudSearchWords);
            setSimpleSearchText(payload.simpleSearchText);
            setActiveTabKey(payload.activeTabKey);
            setPageNumber(payload.pageNumber);
            

            if (window.location.pathname.includes('insights')){
                
                const allFilterRows = makeAllFilterRows(payload.checkedTaxonomies, payload.taxonomyDateFilterObj);



                    
                    doRunInsightsSearch(
                        allFilterRows, payload.simpleSearchText, 
                        payload.advancedSearchRows, 
                        payload.activeTabKey, 
                        projectIdRef.current, 
                        payload.cloudSearchWords, 
                        'KEY_PHRASE_CLOUD', '', ''
                    );  
            }
            else {
                // enable auto-refresh here..
                // and set the page number to 1!
                const allFilterRows = makeAllFilterRows(payload.checkedTaxonomies, payload.taxonomyDateFilterObj);
                const newPageNumber = 1;
                setPageNumber(newPageNumber);


                console.log('projectIdRef', projectIdRef.current);
                // if we don't want live updates, just comment the following line, but keep setPageNumber(1):
                doRunSearch(pageSize, newPageNumber, allFilterRows, payload.simpleSearchText, payload.advancedSearchRows, 
                    payload.cloudSearchWords,
                    activeTabKey, sortingType, sortingOrder, projectIdRef.current, showFullContent);
            }
        }
    };

    const windowUnloadHandler = e=>{
        e.preventDefault();
        if (channelRef.current){
            channelRef.current.postMessage({type: 'closed tab', 
                payload: {
                    tabName: window.location.pathName
                }
            });
            channelRef.current.close();
            channelRef.current = null;
        }
        //return;
    };

    const setupChannel = ()=>{
        if (channelRef.current){
            console.log('channel is aready ON, '+ window.location.pathname);
            return;
        }
        const urlParams = new URLSearchParams(window.location.search);
        const scope = urlParams.get('scope');
        if (!scope){
            console.log('Not running in the scope for the channel')
            return;
        }
        console.log('going to open the channel');
        

        //setChannelScope(scope);

        channelRef.current = new BroadcastChannel("ci-channel");


        console.log('channel has been created from initial load, ' + window.location.pathname);
        // channel = channelRef.current;
        //onsole.log('assigned channel');
        

        window.addEventListener('unload', windowUnloadHandler);

        channelRef.current.onmessage = msg => handleChannelMessage(msg);

        // channel.onmessage = msg => handleChannelMessage(msg);
        
        // return ()=>{
        //     if (channelScope){
        //         window.removeEventListener('unload', windowUnloadHandler);
        //     }
            
        //     channel.close();
        // };

    };

    useEffect(()=>{
        // initial load
        setupChannel();
        setIsGlobalLoading(false);

        reload();



        return ()=>{
            if (channelRef.current){
                
                channelRef.current.postMessage({type: 'unsubscribe', 
                    payload: {
                        tabName: window.location.pathname, 
                        message: 'We navigated to another component that is not interested in events from ci-channel'
                    }
                });
        
                channelRef.current.close(); 
                channelRef.current = null;
            }
            else{
                console.log('Unloading MainFrame component and channel is already closed')
            }

        };
        
    }, []);
    
    // useEffect(()=>{
        
    // }, []);

    const openInsightsModal = ()=>{
        if (!channelRef.current){
            channelRef.current = new BroadcastChannel("ci-channel");
        }
        else{
            console.log('channel alraedy opened');
        }
        
        let pathname = window.location.href;
        let newUrl = pathname.split("/");
        newUrl[newUrl.length - 1] = "insights";
        let updatedUrl = newUrl.join("/");
        
        window.open(updatedUrl + "?scope=" + "A");

        history.push({
            pathame: `${ROUTES.HOME}`,
            search: `?scope=A`
        });

        window.addEventListener('unload', windowUnloadHandler);

        // because we know this button is only available on Articles tab, we can be sure that we are on Articles
        setTimeout(()=>{
            console.log('posting message opening_insights_from_articles');
            channelRef.current.postMessage({
                type: 'opening_insights_from_articles', 
                scope: 'A',
                payload: {
                    checkedTaxonomies, 
                    taxonomyDateFilterObj, 
                    simpleSearchText, 
                    advancedSearchRows, 
                    cloudSearchWords,
                    activeTabKey, 
                    currentProject
                }
            });
        }, 2000);
    };

    // const syncScope = ()=>{
    //     if (channel){
    //         channel.postMessage({
    //             type: 'opening_insights_from_articles', 
    //             scope: 'A',
    //             payload: {
    //                 loadedTaxonomies, checkedTaxonomies, 
    //                 dateFilterObj: taxonomyDateFilterObj, 
    //                 simpleSearchText, advancedSearchRows, 
                    
    //                 // TODO: might need to chuck more parameters to sync here
                
    //             }
    //         });
    //     }
    // };

    const setAsGroupDefaultQuery = (record)=>{
        debugger;
        // this should update the default query in state
        setDefaultQuery({queryId: record.id, projectId: currentProject.id}).then(res=>{
            setDefaultQueryId(record.id);
            notify.success('Successfully set as the default query for this group');
        }).catch(err=>{
            console.log(err);
            notify.success('Error setting the default query');
        })
    };

    const [showFullContent, setShowFullContent] = useState(false);
    const  showFullContentRef = useRef(false);
    useEffect(()=>{
        showFullContentRef.current = showFullContent;
    }, [showFullContent])

    const toggleShowFullContent =()=>{
        const newShowFullContent = !showFullContent;
        setShowFullContent(newShowFullContent);

        // we need to re-run search only if the full content has not been loaded yet (simpleSearch or advancedSearch applied)
        if (simpleSearchText || advancedSearchRows.length){
            doRunSearch(pageSize, pageNumber, allFilterRows, simpleSearchText, advancedSearchRows, 
                cloudSearchWords, activeTabKey, sortingType, sortingOrder, currentProject.id, newShowFullContent, insightsChartData, excludedWords);
        }
    };

    const handleLoadQuery = (record)=>{
        // a query has been applied from the dialog window of search queries
        debugger;
        console.log(record);
        
        let payload = null;

        try{
            payload = JSON.parse(record.savedResult);
        }
        catch (ex){
            console.log('error parsing the default query payload', ex);
        }

        if (payload){
            setAppliedQuery({id: record.id, name: record.searchKeyword, payload});
            applyQuery(payload, currentProject.id);

            // but the default query for the user still is different
        }
    };

    const handleExcludedWordsClear = ()=>{
        // this also can be clicked from both articles and insights
        debugger;
        setExcludedWords([]);

        if (window.location.pathname?.toLowerCase().includes('insights')){
            doRunInsightsSearch(allFilterRows, simpleSearchText, advancedSearchRows, activeTabKey, currentProject.id, cloudSearchWords, 
                insightsChartData.chartKey, insightsChartData.option1, insightsChartData.option2, cloudTaxonomy, []);

            if (channelRef.current){
                const urlParams = new URLSearchParams(window.location.search);
                const scope = urlParams.get('scope');
    
                channelRef.current.postMessage({
                    type: 'ci-updates', 
                    scope, 
                    payload: {
                        loadedTaxonomies, 
                        checkedTaxonomies, 
                        taxonomyDateFilterObj, 
                        simpleSearchText,
                        advancedSearchRows,
                        cloudSearchWords, 
                        activeTabKey,
                        currentProject, 
                        insightsChartData, 
                        excludedWords: []
                    }
                    , action: 'clearExcludedWords'
                    , sender: window.location.pathname
     
                });
            }
        }
        else {
            const newPageNumber = 1;
            setPageNumber(1);
            doRunSearch(pageSize, newPageNumber, allFilterRows, simpleSearchText, advancedSearchRows, 
                cloudSearchWords, activeTabKey, sortingType, sortingOrder, currentProject.id, showFullContent, insightsChartData, []);

            if (channelRef.current){
                const urlParams = new URLSearchParams(window.location.search);
                const scope = urlParams.get('scope');
    
                channelRef.current.postMessage({
                    type: 'ci-updates', 
                    scope, 
                    payload: {
                        loadedTaxonomies, 
                        checkedTaxonomies, 
                        taxonomyDateFilterObj, 
                        simpleSearchText,
                        advancedSearchRows,
                        cloudSearchWords, 
                        activeTabKey,
                        currentProject,
                        insightsChartData, 
                        excludedWords: []
                    }
                    , action: 'clearExcludedWords'
                    , sender: window.location.pathname
                    , pageNumber 
     
                });
            }

        }

    };

    const handleCloudWordsClear = ()=>{
        // can click both from articles and insights, so need to check where we are:
        setCloudSearchWords([]);
        if (window.location.pathname?.toLowerCase().includes('insights')){
            doRunInsightsSearch(allFilterRows, simpleSearchText, advancedSearchRows, activeTabKey, currentProject.id, [], insightsChartData.chartKey, insightsChartData.option1, insightsChartData.option2);

            if (channelRef.current){
                const urlParams = new URLSearchParams(window.location.search);
                const scope = urlParams.get('scope');
    
                channelRef.current.postMessage({
                    type: 'ci-updates', 
                    scope, 
                    payload: {
                        loadedTaxonomies, 
                        checkedTaxonomies, 
                        taxonomyDateFilterObj, 
                        simpleSearchText,
                        advancedSearchRows,
                        cloudSearchWords: [], 
                        activeTabKey,
                        currentProject, 
                        insightsChartData, 
                        excludedWords
                    }
                    , action: 'clearCloudSearchWords'
                    , sender: window.location.pathname
     
                });
            }
        }
        else {
            const newPageNumber = 1;
            setPageNumber(1);
            doRunSearch(pageSize, newPageNumber, allFilterRows, simpleSearchText, advancedSearchRows, 
                [], activeTabKey, sortingType, sortingOrder, currentProject.id, showFullContent, insightsChartData, excludedWords);

            if (channelRef.current){
                const urlParams = new URLSearchParams(window.location.search);
                const scope = urlParams.get('scope');
    
                channelRef.current.postMessage({
                    type: 'ci-updates', 
                    scope, 
                    payload: {
                        loadedTaxonomies, 
                        checkedTaxonomies, 
                        taxonomyDateFilterObj, 
                        simpleSearchText,
                        advancedSearchRows,
                        cloudSearchWords: [], 
                        activeTabKey,
                        currentProject,
                        insightsChartData, 
                        excludedWords
                    }
                    , action: 'clearCloudSearchWords'
                    , sender: window.location.pathname
                    , pageNumber 
     
                });
            }

        }

        // we can only click it from where? 
        // setCloudSearchWords([]);
        // const newPageNumber = 1;
        // setPageNumber(1);
        // doRunSearch(pageSize, newPageNumber, allFilterRows, simpleSearchText, advancedSearchRows, [], activeTabKey, sortingType, sortingOrder, currentProject.id, showFullContent);
    
    
    }

    
    const [showAllTags, setShowAllTags] = useState(false); // this one doesn't need to be here (yet) but might be needed for sync or for saving user setttngs



    return (
        <div className={global.mainWrapper}>
          
            <div className={searchStyle}>
     
            </div>

            {(advancedSearchVisible) && window.location.href.split('?')[0]  ? <AdvanceSearchContainer2
                dateFormat={dateFormat} 
                advancedSearchRows={advancedSearchRows}
                setAdvancedSearchVisible={setAdvancedSearchVisible} 
                onApply={onApplyAdvanceSearch}
                projectId={currentProject.id}
                allTaxonomies={taxonomiesAsMetadataForAdvancedSearch} 
            /> : null}
            
            <MyPageWrapper hideSideBar={hasToShowSideBar()}>
                {hasToShowSideBar() && (<MySideBar2 
                    showSideBarNav={hasToShowSideBar()}
                    CurrentUserProfile={{
                        userProfile:  userProfileId,
                        location: useLocation()
                    }}
                    location={props.location}
                />)}

                <PageBody addOnClassName={state.sideBarColapse.visible ? state.sideBarColapse.colapsePageBodyClassName : null}>
                    {hasToShowHeader() && (<PageHeading text={setPageHeading()}>
                        {props.location.pathName === ROUTES.INSIGHTS  
                        || props.location.pathName === ROUTES.DOWNLOAD_PREVIEW ? 
                        (<div className={global.headerProjectname}>
                            <p>Project Selected:</p>
                            <h4>{props.CurrentProfile?.projectinfo?.name}</h4>
                        </div>) : (
                            <>
                                {window.location.pathname.toLowerCase().indexOf('insights') > -1 ? <div className={global.headerProjectname}>
                                    <p>Project Selected:</p><h4>{currentProject.name}</h4></div> 
                                : <ProjectSelector projectList={allProjects} currentProjectId={currentProject.id} handleProjectChange={(id)=>handleProjectChange(id, true)}/>}
                                
                                

                                <ProfileOptions 
                                    userProfile={{
                                        id: userProfileId, 
                                        firstName: firstName,
                                        lastName: lastName,
                                        userEmail
                                    }}
                                    profileLoading={false} 
                                    superAdminCredChange={superAdminCredChange} 
                                />
                            </>
                        )}
                    </PageHeading>)}

                    {/* <div>
                        appliedQuery: {appliedQuery ? <span>{appliedQuery.id}: {appliedQuery.name}</span> : <>none</>}
                        <br/>
                        defaultQueryId: {JSON.stringify(defaultQueryId)}
                    </div>*/}

                    <button onClick={()=>setShowStateOfMain(!showStateOfMain)}>state of main</button>
                    {showStateOfMain ? <div style={{border: '2px solid red'}}>
                        <br/>
                        <b>loaded taxonomies:</b>
                        <br/>
                        {JSON.stringify(loadedTaxonomies)}

                        <br/>
                        <b>checked taxonomies:</b>
                        <br/>
                        {JSON.stringify(checkedTaxonomies)}

                        <br/>
                        <b>Taxonomy filter rows:</b>

                        <br/>
                        {JSON.stringify(allFilterRows)}

                        <br/>
                        <b>Advanced search rows:</b>

                        <br/>
                        {JSON.stringify(advancedSearchRows)}


                        <br/>
                        <b>Received message:</b>

                        <br/>
                        {JSON.stringify(receivedMessage)}

                        <br/>
                        <b>advancedSearchVisible:</b> 
                        {JSON.stringify(advancedSearchVisible)}

                        <br/>
                        <b>current project:</b> 
                        {JSON.stringify(currentProject.id)}

                        <br/>
                        <button onClick={()=>props.showLoginAs()}>Login As</button>

                        <br/>
                        {JSON.stringify(insightsChartData)}

                        <br/>
                        <b>excluded words:</b>{JSON.stringify(excludedWords)}

                        <br/>
                        <b>cloud search words:</b>{JSON.stringify(cloudSearchWords)}

                        <br/>
                        <b>View Type:</b>{JSON.stringify(viewType)}


                        <br/>

                        


                    </div> : null}
                    
                    {loadingData ? <>
                    
                    </> : 
                    <Switch>
                        <Route exact path={ROUTES.INDEX} render={()=><Redirect to={ROUTES.HOME} />} />

                        <PrivateRoute path={`${ROUTES.HOME}/`} component={MyHome2} componentProps={{

                            sortOrder,

                            cloudTaxonomy: insightsChartData.cloudTaxonomy,
                            cloudSearchWords,
                            excludedWords: excludedWords,
                            handleClearCloudSearchWordsClicked: handleCloudWordsClear,
                            handleClearExcludedWords: handleExcludedWordsClear, 

                            handleLoadQuery,    
                            defaultQueryId,
                            setAsGroupDefaultQuery,
                            userEmail, 
                            userProfileId, 
                            isGroupAdmin,
                            markedDocumentIds, setMarkedDocumentIds,    


                            clearAllTaxonomyFilters,

                            dateFormat, 

                            allProjectTaxonomies, 

                            saveSearchSettings: handleSaveSearchSettingsClicked,
                            exportToXls, 
                            exportLoading, 


                            viewLineCountSettings, 

                            updateUserTags,
                            showAllTags, setShowAllTags, 
                            showFullContent, toggleShowFullContent, 
                            externalData: externalDataForFilterPanel,
                            //syncScope,
                            loadedTaxonomies, 
                            checkedTaxonomies,
                            taxonomyDateFilterObj,
                            openInsightsModal,
                            setAdvancedSearchVisible,
                            advancedSearchVisible,
                            advancedSearchRows,
                            clearAdvancedSearch: clearAdvancedSearch, 
                            currentProject: props.currentProject,
                            
                            runSearch, 
                            runSimpleSearch,

                            taxonomiesForFilterPanel,
                            needDateFilter, 

                            updateLoadedAndCheckedTaxonomies,

                            taxonomiesAsMetadata,
                            handleViewTypeChange, 
                            viewType, 
                            searchResult, setSearchResult, 
                            activeTabKey, handleTabChange, 
                            pageNumber, handlePageNumberChange, 
                            
                            pageSize, showPagesizeChanger: (viewType == 'gridOne'), 

                            sortingType, handleSortingTypeChange, 
                            sortingOrder, handleSortingOrderChange,
                            taxonomyDateFilterObj,

                            simpleSearchText, 
                            projectId: currentProject.id
                        }} />

                        {/* Route allows to open tabs tab */}
                        <PrivateRoute
                            path={ROUTES.VIEW_TAB_ARTICLES}
                            component={Home}
                            componentProps={{ mainFrameState: {
                                state:{
                                    filterVisible: state.filterVisible,
                                }, 
                                handleFilterVisible
                            } }}
                        />

                        

                        <PrivateRoute
                            path={`${ROUTES.INSIGHTS}`}
                            component={Insights2}
                            componentProps={{

                                sortOrder,
                                activeTabKey, 
                                saveInsightSettings,

                                insightsChartKey: insightsChartData.chartKey, 
                                option1: insightsChartData.option1,
                                option2: insightsChartData.option2, 
                                cloudTaxonomy: insightsChartData.cloudTaxonomy,

                                handleInsightsChartKeyChanged, 

                                cloudSearchWords,

                                runInsightsSearchWithCloudWords: handleCloudWordsChanged, 
                                updateExcludedWords: updateExcludedWords, 

                                excludedWords: excludedWords, 
                                
                                clearExcludedWords: handleExcludedWordsClear,

                                handleClearCloudSearchWords: handleCloudWordsClear,
                                handleClearExcludedWords: handleExcludedWordsClear, 


                                clearAdvancedSearch,
                                clearAllTaxonomyFilters,

                                externalData: externalDataForFilterPanel,
                                loadedTaxonomies, 
                                checkedTaxonomies, taxonomyDateFilterObj, 
                                taxonomiesForFilterPanel,
                                needDateFilter, 
                                
                                setAdvancedSearchVisible,
                                advancedSearchVisible,

                                updateLoadedAndCheckedTaxonomies, 

                                advancedSearchVisible: advancedSearchVisible,
                                advancedSearchRows,
                                clearAdvancedSearch: clearAdvancedSearch, 

                                searchResult: insightsSearchResult,
                                
                                runSearch: runInsightsSearch,
                                runSimpleSearch: runInsightsSimpleSearch, 
                                allFilterRows, 

                                simpleSearchText, 
                                projectId: currentProject.id
                            }}
                        />

                        <PrivateRoute
                            path={ROUTES.DASHBOARD}
                            component={Dashboard2}
                            //component={DashboardTest}
                            componentProps={{ mainFrameState: {
                                state:{
                                    // trying to pass just the subset of state:
                                    filterVisible: state.filterVisible,
                                    
                                }, 
                                
                                handleFilterVisible,
                                
                            },
                            projectId: currentProject.id
                        }}
                        />

                        
                        <PrivateRoute path={ROUTES.SETTINGS} component={SettingsScreen} componentProps={{
                            dateFormat,
                            sortOrder, 
                            hideResultsZeroCount, 
                            viewLineCountSettings,
                            updatePreferenceSettings, 
                            updateUserLinecountSettings,
                            projectId: currentProject.id,

                        }} />

                        <PrivateRoute path='/charts1' component={NewCharts} />
                        <PrivateRoute path='/radar' component={NewChartRadar} />
                        <PrivateRoute path='/relationship' component={NewChartRelationship2} />
                        <PrivateRoute path='/stream' component={NewChartStream} />
                        <PrivateRoute path='/stacked-bar' component={NewChartStackedBar} />


                        <PrivateRoute path={ROUTES.USER_LOGS} component={UserLogs2} />
                        <PrivateRoute path={ROUTES.USERS} component={Users3} componentProps={{
                            loggedInSuperAdmin: userProfileId === 3, 
                            userEmail, 
                            refreshWholeScreen: ()=>{
                            reload();
                        }}} />
                        <PrivateRoute path={ROUTES.USER_GROUPS} component={UserGroups3} componentProps={{projectId: currentProject.id}} />

                        {/* we need to pass some properties from Dashboards into this component but we are using the history state API */}
                        <PrivateRoute path={ROUTES.DOWNLOAD_PREVIEW} component={DownloadPreview2} />
                        
                        <PrivateRoute
                            path={ROUTES.VIEW_IN_NEW_TAB}
                            component={Insights}
                            componentProps={{ mainFrameState: {
                                state:{
                                    // passing just the substate
                                    filterVisible: state.filterVisible,
                                }, 
                                
                                handleFilterVisible
                            } }}
                        />

                    </Switch>}


                    <TimeOutModal {...propsForTimeOutModal}/>
                        
                    <Showlogoutmodal {...propsForShowlogoutmodal}/>

                    <ProjectSwitchingModal showProjectSwitchingModal={state.showProjectSwitchingModal} />
                </PageBody>
            </MyPageWrapper>

            <PasswordResetModal
                onChange={onResetPasswordChange}
                state={state}
                onSubmit={resetSuperAdminPassword}
                cancel={superAdminCredChange}
                visible={state.superAdminPassChange}
                currentProfile={props.CurrentProfile}
            />
        </div>
    );
};

