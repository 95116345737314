import ContentBody from "Components/ContentBody";
import global from '../../styles/globel/style.module.scss';
import { useEffect, useState } from "react";
import { getActivityLogPageSize } from "api/settings";
import { USER_LOGS_CONFIG } from "./Userlog.config";
import { DEFAULT_PAGE_COUNT } from "constants";
import TableElement from "Components/TableElement";
import Pagination from "Components/Pagination";

export const UserLogs2 = props=>{

    const [activityLog, setActivityLog] = useState({items: [], totalCount: 0});
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const [sortingInfo, setSortingInfo] = useState({columnKey: 'timestamp', field: 'timestamp', order: 'descend'});

    useEffect(()=>{
        
        getActivityLogPageSize({pageNumber, pageSize}).then(res=>{
            debugger;
            setActivityLog({items: res.data.result.result, totalCount: res.data.result.resultHits});
        });
    }, [pageNumber, pageSize]);

    const handleUserLogPagination = (pageNumber, pageSize)=>{
        debugger;
        setPageNumber(pageNumber);
        setPageSize(pageSize);
    };

    const handleChange = ()=>{

    }

    return (<ContentBody>
            
        <div className={global.paginationWrapper}>
            <div className={global.searchresultCount}>
            <span>
                {activityLog.items.length > 0
                ? 'Showing ' +
                activityLog.items.length +
                ' out of ' +
                activityLog.totalCount +
                ' Results'
                : ''}
            </span>
            </div>
            <div className={global.searchresultPage}>
            {UserLogPagination({
                pageNumber, pageSize, 
                totalCount: activityLog.totalCount,
                onChange: handleUserLogPagination,
            })}
            </div>
        </div>
        <TableElement
            rowKey='id'
            columns={USER_LOGS_CONFIG.COLUMNS({ sortedInfo: sortingInfo })}
            data={activityLog.items}
            onChange={handleChange}
            scrollY={'calc(100vh - 240px)'}
        />
        
        
        </ContentBody>

    );
};

function UserLogPagination({ pageNumber, pageSize, totalCount, onChange }) {
    debugger;
    return totalCount > DEFAULT_PAGE_COUNT ? (
      <Pagination
        pageSize={pageSize}
        current={pageNumber}
        total={totalCount}
        onChange={onChange}
        showSizeChanger={true}
        pageSizeOptions={[10, 20, 50, 100]}
      />
    ) : (
      <></>
    );
  }