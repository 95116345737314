import ShowModalwindowDelete from "Routes/Dashboard/ShowModalWindowDelete";
import style from "./style-insights.module.scss";
import global from "styles/globel/style.module.scss";
import { INSIGHTS_CONFIG } from "Routes/Insights/Insights.config";
import { deleteInsight, getInsightsList, getSavedInsights } from "api/insights";
import { useEffect, useState } from "react";
import PageHeading from "Components/PageHeading";
import TableElement from "Components/TableElement";
import Pagination from "Components/Pagination";

export const SavedInsightsModalContent = props=>{

    const [savedInsights, setSavedInsights] = useState([]);
    
    const [totalCount, setTotalCount] = useState(0);

    const [pageSize, setPageSize] = useState(5);
    const [pageNumber, setPageNumber] = useState(1);

    const loadData = (pageNumber, pageSize, projectId)=>{

        getInsightsList({pageNumber, pageSize, projectId}).then(res=>{
            debugger;

            setSavedInsights(res.data.result.result); 
            setTotalCount(res.data.result.resultHits); 
        });
    };

    
    useEffect(()=>{
        debugger;
        loadData(pageNumber, pageSize, props.projectId)
    }, []);
    
    
    const onPaginationChange = (newPageNumber, newPageSize)=>{
        debugger;
        setPageSize(newPageSize);
        setPageNumber(newPageNumber);

        loadData(newPageNumber, newPageSize, props.projectId);
    };

    const [showDeleteSavedInsightConfirmationDialog, setShowDeleteSavedInsightConfirmationDialog] = useState(null); // another modal dialog that confirms the deleetion of a saved insight


    const doDeleteInsight = ()=>{
        // user clicked to confirm the deletion of an insight, the insight object is in showDeleteSavedInsightConfirmationDialog state
        debugger;
        const insightId = showDeleteSavedInsightConfirmationDialog.id;
        deleteInsight({id: insightId}).then(res=>{
            debugger;
            console.log(res);
            // and reload the data: 
            loadData(pageNumber, pageSize, props.projectId);
            showDeleteSavedInsightConfirmationDialog(null); // it's okay...
        });
    };


    return (<div className={style.savedInsight}>
            
        {showDeleteSavedInsightConfirmationDialog ? <ShowModalwindowDelete
            state={{showDeleteModal: true}}
            deleteModalHandler={()=>setShowDeleteSavedInsightConfirmationDialog(null)} 
            deleteModalHandlerYes={doDeleteInsight} /> : null}

        <PageHeading text={"Saved Insights"}>
            <div className={global.searchresultCount}>
                <span> {savedInsights.length > 0 ? `Showing ${savedInsights.length} out of ${totalCount} results` : ''}</span>
            </div>
        </PageHeading>
    
        <div className={style.insightData}>
            <div className={style.insightData__table}>
                <TableElement
                    rowKey='id'
                    columns={INSIGHTS_CONFIG.COLUMNS({
                        deleteHandler: record=>setShowDeleteSavedInsightConfirmationDialog(record),
                        handleViewInsights: props.handleViewInsights,
                        downloadInsightsHandler: props.downloadInsightHandler,
                    })}
                data={savedInsights}
                onChange={props.onPaginationChange}
                //loading={props.props.InsightsPage?.loading}
                scrollY={400}
            />
             {totalCount > 5 ? <Pagination
                pageSize={pageSize}
                current={pageNumber}
                total={totalCount}
                onChange={onPaginationChange}
                showSizeChanger={true}
                pageSizeOptions={["5", "10", "20", "50"]}
            /> : null}
        </div>
    </div>
</div>);
}