import ButtonElement from "Components/ButtonElement";
import { SimpleSearchControl } from "Components/SearchBar/SimpleSearchControl";
import SVGIcons from "Components/SVGIcons";
import { TwoButtonsControl } from "./TwoButtonsControl";
import { useState } from "react";
import style from './style.module.scss';
import { AdvancedSearchBanner } from "./AdvancedSearchBanner";
import { SavedInsightsModal } from "Components/SavedSearches/SavedInsightsModal";
import { FilterButton } from "./FilterButton";
import { SaveResultsModal2 } from "./HomeHeader/children/SaveResultsModal2";

export const InsightsHeaderSection2 = props=>{
    
    const [showSaveModal, setShowSaveModal] = useState(false);
    const [showLoadInsightsDialog,  setShowLoadInsightsDialog] = useState(false);


    const saveInsightSettings = name=>{
        props.saveInsightSettings(name, ()=>{
            setShowSaveModal(false);
        });
    };


    return (<>
    <div style={{padding: '8px', position: 'relative'}} className={style['header-section']}>
        {/* This is the toolbar */}
        <div style={{display: 'flex'}} className={style['header-section-sub']}>
            
            <FilterButton 
                filterIsOn={props.taxonomyFilterOpened}
                onClick={()=>props.setTaxonomyFilterOpened(!props.taxonomyFilterOpened)}
            />
    
            <div style={{marginLeft: '8px'}}>
                <SimpleSearchControl runSimpleSearch={props.runSimpleSearch} simpleSearchText={props.simpleSearchText}/>
            </div>
        </div>

        <div className={style['header-section-sub2']}>
            <div className={style['header-section-sub2-content']}>

                <ButtonElement styleProps={{margin: '4px', height: '32px'}} onClick={props.handleModifyInsightsButtonClick}>
                    <span>Modify Insights</span>
                </ButtonElement>

                <ButtonElement styleProps={{margin: '4px', height: '32px'}} onClick={props.handleExclusionsButtonClick}>
                    <span>Exclusion List</span>
                </ButtonElement>

            
                <ButtonElement styleProps={{margin: '4px', height: '32px'}} onClick={()=>props.setAdvancedSearchVisible(!props.advancedSearchVisible)}>Advanced Search</ButtonElement>

                <TwoButtonsControl 
                    handleSavedClicked={()=>setShowSaveModal(true)}
                    handleLoadClicked={()=>setShowLoadInsightsDialog(true)}
                />
                
            </div>
        </div>

    </div>

    <AdvancedSearchBanner advancedSearchRows={props.advancedSearchRows} clearAdvancedSearch={props.clearAdvancedSearch}/>

    {showSaveModal ? <SaveResultsModal2 
        handleSaveClicked = {(insightName)=>saveInsightSettings(insightName)}
        handleCloseModal = {()=>setShowSaveModal(false)}
    /> : null}

    {showLoadInsightsDialog ? <SavedInsightsModal 
        // a number of handlers for each row click
        projectId={props.projectId}
        handleCloseModal = {()=>setShowLoadInsightsDialog(false)}
    /> : null}
        

</>);
};
    