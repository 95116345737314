import { FilterPanel } from "Components/MyFilter/FilterPanel";
import style from "./style.module.scss";
import global from "styles/globel/style.module.scss";
import { InsightsHeaderSection2 } from "Components/HeaderSection/InsightsHeaderSection2";
import { PanelWithInsightsChart } from "./components/CreateInsights/PanelWithInsightsChart";
import { useEffect, useMemo, useRef, useState } from "react";
import { loadCloudData } from "api/insights";
import { TaxonomyFilterChips } from "Components/HeaderSection/TaxonomyFilterChips/TaxonomyFilterChips";
import { AdvancedSearchBanner } from "Components/HeaderSection/AdvancedSearchBanner";
import ModalWindow from "Components/ModalWindow";
import { ExclusionsModalContent } from "Components/WordCloud/ExclusionsModalContent";
import { ModifyInsightsModalContent } from "./components/CreateInsights/Children/ModifyInsightsModalContent";

export const Insights2 = props=>{

    const [taxonomyFilterOpened, setTaxonomyFilterOpened] = useState(true);

    
    
    
    const [activeTabKey, setActiveTabKey] = useState('All Articles');

    useEffect(()=>{
        console.log('setting activeTabKey')
        setActiveTabKey(props.activeTabKey);
    }, [props.activeTabKey]);


    const searchResult = useMemo(()=>{
        return props.searchResult;
    }, [props.searchResult]);

    const items = useMemo(()=>{
        return searchResult.items;
    }, [searchResult]);

    const [modifyInsightsVisible, setModifyInsightsVisible] = useState(false);

    const [exclusionsVisible, setExclusionsVisible] = useState(false);


    // useEffect(()=>{
    //     console.log(window.location.href)
    //     if (!window.location.href.includes('scope=')){
    //         props.runSearch();
    //     }
    // }, []);

    
    const handleModifyInsightsButtonClick = ()=>{
        // user clicked on "Modify Insights"
        setModifyInsightsVisible(true);
    };

    const handleExclusionsButtonClick = ()=>{
        setExclusionsVisible(true);
    };

    const handleInsightOpenModalOnOk = ()=>{
        // this one will need to update the chart type most likely
    }

    const handleInsightsSettingsModalClosed = (x)=>{
        setModifyInsightsVisible(false); 
    };

    const applySettingsFromInsightsSettingsModal = (chartOptions)=>{
        props.handleInsightsChartKeyChanged(chartOptions);

        setModifyInsightsVisible(false);    
    };


    return (<div className={`${global.layoutBlock} ${taxonomyFilterOpened ? global['filterToggle'] : ""} `}>
        <div className={global.pageAside} style={{ display: 'flex', flexDirection: 'row'}}>
        
            <FilterPanel 

                sortOrder={props.sortOrder}
                clearAllTaxonomyFilters={props.clearAllTaxonomyFilters}
                projectId={props.projectId}

                updateLoadedAndCheckedTaxonomies={props.updateLoadedAndCheckedTaxonomies}

                runSearch={()=>props.runSearch()} 

                taxonomies={props.taxonomiesForFilterPanel}
                needDateFilter={props.needDateFilter}
                externalData={props.externalData}
            />

        </div>

        <div className={style.InsightContainer}>

            <InsightsHeaderSection2 runSimpleSearch={props.runSimpleSearch} 
                setAdvancedSearchVisible={props.setAdvancedSearchVisible}
                advancedSearchVisible={props.advancedSearchVisible} 
                simpleSearchText={props.simpleSearchText} 
                advancedSearchRows={props.advancedSearchRows} 
                clearAdvancedSearch={props.clearAdvancedSearch}
                projectId={props.projectId}


                saveInsightSettings={props.saveInsightSettings}

                taxonomyFilterOpened={taxonomyFilterOpened}
                setTaxonomyFilterOpened={setTaxonomyFilterOpened}

                handleModifyInsightsButtonClick={handleModifyInsightsButtonClick}
                handleExclusionsButtonClick={handleExclusionsButtonClick}
  
            />

            <TaxonomyFilterChips loadedTaxonomies={props.loadedTaxonomies} checkedTaxonomies={props.checkedTaxonomies} taxonomyDateFilterObj={props.taxonomyDateFilterObj} updateLoadedAndCheckedTaxonomies={props.updateLoadedAndCheckedTaxonomies} />

            
            <PanelWithInsightsChart items={items} totalCount={searchResult.totalCount} activeTabKey={activeTabKey} 
                chartName={props.insightsChartKey}
                handleClearCloudSearchWords={props.handleClearCloudSearchWords}
                handleClearExcludedWords={props.handleClearExcludedWords}
                option1={props.option1}
                option2={props.option2}
                cloudTaxonomy={props.cloudTaxonomy}
                clearExcludedWords={props.clearExcludedWords}
                cloudSearchWords={props.cloudSearchWords} 
                excludedWords={props.excludedWords} updateExcludedWords={props.updateExcludedWords}
                runInsightsSearchWithCloudWords={props.runInsightsSearchWithCloudWords}
            
            />
            
        </div>

        {/* Modal for Modify Insights */}
        {modifyInsightsVisible ? <ModalWindow
            open={true}
            title='Insight Parameters'
            width={500}
            footer={null}
            // onOk={handleInsightOpenModalOnOk}
            // onCancel={()=>setModifyInsightsVisible(false)}
            isNonMaskable={true}
            isNotClosable={true}
            // isHideCancelButton={true}
        >
            <ModifyInsightsModalContent onClose={handleInsightsSettingsModalClosed} 
            insightsChartKey={props.insightsChartKey}
            option1={props.option1}
            option2={props.option2}
            cloudTaxonomy={props.cloudTaxonomy}
            taxonomies={props.taxonomiesForFilterPanel}
            onApply={applySettingsFromInsightsSettingsModal}/>
        </ModalWindow> : null}

         {/* Modal for Exclusions */}
         {exclusionsVisible ? <ModalWindow
            open={true}
            title='Exclusions List'
            width={900}
            okText="Ok"
            cancelText="Cancel"
            onOk={handleInsightOpenModalOnOk}
            onCancel={()=>setExclusionsVisible(false)}
            isNonMaskable={true}
            isNotClosable={true}
            // isHideCancelButton={true}
        >
            <ExclusionsModalContent/>
        </ModalWindow> : null}


    </div>);
};