import { getUserDashboards } from "api/dashboard";
import SVGIcons from "Components/SVGIcons";
import TableElement from "Components/TableElement";
import TooltipContainer from "Components/Tooltip";
import { useEffect, useMemo, useState } from "react";
import global from "../../styles/globel/style.module.scss";

const UserRowActions = props=>{
    return (<ul className={global.tableActions}>
        <li>
            <TooltipContainer title={'View Dashboard'} mouseLeaveDelay={0} placement='bottom'>
                <span onClick={(e) => { props.actionHandlers.handleView(props.record) }}>
                    <SVGIcons type={'SVG-viewicon'} />
                </span>
            </TooltipContainer>
        </li>

        <li>
            <TooltipContainer title={'Download Dashboard'} mouseLeaveDelay={0} placement='bottom'>
                <span onClick={(e) => { props.actionHandlers.handleDownload(props.record) }}>
                    <SVGIcons type={'SVG-download'} />
                </span>
            </TooltipContainer>
        </li>

        <li>
            <TooltipContainer title={'Delete Dashboard'} mouseLeaveDelay={0} placement='bottom'>
                <span onClick={(e) => { props.actionHandlers.handleDelete(props.record) }}>
                    <SVGIcons type={'SVG-delete'} />
                </span>
            </TooltipContainer>
        </li>

    </ul>);
};

const makeColumns = (actionHandlers)=>{

    return [
        {
            title: 'Name',
            dataIndex: 'dashboardName',
            sorter: false,
        },
        {
            title: 'Project',
            dataIndex: 'projectName',
            sorter: false,
        },
        {
            title: 'Created Date',
            dataIndex: 'createdDate'
        },
        {
            title: 'Updated Date',
            dataIndex: 'updatedDate'
        },

        {
            title: 'Actions',
            key: 'actions',
            render: (text, record)=><UserRowActions record={record} actionHandlers={actionHandlers} />
            
        }
    ];
};


export const LoadDashboardContent = props=>{
    // this is the content of the modal window that shows a list of available saved dashboards

    const [items, setItems] = useState([]);
    
    const loadItems = ()=>{
        getUserDashboards().then(res=>{
            debugger;
            console.log(res.data);
            setItems(res.data);

        }).catch(err=>{
            debugger;
            console.log(err);
        })

    };

    useEffect(()=>{
        loadItems();
    }, [props.triggerReload]);

    const handleView = (record)=>{
        debugger;
        props.handleView(record);
    };

    const handleDownload = (record)=>{
        debugger;
        props.handleDownload(record);
    };

    const handleDelete = (record)=>{
        debugger;
        props.handleDelete(record);
    };

    


    const columns = useMemo(()=>{
        const result = makeColumns({handleView, handleDownload, handleDelete});
        return result;

    }, []);


    return (<>
    <TableElement 
        style={{width: 'calc(100vw - 125px)'}}
        scrollY={"calc(100vh - 270px)"}
        columns={columns}
        data={items}
        tableLayout='fixed'
        rowKey='id'
    />
    </>);
};