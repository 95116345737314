import { getRelationshipChartData } from 'api/search';
import ReactECharts from 'echarts-for-react';
import { useEffect, useMemo, useState } from 'react';

import { nodesFromApi } from './nodesFromAPi';

export const NewChartRelationship2 = props=>{

    // chart with links between nodes from different categories - impossible to calculate weight of link within each category

    const [linkCountPercent, setLinkCountPercent] = useState(50); // this is the percentage
    const [debouncedLinkCountPercent, setDebouncedLinkCountPercent] = useState(50); // this is the percentage


    const [range, setRange] = useState({min: 0, max: 1});    

    const [graphData, setGraphData] = useState(null); // this is the whole API response

    const [wordCounts, setWordCounts] = useState([]);


    const [dragData, setDragData] = useState(null);
    
    // nodes are calculated from graphData.nodes and current window of wordCounts
    const [graphNodes, setGraphNodes] = useState([]);

    // links will have to be calculated in a similar manner
    const [links, setLinks] = useState([]);
    


    const [categories, setCategories] = useState([]);

    //const [apiNodes, setApiNodes] = useState([]);
    
    useEffect(()=>{
        // setGraphData({
        //     nodes: [], 
        //     links: [], 
        //     nodeSteps: nodeWordCategories,
        //     categories: []
        // });
        // return;
        //debugger;
        // setApiNodes(nodesFromApi);
        // return;
        
        // need to call an endpoint that takes the search and returns series -number of tags

        getRelationshipChartData().then(res=>{
            debugger;
            console.log(res);
            // TODO: place the data into state

            const data = res.data;
            setGraphData(data); // nodes, links, linkSteps

            setWordCounts(data.linkSteps); // These are the different counts
            
            // const nodes = [];
            
            // const nodesKeys = Object.keys(data);

            // // collect all nodes:
            // nodesKeys.forEach(key=>{
            //     data[key].nodes.forEach(node=>{
            //         nodes.push(node);
            //     });
            // });

            // //setGraphNodes(nodes);
            setCategories(res.data.categories);

            setRange({min: 0, max: 1});

            

        })

    }, []);

    useEffect(()=>{
        const handler = setTimeout(()=>{
            setDebouncedLinkCountPercent(linkCountPercent);
        }, [2000]);

        return ()=>clearTimeout(handler);
    }, [linkCountPercent]);


    // const links = useMemo(()=>{
    //     return;
    //     debugger;
    //     if (!graphData){
    //         return [];
    //     }

    //     // links need to be constructed for each category using the percentage
    //     const links = [];

    //     const nodesKeys = Object.keys(graphData);
    //     nodesKeys.forEach(key=>{
    //         const linkSteps = graphData[key].linkSteps;
    //         debugger;


    //         // linkCountPercent is the percentage of the maximum number of connections.

    //         // linkSteps is ordered, so the last element is the max
    //         // const maxNumberOfLinks = linkSteps[linkSteps.length - 1];

    //         // const threshold = Math.floor((maxNumberOfLinks * linkCountPercent) / 100);

    //         // use linkCountPercent as top N percent of items: 

    //         //const pos = Math.floor(((linkSteps.length-1) * linkCountPercent) / 100);
    //         const pos = Math.floor(linkSteps.length - (linkSteps.length * linkCountPercent) / 100);
            
    //         let threshold = linkSteps[linkSteps.length - 1] + 1;
    //         if (pos < linkSteps.length - 1){
    //             threshold = linkSteps[pos];
    //         }
            

    //         graphData[key].links.forEach(link=>{
    //             if (link.linkCount >= threshold){
    //                 links.push(link);
    //             }
    //         });


    //         // now we also need to hide the nodes which do not have any links...
            

    //     });




    //     return links;
    //     // const result = graphData.links.filter(x=>x.linkCount >= linkCountPercent);
    //     // return result;
    // }, [debouncedLinkCountPercent, graphData]);

    useEffect(()=>{
        if (!graphData){
            return;
        }
        debugger;
        


        // firstly need to get links between the active nodes:
        const linksBetweenActiveNodes = [];

        const activeNodes = Object.fromEntries(graphNodes.map(x=>([x.id, x.id])));

        graphData.links.forEach(link=>{
            if (activeNodes[link.target] && activeNodes[link.source]){
                linksBetweenActiveNodes.push(link);
            }
        });

        // find the maximum: 
        let maxNumberOfLinks = linksBetweenActiveNodes[0].linkCount;
        for (let i = 1; i < linksBetweenActiveNodes.length; i++){
            if (maxNumberOfLinks < linksBetweenActiveNodes[i].linkCount){
                maxNumberOfLinks = linksBetweenActiveNodes[i].linkCount;
            }
        }



        //const maxNumberOfLinks = graphData.linkSteps[graphData.linkSteps.length - 1]; // this is the maximum number of connections

        ///const maxNumberOfLinks = linksBetweenActiveNodes[linksBetweenActiveNodes - 1]; // this is the maximum number of connections for the nodes that are visible 
        

        const threshold = Math.floor((maxNumberOfLinks * linkCountPercent) / 100); // threshold is the percentage of the maximum connections


        const pos = Math.floor(((graphData.linkSteps.length-1) * linkCountPercent) / 100);
        //const pos = Math.floor(linkSteps.length - (linkSteps.length * linkCountPercent) / 100);
        
        // let threshold = linkSteps[linkSteps.length - 1] + 1;
        // if (pos < linkSteps.length - 1){
        //     threshold = linkSteps[pos];
        // }
        

        //const filteredLinks = [];
        const filteredLinks = linksBetweenActiveNodes.filter(x=>x.linkCount > threshold);

        // linksBetweenActiveNodes.forEach(link=>{
        //     if (link.linkCount >= threshold){
        //         filteredLinks.push(link);
        //     }
        // });

        setLinks(filteredLinks);



    }, [graphNodes, debouncedLinkCountPercent]);
    

    // useEffect(()=>{
    //     return;
    //     if (!graphData){
    //         return;
    //     }
    //     const nodes = [];
            
    //     const nodesKeys = Object.keys(graphData);

    //     // collect all nodes that have links:
    //     nodesKeys.forEach(key=>{
    //         graphData[key].nodes.forEach(node=>{
    //             const involvedLink = links.find(link=>link.target == node.id || link.source == node.id);
    //             if (involvedLink){
    //                 nodes.push(node);
    //             }
    //         });
    //     });

    //     setGraphNodes(nodes);

    // }, [links]);

    const nodeWordCategories =[
        {
            "words": [
                {
                    "word": "inflight experience",
                    "category": "1.2 Airlines"
                }
            ],
            "count": 1298
        },
        {
            "words": [
                {
                    "word": "airline competition",
                    "category": "1.2 Airlines"
                }
            ],
            "count": 1402
        },
        {
            "words": [
                {
                    "word": "qantas [hkia]",
                    "category": "2.2 Airline List"
                }
            ],
            "count": 3403
        },
        {
            "words": [
                {
                    "word": "emirates [hkia] [hk10c]",
                    "category": "2.2 Airline List"
                }
            ],
            "count": 3456
        },
        {
            "words": [
                {
                    "word": "british airways [hkia]",
                    "category": "2.2 Airline List"
                }
            ],
            "count": 3476
        },
        {
            "words": [
                {
                    "word": "southwest airlines [lcc]",
                    "category": "2.2 Airline List"
                }
            ],
            "count": 3494
        },
        {
            "words": [
                {
                    "word": "indigo [lcc] [hkia]",
                    "category": "2.2 Airline List"
                }
            ],
            "count": 3540
        },
        {
            "words": [
                {
                    "word": "(svo) moscow sheremetyevo, russia, europe [hk30c] [hklh] [acf]",
                    "category": "2.1 Airport List"
                },
                {
                    "word": "(svo) moscow sheremetyevo",
                    "category": "2.1.1 Airport List Short"
                }
            ],
            "count": 3642
        },
        {
            "words": [
                {
                    "word": "(fra) frankfurt, germany, europe [eu27] [w30p] [w30i] [w30c] [hk50p] [hk30c] [hklh] [acf]",
                    "category": "2.1 Airport List"
                }
            ],
            "count": 3867
        },
        {
            "words": [
                {
                    "word": "ryanair [lcc]",
                    "category": "2.2 Airline List"
                }
            ],
            "count": 3897
        },
        {
            "words": [
                {
                    "word": "(fra) frankfurt",
                    "category": "2.1.1 Airport List Short"
                }
            ],
            "count": 3914
        },
        {
            "words": [
                {
                    "word": "(icn) seoul incheon",
                    "category": "2.1.1 Airport List Short"
                }
            ],
            "count": 4241
        },
        {
            "words": [
                {
                    "word": "(icn) seoul incheon, south korea, east asia [w30p] [w30i] [w30c][hk10p] [hk30p] [hk50p] [hk30c] [hksh] [acf] [ret]",
                    "category": "2.1 Airport List"
                }
            ],
            "count": 4247
        },
        {
            "words": [
                {
                    "word": "delta air lines [hkia]",
                    "category": "2.2 Airline List"
                }
            ],
            "count": 4293
        },
        {
            "words": [
                {
                    "word": "(lax) los angeles, united states, north america [w30p] [w30i] [w30c] [hk30p] [hk50p] [hk30c] [hklh] [acf]",
                    "category": "2.1 Airport List"
                }
            ],
            "count": 4478
        },
        {
            "words": [
                {
                    "word": "(lax) los angeles",
                    "category": "2.1.1 Airport List Short"
                }
            ],
            "count": 4510
        },
        {
            "words": [
                {
                    "word": "united airlines [hkia]",
                    "category": "2.2 Airline List"
                }
            ],
            "count": 4572
        },
        {
            "words": [
                {
                    "word": "lufthansa [hkia]",
                    "category": "2.2 Airline List"
                }
            ],
            "count": 5000
        },
        {
            "words": [
                {
                    "word": "(cdg) paris charles de gaulle, france, europe [eu27] [w30p] [w30i] [w30c] [hk30p] [hk50p] [hklh] [acf]",
                    "category": "2.1 Airport List"
                }
            ],
            "count": 5034
        },
        {
            "words": [
                {
                    "word": "(cdg) paris charles de gaulle",
                    "category": "2.1.1 Airport List Short"
                }
            ],
            "count": 5035
        },
        {
            "words": [
                {
                    "word": "(dxb) dubai",
                    "category": "2.1.1 Airport List Short"
                }
            ],
            "count": 5091
        },
        {
            "words": [
                {
                    "word": "(dxb) dubai, united arab emirates, middle east [w30p] [w30i] [w30c] [hk10p] [hk30p] [hk50p] [hk30c] [hklh] [acf]",
                    "category": "2.1 Airport List"
                }
            ],
            "count": 5092
        },
        {
            "words": [
                {
                    "word": "american airlines [hkia]",
                    "category": "2.2 Airline List"
                }
            ],
            "count": 5324
        },
        {
            "words": [
                {
                    "word": "(hkg) hong kong",
                    "category": "2.1.1 Airport List Short"
                }
            ],
            "count": 5981
        },
        {
            "words": [
                {
                    "word": "(hkg) hong kong, east asia [gba] [w30p] [w30i] [w30c] [acf]",
                    "category": "2.1 Airport List"
                }
            ],
            "count": 5990
        },
        {
            "words": [
                {
                    "word": "airline collaboration",
                    "category": "1.2 Airlines"
                }
            ],
            "count": 6597
        },
        {
            "words": [
                {
                    "word": "(sin) singapore",
                    "category": "2.1.1 Airport List Short"
                }
            ],
            "count": 6813
        },
        {
            "words": [
                {
                    "word": "(sin) singapore, se asia [asean] [w30p] [w30i] [w30c] [hk10p] [hk30p] [hk50p] [hk30c] [hksh] [acf] [ret]",
                    "category": "2.1 Airport List"
                }
            ],
            "count": 6830
        },
        {
            "words": [
                {
                    "word": "(jfk) new york jfk, united states, north america [w30p] [w30i] [w30c] [hk10p] [hk50p] [hk30c] [hklh] [acf]",
                    "category": "2.1 Airport List"
                }
            ],
            "count": 7297
        },
        {
            "words": [
                {
                    "word": "(jfk) new york jfk",
                    "category": "2.1.1 Airport List Short"
                }
            ],
            "count": 7436
        },
        {
            "words": [
                {
                    "word": "airbus",
                    "category": "3. Top N KeyPhrases"
                }
            ],
            "count": 7474
        },
        {
            "words": [
                {
                    "word": "asia",
                    "category": "3. Top N KeyPhrases"
                }
            ],
            "count": 7479
        },
        {
            "words": [
                {
                    "word": "airport",
                    "category": "3. Top N KeyPhrases"
                }
            ],
            "count": 7883
        },
        {
            "words": [
                {
                    "word": "president",
                    "category": "3. Top N KeyPhrases"
                }
            ],
            "count": 8567
        },
        {
            "words": [
                {
                    "word": "twitter",
                    "category": "3. Top N KeyPhrases"
                }
            ],
            "count": 9292
        },
        {
            "words": [
                {
                    "word": "(lhr) london heathrow",
                    "category": "2.1.1 Airport List Short"
                }
            ],
            "count": 9864
        },
        {
            "words": [
                {
                    "word": "(lhr) london heathrow, united kingdom, europe [w30p] [w30i] [w30c] [hk10p] [hk30p] [hk50p] [hk30c] [hklh] [acf] [ret]",
                    "category": "2.1 Airport List"
                }
            ],
            "count": 9871
        },
        {
            "words": [
                {
                    "word": "china",
                    "category": "3. Top N KeyPhrases"
                }
            ],
            "count": 9975
        },
        {
            "words": [
                {
                    "word": "india",
                    "category": "3. Top N KeyPhrases"
                }
            ],
            "count": 10604
        },
        {
            "words": [
                {
                    "word": "canada, north america",
                    "category": "2.3 Countries"
                }
            ],
            "count": 10702
        },
        {
            "words": [
                {
                    "word": "covid-19",
                    "category": "3. Top N KeyPhrases"
                }
            ],
            "count": 10789
        },
        {
            "words": [
                {
                    "word": "lccs",
                    "category": "1.2 Airlines"
                }
            ],
            "count": 11833
        },
        {
            "words": [
                {
                    "word": "europe",
                    "category": "3. Top N KeyPhrases"
                }
            ],
            "count": 11933
        },
        {
            "words": [
                {
                    "word": "japan, east asia",
                    "category": "2.3 Countries"
                }
            ],
            "count": 12821
        },
        {
            "words": [
                {
                    "word": "germany, europe [eu27]",
                    "category": "2.3 Countries"
                }
            ],
            "count": 12857
        },
        {
            "words": [
                {
                    "word": "australia, australasia",
                    "category": "2.3 Countries"
                }
            ],
            "count": 13331
        },
        {
            "words": [
                {
                    "word": "france, europe [eu27]",
                    "category": "2.3 Countries"
                }
            ],
            "count": 13420
        },
        {
            "words": [
                {
                    "word": "ceo",
                    "category": "3. Top N KeyPhrases"
                }
            ],
            "count": 14564
        },
        {
            "words": [
                {
                    "word": "russia, europe",
                    "category": "2.3 Countries"
                }
            ],
            "count": 15626
        },
        {
            "words": [
                {
                    "word": "india, south asia",
                    "category": "2.3 Countries"
                }
            ],
            "count": 15885
        },
        {
            "words": [
                {
                    "word": "united kingdom, europe",
                    "category": "2.3 Countries"
                }
            ],
            "count": 25902
        },
        {
            "words": [
                {
                    "word": "china, east asia",
                    "category": "2.3 Countries"
                }
            ],
            "count": 39562
        },
        {
            "words": [
                {
                    "word": "fleet",
                    "category": "1.2 Airlines"
                }
            ],
            "count": 47257
        },
        {
            "words": [
                {
                    "word": "united states, north america",
                    "category": "2.3 Countries"
                }
            ],
            "count": 54646
        },
        {
            "words": [
                {
                    "word": "network",
                    "category": "1.2 Airlines"
                }
            ],
            "count": 74013
        }
    ];

    

    useEffect(()=>{
        if (!graphData){
            return;
        }

        // we need to look at the number N and all links
        const nodeSteps = graphData.nodeSteps;
        console.log('nodeSteps: ', nodeSteps);

        // const N = 10;
        // const min = nodeSteps[0].count;
        // const max = nodeSteps [N - 1].count;

        const min = nodeSteps[range.min].count;
        const max = nodeSteps[range.max].count;

        let scale = 1.0;
        let nodeStepsToTake; 
        // need to scale min and max
        if (max > 10000){
            scale = 0.5;
            if (max > 20000){
                scale = 0.1;
            }
            nodeStepsToTake = graphData.nodes.filter(x=> x.value >= min && x.value <= max).map(x=>({...x, symbolSize: scale * x.symbolSize}));
        }
        else{
            nodeStepsToTake = graphData.nodes.filter(x=> x.value >= min && x.value <= max);
        }


        
        console.log('nodeStepsToTake: ', nodeStepsToTake);
        setGraphNodes(nodeStepsToTake);


    }, [graphData, range]);


    const graph = {
        "nodes": [
            {
                "id": "0",
                "name": "Myriel",
                "symbolSize": 19.12381,
                "x": -266.82776,
                "y": 299.6904,
                "value": 28.685715,
                "category": 0
            },
            {
                "id": "1",
                "name": "Napoleon",
                "symbolSize": 2.6666666666666665,
                "x": -418.08344,
                "y": 446.8853,
                "value": 4,
                "category": 0
            },
            {
                "id": "2",
                "name": "MlleBaptistine",
                "symbolSize": 6.323809333333333,
                "x": -212.76357,
                "y": 245.29176,
                "value": 9.485714,
                "category": 1
            },
            {
                "id": "3",
                "name": "MmeMagloire",
                "symbolSize": 6.323809333333333,
                "x": -242.82404,
                "y": 235.26283,
                "value": 9.485714,
                "category": 1
            },
            {
                "id": "4",
                "name": "CountessDeLo",
                "symbolSize": 2.6666666666666665,
                "x": -379.30386,
                "y": 429.06424,
                "value": 4,
                "category": 0
            },
            {
                "id": "5",
                "name": "Geborand",
                "symbolSize": 2.6666666666666665,
                "x": -417.26337,
                "y": 406.03506,
                "value": 4,
                "category": 0
            },
            {
                "id": "6",
                "name": "Champtercier",
                "symbolSize": 2.6666666666666665,
                "x": -332.6012,
                "y": 485.16974,
                "value": 4,
                "category": 0
            },
            {
                "id": "7",
                "name": "Cravatte",
                "symbolSize": 2.6666666666666665,
                "x": -382.69568,
                "y": 475.09113,
                "value": 4,
                "category": 0
            },
            {
                "id": "8",
                "name": "Count",
                "symbolSize": 2.6666666666666665,
                "x": -320.384,
                "y": 387.17325,
                "value": 4,
                "category": 0
            },
            {
                "id": "9",
                "name": "OldMan",
                "symbolSize": 2.6666666666666665,
                "x": -344.39832,
                "y": 451.16772,
                "value": 4,
                "category": 0
            },
            {
                "id": "10",
                "name": "Labarre",
                "symbolSize": 2.6666666666666665,
                "x": -89.34107,
                "y": 234.56128,
                "value": 4,
                "category": 1
            },
            {
                "id": "11",
                "name": "Valjean",
                "symbolSize": 66.66666666666667,
                "x": -87.93029,
                "y": -6.8120565,
                "value": 100,
                "category": 1
            },
            {
                "id": "12",
                "name": "Marguerite",
                "symbolSize": 4.495239333333333,
                "x": -339.77908,
                "y": -184.69139,
                "value": 6.742859,
                "category": 1
            },
            {
                "id": "13",
                "name": "MmeDeR",
                "symbolSize": 2.6666666666666665,
                "x": -194.31313,
                "y": 178.55301,
                "value": 4,
                "category": 1
            },
            {
                "id": "14",
                "name": "Isabeau",
                "symbolSize": 2.6666666666666665,
                "x": -158.05168,
                "y": 201.99768,
                "value": 4,
                "category": 1
            },
            {
                "id": "15",
                "name": "Gervais",
                "symbolSize": 2.6666666666666665,
                "x": -127.701546,
                "y": 242.55057,
                "value": 4,
                "category": 1
            },
            {
                "id": "16",
                "name": "Tholomyes",
                "symbolSize": 17.295237333333333,
                "x": -385.2226,
                "y": -393.5572,
                "value": 25.942856,
                "category": 2
            },
            {
                "id": "17",
                "name": "Listolier",
                "symbolSize": 13.638097333333334,
                "x": -516.55884,
                "y": -393.98975,
                "value": 20.457146,
                "category": 2
            },
            {
                "id": "18",
                "name": "Fameuil",
                "symbolSize": 13.638097333333334,
                "x": -464.79382,
                "y": -493.57944,
                "value": 20.457146,
                "category": 2
            },
            {
                "id": "19",
                "name": "Blacheville",
                "symbolSize": 13.638097333333334,
                "x": -515.1624,
                "y": -456.9891,
                "value": 20.457146,
                "category": 2
            },
            {
                "id": "20",
                "name": "Favourite",
                "symbolSize": 13.638097333333334,
                "x": -408.12122,
                "y": -464.5048,
                "value": 20.457146,
                "category": 2
            },
            {
                "id": "21",
                "name": "Dahlia",
                "symbolSize": 13.638097333333334,
                "x": -456.44113,
                "y": -425.13303,
                "value": 20.457146,
                "category": 2
            },
            {
                "id": "22",
                "name": "Zephine",
                "symbolSize": 13.638097333333334,
                "x": -459.1107,
                "y": -362.5133,
                "value": 20.457146,
                "category": 2
            },
            {
                "id": "23",
                "name": "Fantine",
                "symbolSize": 128.266666666666666,
                "x": -3013.42786,
                "y": -289.44803,
                "value": 42.4,
                "category": 2
            },
            {
                "id": "24",
                "name": "MmeThenardier",
                "symbolSize": 20.95238266666667,
                "x": 4.6313396,
                "y": -273.8517,
                "value": 31.428574,
                "category": 7
            },
            {
                "id": "25",
                "name": "Thenardier",
                "symbolSize": 30.095235333333335,
                "x": 82.80825,
                "y": -203.1144,
                "value": 45.142853,
                "category": 7
            },
            {
                "id": "26",
                "name": "Cosette",
                "symbolSize": 20.95238266666667,
                "x": 78.64646,
                "y": -31.512747,
                "value": 31.428574,
                "category": 6
            },
            {
                "id": "27",
                "name": "Javert",
                "symbolSize": 31.923806666666668,
                "x": -81.46074,
                "y": -204.20204,
                "value": 47.88571,
                "category": 7
            },
            {
                "id": "28",
                "name": "Fauchelevent",
                "symbolSize": 8.152382000000001,
                "x": -225.73984,
                "y": 82.41631,
                "value": 12.228573,
                "category": 4
            },
            {
                "id": "29",
                "name": "Bamatabois",
                "symbolSize": 15.466666666666667,
                "x": -385.6842,
                "y": -20.206686,
                "value": 23.2,
                "category": 3
            },
            {
                "id": "30",
                "name": "Perpetue",
                "symbolSize": 4.495239333333333,
                "x": -403.92447,
                "y": -197.69823,
                "value": 6.742859,
                "category": 2
            },
            {
                "id": "31",
                "name": "Simplice",
                "symbolSize": 8.152382000000001,
                "x": -281.4253,
                "y": -158.45137,
                "value": 12.228573,
                "category": 2
            },
            {
                "id": "32",
                "name": "Scaufflaire",
                "symbolSize": 2.6666666666666665,
                "x": -122.41348,
                "y": 210.37503,
                "value": 4,
                "category": 1
            },
            {
                "id": "33",
                "name": "Woman1",
                "symbolSize": 4.495239333333333,
                "x": -234.6001,
                "y": -113.15067,
                "value": 6.742859,
                "category": 1
            },
            {
                "id": "34",
                "name": "Judge",
                "symbolSize": 11.809524666666666,
                "x": -387.84915,
                "y": 58.7059,
                "value": 17.714287,
                "category": 3
            },
            {
                "id": "35",
                "name": "Champmathieu",
                "symbolSize": 11.809524666666666,
                "x": -338.2307,
                "y": 87.48405,
                "value": 17.714287,
                "category": 3
            },
            {
                "id": "36",
                "name": "Brevet",
                "symbolSize": 11.809524666666666,
                "x": -453.26874,
                "y": 58.94648,
                "value": 17.714287,
                "category": 3
            },
            {
                "id": "37",
                "name": "Chenildieu",
                "symbolSize": 11.809524666666666,
                "x": -386.44904,
                "y": 140.05937,
                "value": 17.714287,
                "category": 3
            },
            {
                "id": "38",
                "name": "Cochepaille",
                "symbolSize": 11.809524666666666,
                "x": -446.7876,
                "y": 123.38005,
                "value": 17.714287,
                "category": 3
            },
            {
                "id": "39",
                "name": "Pontmercy",
                "symbolSize": 6.323809333333333,
                "x": 336.49738,
                "y": -269.55914,
                "value": 9.485714,
                "category": 6
            },
            {
                "id": "40",
                "name": "Boulatruelle",
                "symbolSize": 2.6666666666666665,
                "x": 29.187843,
                "y": -460.13132,
                "value": 4,
                "category": 7
            },
            {
                "id": "41",
                "name": "Eponine",
                "symbolSize": 20.95238266666667,
                "x": 238.36697,
                "y": -210.00926,
                "value": 31.428574,
                "category": 7
            },
            {
                "id": "42",
                "name": "Anzelma",
                "symbolSize": 6.323809333333333,
                "x": 189.69513,
                "y": -346.50662,
                "value": 9.485714,
                "category": 7
            },
            {
                "id": "43",
                "name": "Woman2",
                "symbolSize": 6.323809333333333,
                "x": -187.00418,
                "y": -145.02663,
                "value": 9.485714,
                "category": 6
            },
            {
                "id": "44",
                "name": "MotherInnocent",
                "symbolSize": 4.495239333333333,
                "x": -252.99521,
                "y": 129.87549,
                "value": 6.742859,
                "category": 4
            },
            {
                "id": "45",
                "name": "Gribier",
                "symbolSize": 2.6666666666666665,
                "x": -296.07935,
                "y": 163.11964,
                "value": 4,
                "category": 4
            },
            {
                "id": "46",
                "name": "Jondrette",
                "symbolSize": 2.6666666666666665,
                "x": 550.3201,
                "y": 522.4031,
                "value": 4,
                "category": 5
            },
            {
                "id": "47",
                "name": "MmeBurgon",
                "symbolSize": 4.495239333333333,
                "x": 488.13535,
                "y": 356.8573,
                "value": 6.742859,
                "category": 5
            },
            {
                "id": "48",
                "name": "Gavroche",
                "symbolSize": 41.06667066666667,
                "x": 387.89572,
                "y": 110.462326,
                "value": 61.600006,
                "category": 8
            },
            {
                "id": "49",
                "name": "Gillenormand",
                "symbolSize": 13.638097333333334,
                "x": 126.4831,
                "y": 68.10622,
                "value": 20.457146,
                "category": 6
            },
            {
                "id": "50",
                "name": "Magnon",
                "symbolSize": 4.495239333333333,
                "x": 127.07365,
                "y": -113.05923,
                "value": 6.742859,
                "category": 6
            },
            {
                "id": "51",
                "name": "MlleGillenormand",
                "symbolSize": 13.638097333333334,
                "x": 162.63559,
                "y": 117.6565,
                "value": 20.457146,
                "category": 6
            },
            {
                "id": "52",
                "name": "MmePontmercy",
                "symbolSize": 4.495239333333333,
                "x": 353.66415,
                "y": -205.89165,
                "value": 6.742859,
                "category": 6
            },
            {
                "id": "53",
                "name": "MlleVaubois",
                "symbolSize": 2.6666666666666665,
                "x": 165.43939,
                "y": 339.7736,
                "value": 4,
                "category": 6
            },
            {
                "id": "54",
                "name": "LtGillenormand",
                "symbolSize": 8.152382000000001,
                "x": 137.69348,
                "y": 196.1069,
                "value": 12.228573,
                "category": 6
            },
            {
                "id": "55",
                "name": "Marius",
                "symbolSize": 35.58095333333333,
                "x": 206.44687,
                "y": -13.805411,
                "value": 53.37143,
                "category": 6
            },
            {
                "id": "56",
                "name": "BaronessT",
                "symbolSize": 4.495239333333333,
                "x": 194.82993,
                "y": 224.78036,
                "value": 6.742859,
                "category": 6
            },
            {
                "id": "57",
                "name": "Mabeuf",
                "symbolSize": 20.95238266666667,
                "x": 597.6618,
                "y": 135.18481,
                "value": 31.428574,
                "category": 8
            },
            {
                "id": "58",
                "name": "Enjolras",
                "symbolSize": 28.266666666666666,
                "x": 355.78366,
                "y": -74.882454,
                "value": 42.4,
                "category": 8
            },
            {
                "id": "59",
                "name": "Combeferre",
                "symbolSize": 20.95238266666667,
                "x": 515.2961,
                "y": -46.167564,
                "value": 31.428574,
                "category": 8
            },
            {
                "id": "60",
                "name": "Prouvaire",
                "symbolSize": 17.295237333333333,
                "x": 614.29285,
                "y": -69.3104,
                "value": 25.942856,
                "category": 8
            },
            {
                "id": "61",
                "name": "Feuilly",
                "symbolSize": 20.95238266666667,
                "x": 550.1917,
                "y": -128.17537,
                "value": 31.428574,
                "category": 8
            },
            {
                "id": "62",
                "name": "Courfeyrac",
                "symbolSize": 24.609526666666667,
                "x": 436.17184,
                "y": -12.7286825,
                "value": 36.91429,
                "category": 8
            },
            {
                "id": "63",
                "name": "Bahorel",
                "symbolSize": 22.780953333333333,
                "x": 602.55225,
                "y": 16.421427,
                "value": 34.17143,
                "category": 8
            },
            {
                "id": "64",
                "name": "Bossuet",
                "symbolSize": 24.609526666666667,
                "x": 455.81955,
                "y": -115.45826,
                "value": 36.91429,
                "category": 8
            },
            {
                "id": "65",
                "name": "Joly",
                "symbolSize": 22.780953333333333,
                "x": 516.40784,
                "y": 47.242233,
                "value": 34.17143,
                "category": 8
            },
            {
                "id": "66",
                "name": "Grantaire",
                "symbolSize": 19.12381,
                "x": 646.4313,
                "y": -151.06331,
                "value": 28.685715,
                "category": 8
            },
            {
                "id": "67",
                "name": "MotherPlutarch",
                "symbolSize": 2.6666666666666665,
                "x": 668.9568,
                "y": 204.65488,
                "value": 4,
                "category": 8
            },
            {
                "id": "68",
                "name": "Gueulemer",
                "symbolSize": 19.12381,
                "x": 78.4799,
                "y": -347.15146,
                "value": 28.685715,
                "category": 7
            },
            {
                "id": "69",
                "name": "Babet",
                "symbolSize": 19.12381,
                "x": 150.35959,
                "y": -298.50797,
                "value": 28.685715,
                "category": 7
            },
            {
                "id": "70",
                "name": "Claquesous",
                "symbolSize": 19.12381,
                "x": 137.3717,
                "y": -410.2809,
                "value": 28.685715,
                "category": 7
            },
            {
                "id": "71",
                "name": "Montparnasse",
                "symbolSize": 17.295237333333333,
                "x": 234.87747,
                "y": -400.85983,
                "value": 25.942856,
                "category": 7
            },
            {
                "id": "72",
                "name": "Toussaint",
                "symbolSize": 6.323809333333333,
                "x": 40.942253,
                "y": 113.78272,
                "value": 9.485714,
                "category": 1
            },
            {
                "id": "73",
                "name": "Child1",
                "symbolSize": 4.495239333333333,
                "x": 437.939,
                "y": 291.58234,
                "value": 6.742859,
                "category": 8
            },
            {
                "id": "74",
                "name": "Child2",
                "symbolSize": 4.495239333333333,
                "x": 466.04922,
                "y": 283.3606,
                "value": 6.742859,
                "category": 8
            },
            {
                "id": "75",
                "name": "Brujon",
                "symbolSize": 13.638097333333334,
                "x": 238.79364,
                "y": -314.06345,
                "value": 20.457146,
                "category": 7
            },
            {
                "id": "76",
                "name": "MmeHucheloup",
                "symbolSize": 13.638097333333334,
                "x": 712.18353,
                "y": 4.8131495,
                "value": 20.457146,
                "category": 8
            }
        ],
        "links": [
            {
                "source": "1",
                "target": "0"
            },
            {
                "source": "2",
                "target": "0"
            },
            {
                "source": "3",
                "target": "0"
            },
            {
                "source": "3",
                "target": "2"
            },
            {
                "source": "4",
                "target": "0"
            },
            {
                "source": "5",
                "target": "0"
            },
            {
                "source": "6",
                "target": "0"
            },
            {
                "source": "7",
                "target": "0"
            },
            {
                "source": "8",
                "target": "0"
            },
            {
                "source": "9",
                "target": "0"
            },
            {
                "source": "11",
                "target": "0"
            },
            {
                "source": "11",
                "target": "2"
            },
            {
                "source": "11",
                "target": "3"
            },
            {
                "source": "11",
                "target": "10"
            },
            {
                "source": "12",
                "target": "11"
            },
            {
                "source": "13",
                "target": "11"
            },
            {
                "source": "14",
                "target": "11"
            },
            {
                "source": "15",
                "target": "11"
            },
            {
                "source": "17",
                "target": "16"
            },
            {
                "source": "18",
                "target": "16"
            },
            {
                "source": "18",
                "target": "17"
            },
            {
                "source": "19",
                "target": "16"
            },
            {
                "source": "19",
                "target": "17"
            },
            {
                "source": "19",
                "target": "18"
            },
            {
                "source": "20",
                "target": "16"
            },
            {
                "source": "20",
                "target": "17"
            },
            {
                "source": "20",
                "target": "18"
            },
            {
                "source": "20",
                "target": "19"
            },
            {
                "source": "21",
                "target": "16"
            },
            {
                "source": "21",
                "target": "17"
            },
            {
                "source": "21",
                "target": "18"
            },
            {
                "source": "21",
                "target": "19"
            },
            {
                "source": "21",
                "target": "20"
            },
            {
                "source": "22",
                "target": "16"
            },
            {
                "source": "22",
                "target": "17"
            },
            {
                "source": "22",
                "target": "18"
            },
            {
                "source": "22",
                "target": "19"
            },
            {
                "source": "22",
                "target": "20"
            },
            {
                "source": "22",
                "target": "21"
            },
            {
                "source": "23",
                "target": "11"
            },
            {
                "source": "23",
                "target": "12"
            },
            {
                "source": "23",
                "target": "16"
            },
            {
                "source": "23",
                "target": "17"
            },
            {
                "source": "23",
                "target": "18"
            },
            {
                "source": "23",
                "target": "19"
            },
            {
                "source": "23",
                "target": "20"
            },
            {
                "source": "23",
                "target": "21"
            },
            {
                "source": "23",
                "target": "22"
            },
            {
                "source": "24",
                "target": "11"
            },
            {
                "source": "24",
                "target": "23"
            },
            {
                "source": "25",
                "target": "11"
            },
            {
                "source": "25",
                "target": "23"
            },
            {
                "source": "25",
                "target": "24"
            },
            {
                "source": "26",
                "target": "11"
            },
            {
                "source": "26",
                "target": "16"
            },
            {
                "source": "26",
                "target": "24"
            },
            {
                "source": "26",
                "target": "25"
            },
            {
                "source": "27",
                "target": "11"
            },
            {
                "source": "27",
                "target": "23"
            },
            {
                "source": "27",
                "target": "24"
            },
            {
                "source": "27",
                "target": "25"
            },
            {
                "source": "27",
                "target": "26"
            },
            {
                "source": "28",
                "target": "11"
            },
            {
                "source": "28",
                "target": "27"
            },
            {
                "source": "29",
                "target": "11"
            },
            {
                "source": "29",
                "target": "23"
            },
            {
                "source": "29",
                "target": "27"
            },
            {
                "source": "30",
                "target": "23"
            },
            {
                "source": "31",
                "target": "11"
            },
            {
                "source": "31",
                "target": "23"
            },
            {
                "source": "31",
                "target": "27"
            },
            {
                "source": "31",
                "target": "30"
            },
            {
                "source": "32",
                "target": "11"
            },
            {
                "source": "33",
                "target": "11"
            },
            {
                "source": "33",
                "target": "27"
            },
            {
                "source": "34",
                "target": "11"
            },
            {
                "source": "34",
                "target": "29"
            },
            {
                "source": "35",
                "target": "11"
            },
            {
                "source": "35",
                "target": "29"
            },
            {
                "source": "35",
                "target": "34"
            },
            {
                "source": "36",
                "target": "11"
            },
            {
                "source": "36",
                "target": "29"
            },
            {
                "source": "36",
                "target": "34"
            },
            {
                "source": "36",
                "target": "35"
            },
            {
                "source": "37",
                "target": "11"
            },
            {
                "source": "37",
                "target": "29"
            },
            {
                "source": "37",
                "target": "34"
            },
            {
                "source": "37",
                "target": "35"
            },
            {
                "source": "37",
                "target": "36"
            },
            {
                "source": "38",
                "target": "11"
            },
            {
                "source": "38",
                "target": "29"
            },
            {
                "source": "38",
                "target": "34"
            },
            {
                "source": "38",
                "target": "35"
            },
            {
                "source": "38",
                "target": "36"
            },
            {
                "source": "38",
                "target": "37"
            },
            {
                "source": "39",
                "target": "25"
            },
            {
                "source": "40",
                "target": "25"
            },
            {
                "source": "41",
                "target": "24"
            },
            {
                "source": "41",
                "target": "25"
            },
            {
                "source": "42",
                "target": "24"
            },
            {
                "source": "42",
                "target": "25"
            },
            {
                "source": "42",
                "target": "41"
            },
            {
                "source": "43",
                "target": "11"
            },
            {
                "source": "43",
                "target": "26"
            },
            {
                "source": "43",
                "target": "27"
            },
            {
                "source": "44",
                "target": "11"
            },
            {
                "source": "44",
                "target": "28"
            },
            {
                "source": "45",
                "target": "28"
            },
            {
                "source": "47",
                "target": "46"
            },
            {
                "source": "48",
                "target": "11"
            },
            {
                "source": "48",
                "target": "25"
            },
            {
                "source": "48",
                "target": "27"
            },
            {
                "source": "48",
                "target": "47"
            },
            {
                "source": "49",
                "target": "11"
            },
            {
                "source": "49",
                "target": "26"
            },
            {
                "source": "50",
                "target": "24"
            },
            {
                "source": "50",
                "target": "49"
            },
            {
                "source": "51",
                "target": "11"
            },
            {
                "source": "51",
                "target": "26"
            },
            {
                "source": "51",
                "target": "49"
            },
            {
                "source": "52",
                "target": "39"
            },
            {
                "source": "52",
                "target": "51"
            },
            {
                "source": "53",
                "target": "51"
            },
            {
                "source": "54",
                "target": "26"
            },
            {
                "source": "54",
                "target": "49"
            },
            {
                "source": "54",
                "target": "51"
            },
            {
                "source": "55",
                "target": "11"
            },
            {
                "source": "55",
                "target": "16"
            },
            {
                "source": "55",
                "target": "25"
            },
            {
                "source": "55",
                "target": "26"
            },
            {
                "source": "55",
                "target": "39"
            },
            {
                "source": "55",
                "target": "41"
            },
            {
                "source": "55",
                "target": "48"
            },
            {
                "source": "55",
                "target": "49"
            },
            {
                "source": "55",
                "target": "51"
            },
            {
                "source": "55",
                "target": "54"
            },
            {
                "source": "56",
                "target": "49"
            },
            {
                "source": "56",
                "target": "55"
            },
            {
                "source": "57",
                "target": "41"
            },
            {
                "source": "57",
                "target": "48"
            },
            {
                "source": "57",
                "target": "55"
            },
            {
                "source": "58",
                "target": "11"
            },
            {
                "source": "58",
                "target": "27"
            },
            {
                "source": "58",
                "target": "48"
            },
            {
                "source": "58",
                "target": "55"
            },
            {
                "source": "58",
                "target": "57"
            },
            {
                "source": "59",
                "target": "48"
            },
            {
                "source": "59",
                "target": "55"
            },
            {
                "source": "59",
                "target": "57"
            },
            {
                "source": "59",
                "target": "58"
            },
            {
                "source": "60",
                "target": "48"
            },
            {
                "source": "60",
                "target": "58"
            },
            {
                "source": "60",
                "target": "59"
            },
            {
                "source": "61",
                "target": "48"
            },
            {
                "source": "61",
                "target": "55"
            },
            {
                "source": "61",
                "target": "57"
            },
            {
                "source": "61",
                "target": "58"
            },
            {
                "source": "61",
                "target": "59"
            },
            {
                "source": "61",
                "target": "60"
            },
            {
                "source": "62",
                "target": "41"
            },
            {
                "source": "62",
                "target": "48"
            },
            {
                "source": "62",
                "target": "55"
            },
            {
                "source": "62",
                "target": "57"
            },
            {
                "source": "62",
                "target": "58"
            },
            {
                "source": "62",
                "target": "59"
            },
            {
                "source": "62",
                "target": "60"
            },
            {
                "source": "62",
                "target": "61"
            },
            {
                "source": "63",
                "target": "48"
            },
            {
                "source": "63",
                "target": "55"
            },
            {
                "source": "63",
                "target": "57"
            },
            {
                "source": "63",
                "target": "58"
            },
            {
                "source": "63",
                "target": "59"
            },
            {
                "source": "63",
                "target": "60"
            },
            {
                "source": "63",
                "target": "61"
            },
            {
                "source": "63",
                "target": "62"
            },
            {
                "source": "64",
                "target": "11"
            },
            {
                "source": "64",
                "target": "48"
            },
            {
                "source": "64",
                "target": "55"
            },
            {
                "source": "64",
                "target": "57"
            },
            {
                "source": "64",
                "target": "58"
            },
            {
                "source": "64",
                "target": "59"
            },
            {
                "source": "64",
                "target": "60"
            },
            {
                "source": "64",
                "target": "61"
            },
            {
                "source": "64",
                "target": "62"
            },
            {
                "source": "64",
                "target": "63"
            },
            {
                "source": "65",
                "target": "48"
            },
            {
                "source": "65",
                "target": "55"
            },
            {
                "source": "65",
                "target": "57"
            },
            {
                "source": "65",
                "target": "58"
            },
            {
                "source": "65",
                "target": "59"
            },
            {
                "source": "65",
                "target": "60"
            },
            {
                "source": "65",
                "target": "61"
            },
            {
                "source": "65",
                "target": "62"
            },
            {
                "source": "65",
                "target": "63"
            },
            {
                "source": "65",
                "target": "64"
            },
            {
                "source": "66",
                "target": "48"
            },
            {
                "source": "66",
                "target": "58"
            },
            {
                "source": "66",
                "target": "59"
            },
            {
                "source": "66",
                "target": "60"
            },
            {
                "source": "66",
                "target": "61"
            },
            {
                "source": "66",
                "target": "62"
            },
            {
                "source": "66",
                "target": "63"
            },
            {
                "source": "66",
                "target": "64"
            },
            {
                "source": "66",
                "target": "65"
            },
            {
                "source": "67",
                "target": "57"
            },
            {
                "source": "68",
                "target": "11"
            },
            {
                "source": "68",
                "target": "24"
            },
            {
                "source": "68",
                "target": "25"
            },
            {
                "source": "68",
                "target": "27"
            },
            {
                "source": "68",
                "target": "41"
            },
            {
                "source": "68",
                "target": "48"
            },
            {
                "source": "69",
                "target": "11"
            },
            {
                "source": "69",
                "target": "24"
            },
            {
                "source": "69",
                "target": "25"
            },
            {
                "source": "69",
                "target": "27"
            },
            {
                "source": "69",
                "target": "41"
            },
            {
                "source": "69",
                "target": "48"
            },
            {
                "source": "69",
                "target": "68"
            },
            {
                "source": "70",
                "target": "11"
            },
            {
                "source": "70",
                "target": "24"
            },
            {
                "source": "70",
                "target": "25"
            },
            {
                "source": "70",
                "target": "27"
            },
            {
                "source": "70",
                "target": "41"
            },
            {
                "source": "70",
                "target": "58"
            },
            {
                "source": "70",
                "target": "68"
            },
            {
                "source": "70",
                "target": "69"
            },
            {
                "source": "71",
                "target": "11"
            },
            {
                "source": "71",
                "target": "25"
            },
            {
                "source": "71",
                "target": "27"
            },
            {
                "source": "71",
                "target": "41"
            },
            {
                "source": "71",
                "target": "48"
            },
            {
                "source": "71",
                "target": "68"
            },
            {
                "source": "71",
                "target": "69"
            },
            {
                "source": "71",
                "target": "70"
            },
            {
                "source": "72",
                "target": "11"
            },
            {
                "source": "72",
                "target": "26"
            },
            {
                "source": "72",
                "target": "27"
            },
            {
                "source": "73",
                "target": "48"
            },
            {
                "source": "74",
                "target": "48"
            },
            {
                "source": "74",
                "target": "73"
            },
            {
                "source": "75",
                "target": "25"
            },
            {
                "source": "75",
                "target": "41"
            },
            {
                "source": "75",
                "target": "48"
            },
            {
                "source": "75",
                "target": "68"
            },
            {
                "source": "75",
                "target": "69"
            },
            {
                "source": "75",
                "target": "70"
            },
            {
                "source": "75",
                "target": "71"
            },
            {
                "source": "76",
                "target": "48"
            },
            {
                "source": "76",
                "target": "58"
            },
            {
                "source": "76",
                "target": "62"
            },
            {
                "source": "76",
                "target": "63"
            },
            {
                "source": "76",
                "target": "64"
            },
            {
                "source": "76",
                "target": "65"
            },
            {
                "source": "76",
                "target": "66"
            }
        ],
        "categories": [
            {
                "name": "A"
            },
            {
                "name": "B"
            },
            {
                "name": "C"
            },
            {
                "name": "D"
            },
            {
                "name": "E"
            },
            {
                "name": "F"
            },
            {
                "name": "G"
            },
            {
                "name": "H"
            },
            {
                "name": "I"
            }
        ]
    };



    const onChartClick = params=>{
        debugger;
        console.log(params);
        if (params.dataType == 'node'){
            
            debugger;
        }
    };

    if(!graphData){
        return <>Loading...</>
    }

    const option = {
        tooltip: {},
        legend: [
          {
            data: categories.map(function (a) {
              return a.name;
            })
          }
        ],
        series: [
          {
            // symbolSize: (value, params) => Math.sqrt(value) * 5, 
            name: 'Related words',
            type: 'graph',
            layout: 'force',
            force: {repulsion: 5000},
            //data: graph.nodes,
            data: graphNodes,
            //data: graphData.nodes,
            // links: graphData.links,
            // links: graphData.links.map(x=>({...x, tooltip: {show: true, formatter: `${x.source}>${x.target}: ${x.linkCount}` }})),
            //links: graph.links,
            links: links.map(x=>({...x, tooltip: {show: true, formatter: `${x.source}>${x.target}: ${x.linkCount}` }})),
            categories: categories,
            roam: true,
            label: {
              show: true,
              position: 'right',
              formatter: '{b}'
            },
            labelLayout: {
              hideOverlap: true
            },
            scaleLimit: {
              min: 0.4,
              max: 5
            },
            lineStyle: {
              color: 'source',
              curveness: 0.3
            }
          }
        ]
    };

    const getBackgroundColor = ix=>{
        if (ix >= range.min && ix <= range.max){
            return 'yellow';
        }
    };

    

    const handleMinDragStart = (event, itemData)=>{
        //debugger;
        // event.dataTransfer.setData("text/plain", JSON.stringify({itemData}));
        // event.dataTransfer.effectAllowed = "move"; // Allows moving

        // console.log("Types available:", event.dataTransfer.types); 
        

    };

    const handleDragOver = event=>{

        event.preventDefault();
        // console.log("Available data types onDragOver:", event.dataTransfer.types);

    }

    const handleMaxDragStart = event=>{

    };

    const handleDragStart = (event, draggedIndex)=>{
        setDragData({draggedIndex});
    };

    const handleDoubleClick = (event, ix)=>{
        debugger;
        if(ix > range.max){
            setRange({...range, max: ix});
        }
        else{
            setRange({...range, min: ix})
        }
    };

    const handleDrop = event=>{
        // event.persist();
        
        // event.preventDefault();

        // const data = event.dataTransfer.getData("text/plain");

        // console.log('!!!', data);

        if (!dragData){
            return;
        }

        const {draggedIndex} = dragData; // which index we were dragging

        const id = +event.currentTarget.id.replace('div-ns-', '');

        if (range.min === range.max){
            if (id < range.min){
                setRange({...range, min: id});
            }
            else {
                setRange({...range, max: id});
            }
        }
        else{
            if (range.min === draggedIndex){
                // we dragged the min
                if (id < range.max){
                    setRange({...range, min: id})
                }
                else {
                    setRange({min: range.max, max: id})
                }
            }
            if (range.max === draggedIndex){
                // we dragged the max
                if (id > range.min){
                    setRange({...range, max: id});
                }
                else{
                    setRange({min: id, max: range.min});
                }
            }
        }

        //`div-ns-${ix}`

        // if (type==='min'){
        //     setRange({...range, min: id});
        // }

        // if (type==='max'){
        //     setRange({...range, max: id});
        // }
        
        setDragData(null);
        
    };

    const handleMaxDrop = event=>{

        debugger;
    };

    const getCursortStyle = ix=>{
        if (ix === range.min || ix === range.max){
            return 'col-resize';
        }
    }

    // {ix === range.min ? <div draggable={true} onDrag={e=>handleMinDragStart(e, range.min)} onDragOver={handleDragOver} onDrop={handleMinDrop} style={{textAlign: 'center', cursor: 'col-resize'}}>&#9679;</div> : null}
    // { ix === range.max ? <div draggable={true} onDrag={e=>handleMaxDragStart(e, range.max)} onDragOver={handleDragOver} onDrop={handleMaxDrop} style={{textAlign: 'center', cursor: 'col-resize'}}>&#9679;</div> : null}

    return <>
    <div style={{overflowX: 'auto', whiteSpace: 'nowrap', maxWidth: 'calc(100vw - 80px)', border: '1px solid black', display: 'flex', padding: '20px 0 20px 0'}} 
    
    onDragOver={handleDragOver} 
    >
    <ul style={{display: 'flex', width: '100%'}}> 
        {graphData.nodeSteps.map((ns, ix)=>{
            //return <li key={`nx-${ix}`}>{JSON.stringify(ns)}</li>
            return <li key={`nx-${ix}`} style={{display: 'inline-block'}}>
            <div style={{backgroundColor: getBackgroundColor(ix)}}>
                
                <NodeStep data={ns}/>
            </div>

            <div onDoubleClick={e=>handleDoubleClick(e, ix)} onDrop={handleDrop}  id={`div-ns-${ix}`} draggable={true} onDrag={e=>handleDragStart(e, ix)} style={{textAlign: 'center', cursor: getCursortStyle(ix)}}>
                {ix === range.min || ix === range.max ? <>&#9679;</> : <>&nbsp;</>}
            </div>
                
                </li>
        })}
    </ul>
    </div>
        {/* <ul>
        {graphData.linkSteps.map((s, ix)=>{
            return <li key={ix}>{s}</li>
        })}
        </ul> */}


        <div style={{marginTop: '20px'}}>
        <input type='number' value={linkCountPercent} onChange={e=>setLinkCountPercent(e.target.value)}/>
        </div>


{JSON.stringify(range)}
<br/>
{/* {JSON.stringify(links)} */}

        <ReactECharts option={option} style={{ height: '100vh', width: "100%" }} onEvents={{click: onChartClick}}/>
    </>
};

const NodeStep = props=>{
    const data = props.data;

    return <div style={{marginLeft: '20px'}}>
        {data.count}
        

        {/* <div>
            {data.words.map((nwc, ix)=>{
                return <div key={`nwc-${ix}`}>
                {nwc.category} - {nwc.word}
                </div>
                
            })}
        </div> */}

        
    </div>
}