import global from "../../../styles/globel/style.module.scss";
import { MyArticleContent } from "./MyArticleContent";

// temp wrapper that uses MyArticleContent 
export const MyArticleContentWrapper = props=>{

    const {numberOfLines, document} = props;

    // copied from ResultCard.js
const normalizeContent = contentAsStringOrArray=>{
        if (Array.isArray(contentAsStringOrArray)){
          const result = contentAsStringOrArray.reduce((acc, curr) => acc + "..." + curr + "...<br />", "");
          return result;
        }
        else{
          return contentAsStringOrArray;
        }
    };


    // document here is elastic document. We assume it always have property Content
    const normalizedContent = normalizeContent(document.Content);



    return (<div className={global.articleText}>
    <MyArticleContent
        numberOfLines={numberOfLines} 
        content={normalizedContent}

        full_content_with_highlights={document.full_content_with_highlights}// {props.showFullContent ? (document.full_content_with_highlights || normalizedContent) : null} 
        // document.full_content_with_highlights will be available from the api response if showFullContent is true. If it is null (when highlights affect only other fields, eg keywords), 
        // then full_content_with_highlights defaults to just normal document.content (that logic is moved into MyArticleContent, useMemo)

        scrollToTitle={props.scrollToTitle}

        showFullContent={props.showFullContent}
        documentId={document.DocumentId}
    
        simpleSearchText={props.simpleSearchText}
        advancedSearchRows={props.advancedSearchRows}

        
    />
</div>)
}