import { Responsive, WidthProvider } from "react-grid-layout";
import { ChartSwitcher } from "./ChartSwitcher";
import "./ChartInGridStyles.scss";
import { useMemo, useState } from "react";
import { initial } from "lodash";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

export const ChartsInGridExample = props=>{

    //const charts = props.charts;

    // some chart items received from the API:
    const charts = [{}, {}, {}, {}];


    // breakpoints is an object with different dimentions:
    const breakpoints = { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 320 };

    // cols is an object with column count for different breakpoints:
    //const cols = { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 };

    // it's possible to define differnt number of columns for different screen sizes, but we would take the default 12:
    //const cols = { lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 };


    const cols = { lg: 6, md: 6, sm: 6, xs: 6, xxs: 6 };

    //const cols = { lg: 12, md: 12, sm: 1, xs: 1, xxs: 1 };

    // depending on the screen size we can have different number of items in row (3 - 2 - 1):
    const itemsInRowObject = { lg: 3, md: 2, sm: 1, xs: 1, xxs: 1 };

    // layouts object contains sizing data for different breakpoints (lg, sm etc , which are keys of the breakpoints object).
    // We pass breakpoints and layouts into the grid layout as a starting point. 
    // As we resize the grid, onLayoutChange fires with the current sizing array (i, x, y, w, h) which in theory can be used for saving and loading the dashboard. 
    // It would be also helpful to save the breakpoints object so that the dashboard items can be reloaded correctly into screen of any size.
    // const layouts = {
    //     // lg: [
    //     //   { i: "item1", x: 0, y: 0, w: 2, h: 3 }, // 3 rows high
    //     //   { i: "item2", x: 2, y: 0, w: 4, h: 2 }, // 2 rows high
    //     // ],

    //     lg: [
    //         { i: "item1", x: 0, y: 0, w: 6, h: 3 }, // 3 rows high
    //         { i: "item2", x: 6, y: 0, w: 6, h: 2 }, // 2 rows high
    //         { i: "item3", x: 0, y: 18, w: 6, h: 2 }, // 2 rows high
    //         { i: "item4", x: 6, y: 0, w: 6, h: 2 }, // 2 rows high
    //       ],


    // };

    // TODO: we need the current screen size

    // TODO: we need to calculate the layouts (items) based on the number of charts in the dashboard: 
    
    const itemHeightInRows = 3;
    const itemsInRow = 3; // for large screen. 2 for medium screens and 1 for smaller than medium
    // however, if there is 1 item  - it's full screen, and 2 items - it's 3

    const initialLayouts = useMemo(()=>{
        const layoutItems = [];
        // we need to create layouts object for each item in the sequence (eg if there are 8 items over 4 rows-  we need 8 items in the layouts array)

        if (charts.length == 1){
            layoutItems.push({
                i: 'item1', 
                x: 0, 
                y: 0, 
                w: 12, 
                h: itemHeightInRows
            });
        }
        else if (charts.length == 2){
            
            layoutItems.push({
                i: 'item1', 
                x: 0, 
                y: 0, 
                w: 6, 
                h: itemHeightInRows
            });

            layoutItems.push({
                i: 'item2', 
                x: 6, 
                y: 0, 
                w: 6, 
                h: itemHeightInRows
            });
        } else{
            const step = 12 / itemsInRow; // 4 or 6
            let xPos = 0; 
            let yPos = 0;
            for (let i = 0; i < charts.length; i++){
                
                const item = {
                    i: `item${i+1}`, // key must be in this format
                    x: xPos, //'',  // 0/4/8 or 0/6
                    y: yPos, // needs to be recalculated
                    w: step,
                    h: itemHeightInRows
                };
                xPos = (xPos + step) % 12;
                if (xPos == 0){
                    yPos += 1;
                }

                layoutItems.push(item);
            }
            
        }

    return layoutItems;
    }, [charts.length, itemsInRow, itemHeightInRows]);

    const layouts = {
        lg: initialLayouts, 
        md: initialLayouts, 
        sm: initialLayouts
    };


    // Initial layout must be for the largest screen.
    // So when we load the screen first time, the corresponding layout will be picked. 
    // Eg load the screen with large size, but we only have xs layout - it will be picked. 
    // Later as we change screens - the layout will be selected according to the given breakpoints
    const someLayouts = {
        xs: [{ i: "item1", x: 0, y: 0, w: 6, h: 3 }] // 3 rows high
        , lg: [{ i: "item2", x: 0, y: 0, w: 6, h: 3 }]
    };

    // we want a universal layout for different screen dimensions where we could just change the number of columns 
    const universalLayoutItem = charts.map((item, index)=>{
        // we want a universal layout item where in 
    });





    // const [tracketLayouts, setTrackedLayouts] = useState(layouts.lg);
    const [tracketLayouts, setTrackedLayouts] = useState(initialLayouts);

    const onLayoutChange = (currentLayouts, allLayouts)=>{
        console.log(currentLayouts);
        // debugger;
        // happens when the layout is changing due to dragging tiles or resizing the screen
        const sizingData = currentLayouts.map(item=>{
            const { i, x, y, w, h } = item;
            return { i, x, y, w, h };
        });
        setTrackedLayouts(sizingData);
        
    };

    // Same number of columns to mimic the behaviour of bootstrap.
    // It is possible to split the screen size into diffent number of columns but that's too complicated.
    // This also defines how granular we can move items in the grid (12 columns is more accurate than 6)
    const cols1 = { lg: 6, md:6, sm: 6, xs: 6, xxs: 6 };


    // layout items need to be generated from items: 
    const layouts1 = [
        { i: "item1", x: 0, y: 0, w: 2, h: 3 },
        { i: "item2", x: 2, y: 0, w: 2, h: 3 },
        { i: "item3", x: 4, y: 0, w: 2, h: 3 },
        { i: "item4", x: 0, y: 0, w: 2, h: 3 },
        { i: "item5", x: 2, y: 0, w: 2, h: 3 },
        { i: "item6", x: 4, y: 0, w: 2, h: 3 },
        { i: "item7", x: 0, y: 0, w: 2, h: 3 },
        { i: "item8", x: 2, y: 0, w: 2, h: 3 },
    ];

    const layouts2 = [
        { i: "item1", x: 0, y: 0, w: 3, h: 3 },
        { i: "item2", x: 3, y: 0, w: 3, h: 3 },
        { i: "item3", x: 0, y: 0, w: 3, h: 3 },
        { i: "item4", x: 3, y: 0, w: 3, h: 3 },
        { i: "item5", x: 0, y: 0, w: 3, h: 3 },
        { i: "item6", x: 3, y: 0, w: 3, h: 3 },
        { i: "item7", x: 0, y: 0, w: 3, h: 3 },
        { i: "item8", x: 3, y: 0, w: 3, h: 3 },
    ];

    const layouts3 = [
        { i: "item1", x: 0, y: 0, w: 6, h: 3 },
        { i: "item2", x: 0, y: 0, w: 6, h: 3 },
        { i: "item3", x: 0, y: 0, w: 6, h: 3 },
        { i: "item4", x: 0, y: 0, w: 6, h: 3 },
        { i: "item5", x: 0, y: 0, w: 6, h: 3 },
        { i: "item6", x: 0, y: 0, w: 6, h: 3 },
        { i: "item7", x: 0, y: 0, w: 6, h: 3 },
        { i: "item8", x: 0, y: 0, w: 6, h: 3 },
    ];

    return (
    <ResponsiveReactGridLayout
        onLayoutChange={onLayoutChange}
        //layouts={layouts}
        //layouts={{lg: initialLayouts}}
        layouts={{lg: layouts1, md: layouts2, sm: layouts3}}
        breakpoints={breakpoints}
        cols={cols1}
        rowHeight={100} // Each row is 30px high
        width={1200}
        //onLayoutChange={(layout, allLayouts) => setLayouts(allLayouts)}
    >
        <div key="item1" style={{ background: "lightblue" }}>Item 1</div>
        <div key="item2" style={{ background: "lightgreen" }}>Item 2</div>
        <div key="item3" style={{ background: "yellow" }}>Item 3</div>
        <div key="item4" style={{ background: "red" }}>Item 4</div>
        <div key="item5" style={{ background: "blue" }}>Item 5</div>
        <div key="item6" style={{ background: "green" }}>Item 6</div>
        <div key="item7" style={{ background: "wheat" }}>Item 7</div>
        <div key="item8" style={{ background: "pink" }}>Item 8</div>
    </ResponsiveReactGridLayout>
    );

    return (<>
    <div style={{display: 'flex', border: '2px solid red', height: '600px'}}>
    <ResponsiveReactGridLayout 
    className="layout"
    layouts={layouts}
    rowHeight={120}>
        {charts.map((chart, ix)=>{
            debugger;
            return (<div key={ix} className='grid-item layouts' style={{border: '2px solid blue', height: '400px'}}>
                <div>{chart.name}({chart.chartType})</div>

                <ChartSwitcher chart={chart} />
            </div>)
        })}
    </ResponsiveReactGridLayout>
    </div>
    </>)
}