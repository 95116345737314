import ButtonElement from "Components/ButtonElement";
import TooltipContainer from "Components/Tooltip";
import { useMemo } from "react";

export const ExcludedWordsBanner = props=>{
    // this is the control to show the excluded words 

    const excludedWordsText = useMemo(()=>{
            
        if (!props.excludedWords || !props.excludedWords.length){
            return '';
        }
        if (props.excludedWords.length == 1){
            return props.excludedWords[0];
        }

        let result = props.excludedWords[0];
        for (let i = 1; i < props.excludedWords.length; i++){
            result += ` AND ${props.excludedWords[i]}`;
        }

        return result;
    }, [props.excludedWords]);

    if (!props.excludedWords  || ! props.excludedWords.length){
        return null;
    }

    return (<>
    
    <div style={{display: 'flex', width: '100%'}}>

        <h5>Excluded Words:</h5>
        
        <TooltipContainer title={`Exclude documents that contain these words in ${props.cloudTaxonomy}`}>
            <>
            
            <span>{props.cloudTaxonomy}</span> <b>must not</b> have <span>{excludedWordsText}&nbsp;</span>
            </>
        </TooltipContainer>
        
        <ButtonElement onClick={()=>props.clearExcludedWords()} 
            styleProps={{marginLeft: 'auto', marginRight: '30px', height: '28px'}}
        >Back to Default</ButtonElement> 
    </div>
    
        
    </>);
        
};