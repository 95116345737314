import { APIInstance } from "./apiInstance";

const BASE_URL_USERS = "/api/users";
/**
 *
 * @param {{emailId: string}} param
 */
export const getCurrentUserDetails = ({ emailId }) => {
  const instance = new APIInstance({
    baseURL: BASE_URL_USERS,
  });

  const api = instance.api;

  return api.post("/GetUserbyEmail", { emailid: emailId });
};

// obsolete, api method removed
export const getUsersProfilesPageSize = (payload) => {
  const instance = new APIInstance({
    baseURL: BASE_URL_USERS,
  });

  const api = instance.api;
  return api.post("/GetUserbyPage", payload);
};

export const getUsersPaginated = (payload) => {
  const instance = new APIInstance({
    baseURL: BASE_URL_USERS,
  });

  const api = instance.api;
  return api.get("/GetUsersPaginated", {params: payload});
};


export function updateUser(payload){
  const newInstance = new APIInstance({
    baseURL: BASE_URL_USERS,
  });
  const newApi = newInstance.api;
  return newApi.put('/UpdateUser', payload);
};

export function createUser(payload){
  const newInstance = new APIInstance({
    baseURL: BASE_URL_USERS,
  });
  const newApi = newInstance.api;
  return newApi.post('/CreateUser', payload);
};

export function deleteUser(id){
  const newInstance = new APIInstance({
    baseURL: BASE_URL_USERS,
  });
  const newApi = newInstance.api;
  return newApi.delete('/DeleteUser', { params: {id} });
};
