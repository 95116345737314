import React from 'react';
import SimpleBar from 'simplebar-react';
import PropTypes from 'prop-types';
import 'simplebar/dist/simplebar.min.css';
import './index.scss';
const CustomScrollbar = (props) => {
  const xProps = {...props};
  delete xProps.scrollRef;
  return (
    <div className='simplebar-block' style={{ height: '100%'}}>
      <SimpleBar {...xProps} ref={props.scrollRef}>{props.children}</SimpleBar>
    </div>
  );
};
CustomScrollbar.propTypes = {
  className: PropTypes.string,
  children: PropTypes.element,
};
export default CustomScrollbar;
