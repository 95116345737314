import { useEffect, useRef, useState } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import { ChartSwitcher } from "Routes/Dashboard/ChartSwitcher";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

// not used, because it's a duplicate of ChartsInGridExample2
export const DashboardPreview2 = props=>{
    debugger;
    const gridRef = useRef(null);

    const [gridWidth, setGridWidth] = useState(0); // this is the width of the client rect with dragResize control. 
    useEffect(()=>{
        const gridRefTracker = ()=>{
            // debugger
            if (gridRef.current){
                setGridWidth(gridRef.current.offsetWidth);
                //setWindowWidth(document.documentElement.clientWidth);
                // setWindowWidth(window.innerWidth); // this is the width of the browser window (not the div that contains the drag control). Then breakpoints apply to the browser window size
            }
        };

        window.addEventListener("resize", gridRefTracker);
        gridRefTracker();
        return ()=>window.removeEventListener("resize", gridRefTracker);
    }, []);


    const charts = props.charts; 
    const layout = props.layout;
    const {allLayouts, breakpoints} = layout;
    
    // TODO: componentize the grid
    const cols = { lg: 6, md: 6, sm: 6};

    const [layouts, setLayouts] = useState({});
    useEffect(()=>{
        debugger;
        console.log('allLayouts', allLayouts);
        setLayouts(allLayouts);
    }, [allLayouts, charts]);


    const x = {
        "lg": [
            {
                "i": "item1",
                "x": 0,
                "y": 0,
                "w": 5,
                "h": 3
            },
            {
                "i": "item2",
                "x": 0,
                "y": 3,
                "w": 3,
                "h": 3
            }
        ],
        "md": [
            {
                "i": "item1",
                "x": 0,
                "y": 0,
                "w": 3,
                "h": 3
            },
            {
                "i": "item2",
                "x": 0,
                "y": 0,
                "w": 3,
                "h": 3
            }
        ],
        "sm": [
            {
                "i": "item1",
                "x": 0,
                "y": 0,
                "w": 6,
                "h": 3
            },
            {
                "i": "item2",
                "x": 0,
                "y": 0,
                "w": 6,
                "h": 3
            }
        ]
    };
    
    return (<>


    == Layouts: {JSON.stringify(allLayouts)}
    <br/>
    
    == breakpoints: {JSON.stringify(breakpoints)}
    <br/>
    <div ref={props.previewRef}>
    
    <h3>{props.name}</h3>
       <div id='d' ref={gridRef}  style={{border: '2px solid red'
            // , width: '50%'
            }}>
            <ResponsiveReactGridLayout 
                isDraggable={false}
                isResizable={false}

                //layouts={layouts}
                //layouts={{lg: initialLayouts}}
                //layouts={initialLayouts}
                layouts={x}
                breakpoints={breakpoints}
                cols={cols}
                margin={[20, 20]}
                rowHeight={100} // Each row is 30px high
                width={1200}
            >
                {charts.map((chart, ix)=>{
                    return (
                    
                    <div key={`item${ix+1}`} style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                        {/* =={ix+1}== */}
                        
                        <div><b>{chart.name}</b> ({chart.chartType})</div>
                        {/* TODO: need the [X close] button that would remove the chart from the dashboard */}
                        <div style={{transform: 'scale(1)', transformOrigin: 'center', width: '100%', height: '100%'}}>
                            <ChartSwitcher chart={chart} />
                        </div>
                    </div>)
                })}
            </ResponsiveReactGridLayout>
        </div>
    </div></>);
};