import { SelectElement } from "Components/SelectElement";
import style from "./style.module.scss";
import { DATE_SETTINGS_LABEL, FILTER_CONSTANTS, HIDE_RESULTS_WITH_ZERO_COUNT } from "Constants/Settings/Settings.constants";
import { Checkbox } from "antd";
import { notify } from "Components/Notification";
import { useState } from "react";
import ButtonElement from "Components/ButtonElement";
import { LinesCountSettings2 } from "./LinesCountSettings2";

export const SettingsScreen = props=>{
    // to replace SystemSettings, template.jsx


    const [dateFormat, setDateFormat] = useState(props.dateFormat);
    const [sortOrder, setSortOrder] = useState(props.sortOrder);
    const [hideResultsZeroCount, setHideResultsZeroCount] = useState(props.hideResultsZeroCount);

    const [disabled, setDisabled] = useState(false);

    const handleUpdate = ()=>{
        setDisabled(true);
        props.updatePreferenceSettings({dateFormat, sortOrder, hideResultsZeroCount}, 
        (success)=>{
            if (success){
                notify.success('Settings updated', true);
            }
            else{
                notify.error('Settings update failed', true);
            }
            setDisabled(false);
        });
    };

    return (
        <div className={style.preferencesWrapper}>
            <div className={style.preferenceSettings}>
                <ul className={style.preferenceSettings__list}>
                    <li>
                        <label> Select Date Format:</label>
                        <SelectElement
                            value={dateFormat}
                            onChange={(value)=>setDateFormat(value)}
                            optionList={FILTER_CONSTANTS.DATE_FORMATS}
                        />
                    </li>
                    <li>
                        <label>{DATE_SETTINGS_LABEL}</label>
                        <SelectElement
                            value={sortOrder}
                            onChange={value=>setSortOrder(value)}
                            optionList={FILTER_CONSTANTS.SORT_ORDER}
                        />
                    </li>
                    <li>
                        <Checkbox
                            checked={hideResultsZeroCount}
                            // defaultChecked={true}
                            onChange={e=>setHideResultsZeroCount(e.target.checked)}
                        >
                            {HIDE_RESULTS_WITH_ZERO_COUNT}
                        </Checkbox>
                    </li>
                    <li>
                        <ButtonElement
                            type={"primary"}
                            onClick={handleUpdate}
                            // loading={this.props.preferenceSettingLoading}
                            disabled={disabled}
                        >Update</ButtonElement>
                    </li>
                </ul>
    
                <LinesCountSettings2 
                    projectId={props.projectId} 
                    viewLineCountSettings={props.viewLineCountSettings}
                    updateUserLinecountSettings={props.updateUserLinecountSettings}
                />
            </div>
        </div>
    );

};