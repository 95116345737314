import ConfirmationMessage from "Components/ConfirmationMessage";
import ModalWindow from "Components/ModalWindow";
import { useEffect, useState } from "react";

export const DeleteUserModal2 = props=>{
    const [user, setUser] = useState({});
    const [errors, setErrors] = useState([]);

    useEffect(()=>{
        debugger;
        setUser(props.user);
    }, [props.user]);

    useEffect(()=>{
        debugger;
        setErrors(props.errors);
    }, [props.errors]);
    
    return (<ModalWindow 
        open={true} 
        isNotClosable={true}
        title={false}
        footer={false}
        onCancel={props.onCancel}
    >
         <ConfirmationMessage
            cancelButtonLabel="Yes"
            confirmButtonLabel="No"
            loading={props.isLoading}
            heading={"Confirm Delete"}
            message={`Are you sure you want to delete this user '${user.userName}' ?`}
            onOk={() => {
                props.onCancel();
            }}
            onCancel={() => {
                props.onConfirm(user);
            }}
        />

        {errors.length ? <div>
            <ul>
                {errors.map((err, ixErr)=>{
                    return <li key={`error-${ixErr}`}>{err.error}</li>
                })}
            </ul>
        </div> : null}

    </ModalWindow>)
};
