import React from "react";
import ResizeDrag from "../../../../Components/ResizeDrag";
import EmptyState from "../../../../Components/EmptyState";
import SaveDashboardModal from "./Children/SaveDashboardModal";
import Loader from "../../../../Components/Loader";
import style from "./style.module.scss";
import ShowModalwindowAdd from "../../showModalWindowAdd";
import { DashboardInfoTable } from "../DashboardInfoTable";
function template() {
  return (
    <div className={style.dashboardBlock}>
      {this.state.dataLoading ? (
        <Loader
          hasWrapper={true}
          customHeight={"608px"}
          classList={["sm blue"]}
        />
      ) : (
        <>
          {!this.props.importedInsights?.importError && (
            <ul className={style.dashboardBlock__header}>
              <li>
                {this.state.data.length > 0 && (
                  <DashboardsaveInput {...this} disabled={true} />
                )}
              </li>
              {this.state.showAddModal &&
                this.state?.fullInsightData?.length > 0 && (
                  <ShowModalwindowAdd {...this} />
                )}
              {this.state.saveModalWindow && <SaveDashboardModal {...this} />}
            </ul>
          )}
          {this.state.data?.length > 0 || this.state.layout?.length > 0 ? (
            <>
              <div
                className={style.dashboardBlock__content}
                id="dashboard-block"
              >
                <ResizeDrag
                  data={this.state.data}
                  chartNames={this.state.chartNames}
                  config={this.state.config}
                  labels={this.state.labels}
                  removeItem={this.removeItem}
                  handleEditInsight={this.handleEditInsight}
                  isView={this.state.isView}
                  isEdit={this.state.isEdit}
                  onLayoutChange={this.onLayoutChange}
                  layout={this.state.layout}
                  items={this.state.items}
                />
                <DashboardInfoTable
                  history={this.props.history}
                  insightsData={this.state.selectedInsights}
                  allFields={this.props.allFields}
                  loadFilterFields={this.props.loadFilterFields}
                  loading={this.props.allFieldLoading}
                  allFieldsDataType={this.props.allFieldsWithMetadata}
                />
              </div>
            </>
          ) : this.props.importedInsights?.importError ? (
            <EmptyState content={"ERROR"} type={"errorImage"} />
          ) : (
            <EmptyState
              type="dashboardWelcomeImage"
              showButton={false}
              handleClick={this.buildInsightsRoute}
              content={"DASHBOARD_EMPTY_STATE_TAB"}
            />
          )}
        </>
      )}
    </div>
  );
}

export default template;

function DashboardsaveInput(props) {
  return (
    <>
      <div className={style.dashbordChartinput} disabled={props.disabled}>
        {props.state.dashboardName}
      </div>
    </>
  );
}
