import { TextInput } from "Components/TextInput";
import style from "./style.module.scss";
import SVGIcons from "Components/SVGIcons";
import TooltipContainer from "Components/Tooltip";
import { SEARCH_INFO } from "constants"; // TODO: remove dependency from constants
import { useEffect, useState } from "react";

export const SimpleSearchControl = props=>{
// this is the control that just renders a textbox that initiates a search by hitting "enter"
// it also has a tooltip with information how this search works

const runSimpleSearch = ()=>{

    const trimmedText = searchText.trim();
    
    props.runSimpleSearch(trimmedText);
    
};


const [searchText, setSearchText] = useState('');

useEffect(()=>{
    setSearchText(props.simpleSearchText);
}, [props.simpleSearchText]);



const className = props.className; // Q: why does it need to take className as parameter? 

return (
    <div className={`${style.mainSearch} ${className}`}>
        <div className={style.mainSearch__input}>
            <TextInput
                allowClear={true}
                className={style.inputElement}
                //placeholder={searchKey || HEADER_CONSTANTS.SEARCH_PLACEHOLDER}
                onPressEnter={() => {
                    runSimpleSearch()
                    //onSearchBarEnter(props, { history, searchString });
                }}
                onChange={e=>{
                    setSearchText(e.value);
                    if (!e.value){
                        props.runSimpleSearch('');
                    }
                }}
                value={searchText}
                htmlType={""}
            />
            <span className={style.serachIcon}>
                <SVGIcons type="SVG-lens" />
            </span>
        </div>
        <TooltipContainer
            title={SEARCH_INFO.CONTENT}
            placement="bottom"
            overlayStyle={{width:'auto'}}
            overlayInnerStyle={SEARCH_INFO.STYLE}
        >
            <span className={style.searchInfo}>
            <SVGIcons type="SVG-infoIcon" />
            </span>
        </TooltipContainer>
    </div>
  );

};