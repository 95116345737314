import parse from "html-react-parser";
import global from "../../../styles/globel/style.module.scss";
import style from "./style.module.scss";
import SVGIcons from "Components/SVGIcons";

export const ArticleUserTagsBlock = props=>{

    if (!props.tags || !props.tags.length){
        return null;
    }
    
    return (<div className={global.tagsBlock}>
        <ul className={global.tagList}>
    
    {props.tags.map((item, ix)=>{
        return (<li key={`user-tag-${ix}`}>
            <div className={style.userTagElement}>
                <span>
                    <SVGIcons type="SVG-tags" />
                </span>
                <span>{parse(item)}</span>
            </div>
        </li>);
    })}
    </ul></div>);

   
}