import { useEffect, useState } from "react";
import global from "../../styles/globel/style.module.scss";
import style from "./style.module.scss";
import PageHeading from "Components/PageHeading";
import TableElement from "Components/TableElement";
import { SAVED_SEARCHES_CONFIG } from "./SavedSearches.config";
import { deleteSavedQuery, getSavedQueriesPaginated } from "api/search";
import Pagination from "Components/Pagination";
import ShowModalwindowDelete from "Routes/Dashboard/ShowModalWindowDelete";

export const SavedQueriesModalContent = props=>{

    const [savedQueries, setSavedQueries] = useState([]);
    
    const [totalCount, setTotalCount] = useState(0);

    const [pageSize, setPageSize] = useState(5);
    const [pageNumber, setPageNumber] = useState(1);

    const loadData = (pageNumber, pageSize, projectId)=>{
        getSavedQueriesPaginated({pageNumber, pageSize, projectId}).then(res=>{
            debugger;

            setSavedQueries(res.data.items); 
            setTotalCount(res.data.totalCount); 
        });
    };

    
    useEffect(()=>{
        loadData(pageNumber, pageSize, props.projectId);
    }, []);
    
    
    const onPaginationChange = (newPageNumber, newPageSize)=>{
        debugger;
        setPageSize(newPageSize);
        setPageNumber(newPageNumber);

        loadData(newPageNumber, newPageSize, props.projectId);
    };

    const [showDeleteSavedQueryConfirmationDialog, setShowDeleteSavedQueryConfirmationDialog] = useState(null); // another modal dialog that confirms the deleetion of a saved query


    const doDeleteQuery = ()=>{
        // user clicked to confirm the deletion of a query, the query object is in showDeleteSavedQueryConfirmationDialog state
        // we cannot delete a query that was marked as default so it's okay to run the delete here
        debugger;
        const queryId = showDeleteSavedQueryConfirmationDialog.id;
        deleteSavedQuery({id: queryId}).then(res=>{
            debugger;
            console.log(res);
            // and reload the data: 
            loadData(pageNumber, pageSize, props.projectId);
            setShowDeleteSavedQueryConfirmationDialog(null); // it's okay...
        });
    };

    const doExport = record=>{
        // this is to replace HomeHeader.exportHandler

        const metaData = record.savedResult;
        const parsed = JSON.parse(metaData); // TODO: if you want to manipulate the output
        const element = document.createElement("a");
        const file = new Blob([metaData], { type: "text/plain" });
        element.href = URL.createObjectURL(file);
        element.download = "Saved Query " + record.searchKeyword + ".txt";
        document.body.appendChild(element);
        element.click();
        element.remove();
    };

    return (<div className={style.savedSearch}>
        {/* this one is for confirmation: */}
        {/* <ShowModalwindow {...props} /> */}

        {showDeleteSavedQueryConfirmationDialog ? <ShowModalwindowDelete 
            state={{showDeleteModal: true}} 
            deleteModalHandler={()=>setShowDeleteSavedQueryConfirmationDialog(null)} 
            deleteModalHandlerYes={doDeleteQuery} /> : null}


        <PageHeading text={"Saved Searches"}>
            <div className={global.searchresultCount}>
                <span> {savedQueries.length > 0 ? `Showing ${savedQueries.length} out of ${totalCount} results` : ''}</span>
            </div>
        </PageHeading>

        {/* TODO: check how ContentLoader is used here, see savedSearches/index.js */}
        
        <div className={style.savedSearch__tbldata}>
            <TableElement
                rowKey='id'
                columns={SAVED_SEARCHES_CONFIG.COLUMNS({
                    setAsGroupDefaultQuery: props.setAsGroupDefaultQuery, 
                    defaultQueryId: props.defaultQueryId,
                    userProfile: {
                        isGroupAdmin: props.isGroupAdmin,
                        userProfile: props.userProfileId,
                        emailid: props.userEmail
                    }, 
                    deleteHandler: (record)=>setShowDeleteSavedQueryConfirmationDialog(record),
                    loadHandler: props.handleLoadQuery, 
                    exportHandler: doExport,
                })}
                data={savedQueries}
                //  onChange={props.handleChange}
                scrollY={400}
            />
            {totalCount > 5 ? <Pagination
                pageSize={pageSize}
                current={pageNumber}
                total={totalCount}
                onChange={onPaginationChange}
                showSizeChanger={true}
                pageSizeOptions={[5, 10, 20, 50]}
            /> : null}
            
            
           
        </div>
        
        {/* {props.state.exportLoader && <LoaderModal />} */}
        
    </div>);
};