import AlertElement from "Components/AlertElement";
import SVGIcons from "Components/SVGIcons";
import style from "../../Routes/Home/components/SearchContent/style.module.scss";
import { useMemo } from "react";

export const AdvancedSearchBanner = props=>{
    
    const convertAdvancedSearchRowsToString = (advancedSearchRows)=>{
        
        let asrString = '';
        const singleGroup = advancedSearchRows.length ===1;
        for (let i = 0; i < advancedSearchRows.length; i++){
            const group = advancedSearchRows[i];

            if (i > 0){
                asrString += group.isOr ? ' OR ' : ' AND ';
            }
            
            if (!singleGroup){
                asrString += '(';
            }
            
            
            const singleRow = group.rows.length === 1;
            for (let j = 0; j < group.rows.length; j++){
                const row = group.rows[j];
                if (j > 0){
                    asrString += row.isOr ? ' OR ' : ' AND ';
                }
                if (!singleRow){
                    asrString += '(';
                }
                
                const dateFilterObject = row.dateFilterObject;
                if (dateFilterObject){
                    const mode = dateFilterObject.mode;
                    asrString += `${row.taxonomyName} `;
                    if (mode == 'last-n-days'){
                        asrString += `since last ${dateFilterObject.numberOfDays} days`;
                    }
                    if (mode == 'range'){
                        const dates = dateFilterObject.dates;
                        asrString += `from ${dates.from} to ${dates.to}`;
                    }
                    if (mode == 'from-date-in-past'){
                        const dates = dateFilterObject.dates;
                        asrString += `between ${dates.from} and now`;
                    }
                }
                else{
                    let filterOptionType = row.filterOptionType;
                    if (!row.taxonomyName && filterOptionType != 'None'){
                        filterOptionType = '';
                    }
                    else if (row.filterOptionType == 'None'){
                        filterOptionType = 'No';
                    }
                    if (filterOptionType){
                        filterOptionType += ' ';
                    }
                    asrString += filterOptionType
                    let value = row.taxonomyValues;
                    if (!value || !value.length){
                        value = row.taxonomyValue;
                    }
                    else {
                        value = JSON.stringify(value).replace('\"', '"'); // array to string 
                    }
                    asrString = `${asrString}${value} in ${row.taxonomyName || 'whole document'}`; // taxonomyName is empty for basic search 
                }
                if (!singleRow){
                    asrString += ')';
                }
            }
            if (!singleGroup){
                asrString += ')';
            }
            
        }
        return asrString;
    };

    const text = useMemo(()=>{
        return convertAdvancedSearchRowsToString(props.advancedSearchRows);
    }, [props.advancedSearchRows]);

    if (!text){
        return null;
    }

    return (<>
    <div className={style.advancesearchQuery}>
        <div className={style.advancesearchQuery__item}>
            <AlertElement information={true} icon={"SVG-infoIcon"}>
                {text}
            </AlertElement>
            
        </div>

        <a className={style.advancesearchQuery__button} onClick={() => props.clearAdvancedSearch()}>
            <span>
                <SVGIcons type={"SVG-closeIcon"} />
            </span>
        </a>
    </div></>);
};