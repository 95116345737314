import TooltipContainer from "Components/Tooltip";
import { SelectionIconSection } from "../SearchControls/SelectionIconSection";
import style from "./style.module.scss";
import ButtonElement from "Components/ButtonElement";
import { notify } from "Components/Notification";

export const SelectionAndTaggingButtons = props=>{

    const selectAllInPage = ()=>{
        const newSelectedIds = props.markedDocumentIds.filter(x=>!props.documentsInPage.items.find(doc=>x == doc.DocumentId));
        props.documentsInPage.items.forEach(doc=>{
            const wasMarked = props.markedDocumentIds.find(m=>m == doc.DocumentId);
            if (!wasMarked){
                newSelectedIds.push(doc.DocumentId);
            }
        });

        props.setMarkedDocumentIds(newSelectedIds);
        notify.success(`${newSelectedIds.length || "No"} article(s) selected`, true);
    };

    const clearSelected = ()=>{
        props.setMarkedDocumentIds([]);
        notify.success('No article(s) selected', true);
    };
    
    
    const tooltipTagSelected = (props.markedDocumentIds.length === 0) && (props.selectedTags?.length === 0) ? "Please select some article and some tags first" :
    (props.markedDocumentIds?.length === 0 ? "Please select some articles" :
        (props.selectedTags?.length === 0 ? "Please select some tags if you want to copy them" : ""));

    return (<div className={style.selectionSection}>
        <div className={style.selectionIcons}>
            <SelectionIconSection
                selectAllInPage={selectAllInPage}
                clearSelected={clearSelected}
                isSearchLoading={false}
            >
            </SelectionIconSection>
        </div>
        <TooltipContainer title={tooltipTagSelected} placement="bottom">
            <div className={style.selectionButtons}>
                <ButtonElement size="small" onClick={props.tagSelected} disabled={false}>
                    Tag Selected
                </ButtonElement>
            </div>
        </TooltipContainer>
    </div>);
}