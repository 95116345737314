import LoadResults from "./HomeHeader/children/LoadResults";
import { SaveResultsButton } from "./HomeHeader/children/SaveResultsButton";
import style from "./style.module.scss";

export const TwoButtonsControl = props=>{
return (<ul className={style.controlsElement__list}>
    <li>
        <div className={style.controlsItem}>
            {/* handleSave shows a modal window */}
            <SaveResultsButton title='Save this Insight' handleSavedClicked={props.handleSavedClicked} 
            />
      </div>
    </li>
    <li>
        <div className={style.controlsItem}>
            <LoadResults toolTipTitle={"Saved Insights"} handleLoad={(value) => props.handleLoadClicked(value)} />
        </div>
    </li>
    
</ul>);
};