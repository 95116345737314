import ButtonElement from "Components/ButtonElement";
import { AdvancedSearchRowControl } from "./AdvancedSearchRowControl";
import ToggleSwitch from "Components/ToggleSwitch";
import style from './children/style.module.scss';
import { getGroupErrors } from "./AdvancedSearchValidator";
import { useMemo } from "react";

export const AdvancedSearchGroupControl = props=>{
    const group = props.group;

    const handleGroupIsOrChange = value=>{

        const newGroup = {...props.group, isOr: value};

        props.handleGroupChanged(newGroup);
    };

    const groupErrors = useMemo(()=>{
        const result = getGroupErrors(props.validationErrors, props.group.groupId);
        return result;
    }, [props.validationErrors, props.group.groupId, getGroupErrors]);

    return <div style={{position: 'relative', minWidth: '900px', margin: '8px', backgroundColor: 'rgba(204, 204, 204, 0.3)', border: 'none', boxShadow: '4px 4px 6px rgba(0, 0, 0, 0.3)',  borderRadius: '8px', padding: '8px'}}>

        <div className={style.filterContention} hidden={props.index === 0 ? true : false} style={{position: 'absolute', right: '400px', zIndex: 2}}>

            <span className={style['and-or-marker']}>And</span>
            <span>
                <ToggleSwitch size='small' 
                    handleChange={(e) => handleGroupIsOrChange(e)} 
                    value={group.isOr} 
                />
            </span>
            <span className={style['and-or-marker']}>Or</span>
        </div>

        {/* ====={JSON.stringify(group.rows)}==== */}
        
        {group.rows.map((row, ix)=>{
            return <div style={{display: 'flex', position: 'relative'}} key={`group-control-${group.groupId}-row-control-${row.rowId}`}>
                        
                {/* GroupID: {JSON.stringify(group.groupId)} */}

                <div style={{marginRight: '200px', width: '100%'}}>

                    <AdvancedSearchRowControl key={`search-row-${ix}`} row={row} group={group} 

                        index={ix} 
                        projectId={props.projectId}
                        rowCountInGroup={group.rows.length}
                        addAdvancedSearchRow={props.addAdvancedSearchRow} 
                        deleteAdvancedSearchRow={()=>props.deleteAdvancedSearchRow(group, row)} 
                        allTaxonomies={props.allTaxonomies}
                        handleRowChanged={props.handleRowChanged}
                        validationErrors={props.validationErrors}
                    />

                    {groupErrors.length ? <div>
                        <ul>
                            {groupErrors.map((e, ix)=>{
                                return <li key={`group-error-${ix}`}>{e.message}</li>
                            })}
                        </ul>
                    </div> : null}
                </div>
            
            </div>
        })}

        <div style={{position: 'absolute', right: 0, top: 0, width: '200px', display: 'flex', height: '100%'}}>
            <div style={{margin: 'auto', display: 'flex'}}>
                <ButtonElement onClick={()=>{ props.addAdvancedSearchGroup(); }} styleProps={{margin: '4px'}}> + Group</ButtonElement>
                <ButtonElement onClick={()=>{ props.deleteAdvancedSearchGroup(group); }} styleProps={{margin: '4px', visibility: props.removeGroupButtonVisible ? 'visible' : 'hidden' }}>- Group</ButtonElement>
            </div>
        </div>
    </div>
};