import { SelectElement } from "Components/SelectElement";
import global from "../../styles/globel/style.module.scss";
import { useMemo } from "react";

export const ProjectSelector = props=>{

    const optionList = useMemo(()=>{
        const result = props.projectList.map(x=>({value: x.id, label: x.name}));
        return result;
    }, [props.projectList]);

    return (<>
        <div className={global.headerSelect}>
                        
            <SelectElement 
                showSearch={false}
                onSearch={undefined}
                disabled={false}
                optionList={optionList}
                placeholder="Select project"
                value={props.currentProjectId}
                onChange={props.handleProjectChange}
                loading={false}
            />
        </div>
    </>);
};