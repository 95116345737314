import ButtonElement from "Components/ButtonElement";
import InputField from "Components/InputField";
import TooltipContainer from "Components/Tooltip";
import { useEffect, useMemo, useState } from "react";
import style from './children/style.module.scss';
import asStyle from './style-advanced-control.module.scss';
import { loadTaxonomyCounts } from "api/search";
import { SearchAcrossConcepts3 } from "Components/SearchAcrossConcepts/SearchAcrossConcepts3";
import SelectOptions from "./children/SelectOptions";
import ToggleSwitch from "Components/ToggleSwitch";
import global from "styles/globel/style.module.scss";
import { AdvancedSearchRowControlDates } from "./AdvancedSearchRowControlDates";
import { getGroupRowErrors } from "./AdvancedSearchValidator";


// these options only make sense if values are selected from taxonomy.
// For basic search the value is treated as query_string (can be an expression), so these options are ignored.
// The only meaningful option for basic search is None. So we show Any by default; for basic search we only have Any/None 
// and for Taxonomy search we have Any All Exact None

const OPTIONS_FOR_TAXONOMY_SEARCH = [
    {value: 'Any', label: 'Get any of these partially matched'}, // look for the any word in the selected value, eg when Airline Competition is selected, it looks for Airline or Competition
    {value: 'All', label: 'Get all these matched'}, // look for the whole phrase of the taxonomy value, eg Airline Competition will not pick up Airline or Competition separately. When multiple values are selected, eg "Airline Competition" AND "Airline Collaboration", both these values should be matched as a whole phrase.
    {value: 'Exact', label: 'Get any of these fully matched'}, // look for exact full match (same as All - phrase_query), so it's identitical with All when a single value is selected. However, when multiple values are selected, they are joined by OR, not by AND. Difference with 1 is that Airline Competition will match the whole phrase, not airline or comptetition
    {value: 'None', label: 'Get none of these matched'} // None of these matched 
];

const OPTONS_FOR_FREE_SEARCH = [
    {value: 'Any', label: 'Get any of these matched'},
    {value: 'None', label: 'Get none of these matched'}
];


export const AdvancedSearchRowControl = props=>{

    const [selectedTaxonomy, setSelectedTaxonomy] = useState('');
    const [taxonomyPickerSetTaxonomyObject, setTaxonomyPickerSetTaxonomyObject] = useState(null);
    const [selectedTaxonomyValues, setSelectedTaxonomyValues] = useState([]);
    const [selectedSingleTaxonomyValue, setSelectedSingleTaxonomyValue] = useState('');
    
    const [filterOptionType, setFilterOptionType] = useState('Any');

    const [allTaxonomyValues, setAllTaxonomyValues] = useState({}); 

    const [isOr, setIsOr] = useState(false);

    const [showValidation, setShowValidation] = useState(true);

    const rowErrors = useMemo(()=>{
        if (!showValidation){
            return [];
        }
        const result = getGroupRowErrors(props.validationErrors, props.group.groupId, props.row.rowId);
        return result;
    }, [props.validationErrors, props.group.groupId, props.row.rowId, getGroupRowErrors, showValidation]);


    useEffect(()=>{
        if (props.validationErrors &&  props.validationErrors.length){
            setShowValidation(true);
        }
        else {
            setShowValidation(false);
        }
    }, [props.validationErrors]);

    useEffect(()=>{
        setSelectedTaxonomy(props.row.taxonomyName);
        setSelectedTaxonomyValues(props.row.taxonomyValues);
        setSelectedSingleTaxonomyValue(props.row.taxonomyValue);
        setFilterOptionType(props.row.filterOptionType);
        setIsOr(props.row.isOr);

        if (!props.row.taxonomyValue && (!props.row.taxonomyValues || !props.row.taxonomyValues.length)){
            setTaxonomyPickerSetTaxonomyObject(null);
        }
    }, [props.row, allTaxonomyValues]);

    
    useEffect(()=>{
        if (!selectedTaxonomy){
            setSelectedTaxonomyValues([]);
            setFilterOptionType(props.row.filterOptionType);
            return;
        }

        if (!allTaxonomyValues[selectedTaxonomy]){
            loadTaxonomyCounts(selectedTaxonomy, null, props.projectId).then(res=>{
                const items = res.data.result.map(x=>x.value);

                items.sort((a, b)=>{
                    if (a > b){
                        return 1;
                    }
                    if (a < b){
                        return -1;
                    }
                    return 0;
                });

                const newAllTaxonomyValues = {...allTaxonomyValues, [selectedTaxonomy]: items.map(x=>{
                    return ({value: x, label: x});
                })}

                setAllTaxonomyValues(newAllTaxonomyValues);
                
            }).catch(err=>{
                debugger;
                console.log(err);
            });
        }
    }, [selectedTaxonomy, props.row.filterOptionType, props.projectId]);


    useEffect(()=>{

        if (!taxonomyPickerSetTaxonomyObject){
            return;
        }

        loadTaxonomyCounts(taxonomyPickerSetTaxonomyObject.conceptName, [], props.projectId).then(res=>{

            const items = res.data.result.map(x=>x.value);

            if (taxonomyPickerSetTaxonomyObject.taxonomyValues){
                taxonomyPickerSetTaxonomyObject.taxonomyValues.forEach(keyword=>{
                    if (!items.some(x=>x == keyword)){
                        items.push(keyword);
                    }
                });
            }
            else {
                if (!items.some(x=>x == taxonomyPickerSetTaxonomyObject.keyword)){
                    items.push(taxonomyPickerSetTaxonomyObject.keyword); 
                }
            }

            items.sort((a, b)=>{
                if (a > b){
                    return 1;
                }
                if (a < b){
                    return -1;
                }
                return 0;
            });

            
            setAllTaxonomyValues({...allTaxonomyValues, [taxonomyPickerSetTaxonomyObject.conceptName]: items.map(x=>{
                return ({value: x, label: x});
            })});

            setSelectedTaxonomy(taxonomyPickerSetTaxonomyObject.conceptName);
            if (taxonomyPickerSetTaxonomyObject.taxonomyValues){
                setSelectedTaxonomyValues(taxonomyPickerSetTaxonomyObject.taxonomyValues);
            }
            else {            
                setSelectedTaxonomyValues([taxonomyPickerSetTaxonomyObject.keyword]);
            }
            

            const newRow = {...props.row, 
                taxonomyName: taxonomyPickerSetTaxonomyObject.conceptName, 
                taxonomyValues: taxonomyPickerSetTaxonomyObject.taxonomyValues || [taxonomyPickerSetTaxonomyObject.keyword]
            };
            props.handleRowChanged(props.group, newRow);

        }).catch(err=>{
            debugger;
            console.log(err);
        }).finally(()=>{
            //setTaxonomyPickerSetTaxonomyObject(null);
        });
                
        
    }, [taxonomyPickerSetTaxonomyObject]);



    const handleSearch = e=>{
        debugger;
        console.log(e);
    };
    
    const handleTaxonomyValuesChange = items=>{
        setShowValidation(false);
        setSelectedTaxonomyValues([...items]);
        setSelectedSingleTaxonomyValue('');

        const newRow = {...props.row, taxonomyValues: [...items], taxonomyValue: ''};
        props.handleRowChanged(props.group, newRow);
    };

    const handleSelectSingleValue = value=>{
        setShowValidation(false);
        setSelectedSingleTaxonomyValue(value);
        const newRow = {...props.row, taxonomyValue: value};
        props.handleRowChanged(props.group, newRow);
    }

    const handleSelectFilterTypeChanged = (newType)=>{
        setShowValidation(false);
        setFilterOptionType(newType);
        const newRow = {...props.row, filterOptionType: newType};
        props.handleRowChanged(props.group, newRow);
    };
    

    const handleTaxonomyChange = newTaxonomyName=>{
        debugger;
        setShowValidation(false);
        setTaxonomyPickerSetTaxonomyObject(null); 
        setSelectedTaxonomy(newTaxonomyName);

        // TODO: need to know if the newTaxonomyName is a date taxonomy
        // if (newTaxonomyName.toLowerCase().includes('date')){
        //     setDateFilterObject()
        // }
        setSelectedTaxonomyValues([]); 
        
        const newRow = {...props.row, 
            taxonomyName: newTaxonomyName, 
            taxonomyValues: [], 
            taxonomyValue: '', 
            filterOptionType: (newTaxonomyName || props.row.filterOptionType === 'None') ? props.row.filterOptionType : 'Any'
        };

        if (newTaxonomyName && newTaxonomyName.toLowerCase().includes('date')){
            // setDateFilterObject({});
            //newRow.dateFilterObject = dateFilterObject;
            newRow.dateFilterObject = {};
        }
        else {
            newRow.dateFilterObject = null;
        }

        props.handleRowChanged(props.group, newRow);
    };

    const handleIsOrChange = value=>{
        setShowValidation(false);
        setIsOr(value);

        const newRow = {...props.row, isOr: value};
        props.handleRowChanged(props.group, newRow);
    };

    const selectAll=(searchValue)=>{
        setShowValidation(false);
        const sv = searchValue.toLowerCase();
        let allOptions = allTaxonomyValues[selectedTaxonomy];
        if (searchValue){
            allOptions =  allOptions.filter(x=>x.value.toLowerCase().indexOf(sv) > -1);
        }

        const values = allOptions.map(x=>x.value);
        setSelectedTaxonomyValues(values);
        setSelectedSingleTaxonomyValue('');

        const newRow = {...props.row, taxonomyValues: [...values], taxonomyValue: ''};
        props.handleRowChanged(props.group, newRow);

        
    };

    const clearAll=()=>{
        setShowValidation(false);
        setSelectedTaxonomyValues([]);

        const newRow = {...props.row, taxonomyValues: []};
        props.handleRowChanged(props.group, newRow);
    };

    const handleSelectAllInTaxonomy = (taxonomyName, taxonomyValues)=>{
        setShowValidation(false);
        setTaxonomyPickerSetTaxonomyObject({conceptName: taxonomyName, taxonomyValues: taxonomyValues.map(x=>x.label)});
        setSelectedSingleTaxonomyValue('');

    };

    const handleSearchAcrossConceptsSetValue = (data)=>{
        setShowValidation(false);
        setTaxonomyPickerSetTaxonomyObject(data);
        setSelectedSingleTaxonomyValue('');

    };

    const handleDateFilterChanged=dateFilter=>{
        // TODO: need to understand where to save the update
        debugger;
        setShowValidation(false);
        // setDateFilterObject(dateFilter); // this will include the mode and other bits. We will use them later to build the query
        const newRow = {...props.row, dateFilterObject: {mode: dateFilter.mode, dates: dateFilter.dates, numberOfDays: dateFilter.numberOfDays}};
        props.handleRowChanged(props.group, newRow)
    };

    const taxonomyError = useMemo(()=>{
        const result = (rowErrors.find(e=>e.field === 'taxonomyName') || {}).message;
        return result;
    }, [rowErrors]);

    
    const taxonomyValuesError = useMemo(()=>{
        const result = (rowErrors.find(e=>e.field === 'taxonomyValues') || {}).message;
        return result;
    }, [rowErrors]);

    const taxonomyValueError = useMemo(()=>{
        const result = (rowErrors.find(e=>e.field === 'taxonomyValue') || {}).message;
        return result;
    }, [rowErrors]);

    const dateModeError = useMemo(()=>{
        const result = (rowErrors.find(e=>e.field === 'mode') || {}).message;
        return result;
    }, [rowErrors]);

    const numberOfDaysError = useMemo(()=>{
        const result = (rowErrors.find(e=>e.field === 'numberOfDays') || {}).message;
        return result;
    }, [rowErrors]);

    const fromError = useMemo(()=>{
        const result = (rowErrors.find(e=>e.field === 'from') || {}).message;
        return result;
    }, [rowErrors]);

    const toError = useMemo(()=>{
        const result = (rowErrors.find(e=>e.field === 'to') || {}).message;
        return result;
    }, [rowErrors]);


    const tooltipTitle = useMemo(()=>{
        
        if (selectedTaxonomyValues.length){
                return <ul>{selectedTaxonomyValues.map((x, ix)=><li key={`${props.row.rowId}-ttvalue-${ix}`}>{x}</li>)}</ul>;
        }
        else {
            return 'Select one or more values';
        }
        
    }, [selectedTaxonomyValues]);

    return (<>

{/* ===RowId: {JSON.stringify(props.row.rowId)}=== */}

{/* =============={JSON.stringify(rowErrors)} */}

    <div style={{display: 'flex', position: 'relative'}}>

        <div style={{width: '100%', marginRight: '200px'}}>

            <div className={style.filterContention} hidden={props.index === 0 ? true : false}>
                
                <span className={style['and-or-marker']}>And</span>
                <span>
                    
                    <ToggleSwitch size='small' 
                        handleChange={(e) => handleIsOrChange(e)} 
                        value={isOr} 
                    />
                </span>
                <span className={style['and-or-marker']}>Or</span>
            </div>

            <div style={{display: 'flex'}}>
                <div className={asStyle['long-input']} style={{width: '60%', minWidth: '220px', padding: '4px'}}>
                    <label className={style.feildlabel}>
                        <span className={style.labelBlock}>Select Taxonomy</span>
                    </label>
                    <InputField 
                        optionList={props.allTaxonomies.map(x=>({label: x, value: x}))}
                        showSearch={true}
                        name='select filter'
                        title='select filter'
                        allowClear={true}
                        inputType='selectElement'
                        value={selectedTaxonomy}
                        onChange={e=>handleTaxonomyChange(e)}
                        onSearch={e=>handleSearch(e)}
                    />
                    <span className={global.controlError}>
                        {taxonomyError}
                    </span>
                </div>


                <div style={{width: '300px',  padding: '4px'}}>

                    <label className={style.feildlabel}>
                        <span className={style.labelBlock}>or search across all Taxonomies</span>
                    </label>

                    <SearchAcrossConcepts3 
                        value={taxonomyPickerSetTaxonomyObject}
                        onSelectAll={(groupName, valuesInGroup) => handleSelectAllInTaxonomy(groupName, valuesInGroup)}
                        onSetValue={(data)=>handleSearchAcrossConceptsSetValue(data)}
                    />
                </div>

            </div>


            <div style={{display: 'flex', minWidth: '520px'}}>
                {!selectedTaxonomy ? <div className={asStyle['long-input']} style={{width: '60%', minWidth: '320px', padding: '4px'}}>
                    <label className={style.feildlabel}>
                        <span className={style.labelBlock}>Basic Search</span>
                    </label>
                    <InputField
                        style={{backgroundColor: 'white'}}
                        onChange={e=>handleSelectSingleValue(e.value)}
                        value={selectedSingleTaxonomyValue}
                        placeholder='Basic search: look up in all taxonomies'
                        allowClear={true}
                    />

                    <span className={global.controlError}>
                        {taxonomyValueError}
                    </span>
                    </div> : <>{selectedTaxonomy && selectedTaxonomy.toLowerCase().includes('date') && props.row.dateFilterObject ? 
                        <div style={{width: '100%'}}> 

                        
                        {/* ====={JSON.stringify(props.row.dateFilterObject)}====== */}

                            {/* This is the date picker block */}
                            {/* <TaxonomyFilterDate horizontal={true}/> */}
                            <AdvancedSearchRowControlDates dateFilterObject={props.row.dateFilterObject} handleDatesChanged={handleDateFilterChanged} 
                                dateModeError={dateModeError} numberOfDaysError={numberOfDaysError} fromError={fromError} toError={toError} 
                            />

                        </div> : 
                        <div className={asStyle['long-input']} style={{padding: '4px', width: '60%', minWidth: '320px'}}>
                            <TooltipContainer title={tooltipTitle} placement='right'>                    

                                <div className={style.facetSelect}>
                                    <label className={style.feildlabel}>
                                        <span className={style.labelBlock}>Values in taxonomy</span>
                                    </label>


                                    <InputField
                                        showSelectAllButtons={true} 
                                        autoClearSearchValue={false}
                                        showSearch={true}
                                        onSearch={props.onSearch}
                                        onChange={handleTaxonomyValuesChange}
                                        value={selectedTaxonomyValues}
                                        optionList={allTaxonomyValues[selectedTaxonomy] || []}
                                        optionFilterProp='label'
                                        placeholder={props.placeholder}
                                        inputType='selectElement'
                                        mode='multiple'
                                        htmlForName={'Select Value'}
                                        disabled={props.disabled}
                                        onBlur={props.onBlur}
                                        loading={props.loading}
                                        maxTagCount={props.maxTagCount}
                                        className={props.className}
                                        onPopupScroll={props.onPopupScroll}
                                        onClick={props.onClick}
                                        handleSelectAll={selectAll}
                                        handleClearAll={clearAll}
                                    />
                                    <span className={global.controlError}>
                                        {taxonomyValuesError}
                                    </span>
                                </div>
                            

                            </TooltipContainer>
                        </div>
                    }</>
                }

                {!((selectedTaxonomy || '').toLowerCase().includes('date')) ? <div style={{padding: '4px', width: '280px'}}>
                {/* Options for the select: do not show when selectedTaxonomy includes('date'). selectedTaxonomy can be 'undefined' after clearing  */}
                    <SelectOptions
                        value={filterOptionType}
                        onChange={(e) =>{
                            debugger;
                            handleSelectFilterTypeChanged(e);
                        }}
                        optionList={selectedTaxonomy  ? OPTIONS_FOR_TAXONOMY_SEARCH : OPTONS_FOR_FREE_SEARCH }
                    />
                </div> : null}
            </div>

        
        </div>
        <div style={{width: '200px', height: '100%', position: 'absolute', right: 0, top: 0, display: 'flex'}}>
            <div style={{margin: 'auto', display: 'flex'}}>
            <ButtonElement onClick={()=>props.addAdvancedSearchRow(props.group)} styleProps={{margin: '4px'}}>+ Filter</ButtonElement>

            <ButtonElement onClick={()=>props.deleteAdvancedSearchRow()}styleProps={{margin: '4px', visibility: props.rowCountInGroup > 1 ? 'visible' : 'hidden' }}>- Filter</ButtonElement>
            </div>
        </div>
    </div></>);
}