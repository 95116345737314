import React, { useEffect, useState } from "react";
import ConfirmationMessage from "../../../Components/ConfirmationMessage";
import ModalWindow from "../../../Components/ModalWindow";
export const DeleteUserGroupModal2 = props => {

    const [group, setGroup] = useState({});
    const [errors, setErrors] = useState([]);

    useEffect(()=>{
        setGroup(props.group);
    }, [props.group]);

    useEffect(()=>{
        setErrors(props.errors);
    }, [props.errors]);

    return (<ModalWindow 
            open={true}
            isNotClosable={true}
            title={false}
            footer={false}
        >
            <ConfirmationMessage
                cancelButtonLabel="Yes"
                confirmButtonLabel="No"
                loading={props.isLoading}
                heading={"Confirm Delete"}
                message={`Are you sure you want to delete this user group '${props.group.name}' ?`}
                onOk={() => {
                    props.onCancel();
                }}
                onCancel={() => {
                    props.onConfirm(group);
                }}
            />

        {errors.length ? <div>
            <ul>
                {errors.map((err, ixErr)=>{
                    return <li key={`error-${ixErr}`}>{err.error}</li>
                })}
            </ul>
        </div> : null}
    </ModalWindow>);
}
