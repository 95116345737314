import { getUsersProfilesPageSize } from "api/users";
import ContentBody from "Components/ContentBody";
import ErrorState from "Components/ErrorState";
import { useEffect, useState } from "react";
import global from "../../styles/globel/style.module.scss";
import style from "./style.module.scss";
import ButtonElement from "Components/ButtonElement";
import { DEFAULT_PAGE_COUNT } from "constants";
import Pagination from "Components/Pagination";
import TableElement from "Components/TableElement";
import { TABLE_COLUMNS } from "./TableColumns";
import { EditUserModal } from "./TableActionModal/EditUserModal";
import { DeleteUserModal } from "./TableActionModal/DeleteUserModal";
import { saveUserDetailsSetting } from "api/settings";
import { validateUserDetails } from "./helper";
import { notify } from "Components/Notification";
import { getUserGroups } from "api/userGroups";

export const Users2 = props => {

    const [users, setUsers] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_COUNT);
    const [columnKey, setColumnKey] = useState('id');
    const [sortField, setSortField] = useState('id');
    const [sortOrder, setSortOrder] = useState('asc');
    const [error, setError] = useState('');
    const [errorMessage, setErrorMessage] = useState('error');
    const [userGroupStore, setUserGroupStore] = useState([]);
    const [addModalVisible, setAddModalVisible] = useState(false);
    const [updateModalVisible, setUpdateModalVisible] = useState(false);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [user, setUser] = useState(null); // this is the user being added or edited

    const loadData = () => {
        getUsersProfilesPageSize({ pageNumber, pageSize, sortField, sortOrder }).then(res => {
            debugger;
            console.log(res);
            setUsers(res.data.result.result);
            setTotalCount(res.data.result.resultHits);
        });
    };

    useEffect(() => {
        loadData();
        getUserGroups().then(res => {
            setUserGroupStore(res.data.result);
        });
    }, [pageNumber, pageSize, sortField, sortOrder]);

    const handleSortingChange = (_, __, sorter) => {
        setColumnKey(sorter.columnKey);
        setSortField(sorter.field);
        setSortOrder(sorter.order === 'descend' ? 'desc' : 'asc');
    };


    const profileNameToId = (profilename) => {
        return profilename === 'superadmin' ? 3 : (profilename === 'analyst' ? 2 : 1);
    }

    const onAddSubmit = () => {
        debugger;
        let { firstname, lastname, profilename, emailid, groupId, isGroupAdmin } = user;
        let dismissModal = () => { setAddModalVisible(false) };
        const userProfile = profileNameToId(profilename);
        let userDetailsObject = { firstname, lastname, userProfile, emailid, isActive: true, groupId, isGroupAdmin };
        let errorMessage = validateUserDetails(userDetailsObject);
        if (!errorMessage.valid) {
            notify.error(errorMessage.message)
        }
        else {
            saveUserDetailsSetting(userDetailsObject).then(res => {
                debugger;
                console.log(res);
                notify.success('Successfully added user');
                loadData();
            }).catch((err) => {
                console.log(err);
                notify.error('Failed to add the user');
            }).finally(() => {
                dismissModal();
            });
        }
    }

    const handleAdd = () => {
        setUser({});
        setAddModalVisible(true);
    };

    const cancelAdd = () => {
        setAddModalVisible(false);
    };

    const handleUpdate = (record) => {
        setUpdateModalVisible(true);
        setUser(record);

    };

    const handleDelete = (record) => {
        setDeleteModalVisible(true);
        setUser(record);
    };

    const cancelUpdate = () => {
        setUpdateModalVisible(null);
    };

    const onDetailsChange = (field, value) => {
        debugger;
        const json = {};
        json[field] = value;
        setUser({ ...user, ...json });
    };

    const onUpdateSubmit = () => {
        const { firstname, lastname, profilename, emailid, id, groupId, isGroupAdmin } = user;
        const userProfile = profileNameToId(profilename);
        const userDetailsObject = { firstname, lastname, userProfile, emailid, id, isActive: true, groupId, isGroupAdmin };
        const errorMessage = validateUserDetails(userDetailsObject);

        if (!errorMessage.valid) {
            notify.error(errorMessage.message)
        }
        else {
            saveUserDetailsSetting(userDetailsObject).then(async () => {
                debugger;
                setUpdateModalVisible(null);
                setUser({});
                notify.success('Successfully updated user');

                if (props.userEmail === emailid) //if it's the current login user to update own details, do a refresh 
                {
                    await props.refreshWholeScreen();
                }

                loadData();
            });
        }
    };

    const cancelDelete = () => {
        setDeleteModalVisible(null);
    };

    const onConfirmDelete = () => {
        // call update IsActive to false

        saveUserDetailsSetting({ ...user, isActive: false }).then(() => {
            setDeleteModalVisible(null);
            loadData();
        });

    };

    const handlePagination = (pageNumber, pageSize) => {
        setPageNumber(pageNumber);
        setPageSize(pageSize);
    };



    const isSuperAdmin = props.userProfileId === 3;

    return (<>
        <ContentBody>
            {error ? (
                <ErrorState
                    customHeight={"600"}
                    content="ERROR"
                    type={"errorImage"}
                    errorMessage={props.errorMessage}
                />
            ) : (
                (users.length > 0) && (
                    <>
                        <div className={global.paginationWrapper}>
                            <div className={style.addButtonWrapper}>
                                <ButtonElement
                                    size={"small"}
                                    onClick={handleAdd}
                                    children={"Add User"}
                                />
                            </div>
                            <EditUserModal
                                title={"Add User"}
                                buttonTitle={"Add"}
                                onCancel={cancelAdd}
                                visible={addModalVisible}
                                onChange={onDetailsChange}
                                onSubmit={() => onAddSubmit()}
                                loader={props.addUserLoading}
                                user={user}
                                userGroupStore={userGroupStore}
                                isSuperAdmin={isSuperAdmin}
                                allowEditId={true}
                            />
                        </div>

                        <div className={global.paginationWrapper}>
                            <div className={global.searchresultCount}>
                                <span>{users.length > 0 ? "Showing " + users.length + " out of " + totalCount + " Results" : ""} </span>
                            </div>

                            <div className={global.searchresultPage}>
                                {totalCount > DEFAULT_PAGE_COUNT ? <UserPagination handlePagination={handlePagination} pageNumber={pageNumber} pageSize={pageSize} totalCount={totalCount} /> : null}
                            </div>
                        </div>

                        <TableElement
                            columns={TABLE_COLUMNS.COLUMNS({
                                sortedInfo: { columnKey, field: sortField, order: sortOrder },
                                handleUpdate: handleUpdate,
                                handleDelete: handleDelete,
                                currentProfileId: props.userEmail,
                                isSuperAdmin,
                            })}

                            data={users.map((item, key) => ({
                                ...item,
                                groupName: userGroupStore.find(g => g.id === item.groupId)?.name,
                                key,
                            }))}
                            onChange={handleSortingChange}
                            scrollY={"calc(100vh - 270px)"}
                            loading={props.userListloading}
                            resourceName="Users"
                            emptyText="No User"
                        />
                    </>
                )
            )}
        </ContentBody>

        <EditUserModal
            title={"Update User"}
            buttonTitle={"Update"}
            onCancel={cancelUpdate}
            visible={updateModalVisible}
            onChange={onDetailsChange}
            onSubmit={onUpdateSubmit}
            user={user}
            loader={props.updateUserLoading}
            userGroupStore={userGroupStore}
            isSuperAdmin={isSuperAdmin}
            emailId={props.userEmail}
            allowEditId={false}
        />
        <DeleteUserModal
            visible={deleteModalVisible}
            onCancel={cancelDelete}
            confirmDelete={onConfirmDelete}
            loader={props.deleteUserLoading}
        />
    </>);
};

const UserPagination = ({ pageSize, pageNumber, totalCount, handlePagination }) => {
    return (
        <Pagination
            pageSize={pageSize}
            current={pageNumber}
            total={totalCount}
            onChange={handlePagination}
            showSizeChanger={true}
            pageSizeOptions={[10, 20, 50, 100]}
        />
    );
};
