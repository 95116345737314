import { Tag } from "antd";

import style from "./style.module.scss";
import { TaxonomyFilterInnerChipItem } from "./TaxonomyFilterInnerChipItem";

export const TaxonomyFilterOuterChipItem = props=>{

    const handleDeleteInnerChip = value=>{
        debugger;
        props.handleInnerChipClosed(props.taxonomyKey, value);
    }

    const handleOuterChipClosed = ()=>{
        debugger;
        props.handleOuterChipClosed(props.taxonomyKey, props.isDateChip);
    };

    
    if (!props.taxonomyValues.length){
        return null;
    }
    
    return (<Tag closable={true} 
        onClose={handleOuterChipClosed} className={style.outerTags}>
        {props.taxonomyKey}
        {props.taxonomyValues.map((item, ix)=>{
            return <TaxonomyFilterInnerChipItem key={`inner-chip-${ix}`} canClose={!props.isDateChip} value={item.value} handleDeleteInnerChip={handleDeleteInnerChip}/>
        })}  
    </Tag>);
}