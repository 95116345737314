import global from "../../styles/globel/style.module.scss";
import SVGIcons from "../../Components/SVGIcons";
import style from "./style.module.scss";
import { AdvancedSearch2 } from "./AdvancedSearch2";

export const AdvanceSearchContainer2 = props=>{
    // this class to be used to replace dbiz stuff 

    const handleOnCancel = () => {
        props.setAdvancedSearchVisible(false);  
    };

    const searchStyle = `${global.searchHolder} ${global.searchToggle}`;

    return (<div className={searchStyle}>
        <div style={{minHeight: '3rem'}}>
            <span className={style.searchClose} onClick={() => handleOnCancel()}>
            <SVGIcons type={"SVG-closeIcon"}/>
          </span>
        </div>
       
        <AdvancedSearch2 allTaxonomies={props.allTaxonomies} projectId={props.projectId}
            dateFormat={props.dateFormat}
            onApply={props.onApply} 
            advancedSearchRows={props.advancedSearchRows} 
            setAdvancedSearchRows={props.setAdvancedSearchRows}
        />
    </div>);
};

