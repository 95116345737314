import InputField from "Components/InputField";
import style from '../TagBlock/style.module.scss';
import ButtonElement from "Components/ButtonElement";
import TooltipContainer from "Components/Tooltip";

export const TagRow = props=>{

    //return <>This is a tag row {props.index} {JSON.stringify(props.tag)} </>

    const tag = props.tag;
    const value=tag.value;

    const tagIsEmpty = !value || !value.length;
    return (<>
    <div className={style.tagItems}>
        <div className={style.tagItems__fields}>
            <InputField value={value} onChange={(e)=>props.handleTagChange(e.target.value)}/>
        </div>
    </div>

    <div className={style.tagButtonpane}>
        <div className={style.tagButtonpane__element}>
            <ButtonElement onClick={() => props.handleRemoveTag()} children={'-tag'} />
        </div>

        <TooltipContainer placement={'top'} title={tag.value ? '' : 'Enter a value for the tags' }>
            <div className={style.tagButtonpane__element} hidden={!props.addButtonVisible}>
                <ButtonElement onClick={()=>props.handleAddTag()} type='primary' disabled={tagIsEmpty} 
                    styleProps={tagIsEmpty ? {pointerEvents: 'none'} : {}}>
                    +tag
                </ButtonElement>
            </div>
        </TooltipContainer>
    </div>
    </>);
}